import {
  SETTINGS_FLUXUX_ACCEPT,
  SETTINGS_FLUXUX_LOAD,
  SETTINGS_FLUXUX_REJECT,
  SETTINGS_FLUXUX_REMOVE,
  SETTINGS_FLUXUX_RESET,
  SETTINGS_FLUXUX_STARTMONITORING
} from "./actions";
import { SETTINGS_FLUXUX_SET } from "./mutations";

export const fluxUxState = {
  Initial: "INITIAL",
  Accepted: "ACCEPTED",
  Rejected: "REJECTED"
};
export const activeFlux = null;
const removableFluxes = [
  {
    name: "COLABORATIVO_campaignUxState",
    gtagEventName: "CAMPAIGN_NEW_UX",
    fluxTitle: "criação / edição de campanha"
  },
  {
    name: "COLABORATIVO_coreUxState",
    gtagEventName: "CORE_NEW_UX",
    fluxTitle: "apresentação de colaborações"
  }
];

let FLUXUX_INTERVAL_ID = null;

const getCurrentTimestamp = () => Math.floor(Date.now() / 1000);

const state = {
  fluxUxSelectionState: fluxUxState.Rejected
};

const getters = {
  shouldAllowChangingPreferences: state =>
    state.fluxUxSelectionState === fluxUxState.Initial,
  useNewFluxUx: state =>
    process.env.VUE_APP_NEW_FLUX_UX_AVAILABLE === "1" &&
    state.fluxUxSelectionState === fluxUxState.Accepted,
  fluxUxStateKey: (_, __, ___, rootGetters) =>
    activeFlux?.name + rootGetters.userId,
  hasNewFlux: () => (activeFlux ? true : false),
  unusedFluxUxStateKey: (_, __, ___, rootGetters) =>
    removableFluxes.map(key => key.name + rootGetters.userId),
  fluxUxRejectionTsKey: (_, __, ___, rootGetters) =>
    `${activeFlux?.name}_rejection_ts${rootGetters.userId}`,
  unusedFluxUxRejectionTsKey: (_, __, ___, rootGetters) =>
    removableFluxes.map(key => `${key.name}_rejection_ts${rootGetters.userId}`)
};

const actions = {
  [SETTINGS_FLUXUX_LOAD]: ({ commit, getters }) => {
    commit(
      SETTINGS_FLUXUX_SET,
      localStorage.getItem(getters.fluxUxStateKey) || fluxUxState.Initial
    );
  },
  [SETTINGS_FLUXUX_ACCEPT]: ({ commit, getters }) => {
    localStorage.setItem(getters.fluxUxStateKey, fluxUxState.Accepted);
    localStorage.removeItem(getters.fluxUxRejectionTsKey);
    commit(SETTINGS_FLUXUX_SET, fluxUxState.Accepted);
  },
  [SETTINGS_FLUXUX_REJECT]: ({ commit, getters }) => {
    localStorage.setItem(getters.fluxUxStateKey, fluxUxState.Rejected);
    localStorage.setItem(getters.fluxUxRejectionTsKey, getCurrentTimestamp());
    commit(SETTINGS_FLUXUX_SET, fluxUxState.Rejected);
  },
  [SETTINGS_FLUXUX_RESET]: ({ commit, getters }) => {
    localStorage.setItem(getters.fluxUxStateKey, fluxUxState.Initial);
    localStorage.removeItem(getters.fluxUxRejectionTsKey);
    commit(SETTINGS_FLUXUX_SET, fluxUxState.Initial);
  },
  [SETTINGS_FLUXUX_REMOVE]: ({ getters }) => {
    for (let key of [
      ...getters.unusedFluxUxRejectionTsKey,
      ...getters.unusedFluxUxStateKey
    ]) {
      localStorage.removeItem(key);
    }
  },
  [SETTINGS_FLUXUX_STARTMONITORING]: ({ dispatch, state, getters }) => {
    if (FLUXUX_INTERVAL_ID) {
      return;
    }

    FLUXUX_INTERVAL_ID = setInterval(() => {
      const lastTime =
        Number(localStorage.getItem(getters.fluxUxRejectionTsKey)) || 0;
      const timeElapsed = getCurrentTimestamp() - lastTime;
      if (
        state.fluxUxSelectionState === fluxUxState.Rejected &&
        timeElapsed > 24 * 3600
      ) {
        dispatch(SETTINGS_FLUXUX_RESET);
      }
    }, 30000);
  }
};

const mutations = {
  [SETTINGS_FLUXUX_SET]: (state, newValue) => {
    state.fluxUxSelectionState = newValue;
  }
};

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};
