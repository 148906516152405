<template>
  <v-navigation-drawer
    v-model="drawerControl"
    app
    right
    temporary
    overlay-opacity="0.88"
    width="680"
  >
    <div class="modal full fixed">
      <div class="modal-header">
        <div class="title--max">
          Filtros
        </div>
        <div class="modal-header__actions">
          <ButtonSecondary
            icon="mdi-arrow-left"
            label="Voltar"
            class="mr-4"
            @click-handler="toggleDrawer(false)"
          ></ButtonSecondary>
          <ButtonPrimary
            icon="mdi-filter-variant-remove"
            label="Limpar"
            @click-handler="cleanFilters"
          ></ButtonPrimary>
        </div>
      </div>
      <div class="modal-content fullHeight">
        <div
          class="modal-content__item mb-12"
          v-for="(filter, index) in collaborationGroupFilters"
          :key="index"
        >
          <div class="title--small">
            {{ filter.title }}
          </div>
          <div class="input-item">
            <v-list>
              <v-list-item-group
                v-model="localFilters[filter.controller]"
                multiple
                color="primary"
              >
                <v-list-item
                  v-for="({ value, name, icon, classIcon }, i) in [
                    ...filter.principal,
                    ...filter.others
                  ]"
                  :key="i"
                  :value="value"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-icon>
                      <v-icon v-if="icon">{{ icon }}</v-icon>
                      <span v-else :class="classIcon" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ name }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox :input-value="active" color="primary" />
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </div>
        <div class="modal-content__item mb-12">
          <div class="title--small">Data / Hora</div>
          <div class="input-item">
            <v-date-picker
              v-model="localFilters.datePicker"
              range
              show-current
              full-width
              elevation="1"
              locale="pt-br"
            />
          </div>
          <div class="input-item mt-4">
            <v-text-field
              v-model="localFilters.initial_time"
              label="Hora inicial"
              outlined
              type="time"
              prepend-inner-icon="mdi-clock-time-four"
            />
          </div>
          <div class="input-item mt-2">
            <v-text-field
              v-model="localFilters.final_time"
              label="Hora final"
              outlined
              type="time"
              prepend-inner-icon="mdi-clock-time-four"
            />
          </div>
        </div>
        <div class="modal-content__item mb-12">
          <div class="title--small">Perfil do colaborador</div>
          <div class="input-item">
            <template
              v-for="({ label, icon, controller, dataType }, i) in [
                ...collaborationUsersFilters.principal,
                ...collaborationUsersFilters.others
              ]"
            >
              <v-text-field
                v-if="dataType === 'str'"
                :key="i"
                @change="localFilters[controller] = $event"
                color="graydark"
                class="mt-3"
                :label="label"
                outlined
                single-line
                hide-details
                :prepend-inner-icon="icon"
              />
              <v-text-field
                v-else-if="dataType === 'bool'"
                :key="i"
                color="graydark"
                class="mt-3"
                :label="label"
                outlined
                readonly
                disabled
                single-line
                hide-details
                :prepend-inner-icon="icon"
              >
                <template v-slot:append>
                  <v-switch
                    @change="localFilters[controller] = $event"
                    :value="localFilters[controller]"
                  />
                </template>
              </v-text-field>
            </template>
          </div>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";

import ButtonPrimary from "@/components/Commons/Buttons/ButtonPrimary";
import ButtonSecondary from "@/components/Commons/Buttons/ButtonSecondary";
import collaborationsFilters from "@/mixins/collaborations_filters";
import { UI_COLLABORATIONS_FILTERS_DRAWER_HANDLE } from "@/store/actions";
import {
  collaborationCollaboratorFilter,
  collaborationGroupFilters
} from "@/util/collaborations";

export default {
  components: {
    ButtonPrimary,
    ButtonSecondary
  },
  mixins: [collaborationsFilters],
  data() {
    return {
      collaborationGroupFilters: collaborationGroupFilters,
      collaborationUsersFilters: collaborationCollaboratorFilter
    };
  },
  computed: {
    ...mapState({
      isCollaborationsFiltersDrawerVisible: state =>
        state.ui.isCollaborationsFiltersDrawerVisible
    }),
    drawerControl: {
      get() {
        return this.isCollaborationsFiltersDrawerVisible;
      },
      set(value) {
        this.toggleDrawer(value);
      }
    }
  },
  methods: {
    toggleDrawer(value) {
      this.$store.dispatch(UI_COLLABORATIONS_FILTERS_DRAWER_HANDLE, value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/headers.scss";
@import "@/scss/theme.scss";
@import "@/scss/modal.scss";
@import "@/scss/form-inputs.scss";

.v-navigation-drawer {
  width: 500px !important;
  ::v-deep .filters-section {
    height: auto;
    .filters-items {
      align-items: initial;
      height: auto;
      flex-direction: column;
      justify-content: initial;
    }
  }
  .v-list-item {
    border-top: 1px solid rgba($color-gray-main, 0.2);
    &:last-child {
      border-bottom: 1px solid rgba($color-gray-main, 0.2);
    }
  }
  .v-item-group {
    flex-direction: column;
  }
  .v-icon {
    color: $color-gray-main;
    font-size: 16px;
  }
  .v-list-item__title {
    color: $color-gray-main;
    font-size: 14px !important;
  }
}
::v-deep .v-menu__content {
  min-width: initial !important;
}
</style>
