<template>
  <div class="gradient" :class="{ hasBanner: hasBanner }">
    <div class="gradient-item higher"></div>
    <div class="gradient-item high"></div>
    <div class="gradient-item normal"></div>
    <div class="gradient-item low"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hasBanner: true
    };
  },
  created() {
    window.addEventListener("scroll", () => {
      this.hasBanner = window.pageYOffset > 0 ? false : true;
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", () => {
      this.hasBanner = false;
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";

.v-banner + .v-main + .v-main .gradient.hasBanner {
  margin-top: 40px !important;
}

.gradient {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $color-background;

  .gradient-item {
    display: block;
    width: 100%;
    height: 16px;
    background-color: $color-primary;

    &.higher {
      opacity: 0.64;
    }
    &.high {
      opacity: 0.48;
    }
    &.normal {
      opacity: 0.32;
    }
    &.low {
      opacity: 0.16;
    }
  }
}
</style>
