import querystring from "querystring";
import storejs from "store";

import { loginAccountsConnect, loginRefresh } from "../gateways";
import router from "../router";
import store from "../store";
import {
  AUTH_CLEARUSER,
  AUTH_SAVEUSER,
  UI_SNACKBAR_SHOW
} from "../store/actions";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
const timeToRefreshToken = (expiration, now) => (expiration - now) * 0.5 * 1000;

class TokenManager {
  constructor() {
    this.handle = null;
  }

  refresh() {
    return new Promise((resolve, reject) => {
      loginRefresh()
        .then(r => r.data)
        .then(data => {
          const { access_token, user_data, expiration } = data;
          store.dispatch(AUTH_SAVEUSER, {
            access_token,
            user_data,
            expiration
          });
          resolve(data);
        })
        .catch(err => {
          store.dispatch(AUTH_CLEARUSER);
          store.dispatch(UI_SNACKBAR_SHOW, {
            message: "A sua sessão expirou. Por favor, efetue login novamente."
          });
          reject(err);
        });
    });
  }

  refreshCallback() {
    this.refresh()
      .then(({ expiration, server_time }) => {
        this.scheduleNextRefresh(expiration, server_time);
      })
      .catch(() => router.push("/login"));
  }

  cancelNextRefresh() {
    clearTimeout(this.handle);
  }

  scheduleNextRefresh(exp, serverTime) {
    const expiration = exp || storejs.get("userTokenExpiration");
    const serverTimeNow = serverTime || Math.floor(new Date().getTime() / 1000);
    let refreshTimeoutMs = timeToRefreshToken(expiration, serverTimeNow);
    if (refreshTimeoutMs < Number(process.env.VUE_APP_REFRESH_TIME_THRESHOLD)) {
      // eslint-disable-next-line no-console
      console.log(
        "** AVISO **",
        "usando fallback para refresh",
        "expiration",
        expiration,
        "serverTime",
        serverTime,
        "serverTimeNow",
        serverTimeNow,
        "threshold",
        process.env.VUE_APP_REFRESH_TIME_THRESHOLD,
        "fallback",
        process.env.VUE_APP_REFRESH_TIME_FALLBACK
      );
      refreshTimeoutMs = Number(process.env.VUE_APP_REFRESH_TIME_FALLBACK);
    }
    this.handle = setTimeout(this.refreshCallback.bind(this), refreshTimeoutMs);
  }
}
export const tokenManager = new TokenManager();

export const oidc = {
  logout: () => {
    const redirect = {
      client_id: process.env.VUE_APP_ACCOUNTSCONNECT_CLIENT_ID,
      redirect_uri: window.location.origin + "/login"
    };
    const url = `${
      process.env.VUE_APP_ACCOUNTSCONNECT_LOGOUT
    }?${querystring.stringify(redirect)}`;
    location.href = url;
  },
  redirect: () => {
    const login_url = process.env.VUE_APP_ACCOUNTSCONNECT_LOGIN;
    const state = makeid(36);
    const redirect = {
      response_type: "code",
      client_id: process.env.VUE_APP_ACCOUNTSCONNECT_CLIENT_ID,
      redirect_uri: window.location.origin + "/callback",
      scope: process.env.VUE_APP_ACCOUNTSCONNECT_SCOPES,
      state
    };
    storejs.set("state", state);
    const url = `${login_url}?${querystring.stringify(redirect)}`;
    location.href = url;
  },
  getUser: async () => {
    const { state, code } = router.currentRoute.query;
    const storeState = storejs.get("state");
    if (storeState !== state) {
      return {
        success: false,
        message: "Falha de autenticação: estado inválido"
      };
    }
    try {
      storejs.remove("state");
      const response = await loginAccountsConnect(code);
      const { data } = response;
      return { success: true, authData: data };
    } catch (e) {
      return { success: false, message: `Falha de autenticação: ${e.message}` };
    }
  }
};
