<template>
  <div class="container">
    <!-- Topo -->
    <div class="topo">
      <div class="title">
        <span>{{ isEditMode ? "Editar" : "Registrar" }}</span> Política de Mídia
      </div>
    </div>
    <div class="main">
      <v-form class="form" v-model="formValid" ref="form">
        <div class="item">
          <v-text-field
            placeholder="Informe o nome."
            primary
            v-model="formData.name"
            :rules="nameRules"
            counter
            maxlength="100"
            class="textfield"
            required
          >
            <template slot="label">
              Nome
              <span class="text-red">*</span>
            </template>
          </v-text-field>
        </div>

        <div class="item margin">
          <v-text-field
            placeholder="Informe a descrição."
            primary
            v-model="formData.description"
            :rules="descriptionRules"
            maxlength="320"
            counter
            class="textfield"
            required
          >
            <template slot="label">
              Descrição
              <span class="text-red">*</span>
            </template>
          </v-text-field>
        </div>

        <div class="item">
          <v-autocomplete
            v-model="formData.type"
            placeholder="Selecione o tipo de mídia"
            label="Tipo de Mídia"
            required
            :items="type_media"
            class="textfield"
            :disabled="isEditMode"
          />
        </div>
        <template v-if="formData.type === 'VIDEO'">
          <!-- ///////////////////////////[VIDEO CODEC]////////////////////////// -->
          <div class="item">
            <v-text-field
              placeholder="Informe o codec de vídeo"
              primary
              v-model="formData.video_codec"
              :rules="nameRules"
              counter
              maxlength="100"
              class="textfield"
              required
            >
              <template slot="label">
                Vídeo - Codec
                <span class="text-red">*</span>
              </template>
            </v-text-field>
          </div>

          <!-- ///////////////////////////[BITRATE MODE]////////////////////////// -->
          <div class="item">
            <v-autocomplete
              v-model="formData.video_bitrate_mode"
              placeholder="Selecione o bitrate mode do vídeo."
              required
              :items="bitrate_modes"
              class="textfield"
            >
              <template slot="label">
                Vídeo - Bitrate Mode
                <span class="text-red">*</span>
              </template>
            </v-autocomplete>
          </div>

          <!-- ///////////////////////////[VIDEO - BITRATE MIN E MAX]////////////////////////// -->
          <div class="item">
            <v-subheader
              >Bitrate de vídeo em Kbps [{{
                formData.video_bitrate_min / 1000
              }}
              - {{ formData.video_bitrate_max / 1000 }}]</v-subheader
            >
            <v-range-slider
              :value="[
                formData.video_bitrate_min / 1000,
                formData.video_bitrate_max / 1000
              ]"
              @input="
                formData.video_bitrate_min = $event[0] * 1000;
                formData.video_bitrate_max = $event[1] * 1000;
              "
              :min="0"
              :max="250000"
            />
          </div>

          <!-- ///////////////////////////[FRAMERATE MODE]////////////////////////// -->
          <div class="item">
            <v-autocomplete
              v-model="formData.video_framerate_mode"
              placeholder="Selecione o Framerate Mode do vídeo."
              required
              :items="framerate_modes"
              class="textfield"
            >
              <template slot="label">
                Vídeo - Framerate Mode
                <span class="text-red">*</span>
              </template>
            </v-autocomplete>
          </div>

          <!-- ///////////////////////////[FRAMERATE MIN E MAX]////////////////////////// -->
          <div class="item">
            <v-subheader
              >Framerate de vídeo em quadros/segundo [{{
                formData.video_framerate_min
              }}
              - {{ formData.video_framerate_max }}]</v-subheader
            >
            <v-range-slider
              :value="[
                formData.video_framerate_min,
                formData.video_framerate_max
              ]"
              @input="
                formData.video_framerate_min = $event[0];
                formData.video_framerate_max = $event[1];
              "
              :min="0"
              :max="500"
            />
          </div>

          <!-- ///////////////////////////[INTERLACING MODE]////////////////////////// -->
          <div class="item">
            <v-autocomplete
              v-model="formData.video_interlacing"
              placeholder="Selecione o modo de varredura do vídeo."
              required
              counter
              :items="interlacing_modes"
              class="textfield"
            >
              <template slot="label">
                Vídeo - Modo de Varredura
                <span class="text-red">*</span>
              </template>
            </v-autocomplete>
          </div>

          <!-- ///////////////////////////[VIDEO COLORIMETRAGEM]////////////////////////// -->
          <div class="item">
            <v-autocomplete
              v-model="formData.video_color_space"
              placeholder="Selecione a Colorimetragem do vídeo."
              required
              :items="colorSpace_modes"
              class="textfield"
            >
              <template slot="label">
                Vídeo - Colorimetragem
                <span class="text-red">*</span>
              </template>
            </v-autocomplete>
          </div>

          <!-- ///////////////////////////[VIDEO chroma subsampling]////////////////////////// -->
          <div class="item">
            <v-text-field
              placeholder="Exemplo: 4:2:2"
              primary
              v-model="formData.video_chroma_subsampling"
              :rules="nameRules"
              counter
              maxlength="10"
              class="textfield"
              required
            >
              <template slot="label">
                Vídeo - Chroma Subsampling
                <span class="text-red">*</span>
              </template>
            </v-text-field>
          </div>
          <!-- ///////////////////////////[VIDEO WIDTH & HEIGHT (min e max)]////////////////////////// -->
          <div class="item">
            <v-subheader
              >Largura do vídeo em pixels [{{ formData.video_width_min }} -
              {{ formData.video_width_max }}]</v-subheader
            >
            <v-range-slider
              :value="[formData.video_width_min, formData.video_width_max]"
              @input="
                formData.video_width_min = $event[0];
                formData.video_width_max = $event[1];
              "
              :min="0"
              :max="4096"
            />
            <!-- //////////////////////// HEIGHT ///////////////// -->

            <v-subheader
              >Altura do vídeo em pixels [{{ formData.video_height_min }} -
              {{ formData.video_height_max }}]</v-subheader
            >
            <v-range-slider
              :value="[formData.video_height_min, formData.video_height_max]"
              @input="
                formData.video_height_min = $event[0];
                formData.video_height_max = $event[1];
              "
              :min="0"
              :max="4096"
            />
          </div>
        </template>
        <!-- ///////////////////////////[AUDIO CODEC]////////////////////////// -->
        <div class="item">
          <v-text-field
            placeholder="Informe o codec do áudio."
            primary
            v-model="formData.audio_codec"
            counter
            maxlength="100"
            class="textfield"
          >
            <template slot="label">
              Áudio - Codec
            </template>
          </v-text-field>
        </div>

        <!-- ///////////////////////////[AUDIO - BITRATE MIN E MAX]////////////////////////// -->
        <div class="item">
          <v-subheader
            >Bitrate de aúdio em Kbps [{{ formData.audio_bitrate_min / 1000 }} -
            {{ formData.audio_bitrate_max / 1000 }}]</v-subheader
          >
          <v-range-slider
            :value="[
              formData.audio_bitrate_min / 1000,
              formData.audio_bitrate_max / 1000
            ]"
            @input="
              formData.audio_bitrate_min = $event[0] * 1000;
              formData.audio_bitrate_max = $event[1] * 1000;
            "
            :min="0"
            :max="25000"
          />
        </div>

        <!-- ///////////////////////////[AUDIO - BITDEPTH MIN E MAX]////////////////////////// -->
        <div class="item">
          <v-subheader
            >Resolução (bitdepth) de aúdio em bits [{{
              formData.audio_bitdepth_min
            }}
            - {{ formData.audio_bitdepth_max }}]</v-subheader
          >
          <v-range-slider
            :value="[formData.audio_bitdepth_min, formData.audio_bitdepth_max]"
            @input="
              formData.audio_bitdepth_min = $event[0];
              formData.audio_bitdepth_max = $event[1];
            "
            :min="0"
            :max="128"
          />
        </div>

        <!-- ///////////////////////////[AUDIO - SAMPLING RATE MIN E MAX]////////////////////////// -->
        <div class="item">
          <v-subheader
            >Taxa de amostragem de aúdio em Khz [{{
              formData.audio_sampling_rate_min / 1000
            }}
            - {{ formData.audio_sampling_rate_max / 1000 }}]</v-subheader
          >
          <v-range-slider
            :value="[
              formData.audio_sampling_rate_min / 1000,
              formData.audio_sampling_rate_max / 1000
            ]"
            @input="
              formData.audio_sampling_rate_min = $event[0] * 1000;
              formData.audio_sampling_rate_max = $event[1] * 1000;
            "
            :min="0"
            :max="1000"
          />
        </div>

        <!-- ///////////////////////////[AUDIO - FAIXAS MIN E MAX]////////////////////////// -->
        <div class="item">
          <v-subheader
            >Total de faixas de áudio [{{ formData.audio_num_tracks_min }} -
            {{ formData.audio_num_tracks_max }}]</v-subheader
          >
          <v-range-slider
            :value="[
              formData.audio_num_tracks_min,
              formData.audio_num_tracks_max
            ]"
            @input="
              formData.audio_num_tracks_min = $event[0];
              formData.audio_num_tracks_max = $event[1];
            "
            :min="0"
            :max="24"
          />
        </div>
        <!-- ///////////////////////////[AUDIO - CANAIS MIN E MAX]////////////////////////// -->
        <div class="item">
          <v-subheader
            >Total de canais de áudio por faixa [{{
              formData.audio_num_channels_min
            }}
            - {{ formData.audio_num_channels_max }}]</v-subheader
          >
          <v-range-slider
            :value="[
              formData.audio_num_channels_min,
              formData.audio_num_channels_max
            ]"
            @input="
              formData.audio_num_channels_min = $event[0];
              formData.audio_num_channels_max = $event[1];
            "
            :min="0"
            :max="24"
          />
        </div>

        <!-- ///////////////////////////////////////////////////// -->
      </v-form>
    </div>

    <!-- Footer -->
    <div class="footer">
      <div class="slot left"></div>

      <!-- Actions -->
      <div class="slot right">
        <v-btn
          large
          color="seccondary"
          class="mr-4"
          @click="$emit('cancelCreateClickedEvent')"
        >
          <v-icon>mdi-close</v-icon>
          Cancelar
        </v-btn>
        <v-btn
          large
          color="primary"
          @click="saveDataHandler"
          :disabled="!formValid && !isSavingData"
          :loading="isSavingData"
        >
          <v-icon>mdi-content-save</v-icon>
          Salvar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { omit, pick } from "ramda";

import {
  BITRATE_MODES,
  COLORSPACE_MODES,
  FRAMERATE_MODES,
  INTERLACING_MODES,
  MEDIA_TYPES
} from "../../../enums";
import { MediaPolicy } from "../../../gateways";
import { UI_SNACKBAR_SHOW } from "../../../store/actions";

const validateRequired = val =>
  Number.isInteger(val) || !!val || "Campo obrigatório.";
const validateNameLength = name =>
  (!!name && name.length <= 100) ||
  "O campo deve ter menos que 100 caracteres.";
const validateDescriptionlLength = description =>
  (!!description && description.length <= 320) ||
  "A descrição deve ter menos que 320 caracteres.";

export default {
  data() {
    return {
      formValid: false,
      formData: {
        id: -1,
        name: null,
        description: null,
        type: MEDIA_TYPES[0],
        video_codec: null,
        video_bitrate_max: 0,
        video_bitrate_min: 0,
        video_bitrate_mode: BITRATE_MODES[0],
        video_framerate_mode: FRAMERATE_MODES[0],
        video_framerate_max: 0,
        video_framerate_min: 0,
        video_interlacing: INTERLACING_MODES[0],
        video_color_space: COLORSPACE_MODES[0],
        video_chroma_subsampling: null,
        video_width_max: 0,
        video_width_min: 0,
        video_height_max: 0,
        video_height_min: 0,
        audio_codec: null,
        audio_bitrate_max: 0,
        audio_bitrate_min: 0,
        audio_bitdepth_max: 0,
        audio_bitdepth_min: 0,
        audio_sampling_rate_max: 0,
        audio_sampling_rate_min: 0,
        audio_num_channels_max: 0,
        audio_num_channels_min: 0,
        audio_num_tracks_max: 0,
        audio_num_tracks_min: 0
      },
      isEditMode: false,
      isSavingData: false,
      type_media: MEDIA_TYPES,
      bitrate_modes: BITRATE_MODES,
      framerate_modes: FRAMERATE_MODES,
      interlacing_modes: INTERLACING_MODES,
      colorSpace_modes: COLORSPACE_MODES
    };
  },
  props: ["editItem"],
  async created() {
    if (this.editItem) {
      const formData = pick(
        [
          "id",
          "name",
          "description",
          "type",
          "video_codec",
          "video_bitrate_max",
          "video_bitrate_min",
          "video_bitrate_mode",
          "video_framerate_mode",
          "video_framerate_max",
          "video_framerate_min",
          "video_interlacing",
          "video_color_space",
          "video_chroma_subsampling",
          "video_width_max",
          "video_width_min",
          "video_height_max",
          "video_height_min",
          "audio_codec",
          "audio_bitrate_max",
          "audio_bitrate_min",
          "audio_bitdepth_max",
          "audio_bitdepth_min",
          "audio_sampling_rate_max",
          "audio_sampling_rate_min",
          "audio_num_channels_max",
          "audio_num_channels_min",
          "audio_num_tracks_max",
          "audio_num_tracks_min"
        ],
        this.editItem
      );
      if (!formData.video_bitrate_mode) {
        formData.video_bitrate_mode = BITRATE_MODES[0];
      }
      if (!formData.video_framerate_mode) {
        formData.video_framerate_mode = FRAMERATE_MODES[0];
      }
      this.formData = formData;
      this.isEditMode = true;
    }
  },
  computed: {
    // numberRules() {
    //   return [
    //     validateRequired,
    //     v => v >= 0 || "O número deve ser maior que 0."
    //   ];
    // },
    nameRules() {
      return [validateRequired, validateNameLength];
    },
    descriptionRules() {
      return [validateRequired, validateDescriptionlLength];
    }
  },
  methods: {
    // revalidate() {
    //   this.$refs.form.validate();
    // },
    replaceIgnoreToEmpty(data, key) {
      data[key] === "IGNORAR" ? (data[key] = "") : null;
    },
    async saveDataHandler() {
      this.isSavingData = true;
      if (this.formData.type === "AUDIO") {
        this.setDefaultVideo();
      }
      const data = omit("id", this.formData);
      this.replaceIgnoreToEmpty(data, "video_bitrate_mode");
      this.replaceIgnoreToEmpty(data, "video_framerate_mode");
      if (this.isEditMode) {
        await this.editItemHandler(this.formData.id, data);
      } else {
        await this.registerItemHandler(data);
      }
      this.isSavingData = false;
    },
    async registerItemHandler(data) {
      try {
        await MediaPolicy.register(data);
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "O perfil de mídia foi registrado com sucesso!"
        });
        this.$emit("itemRegisteredEvent");
      } catch {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "Erro tentando registrar o perfil de mídia :(!"
        });
      }
    },
    async editItemHandler(id, data) {
      try {
        await MediaPolicy.edit(id, data);
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "O perfil de mídia foi editado com sucesso!"
        });
        this.$emit("itemEditedEvent");
      } catch {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "Erro tentando editar o perfil de mídia :(!"
        });
      }
    },
    async setDefaultVideo() {
      this.formData.video_codec = "";
      this.formData.video_bitrate_max = 0;
      this.formData.video_bitrate_min = 0;
      this.formData.video_bitrate_mode = BITRATE_MODES[0];
      this.formData.video_framerate_mode = FRAMERATE_MODES[0];
      this.formData.video_framerate_max = 0;
      this.formData.video_framerate_min = 0;
      this.formData.video_interlacing = INTERLACING_MODES[0];
      this.formData.video_color_space = COLORSPACE_MODES[0];
      this.formData.video_chroma_subsampling = "";
      this.formData.video_width_max = 0;
      this.formData.video_width_min = 0;
      this.formData.video_height_max = 0;
      this.formData.video_height_min = 0;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/theme.scss";
@import "../../../scss/navigation-drawer.scss";
</style>
