var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"label":"","ripple":"","color":_vm.filterColor}},on),[_vm._v(" "+_vm._s(_vm.chipTitle)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}]),model:{value:(_vm.isMenuVisible),callback:function ($$v) {_vm.isMenuVisible=$$v},expression:"isMenuVisible"}},[_c('div',{staticClass:"dropdown --small"},[_c('div',{staticClass:"dropdown-header"},[_vm._v("Escolha uma ou mais opções")]),_c('div',{staticClass:"dropdown-content"},[_c('div',{staticClass:"list-item"},[_c('div',{staticClass:"list-item__title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"list-item__toggle"},[_c('v-btn-toggle',{attrs:{"borderless":"","multiple":""},model:{value:(_vm.selectedItemsControl),callback:function ($$v) {_vm.selectedItemsControl=$$v},expression:"selectedItemsControl"}},_vm._l((_vm.items),function(ref,i){
var value = ref.value;
var name = ref.name;
var icon = ref.icon;
return _c('v-tooltip',{key:i,attrs:{"bottom":"","max-width":"80px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":value}},on),[_c('v-icon',[_vm._v(_vm._s(icon))])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(name))])])}),1)],1)]),_c('div',{staticClass:"list-item"},[_c('div',{staticClass:"list-item__title"},[_vm._v("Outros")]),_c('div',{staticClass:"list-item__toggle"},[_c('v-btn-toggle',{attrs:{"borderless":"","multiple":""},model:{value:(_vm.subBoxSelectedItemsControl),callback:function ($$v) {_vm.subBoxSelectedItemsControl=$$v},expression:"subBoxSelectedItemsControl"}},_vm._l((_vm.subBoxItems),function(ref,i){
var value = ref.value;
var name = ref.name;
var icon = ref.icon;
var classIcon = ref.classIcon;
return _c('v-tooltip',{key:i,attrs:{"bottom":"","max-width":"80px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":value}},on),[(icon)?_c('v-icon',[_vm._v(_vm._s(icon))]):_c('span',{class:classIcon})],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(name))])])}),1)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }