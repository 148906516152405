import { COLLABORATION_MEDIA_PLAYER_HANDLER } from "@/store/actions";

export default {
  created() {
    this.addOverlayClickedHandler();
  },
  methods: {
    addOverlayClickedHandler() {
      if (this.drawer) {
        document.querySelector(".v-overlay").addEventListener("click", () => {
          this.$store.dispatch(COLLABORATION_MEDIA_PLAYER_HANDLER);

          this.drawer = false;
        });
      }
    }
  }
};
