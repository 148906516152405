import { render, staticRenderFns } from "./CampaignCreateModeration.vue?vue&type=template&id=1fbf8583&scoped=true&"
import script from "./CampaignCreateModeration.vue?vue&type=script&lang=js&"
export * from "./CampaignCreateModeration.vue?vue&type=script&lang=js&"
import style0 from "./CampaignCreateModeration.vue?vue&type=style&index=0&id=1fbf8583&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fbf8583",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VAutocomplete,VCheckbox,VDialog,VIcon,VList,VListItem,VMenu,VSwitch})
