<template>
  <v-main>
    <transition name="component-fade" mode="out-in">
      <component
        :is="view"
        :editItem="editItemData"
        v-on:cancelClickedEvent="
          showList = true;
          editItemData = false;
          drawer = false;
        "
        v-on:createClickedEvent="
          showList = false;
          editItemData = false;
        "
        v-on:editClickedEvent="
          user => {
            showList = false;
            editItemData = user;
          }
        "
        v-on:cancelCreateClickedEvent="
          showList = true;
          editItemData = false;
        "
        v-on:itemRegisteredEvent="
          showList = true;
          editItemData = false;
        "
        v-on:itemEditedEvent="
          showList = true;
          editItemData = false;
        "
      />
    </transition>
  </v-main>
</template>

<script>
import tabMixin from "../../../mixins/tabMixin";
import ProductCreation from "./ProductCreation";
import ProductList from "./ProductList";

export default {
  mixins: [tabMixin],
  components: {
    ProductList,
    ProductCreation
  },
  computed: {
    view() {
      return this.showList ? ProductList : ProductCreation;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/tab.scss";
</style>
