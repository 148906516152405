<template>
  <Drawer
    @toggle-drawer="toggleDrawerInputSourceInstagram"
    @reset-enabler="resetEnabler"
    @hard-update="hardUpdate"
    @reset-changes="reset"
    :drawer="isInstagramDrawerVisible"
    title="configurar instagram"
    :isFullHeight="true"
    drawerName="instagram"
    formRef="instagram-drawer-form"
    :areAllFieldsEmpty="areAllFieldsEmpty"
  >
    <template v-slot:drawer-content>
      <div class="modal-content__item">
        <CardHeader
          title="Gerenciar Credenciais Instagram"
          subtitle="As credenciais Instagram estão vinculadas ao grupo escolhido nas informações básicas"
        ></CardHeader>
      </div>
      <div class="modal-content__item">
        <div class="input-item full">
          <div class="select-divisor"></div>
          <div class="select-placeholder">
            Escolha as credenciais cujas contas Instagram vão ter seu conteúdo
            seguido pela campanha
          </div>
          <AutocompleteGroup
            @update="updateSelectedCredentials($event)"
            :items="productInstagramCredentials"
            :itemText="item => `${item.name} ${item.id}`"
            :selectedItems="localSelectedCredentials"
            prependIcon="fab fa-instagram"
          ></AutocompleteGroup>
        </div>
      </div>
      <div class="modal-content__item mt-10">
        <CardHeader
          title="CONTAS INSTAGRAM"
          subtitle="Com as credenciais selecionadas acima, as seguintes contas Instagram terão seu conteúdo seguido."
        ></CardHeader>
      </div>

      <div
        class="modal-content__item"
        v-for="(currentCredential, index) in selectedInstagramCredentials"
        :key="index"
      >
        <br />
        <v-divider></v-divider>
        <br />
        <div class="input-item full">
          <CardHeader
            :title="`${index + 1}. ` + currentCredential.name"
            :subtitle="currentCredential.description"
          ></CardHeader>
          <div class="modal-content__item">
            <v-chip
              v-for="(account, index2) in currentCredential.public_accounts"
              :close="isAccountAllowed(account)"
              @click:close="handleAccountClick(account)"
              :key="index2"
              :color="isAccountAllowed(account) ? 'primary' : 'grey darken-1'"
              :title="account.name"
              @click="handleAccountClick(account)"
              class="mr-1"
            >
              <v-avatar left>
                <v-img :src="account.profile_picture_url"> </v-img>
              </v-avatar>
              <strong
                :class="
                  !isAccountAllowed(account)
                    ? 'text-decoration-line-through'
                    : ''
                "
                >{{ account.username }}</strong
              >
            </v-chip>
            <v-chip
              color="primary"
              class="mr-1"
              @click="toggleRegisterUserField(index)"
            >
              <strong>{{
                indexToShowRegisterField === index ? "-" : "+"
              }}</strong>
            </v-chip>
          </div>
          <div
            class="input-item full"
            v-show="indexToShowRegisterField === index"
          >
            <v-text-field
              v-model="username2find"
              required
              maxlength="255"
              color="graydark"
              label="Usuário do Instagram"
              placeholder="Digite o nome de um usuário do instagram."
              outlined
              prepend-inner-icon="mdi-label"
              :disabled="loading"
            />
            <v-btn
              large
              color="primary"
              @click="findInstagramUser(currentCredential.id)"
              :disabled="username2find === ''"
              :loading="loading"
            >
              <v-icon>person_search</v-icon>Adicionar usuário
            </v-btn>
          </div>
        </div>
      </div>
      <br />

      <div class="modal-content__item mt-10">
        <CardHeader
          title="HASHTAGS INSTAGRAM"
          subtitle="Com as credenciais selecionadas acima, as seguintes hashtags Instagram terão seu conteúdo seguido."
        ></CardHeader>
      </div>

      <div
        class="modal-content__item"
        v-for="(currentCredential, index) in selectedInstagramCredentials"
        :key="`hashtag-${index}`"
      >
        <br />
        <v-divider></v-divider>
        <br />
        <div class="input-item full">
          <CardHeader
            :title="`${index + 1}. ` + currentCredential.name"
            :subtitle="currentCredential.description"
          ></CardHeader>
          <div class="modal-content__item">
            <span
              v-for="(account, index2) in currentCredential.hashtags"
              :key="`hashtag-${index2}`"
            >
              <v-chip
                v-if="!account.kill"
                :close="isHashtagAllowed(account)"
                @click:close="handleHashtagClick(account)"
                :color="isHashtagAllowed(account) ? 'primary' : 'grey darken-1'"
                :title="account.name"
                @click="handleHashtagClick(account)"
                class="mr-1"
              >
                <strong
                  :class="
                    !isHashtagAllowed(account)
                      ? 'text-decoration-line-through'
                      : ''
                  "
                  >{{ `#${account.name}` }}</strong
                >
              </v-chip>
            </span>
          </div>
        </div>
      </div>
    </template>
  </Drawer>
</template>

<script>
import { mapState } from "vuex";

import AutocompleteGroup from "@/components/Commons/AutocompleteGroup";
import CardHeader from "@/components/Commons/CardHeader";
import Drawer from "@/components/Commons/Dialogs/Drawer";
import { UI_INSTAGRAM_DRAWER_HANDLE } from "@/store/actions";
import {
  CC_INSTAGRAM_ADD_NEW_USER_ON_CREDENTIAL,
  CC_INSTAGRAM_GET_INSTAGRAM_CREDENTIALS_BY_PRODUCT_ID
} from "@/store/actions";
import { updateCampaignItem } from "@/util/campaign";

export default {
  name: "InstagramDrawer",
  components: {
    AutocompleteGroup,
    CardHeader,
    Drawer
  },
  data() {
    return {
      firstProductIdChange: true,
      productInstagramCredentials: [],
      localInstagramConfig: {},
      allowedAccounts: [],
      allowedHashtags: [],
      localSelectedCredentials: [],
      indexToShowRegisterField: null,
      username2find: "",
      loading: false
    };
  },
  mounted() {
    this.copyInstagramCopyToLocal();
  },

  computed: {
    ...mapState({
      formData: state => state.listCampaign.createOrEditCampaignParams.formData,
      isInstagramDrawerVisible: state => state.ui.isInstagramDrawerVisible,
      instagramConfig: state =>
        state.listCampaign.createOrEditCampaignParams.formData
          .instagram_configuration
    }),
    currentProductId() {
      return this.formData.product_id;
    },
    areAllFieldsEmpty() {
      return this.localSelectedCredentials?.length === 0;
    },
    selectedInstagramCredentials() {
      let selectedCreds = this.productInstagramCredentials.filter(item =>
        this.localSelectedCredentials.includes(item.id)
      );
      return selectedCreds;
    }
  },
  watch: {
    instagramConfig: {
      handler() {
        this.copyInstagramCopyToLocal();
      },
      deep: true
    },
    async currentProductId(currentProductId) {
      await this.refreshInstagramCredential(currentProductId);
      if (!this.firstProductIdChange) {
        this.updateSelectedCredentials([]);
        this.resetEnabler(false);
        this.hardUpdate();
      } else {
        this.firstProductIdChange = false;
      }
    }
  },
  methods: {
    async refreshInstagramCredential(productId) {
      this.productInstagramCredentials = await this.$store.dispatch(
        CC_INSTAGRAM_GET_INSTAGRAM_CREDENTIALS_BY_PRODUCT_ID,
        productId
      );
    },
    async findInstagramUser(instagramCredentialId) {
      this.loading = true;
      try {
        const publicAccount = await this.$store.dispatch(
          CC_INSTAGRAM_ADD_NEW_USER_ON_CREDENTIAL,
          {
            instagramCredentialId,
            username: this.username2find
          }
        );
        if (publicAccount) {
          this.username2find = "";
          this.allowedAccounts = [
            ...this.allowedAccounts,
            ...publicAccount.user_id
          ];
          await this.refreshInstagramCredential(this.currentProductId);
        }
      } finally {
        this.loading = false;
      }
    },
    toggleRegisterUserField(index) {
      if (this.indexToShowRegisterField !== index) {
        this.indexToShowRegisterField = index;
      } else {
        this.indexToShowRegisterField = null;
      }
      this.username2find = "";
    },
    copyInstagramCopyToLocal() {
      this.localInstagramConfig = { ...this.instagramConfig };
      this.localSelectedCredentials =
        this.instagramConfig.instagram_credentials?.map(i => i.id) || [];
      this.populateAllowedListsFromConfig(this.localInstagramConfig);
    },
    populateAllowedListsFromConfig(newConfig) {
      this.allowedAccounts = [];
      this.allowedHashtags = [];
      newConfig?.instagram_credentials?.forEach(cred => {
        const newAccounts = cred.public_account_allows || [];
        const newHashtags = cred.hashtag_ids || [];
        this.allowedAccounts = [...this.allowedAccounts, ...newAccounts];
        this.allowedHashtags = [...this.allowedHashtags, ...newHashtags];
      }, this);
    },
    repopulateAllAllowedLists() {
      this.localSelectedCredentials?.forEach(cred_id => {
        const completeCredential = this.productInstagramCredentials.filter(
          i => i.id === cred_id
        )[0];
        this.allowedAccounts = [
          ...new Set([
            ...this.allowedAccounts,
            ...completeCredential?.public_accounts.map(i => i.user_id)
          ])
        ];

        const validHashtags = completeCredential?.hashtags.filter(i => !i.kill);
        this.allowedHashtags = [
          ...new Set([...this.allowedHashtags, ...validHashtags.map(i => i.id)])
        ];
      }, this);
    },
    isAccountAllowed(item) {
      return this.allowedAccounts.includes(item.user_id);
    },
    isHashtagAllowed(item) {
      return this.allowedHashtags.includes(item.id);
    },
    handleAccountClick(item) {
      if (this.isAccountAllowed(item)) {
        this.allowedAccounts = this.allowedAccounts.filter(
          el => el !== item.user_id
        );
      } else {
        this.allowedAccounts.push(item.user_id);
      }
    },
    handleHashtagClick(item) {
      if (this.isHashtagAllowed(item)) {
        this.allowedHashtags = this.allowedHashtags.filter(
          el => el !== item.id
        );
      } else {
        this.allowedHashtags.push(item.id);
      }
    },
    saveAllowedLists() {
      let newInstagramCredentials = [];
      this.productInstagramCredentials.forEach(currentCred => {
        let newCred = {
          id: currentCred.id,
          public_account_allows: [],
          hashtag_ids: []
        };
        // process all account ids
        currentCred.public_accounts.forEach(currentAccount => {
          if (this.isAccountAllowed(currentAccount)) {
            newCred.public_account_allows.push(currentAccount.user_id);
          }
        }, this);
        // process all hashtags
        currentCred.hashtags.forEach(currentHashtag => {
          if (this.isHashtagAllowed(currentHashtag)) {
            newCred.hashtag_ids.push(currentHashtag.id);
          }
        }, this);

        // delete empty lists and credentials
        if (newCred.public_account_allows.length === 0)
          delete newCred.public_account_allows;
        if (newCred.hashtag_ids.length === 0) delete newCred.hashtag_ids;
        if (newCred.public_account_allows || newCred.hashtag_ids)
          newInstagramCredentials.push(newCred);
      }, this);
      this.localInstagramConfig.instagram_credentials = newInstagramCredentials;
    },
    toggleDrawerInputSourceInstagram(value) {
      this.$store.dispatch(UI_INSTAGRAM_DRAWER_HANDLE, value ? value : false);
    },
    updateSelectedCredentials(selectedInstagramCredentialIds = []) {
      this.localSelectedCredentials = [...selectedInstagramCredentialIds];

      this.repopulateAllAllowedLists();
    },
    hardUpdate() {
      this.saveAllowedLists();
      updateCampaignItem(
        {
          ...this.formData.instagram_configuration,
          ...this.localInstagramConfig
        },
        "instagram_configuration"
      );
      this.copyInstagramCopyToLocal();
    },
    resetEnabler(value) {
      this.localInstagramConfig.is_enabled = value;
    },
    reset() {
      this.copyInstagramCopyToLocal();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";
@import "@/scss/modal.scss";
@import "@/scss/form-inputs.scss";

::v-deep .v-list.v-select-list.v-sheet.theme--light {
  display: flex !important;
  .v-list__item {
    flex: auto;
  }
}
.modal-content__item {
  margin-bottom: 0 !important;
}
::v-deep .switch-item .v-select__selections {
  margin-top: 0 !important;
  .v-chip {
    background-color: $color-primary-chip !important;
  }
}
</style>
