var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.actionsDisabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"dropMenu"},'div',attrs,false),on),[_c('v-menu',{attrs:{"top":"","open-on-hover":"","content-class":"elevation-4","transition":"scale-transition","disabled":_vm.actionsDisabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" more_vert ")])]}}],null,true),model:{value:(_vm.isMenuActive),callback:function ($$v) {_vm.isMenuActive=$$v},expression:"isMenuActive"}},[_c('v-list',{attrs:{"min-width":"240"}},_vm._l((_vm.itemActions.filter(function (act) { return act.show; })),function(item,index){
var _obj;
return _c('v-list-item',{key:index,class:( _obj = { divider: item.divider }, _obj[item.class] = true, _obj ),attrs:{"link":""},on:{"click":function($event){$event.preventDefault();return item.action()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)],1)]}}])},[_c('span',[_vm._v("As ações são desabilitadas no modo de busca em múltiplas campanhas.")])])}
var staticRenderFns = []

export { render, staticRenderFns }