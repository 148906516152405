<template>
  <BaseCard>
    <CardHeader :title="headerTitle" :subtitle="headerSubtitle" />

    <div class="box">
      <div class="box-body">
        <div class="column">
          <template v-if="isAdmin">
            <div class="input-item half">
              <EnablerBox label="Limitar colaborações" icon="border_horizontal">
                <template slot="enabler">
                  <v-switch v-model="handleCollabToggleLimit" inset></v-switch>
                </template>
              </EnablerBox>
            </div>
            <div
              class="input-item half"
              :class="{ disabled: !handleCollabToggleLimit }"
            >
              <v-text-field
                ref="collaborations-limit"
                v-model="handleCollabLimitDisplay"
                :disabled="!hasLimit"
                :rules="[rules.minValue, rules.maxValue]"
                min="1"
                max="1000000"
                color="graydark"
                label="Definir limite"
                placeholder="O valor máximo é de 1 milhão"
                outlined
                type="number"
                prepend-inner-icon="mdi-label-outline"
                append-icon="mdi-unfold-more-horizontal"
              />
            </div>
            <div class="input-item half">
              <EnablerBox
                label="Receber notificação via e-mail"
                icon="mdi-mail"
              >
                <template slot="enabler">
                  <v-switch v-model="notification" inset></v-switch>
                </template>
              </EnablerBox>
            </div>
            <div class="input-item half">
              <v-combobox
                v-model="formData.tags"
                deletable-chips
                multiple
                outlined
                clearable
                class="combobox mb-0"
                label="Clique para adicionar tags"
                color="graydark"
                prepend-inner-icon="mdi-tag"
                append-icon
              >
                <template v-slot:selection="{ attrs, item, selected, parent }">
                  <v-chip
                    label
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click:close="parent.selectItem(item)"
                    >{{ item }}
                  </v-chip>
                </template>
              </v-combobox>
            </div>
            <div class="divisor"></div>
            <div class="title--small mb-1">Políticas de mídia</div>
            <div class="divisor"></div>
            <div class="box-body">
              <div class="column">
                <div class="input-item half">
                  <div class="select-divisor"></div>
                  <div class="select-placeholder">
                    Política de mídia de áudio
                  </div>
                  <AutocompleteGroup
                    @update="updateAudioPolicies($event)"
                    :items="availableAudioPolicies"
                    :selectedItems.sync="selectedAudioPolicies"
                    :itemText="item => item.name"
                    prependIcon="mdi-music-note"
                  />
                </div>
                <div class="input-item half">
                  <div class="select-divisor"></div>
                  <div class="select-placeholder">
                    Política de mídia de vídeo
                  </div>
                  <AutocompleteGroup
                    @update="updateVideoPolicies($event)"
                    :items="availableVideoPolicies"
                    :selectedItems.sync="selectedVideoPolicies"
                    :itemText="item => item.name"
                    prependIcon="movie"
                  />
                </div>
              </div>
            </div>
          </template>

          <div class="divisor"></div>
          <div class="title--small mb-1">Tempo limite de colaborações</div>
          <div class="subtitle--small">
            Colaborações do Uploader Desktop expiram em no máximo 1 mês.
          </div>
          <div class="divisor"></div>

          <div class="input-item full">
            <div class="select-divisor"></div>
            <div class="select-placeholder">
              Expiração de colaborações
            </div>
            <AutocompleteGroup
              @update="updateItem($event, 'collaboration_expiration')"
              :items="expirationValues"
              :multiple="false"
              :selectedItems.sync="defaultExpirationValue"
              :itemText="item => item.name"
              prependIcon="mdi-lock-clock"
            />
          </div>
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import AutocompleteGroup from "@/components/Commons/AutocompleteGroup";
import BaseCard from "@/components/Commons/BaseCard";
import CardHeader from "@/components/Commons/CardHeader";
import EnablerBox from "@/components/Commons/EnablerBox";
import { CollaborationExpirationOptions } from "@/enums";
import { updateCampaignItem } from "@/util/campaign";

export default {
  components: {
    BaseCard,
    CardHeader,
    EnablerBox,
    AutocompleteGroup
  },
  data() {
    return {
      rules: {
        minValue: value => value >= this.minAcceptedValue,
        maxValue: value => value <= 1000000
      },
      headerTitle: "Área administrativa",
      headerSubtitle: "Utilize os campos para configurações administrativas",
      selectedAudioPolicies: [],
      selectedVideoPolicies: [],
      collaborationLimitDefault: 200000,
      collaborationToggleLimitEnabled: false
    };
  },
  computed: {
    ...mapState({
      isAdmin: state => state.auth.user.is_superuser,
      formData: state => state.listCampaign.createOrEditCampaignParams.formData,
      isUploaderEnabled: state =>
        state.listCampaign.createOrEditCampaignParams.formData
          .uploader_configuration.is_enabled
    }),
    ...mapGetters({
      availableMediaPolicies: "createOrUpdateSortedMediaPolicies"
    }),
    minAcceptedValue() {
      return this.handleCollabToggleLimit ? 1 : 0;
    },
    mediaPolicies() {
      return this.formData.media_policies;
    },
    notification: {
      get() {
        return this.formData.notification_is_enabled;
      },
      set(value) {
        this.updateItem(value, "notification_is_enabled");
      }
    },
    expirationValues() {
      const expirationValues = Object.values(CollaborationExpirationOptions);
      if (this.isUploaderEnabled) {
        const monthIndex = expirationValues.findIndex(
          value => value.id === "BIMONTHLY"
        );
        return expirationValues.slice(0, monthIndex);
      }
      return expirationValues;
    },
    defaultExpirationValue() {
      return this.formData.collaboration_expiration;
    },
    availableAudioPolicies() {
      return this.availableMediaPolicies.filter(mp => mp.type === "AUDIO");
    },
    availableVideoPolicies() {
      return this.availableMediaPolicies.filter(mp => mp.type === "VIDEO");
    },
    handleCollabToggleLimit: {
      get() {
        return (
          !!this.formData.collaboration_limit ||
          this.collaborationToggleLimitEnabled
        );
      },
      set(value) {
        this.collaborationToggleLimitEnabled = value;
        if (!value) {
          this.handleCollabLimitDisplay = 0;
        } else {
          this.handleCollabLimitDisplay = this.collaborationLimitDefault;
        }
        this.$nextTick(() => {
          this.$refs["collaborations-limit"].validate();
        });
      }
    },
    hasLimit() {
      return (
        !!this.collaborationToggleLimitEnabled ||
        !!this.formData.collaboration_limit
      );
    },
    handleCollabLimitDisplay: {
      get() {
        return this.formData.collaboration_limit;
      },
      set(value) {
        const intValue = !value ? 0 : parseInt(value);
        this.updateItem(intValue, "collaboration_limit");
      }
    }
  },
  watch: {
    mediaPolicies: {
      immediate: true,
      handler() {
        const options = {};
        this.availableMediaPolicies.forEach(amp => {
          options[amp.id] = amp;
        });
        this.selectedAudioPolicies = this.mediaPolicies.filter(
          mpId => options[mpId]?.type === "AUDIO"
        );
        this.selectedVideoPolicies = this.mediaPolicies.filter(
          mpId => options[mpId]?.type === "VIDEO"
        );
      }
    }
  },
  methods: {
    updateItem(value, item) {
      updateCampaignItem(value, item);
    },
    updateAudioPolicies(value) {
      this.selectedAudioPolicies = value;
      this.updateMediaPolicies();
    },
    updateVideoPolicies(value) {
      this.selectedVideoPolicies = value;
      this.updateMediaPolicies();
    },
    updateMediaPolicies() {
      this.updateItem(
        [...this.selectedVideoPolicies, ...this.selectedAudioPolicies],
        "media_policies"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";
@import "@/scss/form-inputs.scss";

.box {
  font-family: $secondary-fontface;
  background-color: #fff;

  &.full {
    width: 100%;
  }

  .box-title {
    margin: 0 0 8px 0;
    font-size: 0.88em;
    font-weight: 600;
    color: $color-black-80;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  .box-subtitle {
    margin: 0 0 16px 0;
    font-size: 0.8em;
    font-weight: 400;
    color: $color-black-64;
    text-transform: capitalize;
    letter-spacing: 0.1em;
  }

  .divisor {
    flex: 1;
    height: 1px;
    margin: 16px 0;
    // background-color: $color-black-08;
  }

  .box-body {
    display: flex;

    ::v-deep .v-icon {
      font-size: 16px;
    }

    .column {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 0;

      ::v-deep .combobox .v-select__selections {
        margin-top: 0 !important;
        .v-chip {
          background-color: $color-primary-chip !important;
        }
      }

      .input-item .v-input,
      .input-item .enabler-box {
        margin-bottom: 24px;
      }

      .column {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 14px;
        @include for-size(phone-only) {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }
}
</style>
