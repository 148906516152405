<template>
  <div class="header">
    <div class="left">
      <div class="title">
        <span>{{ titleFirstWord }}</span
        >{{ restOfTheTitle }}
      </div>
      <div class="subtitle">{{ subtitle }}</div>
    </div>
    <div class="right" :class="{ 'no-content': !hasRightContent }">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardHeader",
  data() {
    return {};
  },
  props: {
    title: String,
    subtitle: String
  },
  computed: {
    titleFirstWord() {
      const [firstWord] = this.title.split(" ");
      return `${firstWord} `;
    },
    restOfTheTitle() {
      const [, ...otherWords] = this.title.split(" ");
      return otherWords.join(" ");
    },
    hasRightContent() {
      return !!this.$slots.right;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";

.header {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 0 0 24px 0;

  @include for-size(tablet-landscape-up) {
    align-items: flex-start;
    flex-direction: column;

    .v-btn.v-size--large {
      padding: 0 !important;
    }
  }

  .left,
  .right {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .left {
    flex-direction: column;
    align-items: flex-start;

    .title,
    .subtitle {
      font-family: $secondary-fontface !important;
      display: inline;
    }

    .title {
      line-height: 1.4rem !important;
      font-size: 16px !important;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 1.2px !important;

      span {
        font-weight: 600;
      }
    }

    .subtitle {
      font-size: 12px !important;
      opacity: 0.6;
      text-transform: lowercase;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  .right {
    flex-direction: row;
    justify-content: flex-end;

    &.no-content {
      display: none;
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
    }
  }
}
</style>
