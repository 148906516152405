<template>
  <div class="modal">
    <v-dialog v-model="dialog" persistent max-width="560">
      <div class="card-modal">
        <div class="icon" v-show="modal.icon">
          <v-icon>{{ modal.icon }}</v-icon>
        </div>
        <div class="title">
          <span>{{ modal.title.featured }} </span> {{ modal.title.text }}
        </div>
        <div class="message">
          <span>{{ modal.message.featured }}</span>
          {{ modal.message.text }}
        </div>
        <div class="image_container" v-if="modal.image">
          <v-img
            class="image_modal"
            v-show="modal.image.base64OrUrl"
            :src="modal.image.base64OrUrl"
            :width="modal.image.width"
            :height="modal.image.height"
          />
        </div>
        <v-form v-if="modal.form" class="pa-2">
          <template v-if="modal.form.type === 'email'">
            <SendEmailModalFields />
          </template>
        </v-form>
        <div class="action">
          <v-btn
            color="blue-grey"
            class="ma-2 btnModal"
            @click="handleCancel"
            elevation="0"
            text
            large
          >
            <v-icon dark left> arrow_back </v-icon>
            Voltar
          </v-btn>
          <v-btn
            v-show="!modal.onlyShowCancelBtn"
            class="ma-2 btnModal"
            @click="handleConfirm"
            :color="modal.action.color"
            large
          >
            <v-icon left dark> {{ modal.icon || modal.action.icon }} </v-icon>
            {{ modal.action.title }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import SendEmailModalFields from "@/components/SendEmailModalFields.vue";
import Bus from "@/util/EventBus";
const dataModalDefault = () => ({
  icon: "",
  title: {
    featured: "",
    text: ""
  },
  message: {
    featured: "",
    text: ""
  },
  onlyShowCancelBtn: false,
  image: {
    base64OrUrl: null,
    width: 0,
    height: 0
  },
  action: {
    icon: "",
    color: "info"
  }
});

export default {
  name: "modal_confirmation",
  components: {
    SendEmailModalFields
  },
  data() {
    return {
      dialog: false,
      callback: null,
      modal: dataModalDefault()
    };
  },
  mounted() {
    Bus.$on("openModalConfirm", (data, callback) => {
      this.dialog = true;
      this.modal = { ...dataModalDefault(), ...data };
      this.callback = callback;
    });
  },
  methods: {
    handleCancel() {
      this.callback(false);
      this.modal = dataModalDefault();
      this.dialog = false;
    },
    handleConfirm() {
      this.callback(true);
      this.dialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.card-modal {
  width: 560px;
  min-height: 345px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 8px 24 rgba(#000000, 0.08);
  display: flex;
  flex-direction: column;
  .icon {
    height: 65px;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    .v-icon {
      font-size: 64px;
      text-align: center;
      font-weight: 400;
      color: rgb(207, 207, 207);
    }
  }
  .title {
    text-align: center;
    height: 35px;
    margin-top: 16px;
    font-family: "Exo", sans-serif !important;
    text-transform: uppercase;
    font-weight: 400;
    font-style: italic;
    font-size: 24px !important;
    span {
      font-weight: 600;
    }
  }
  .message {
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 60px;
    margin-top: 16px;
    font-family: "Exo", sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: 14px !important;
    padding: 0 40px;
    span {
      font-family: "Exo", sans-serif;
      font-weight: 600;
      font-style: italic;
    }
  }
  .image_container {
    text-align: center;
    .image_modal {
      display: inline-block;
    }
  }
  .action {
    height: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 0 40px;
    display: flex;
    justify-content: flex-end;
    font-family: "Exo", sans-serif !important;
  }
  .btnModal {
    text-transform: capitalize;
  }
}
</style>
