<template>
  <Drawer
    @toggle-drawer="toggleDrawerInputSourceForm"
    @reset-enabler="resetEnabler"
    @hard-update="handleHardUpdate"
    @reset-changes="reset"
    :drawer="isFormDrawerVisible"
    title="configurar formulário"
    :isFullHeight="true"
    drawerName="form"
    formRef="form-drawer-form"
    :areAllFieldsEmpty="areAllFieldsEmpty"
  >
    <template v-slot:drawer-content>
      <div class="modal-content__item border-16 mb-12">
        <v-tabs v-model="activeTab" color="graydark">
          <v-tab>BÁSICAS</v-tab>
          <v-tab>AVANÇADAS</v-tab>
        </v-tabs>
      </div>
      <template v-if="activeTab === 0">
        <div class="modal-content__item mb-12">
          <div class="title--small">
            LINK E PRÉVIA
          </div>
          <div class="input-item">
            <v-text-field outlined readonly :value="formLink">
              <template slot="append">
                <v-icon
                  class="ml-3 mr-3"
                  @click="copyLink"
                  :disabled="!isPreviewAvailable"
                  >mdi-content-copy</v-icon
                >
                <v-icon
                  class="mr-3"
                  @click="openLinkInNewTab"
                  :disabled="!isPreviewAvailable"
                  >mdi-open-in-new</v-icon
                >
              </template>
            </v-text-field>
          </div>
          <div class="input-item">
            <EnablerBox label="CANAL GLOBO" icon="v-icon icon-icon-canalglobo">
              <template slot="enabler">
                <v-switch
                  v-model="localFormConfig.is_canal_globo_enabled"
                  inset
                ></v-switch>
              </template>
            </EnablerBox>
          </div>
          <div class="input-item mt-2">
            <v-combobox
              @change="changeHashtags($event)"
              :value="localFormConfig.canal_globo_twitter_config.hashtags"
              :rules="[startWithAlphanumericOrUnderscoreRule]"
              deletable-chips
              multiple
              clearable
              outlined
              label="Canal globo twitter hashtags ex: bbb24"
              color="graydark"
              prepend-inner-icon="fab fa-twitter"
              append-icon
            >
              <template v-slot:selection="{ attrs, item, selected, parent }">
                <v-chip
                  label
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click:close="parent.selectItem(item)"
                  >{{ item }}
                </v-chip>
              </template>
            </v-combobox>
          </div>
        </div>
        <div class="modal-content__item">
          <div class="title--small">
            PÁGINA DE DESPEDIDA
          </div>
          <div class="input-item mb-3">
            <v-textarea
              color="graydark"
              v-model.trim="localFormConfig.text"
              label="Parágrafo de agradecimento"
              :disabled="useCampaignDescription"
              outlined
              no-resize
              height="116px"
              prepend-inner-icon="mdi-comment-text"
              clearable
            />
          </div>
          <BaseCard>
            <div class="box">
              <div class="box-body">
                <div class="column">
                  <div class="input-item">
                    <v-text-field
                      color="graydark"
                      v-model.trim="localFormConfig.link_text"
                      label="Texto do botão primário"
                      outlined
                      prepend-inner-icon="mdi-alpha-a-box"
                      clearable
                    />
                  </div>
                  <div class="input-item">
                    <v-text-field
                      v-model.trim="localFormConfig.link"
                      color="graydark"
                      label="Link do botão primário"
                      outlined
                      prepend-inner-icon="mdi-link"
                      clearable
                    />
                  </div>
                </div>
              </div></div
          ></BaseCard>
        </div>
      </template>
      <template v-else>
        <div class="modal-content__item">
          <div class="title--small">
            CONFIGURAÇÕES DE CLIENTES
          </div>
          <div class="input-item mb-3">
            <v-text-field
              v-model.trim="localFormConfig.client_id"
              :rules="globoIdConnectRules"
              outlined
              label="GloboID Connect: informe o client id para usar seu próprio serviço"
              prepend-inner-icon="mdi-memory"
              clearable
            />
          </div>
          <div class="input-item">
            <v-text-field
              v-model.trim="localFormConfig.cadun_id"
              :rules="cadunIdRules"
              outlined
              label="GloboID CADUN: Informe o id do CADUN para usar seu próprio serviço"
              prepend-inner-icon="mdi-memory"
              clearable
            />
          </div>
        </div>
        <div class="modal-content__item">
          <div class="title--small mb-1">
            TERMOS
          </div>
          <BaseCard>
            <div class="box">
              <div class="box-body">
                <ButtonLink
                  @click-handler="toggleTooltipDialog(true)"
                  icon="mdi-book-open-outline"
                  label="Termo de participação"
                ></ButtonLink>
                <!-- <div class="column"> -->
                <!-- <div class="input-item">
                    <v-text-field
                      color="graydark"
                      readonly
                      value="Termo de Aceite"
                      outlined
                      hide-details
                      prepend-inner-icon="mdi-gavel"
                    />
                    <v-switch inset @change="updateFormAcceptTerm" />
                  </div> -->
                <div class="input-item">
                  <EnablerBox label="Termo de Participação" icon="mdi-gavel">
                    <template slot="enabler">
                      <v-switch
                        v-model="localFormConfig.agreement"
                        inset
                      ></v-switch>
                    </template>
                  </EnablerBox>
                </div>
              </div>
            </div>
            <!-- </div> -->
          </BaseCard>
          <div class="input-item mb-3">
            <v-file-input
              color="graydark"
              :accept="formAcceptedExtensions.join(', ')"
              :rules="fileRules"
              v-model="disclaimerFile"
              prepend-inner-icon="mdi-paperclip"
              prepend-icon=""
              label="Clique para enviar um termo de aceite personalizado..."
              outlined
            >
              <template v-slot:append>
                <v-tooltip bottom v-if="isEditMode">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      @mouseenter="on.mouseenter"
                      @mouseleave="on.mouseleave"
                      @click="downloadDisclaimer"
                      >mdi-download</v-icon
                    >
                  </template>
                  <span>Baixar termo de aceite</span>
                </v-tooltip>
              </template>
            </v-file-input>
          </div>
        </div>
      </template>
      <Dialog
        :dialog="isTooltipDialogVisible"
        title="Termo de participação"
        description="A minha inscrição para participação no programa é voluntária, 
        e estou ciente de que a Globo poderá utilizar qualquer método de seleção dos participantes 
        de quaisquer programas, a seu exclusivo critério, não se limitando aos inscritos. Li, 
        entendi a mensagem e desejo prosseguir."
      >
        <template v-slot:modal-actions>
          <ButtonSecondary
            icon="mdi-arrow-left"
            label="Voltar"
            @click-handler="toggleTooltipDialog(false)"
          ></ButtonSecondary>
        </template>
      </Dialog>
    </template>
  </Drawer>
</template>

<script>
import { mapState } from "vuex";

import BaseCard from "@/components/Commons/BaseCard";
import ButtonLink from "@/components/Commons/Buttons/ButtonLink";
import ButtonSecondary from "@/components/Commons/Buttons/ButtonSecondary";
import Dialog from "@/components/Commons/Dialogs/Dialog";
import Drawer from "@/components/Commons/Dialogs/Drawer";
import EnablerBox from "@/components/Commons/EnablerBox";
import { UI_FORM_DRAWER_HANDLE } from "@/store/actions";
import { fileToDataUrl } from "@/util";
import { updateCampaignItem } from "@/util/campaign";

export default {
  name: "FormDrawer",
  components: {
    BaseCard,
    ButtonLink,
    ButtonSecondary,
    Dialog,
    Drawer,
    EnablerBox
  },
  props: {
    isEditMode: Boolean
  },
  data() {
    return {
      activeTab: 0,
      formAcceptedExtensions: [".pdf", ".txt", ".jpeg", ".jpg", ".png"],
      fileSizeLimit: 2000000,
      useCampaignDescription: false,
      fileRules: [
        value =>
          !value ||
          value.size < this.fileSizeLimit ||
          "Apenas arquivos menores que 3MB são aceitos."
      ],
      localFile: null,
      localFormConfig: {
        canal_globo_twitter_config: []
      },
      startWithAlphanumericOrUnderscoreRule: v =>
        v.every(tag => /^\w*[0-9a-zA-Z]+\w*[0-9a-zA-Z]$/.test(tag)) ||
        "Cada hashtag deve começar com letra, n'umero ou '_'",
      isTooltipDialogVisible: false
    };
  },
  computed: {
    ...mapState({
      formData: state => state.listCampaign.createOrEditCampaignParams.formData,
      isFormDrawerVisible: state => state.ui.isFormDrawerVisible
    }),
    formLink() {
      return this.localFormConfig.is_canal_globo_enabled
        ? `${process.env.VUE_APP_COLABORATIVO_CANAL_GLOBO_URI}${this.formData.id}`
        : `${process.env.VUE_APP_COLABORATIVO_FORM_URI}${this.formData.id}`;
    },
    formConfig() {
      return this.formData.form_configuration;
    },
    formDataFile() {
      return this.formData.disclaimer;
    },
    disclaimerFile: {
      get() {
        if (!this.formData.disclaimer || !this.formData.disclaimer.url) {
          return null;
        }
        const { filename } = this.formData.disclaimer;
        return new File(new Uint8Array(0), filename);
      },
      set(val) {
        if (!this.canUpdateFile(val)) {
          return;
        }
        if (!val) {
          this.localFile = {
            filename: null,
            url: null
          };
        } else if (val.startsWith && val.startsWith("http")) {
          this.localFile = {
            filename: val.split("/").pop(),
            url: val
          };
        } else if (typeof val === "object") {
          fileToDataUrl(val).then(dataUrl => {
            this.localFile = {
              filename: val.name,
              url: dataUrl
            };
          });
        } else {
          throw new Error(`unknown disclaimerFile set case with val ${val}`);
        }
      }
    },
    isPreviewAvailable() {
      return this.isEditMode;
    },
    areAllFieldsEmpty() {
      return (
        !this.localFormConfig.text &&
        !this.localFormConfig.link_text &&
        !this.localFormConfig.link &&
        !this.localFormConfig.client_id &&
        !this.localFormConfig.cadun_id &&
        !this.localFormConfig.agreement &&
        !this.localFormConfig.is_canal_globo_enabled &&
        !this.localFile?.filename &&
        !this.localFile?.url
      );
    },

    globoIdConnectRules() {
      return [
        val =>
          !val
            ? true
            : !/\s/.test(val) ||
              "Não inclua valores contendo espaço em branco neste campo."
      ];
    },

    cadunIdRules() {
      return [
        val =>
          !val
            ? true
            : /^[\d]*$/.test(val) || "Este ID deve conter apenas dígitos."
      ];
    }
  },
  watch: {
    formDataFile: {
      handler() {
        this.localFile = { ...this.formDataFile };
      },
      deep: true
    },
    formConfig: {
      handler() {
        this.localFormConfig = { ...this.formConfig };
      },
      deep: true
    }
  },
  methods: {
    changeHashtags(hashtags) {
      this.localFormConfig.canal_globo_twitter_config.hashtags = hashtags;
    },
    downloadDisclaimer() {
      window.open(this.formData.disclaimer.url, "_top");
    },
    toggleDrawerInputSourceForm(value) {
      this.$store.dispatch(UI_FORM_DRAWER_HANDLE, value ? value : false);
    },
    copyLink() {
      navigator.clipboard.writeText(this.formLink);
    },
    openLinkInNewTab() {
      window.open(this.formLink);
    },
    canUpdateFile(file) {
      if (file?.size > this.fileSizeLimit) {
        return false;
      }
      return true;
    },
    hardUpdate(value, field) {
      updateCampaignItem(value, field);
    },
    handleHardUpdate() {
      this.hardUpdate(
        { ...this.formData.form_configuration, ...this.localFormConfig },
        "form_configuration"
      );
      this.hardUpdate(this.localFile, "disclaimer");
    },
    resetEnabler(value) {
      this.localFormConfig.is_enabled = value;
    },
    toggleTooltipDialog(value) {
      this.isTooltipDialogVisible = value;
    },
    reset() {
      this.localFormConfig = this.formConfig;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/theme.scss";
@import "@/scss/modal.scss";
@import "@/scss/form-inputs.scss";

.border-16 {
  border-bottom: 1px solid $color-black-16;
}

.mdi-content-copy::before,
::v-deep .mdi-close::before,
.mdi-download::before,
.mdi-open-in-new::before {
  font-size: 16px;
}

::v-deep .mdi-close {
  margin-right: 12px;
}
::v-deep .mdi-download {
  height: 24px;
}

::v-deep .v-inout__append-inner {
  height: 32px;
}

::v-deep .base-card {
  box-shadow: none !important;
  margin: 0 0 16px;
  padding: 0;

  .column {
    grid-template-columns: repeat(2, 1fr);
  }

  .v-btn.link {
    padding-left: 0 !important;
  }
}

::v-deep .v-select__selections {
  margin-top: 0 !important;
  .v-chip {
    background-color: $color-primary-chip !important;
  }
}
</style>
