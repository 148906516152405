var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{class:[
    'floating-actions',
    { collaborations: _vm.$route.name === 'campaignDetails' }
  ],attrs:{"app":"","scroll-target":".main-wrapper","width":"fit-content","height":"fit-content","bottom":_vm.$vuetify.breakpoint.mdAndDown,"hide-on-scroll":_vm.$vuetify.breakpoint.mdAndDown}},[(_vm.isInCampaignView)?[_c('div',{staticClass:"floating-actions-slot"},[_c('v-btn-toggle',{staticClass:"--vertical",attrs:{"borderless":"","mandatory":""},model:{value:(_vm.filterModeCurrent),callback:function ($$v) {_vm.filterModeCurrent=$$v},expression:"filterModeCurrent"}},[_vm._l((_vm.availableCampaignFloatingFilters.filters),function(filter,index){return [_c('v-tooltip',{key:index,attrs:{"top":"","max-width":"80px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"data-cy":filter.value,"value":filter.value,"disabled":filter.value === 'favorite' &&
                    (!_vm.userFavoriteCampaigns || !_vm.userFavoriteCampaigns.length)}},on),[_c('v-icon',[_vm._v(_vm._s(filter.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(filter.label))])])]})],2)],1),_c('div',{staticClass:"floating-actions-divisor"}),_c('div',{staticClass:"floating-actions-slot"},[_c('v-btn-toggle',{staticClass:"--vertical",attrs:{"borderless":"","mandatory":""},model:{value:(_vm.orderByAlphabet),callback:function ($$v) {_vm.orderByAlphabet=$$v},expression:"orderByAlphabet"}},_vm._l((_vm.availableCampaignFloatingFilters.orderings),function(filter,index){return _c('v-tooltip',{key:index,attrs:{"top":"","max-width":"80px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":filter.value,"disabled":_vm.filterModeCurrent === 'favorite'}},on),[_c('v-icon',[_vm._v(_vm._s(filter.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(filter.label))])])}),1)],1)]:[_c('div',{staticClass:"floating-actions-slot"},[(_vm.viewMode !== 'conversation')?_c('v-btn-toggle',{staticClass:"--vertical",attrs:{"borderless":"","mandatory":""},model:{value:(_vm.collaborationModeFilter),callback:function ($$v) {_vm.collaborationModeFilter=$$v},expression:"collaborationModeFilter"}},_vm._l((_vm.availableCollaborationFloatingFilters.filters),function(filter,index){return _c('v-tooltip',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],key:index,attrs:{"top":"","max-width":"80px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({},on),[_c('v-icon',[_vm._v(_vm._s(filter.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(filter.label))])])}),1):_vm._e()],1),(_vm.viewMode !== 'conversation')?_c('div',{staticClass:"floating-actions-divisor"}):_vm._e(),_c('div',{staticClass:"floating-actions-slot"},[_c('v-menu',{attrs:{"offset-x":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var onMenu = ref.on;
                    var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"80px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var onTooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"v-item--active"},'v-btn',attrs,false),Object.assign({}, onMenu, onTooltip)),[_c('v-icon',[_vm._v(_vm._s(_vm.exibitionModeActiveInfo.icon))])],1)]}}],null,true)},[_c('span',[_vm._v("Modo de exibição - "+_vm._s(_vm.exibitionModeActiveInfo.label))])])]}}])},[_c('v-btn-toggle',{attrs:{"borderless":"","mandatory":""},model:{value:(_vm.toggle_exibitionMode),callback:function ($$v) {_vm.toggle_exibitionMode=$$v},expression:"toggle_exibitionMode"}},_vm._l((_vm.availableCollaborationFloatingFilters.viewModes),function(filter,index){return _c('v-tooltip',{key:index,attrs:{"top":"","max-width":"80px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":filter.value}},on),[_c('v-icon',[_vm._v(_vm._s(filter.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(filter.label))])])}),1)],1)],1),_c('div',{staticClass:"floating-actions-divisor"}),_c('div',{staticClass:"floating-actions-slot"},[_c('v-menu',{attrs:{"offset-x":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var onMenu = ref.on;
                    var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"80px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var onTooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"v-item--active"},'v-btn',attrs,false),Object.assign({}, onMenu, onTooltip)),[_c('v-icon',[_vm._v(_vm._s(_vm.densityLevelActiveInfo.icon))])],1)]}}],null,true)},[_c('span',[_vm._v("Nível de densidade - "+_vm._s(_vm.densityLevelActiveInfo.label))])])]}}])},[_c('v-btn-toggle',{attrs:{"borderless":"","mandatory":""},model:{value:(_vm.toggle_densityMode),callback:function ($$v) {_vm.toggle_densityMode=$$v},expression:"toggle_densityMode"}},_vm._l((_vm.availableCollaborationFloatingFilters.densityLevels),function(filter,index){return _c('v-tooltip',{key:index,attrs:{"bottom":"","max-width":"80px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({},on),[_c('v-icon',[_vm._v(_vm._s(filter.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(filter.label))])])}),1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }