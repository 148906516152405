<template>
  <div class="card-list slice-filters--vertical">
    <div
      class="card-wrapper"
      ref="wrapper"
      :class="[{ cardsAreOverlayed: collaborationsOverlayed }, densityClass]"
      name="cards"
      tag="div"
    >
      <CollaborationCardItem
        v-for="(c, i) in collaborations"
        :key="c.collaboration_id + i"
        :collaboration="c"
        :index="i"
        :actionsDisabled="actionsDisabled"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import CollaborationCardItem from "@/components/collaborations/Cards/CollaborationCardItem";
import collaborationsActions from "@/mixins/collaborations_actions.js";
export default {
  components: {
    CollaborationCardItem
  },
  mixins: [collaborationsActions],
  props: {
    actionsDisabled: Boolean
  },
  computed: {
    ...mapState({
      campaigns: state => state.listCampaign.campaigns,
      campaign: state => state.campaign,
      collaborationsOverlayed: state => state.ui.isCollaborationOverlayed,
      densityLevel: state => state.ui.densityLevel
    }),
    collaborations() {
      return this.campaign.tabs[0].collaborations;
    },
    densityClass() {
      return `density-level-${this.densityLevel}`;
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/variables.scss";

// Loading
.loading {
  position: relative;
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 8px auto 48px 24px; */
  /* height: calc(100vh - 232px);*/

  @include for-size(tablet-landscape-up) {
    margin: 0px auto 0px auto;
    height: calc(100vh - 176px);
  }
}

// CARDS LIST
.card-list {
  padding-top: 8px;
  // Lista > Grid
  .card-wrapper {
    overflow-y: scroll;
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: 304px;
    grid-row: auto/span 2;
    grid-gap: 8px;
    padding: 0;
    box-sizing: border-box;
    transition: all 0.4s ease-in-out;
    width: -webkit-fill-available;

    @media only screen and (max-width: $floating-actions-width-breakpoint) {
      margin-bottom: calc(1.25 * #{$floating-actions-slot-base-height});
    }
    @for $i from 0 through 2 {
      $widths: 234px, 281px, 338px;
      &.density-level-#{$i} {
        grid-template-columns: repeat(
          auto-fill,
          minmax(nth($widths, $i + 1), 1fr)
        );
      }
    }

    .collaboration-card {
      transition: all 0.4s ease-in-out;
    }

    &.cardsAreOverlayed {
      .collaboration-card:not(.active) {
        opacity: 0.3;
      }
    }
  }
}
// -----------

.cards-enter-active,
.cards-leave-active {
  transition: all 0.5s;
}
.cards-enter,
.cards-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
