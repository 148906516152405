<template>
  <div class="period-segmenter">
    <div class="period-segmenter-index">
      <span>{{ visibleItems }}</span> de {{ totalItems }} {{ viewTypeText }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visibleItems: {
      type: Number,
      default: 0
    },
    totalItems: {
      type: Number,
      default: 0
    },
    viewTypeText: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/commons.scss";

.period-segmenter {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 16px 0 24px 0;
  padding: 0 16px;

  // Linhas horizontais
  &::before,
  &::after {
    content: "";
    flex: 1;
    height: 1px;
    background-color: $color-border-divisor;
  }

  &::before {
    margin-right: 24px;
  }

  &::after {
    margin-left: 24px;
  }

  @include for-size(tablet-landscape-up) {
    &::before {
      margin-right: 8px;
    }

    &::after {
      margin-left: 8px;
    }
  }

  .period-segmenter-index {
    font-family: "Exo", "sans-serif";
    font-size: 12px;
    font-style: italic;
    font-weight: 300;
    color: rgba($color-black, 0.64);
    margin: 0 8px;

    span {
      font-weight: 600;
    }

    @include for-size(tablet-landscape-up) {
      font-size: 0.8em;
    }
  }
}
</style>
