<template>
  <div class="poster-selector-box">
    <form ref="fileForm">
      <input type="file" ref="fileInput" @change="fileChangeHandler" hidden />
    </form>

    <v-autocomplete
      ref="suggestionField"
      v-model="posterTitle"
      :items="posters"
      :rules="[validatePosterRequired]"
      item-text="title"
      item-value="id"
      :required="required"
      :search-input.sync="search"
      color="graydark"
      :label="labelName"
      :placeholder="placeholder"
      return-object
      no-data
      @keydown="blockSearches = false"
      outlined
      hide-details
      disable-lookup
      allow-overflow
      prepend-inner-icon="mdi-image-search"
    >
      <template v-slot:prepend-item>
        <v-list-item class="v-list-item--top">
          Escolha apenas uma opção
          <span>{{ posters.length }} disponíveis</span>
        </v-list-item>
      </template>
      <template v-slot:append-item>
        <v-list-item class="v-list-item--bottom">
          <v-btn block large color="primary" @click="fileSelectorClicked">
            Clique para enviar uma capa personalizada :)
          </v-btn>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { Poster } from "@/gateways";
import { UI_SNACKBAR_SHOW } from "@/store/actions";
import { fileToDataUrl, urlToDataUrl } from "@/util";

export default {
  name: "PosterSelector",
  data() {
    return {
      originalFilename: null,
      originalFileUrl: null,
      dataUrl: null,
      posterTitle: null,
      poster: { filename: null, url: null },
      search: null,
      posters: [],
      blockSearches: false,
      isSearchingTitles: false,
      debounceTimerId: 0,
      debounceTime: 1000,
      messages: {
        invalidFileType: "invalid file type.",
        invalidFileTypeFriendly:
          "Apenas imagens no formato png ou jpeg são permitidas."
      }
    };
  },
  props: {
    triggerDeletePoster: {
      type: Boolean,
      default: false
    },
    triggerUndoDeletePoster: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: null
    },
    currentPosterUrl: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    labelName: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    }
  },
  mounted() {
    // silenciar erro de prop n usada
    this.value;
    this.setupRules();
  },
  watch: {
    currentPosterUrl() {
      this.setupRules();
    },
    triggerDeletePoster() {
      if (this.triggerDeletePoster) {
        this.posterTitle = "";
        this.$emit("reset-delete-poster");
      }
    },
    triggerUndoDeletePoster() {
      if (this.triggerUndoDeletePoster) {
        this.resetPoster();
        this.$emit("reset-undo-delete-poster");
      }
    },
    search(val) {
      // console.log("watcher > search(val)");
      if (this.blockSearches) return;
      clearTimeout(this.debounceTimerId);
      if (!val) return;
      this.debounceTimerId = setTimeout(() => {
        this.isSearchingTitles = true;
        // get suggestions
        Poster.get(val)
          .then(res => {
            this.posters = res.data;
          })
          .finally(() => {
            this.isSearchingTitles = false;
          });
      }, this.debounceTime);
    },
    async posterTitle(val) {
      if (!val) {
        this.blockSearches = false;
        this.posters = [];
        this.updateInput(null, null);
      } else if (val.title.startsWith("Arquivo: ")) {
        const file = this.$refs.fileInput.files[0];
        const dataUrl = await fileToDataUrl(file);
        this.$refs.suggestionField.blur();
        this.updateInput(file.name, dataUrl);
      } else if (val.title.startsWith("Atual: ")) {
        this.updateInput(this.originalFilename, this.originalFileUrl);
      } else {
        const posterId = val.id;
        const res = await Poster.get(posterId);
        const dataUrl = await urlToDataUrl(res.data.image_url);
        this.updateInput("sugestao_poster.jpg", dataUrl);
      }
      this.$refs.fileForm.reset();
    }
  },
  /* computed: {
    emptyOriginalFileUrl() {
      return !this.originalFileUrl || this.originalFileUrl === "";
    }
  }, */
  methods: {
    // eslint-disable-next-line vue/no-unused-properties
    reset() {
      this.originalFilename = null;
      this.originalFileUrl = null;
      this.dataUrl = null;
      this.posterTitle = null;
      this.search = null;
      this.posters = [];
      this.blockSearches = false;
      this.isSearchingTitles = false;
      this.debounceTimerId = 0;
      this.debounceTime = 1000;
    },
    validatePosterRequired(val) {
      if (!this.required) {
        return true;
      }
      const { filename, url } = this.poster;
      return (!!val && !!filename && !!url) || "Campo obrigatório.";
    },
    resetPoster() {
      this.blockSearches = true;
      this.posters = [
        {
          id: 0,
          title: `Atual: ${this.originalFilename ? this.originalFilename : ""}`
        }
      ];
      this.posterTitle = this.posters[0];
    },
    fileSelectorClicked() {
      this.$refs.fileInput.click();
    },
    validatePosterIsImage(file) {
      const validFileTypes = ["image/jpeg", "image/png"];
      if (!file || !validFileTypes.includes(file.type))
        throw Error(this.messages.invalidFileType);
    },
    async fileChangeHandler() {
      const file = this.$refs.fileInput.files[0];
      if (file.size > 2000000) {
        return this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "Apenas arquivos menores que 2MB são aceitos."
        });
      }
      try {
        this.validatePosterIsImage(file);
        this.blockSearches = true;
        this.posters = [{ id: 0, title: `Arquivo: ${file.name}` }];
        this.posterTitle = this.posters[0];
      } catch {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: this.messages.invalidFileTypeFriendly
        });
      }
    },
    updateInput(filename, url) {
      this.poster = { filename, url };
      this.$emit("input", { filename, url });
      // retrigger validation through validatePosterRequired
      // with new this.poster object
      this.$refs.suggestionField.resetValidation();
    },
    setupRules() {
      this.dataUrl = this.currentPosterUrl;
      this.originalFileUrl = this.currentPosterUrl;
      if (this.originalFileUrl) {
        this.originalFilename = this.originalFileUrl.split("/").pop();
        this.resetPoster();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/theme.scss";
@import "@/scss/form-inputs.scss";
.poster-selector-box {
  width: 100%;
}
</style>
