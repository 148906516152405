<template>
  <div class="container">
    <!-- Topo -->
    <div class="topo">
      <div class="title">
        <span>{{ isEditMode ? "Editar" : "Registrar" }}</span> WhatsApp
      </div>
    </div>
    <div class="main">
      <v-form class="form" v-model="formValid">
        <div class="item">
          <v-text-field
            placeholder="Informe o nome"
            primary
            v-model="formData.name"
            :rules="nameRules"
            counter
            maxlength="100"
            class="textfield"
            required
          >
            <template slot="label">
              Nome
              <span class="text-red">*</span>
            </template>
          </v-text-field>
        </div>

        <div class="item">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon color="primary" dark v-on="on">
                info
              </v-icon>
            </template>
            <span>
              É importante alertar que para criação de um novo número de
              WhatsApp:<br />
              - deve ser adquirido um chip corporativo<br />
              - o chip deve ser encaminhado para: Rua Jardim Botânico, 266 - 7ª
              andar - SUPORTE N2<br />
              - deve ser aberto chamado informando nome da campanha, descrição e
              foto que deve aparecer no contato do whatsapp<br />
              <b>contato: <i>denize.pimenta@g.globo</i></b>
            </span>
          </v-tooltip>

          <v-text-field
            placeholder=" Informe o número do WhatsApp"
            primary
            v-model="formData.phone_number"
            :rules="numberRules"
            mask="(##)-#########"
            counter
            maxlength="15"
            class="textfield"
            required
          >
            <template slot="label">
              Número
              <span class="text-red">* </span>
            </template>
          </v-text-field>
        </div>

        <div class="item margin">
          <v-autocomplete
            placeholder="Escolha o grupo para relacionar ao Número"
            primary
            class="textfield"
            v-model="formData.product_ids"
            required
            multiple
            chips
            draggable
            deletable-chips
            :rules="productRules"
            :items="products"
            item-value="id"
            item-text="name"
          >
            <template slot="label">
              Nome do grupo
              <span class="text-red">*</span>
            </template>
          </v-autocomplete>
        </div>

        <div class="item margin">
          <v-text-field
            placeholder="Informe a descrição do WhatsApp"
            primary
            v-model="formData.description"
            maxlength="320"
            counter
            class="textfield"
          >
            <template slot="label">
              Descrição
              <span class="text-red"></span>
            </template>
          </v-text-field>
        </div>

        <div class="item margin">
          <v-select
            v-model="formData.mode"
            label="Modo de integração"
            :disabled="isEditMode"
            :items="Object.values(whatsappModes)"
          />
        </div>
      </v-form>
    </div>

    <!-- Footer -->
    <div class="footer">
      <div class="slot left"></div>

      <!-- Actions -->
      <div class="slot right">
        <v-btn
          large
          color="seccondary"
          class="mr-4"
          @click="$emit('cancelCreateClickedEvent')"
        >
          <v-icon>mdi-close</v-icon>
          Cancelar
        </v-btn>
        <v-btn
          large
          color="primary"
          @click="saveDataHandler"
          :disabled="!formValid && !isSavingData"
          :loading="isSavingData"
        >
          <v-icon>mdi-content-save</v-icon>
          Salvar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { omit, pick } from "ramda";

import { WhatsappModes } from "../../../enums";
import { Product, Whatsapp } from "../../../gateways";
import { UI_SNACKBAR_SHOW } from "../../../store/actions";

const validateRequired = val => !!val || "Campo obrigatório.";

const validateNameLength = name =>
  (!!name && name.length <= 100) || "O nome deve ter menos que 100 caracteres.";

const RegexNumberPhone = /^\d{11,15}$/gm;
const validateNumberPhone = phone_number =>
  new RegExp(RegexNumberPhone, "gm").test(phone_number) ||
  "Este número de telefone não parece ser válido. Por favor insira de 11 a 15 dígitos sem espaços.";

export default {
  data() {
    return {
      formValid: false,
      products: [],
      formData: {
        id: -1,
        name: null,
        phone_number: null,
        product_ids: [],
        description: "",
        mode: WhatsappModes.ANBOX
      },
      isEditMode: false,
      isSavingData: false
    };
  },
  props: ["editItem"],
  async created() {
    this.whatsappModes = WhatsappModes;
    this._fetchData();
    if (this.editItem) {
      this.formData = pick(
        ["id", "name", "description", "mode"],
        this.editItem
      );
      this.formData.phone_number = this.editItem.phoneNumber;
      this.formData.product_ids = this.editItem.product.map(i => i.id);

      this.isEditMode = true;
    }
  },
  computed: {
    nameRules: {
      get() {
        return [validateRequired, validateNameLength];
      }
    },
    numberRules: {
      get() {
        return [validateRequired, validateNumberPhone];
      }
    },
    productRules() {
      return [product => !![product] || "Este é um campo obrigatório!"];
    }
  },
  methods: {
    async _fetchData() {
      const promises = [Product.get()];
      const results = await Promise.all(promises);
      [this.products] = results.map(r => r.data);
    },
    async saveDataHandler() {
      this.isSavingData = true;
      const data = omit("id", this.formData);
      if (this.isEditMode) {
        await this.editItemHandler(this.formData.id, data);
      } else {
        await this.registerItemHandler(data);
      }
      this.isSavingData = false;
    },
    async registerItemHandler(data) {
      try {
        await Whatsapp.register(data);
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "O número do WhatsApp foi registrado com sucesso!"
        });
        this.$emit("itemRegisteredEvent");
      } catch {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "Erro tentando registrar o número do WhatsApp :(!"
        });
      }
    },
    async editItemHandler(id, data) {
      try {
        await Whatsapp.edit(id, data);
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "O WhatsApp foi editado com sucesso!"
        });
        this.$emit("itemEditedEvent");
      } catch {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "Erro tentando editar o WhatsApp :(!"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/theme.scss";
@import "../../../scss/navigation-drawer.scss";
</style>
