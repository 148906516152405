<template>
  <v-app>
    <NewFluxUXBanner />
    <v-main v-if="isLoggedIn">
      <TheAdministrationNavDrawer />
      <TheCollaborationViewNavDrawer />
      <TheCollaborationCreationNavDrawer />
      <ModalConfirm />
    </v-main>

    <v-main>
      <router-view />
    </v-main>
    <!-- INICIO DO SNACKBAR -->
    <v-snackbar
      v-model="snackbarShow"
      v-bind="snackbarBind"
      v-if="snackbarShow"
      :multi-line="true"
      class="snackbar"
    >
      <span>{{ snackbarMessage }}</span>
      <v-btn
        color="primary"
        v-if="!snackbarActionUpdate"
        text
        @click="snackbarShow = false"
        >Entendi</v-btn
      >
      <v-btn color="primary" v-else text @click="refreshCollaboration"
        >Atualizar</v-btn
      >
    </v-snackbar>
    <!-- FIM DO SNACKBAR -->
  </v-app>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

import ModalConfirm from "@/components/ModalConfirm";
import NewFluxUXBanner from "@/components/NewFluxUXBanner";
import TheAdministrationNavDrawer from "@/components/TheAdministrationNavDrawer";
import TheCollaborationCreationNavDrawer from "@/components/TheCollaborationCreationNavDrawer";
import TheCollaborationViewNavDrawer from "@/components/TheCollaborationViewNavDrawer";
import {
  CAMPAIGN_COLLABORATIONS_LOAD,
  CAMPAIGN_GROUPED_LOAD_COLLABORATORS,
  UI_SNACKBAR_HIDE
} from "@/store/actions";
import { UI_VIEW_MODE_SET } from "@/store/mutations";
import { handleTabTitleChange } from "@/util";

export default {
  name: "App",
  components: {
    TheAdministrationNavDrawer,
    TheCollaborationCreationNavDrawer,
    TheCollaborationViewNavDrawer,
    ModalConfirm,
    NewFluxUXBanner
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn,
      isAdmin: state => state.auth.user.is_superuser,
      snackbar: state => state.ui.snackbar,
      camapaignSearchTerms: state => state.campaign.tabs[0].filters.search_text,
      hasNewCollaborations: state => state.campaign.hasNewCollaborations,
      campaignId: state => state.currentCampaign.campaign.id
    }),
    ...mapGetters(["viewMode", "userId", "userFavoriteViewMode"]),
    snackbarMessage() {
      return this.snackbar.message || "";
    },
    snackbarShow: {
      get() {
        return this.snackbar.show || false;
      },
      set(isToShow) {
        if (!isToShow) {
          this.$store.dispatch(UI_SNACKBAR_HIDE);
        }
      }
    },
    snackbarBind() {
      return {
        style: {
          top: "-16px"
        },
        ...this.snackbar.bind
      };
    },
    snackbarActionUpdate() {
      return this.snackbar.update;
    }
  },
  watch: {
    hasNewCollaborations(value) {
      if (!value) {
        handleTabTitleChange(this.camapaignSearchTerms);
      }
    },
    camapaignSearchTerms(value) {
      handleTabTitleChange(value);
    },
    $route(to) {
      if (to.name !== "campaignDetails") {
        handleTabTitleChange(false);
      }
    },
    userFavoriteViewMode(value) {
      this.UI_VIEW_MODE_SET(value);
    }
  },
  methods: {
    ...mapMutations([UI_VIEW_MODE_SET]),
    refreshCollaboration() {
      if (this.viewMode !== "conversation") {
        this.$store.dispatch(CAMPAIGN_COLLABORATIONS_LOAD, {
          tryPaginating: false,
          refresh: true,
          updateCollaborations: true
        });
      } else {
        this.$store.dispatch(CAMPAIGN_GROUPED_LOAD_COLLABORATORS, {
          loadMoreCollaborators: false
        });
      }
      this.snackbarShow = false;
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/buttons.scss";
@import "@/scss/commons.scss";

.v-application {
  background-color: $color-back !important;
}

.v-main {
  padding: 0 !important;
}

.main-wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: $color-back !important;

  .main-content {
    width: calc(100% - 204px);
    margin: 184px auto 0 auto;
    box-sizing: border-box;
    overflow-y: scroll;

    @include for-size(desktop-up) {
      width: calc(100% - 32px);
    }

    // Transformacao para telas menores
    @include for-size(tablet-landscape-up) {
      margin: 176px auto 0 auto;
      width: 100%;
    }
  }
}

.snackbar {
  .v-snack__content {
    padding: 0 16px !important;
    display: flex !important;
    align-items: center !important;
    span {
      flex: 1;
    }
    .v-btn {
      padding: 0 !important;
    }
  }
}

.v-tooltip__content {
  background-color: #fff !important;
  max-width: 500px !important;
  opacity: 1 !important;
  img {
    height: 20px !important;
  }
}
</style>
