<template>
  <div class="container">
    <!-- Topo -->
    <div class="topo">
      <div class="title"><span>Lista</span> de Servidores SFTP</div>
    </div>
    <div class="main">
      <v-data-table
        :fixed-header="true"
        :headers="headers"
        :items="sftpServers"
        class="table-main"
        :mobile-breakpoint="1200"
        :search="search"
        :loading="isLoading"
        loading-text="Carregando Lista de servidores..."
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-2"
                @click="$emit('editClickedEvent', item)"
                v-on="on"
              >
                edit
              </v-icon>
            </template>
            <span>Editar servidor SFTP</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small @click="deleteItem(item)" v-on="on">
                delete
              </v-icon>
            </template>
            <span>Remover servidor SFTP</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
    <div class="footer search">
      <!-- Search -->
      <div class="slot left">
        <v-text-field
          v-model="search"
          solo
          rounded
          clearable
          flat
          hide-details
          label="Pesquise por Servidor SFTP"
          append-icon="search"
          color="primary"
          clear-icon="close"
          background-color="#F5F5F5"
        ></v-text-field>
      </div>

      <!-- Actions -->
      <div class="slot right">
        <v-btn large color="seccondary" @click="$emit('cancelClickedEvent')">
          <v-icon>mdi-close</v-icon>
          Cancelar
        </v-btn>

        <v-btn large color="primary" @click="$emit('createClickedEvent')">
          <v-icon>add_circle</v-icon>
          Adicionar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { getAllSftpServers, SftpServer } from "@/gateways";
import { UI_SNACKBAR_SHOW } from "@/store/actions";

export default {
  data: () => ({
    isLoading: false,
    search: "",
    sftpServers: [],
    headers: [
      {
        text: "Fluxo",
        align: "left",
        sortable: true,
        value: "name"
      },
      {
        text: "Descrição",
        align: "left",
        sortable: true,
        value: "description"
      },
      {
        text: "Servidor",
        align: "left",
        sortable: true,
        value: "host"
      },
      {
        text: "Diretório",
        align: "left",
        sortable: true,
        value: "path"
      },
      {
        text: "Porta",
        align: "left",
        sortable: true,
        value: "port"
      },
      { text: "Ações", value: "action", align: "right", sortable: false }
    ]
  }),
  created() {
    if (this.drawer) this.fetchData();
  },
  computed: {
    ...mapState({ currentTab: state => state.ui.currentTab }),
    ...mapState({ drawer: state => state.ui.drawer })
  },
  watch: {
    drawer() {
      if (this.drawer && this.currentTab === "sftp-server") this.fetchData();
    },
    currentTab() {
      if (this.drawer && this.currentTab === "sftp-server") this.fetchData();
    }
  },
  methods: {
    async deleteItem(item) {
      try {
        if (confirm("Você tem certeza ?")) {
          (await SftpServer).delete(item.id);
          const index = this.sftpServers.indexOf(item);
          this.sftpServers.splice(index, 1);
        }
      } catch {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message:
            "Não foi possível deletar o servidor sftp. Por favor, tente novamente em alguns minutos."
        });
      }
    },
    async fetchData() {
      this.isLoading = true;
      this.sftpServers = [];

      try {
        this.sftpServers = await getAllSftpServers();
      } catch {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message:
            "Não foi possível carregar a lista de servidores sftp. Por favor, tente novamente em alguns minutos."
        });
      }

      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/theme.scss";
@import "@/scss/navigation-drawer.scss";
</style>
