<template>
  <div :class="({ tabView: tabView }, 'panelbatchActions')">
    <transition
      enter-active-class="animate__animated animate__fadeInUp"
      leave-active-class="animate__animated animate__fadeOutDown"
      :duration="{ enter: 600, leave: 500 }"
      mode="in-out"
      appear
      type="animation"
    >
      <div
        v-show="isShowPanel && !disabled"
        :class="{ batch_actions: true, hasSnackbar: hasSnackbar }"
        @mouseover="sidePanel = true"
        @mouseleave="sidePanel = false"
      >
        <div class="all" tra>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                dark
                class="square"
                v-on="on"
                @click="checkAllCollaborations($event)"
              >
                <v-icon>
                  {{ collaborationSelected ? "mdi-select-all" : "mdi-select" }}
                </v-icon>
              </v-btn>
            </template>
            <span>Selecionar todas colaborações</span>
          </v-tooltip>
          <span>Ações em Lote</span>
        </div>
        <div class="act">
          <v-tooltip
            v-for="(act, i) in actions.filter(it => it.canShow)"
            :key="i"
            top
          >
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                class="square"
                v-on="on"
                :disabled="!collaborationSelected || act.disabled"
                @click="act.handle"
              >
                <v-icon :color="act.color || '#FFFFFF'">{{ act.icon }}</v-icon>
              </v-btn>
            </template>
            <span>{{ act.tooltip }}</span>
          </v-tooltip>
        </div>
      </div>
    </transition>
    <v-dialog v-model="dialog" max-width="480" :persistent="loadingMove">
      <v-card>
        <v-card-title class="headline">Mover colaborações</v-card-title>
        <v-card-text>
          <br />Selecione a campanha para onde deseja mover as colaborações
          selecionadas
          <v-autocomplete
            :items="listCampaignToMove()"
            v-model="campaignSelect"
            item-text="name"
            item-value="id"
            placeholder="Escolha uma campanha"
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            text
            color="primary"
            x-large
            @click="dialog = false"
            :disabled="loadingMove"
          >
            <v-icon right dark>mdi-close</v-icon>Fechar
          </v-btn>

          <v-btn
            :loading="loadingMove"
            color="primary"
            @click.prevent.stop="handleMoveCollaboration"
            :disabled="!campaignSelect || loadingMove"
            x-large
          >
            <v-icon right dark>swap_horiz</v-icon>Mover
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import { CollaborationState } from "@/enums";
import collaborationsActions from "@/mixins/collaborations_actions.js";
import { sorting } from "@/util/sorting";

export default {
  mixins: [collaborationsActions],
  data: () => ({
    tabView: process.env.VUE_APP_SHOW_TAB_VIEW === "1" || false,
    campaignSelect: "",
    loadingMove: false,
    isShowPanel: false,
    dialog: false,

    sidePanel: false,
    idTimeOut: ""
  }),
  props: {
    campaignId: {
      required: true
    },
    disabled: Boolean
  },

  watch: {
    sidePanel(v) {
      return v ? clearTimeout(this.idTimeOut) : this.closePanel();
    },
    collaborationsAllSelectedCount: function(n, o) {
      if (n > 0) {
        this.openPanel();
      }
      if (n === 0 && o > 1) {
        this.closePanel();
      }
    }
  },
  computed: {
    ...mapState({
      filters: state => state.campaign.tabs[0].filters
    }),
    ...mapGetters([
      "collaborationsAllSelected",
      "collaborationsAllSelectedCount",
      "campaigns"
    ]),
    collaborationSelected() {
      return (
        this.collaborationsAllSelectedCount &&
        this.collaborationsAllSelectedCount > 0
      );
    },
    hasSnackbar() {
      return this.$store.state.ui.snackbar.polling;
    },

    canApproveInCurrentModerationLevel() {
      const moderation = this.filters.moderationLevel;
      return (
        moderation === CollaborationState.FirstModerationLevel ||
        moderation === CollaborationState.SecondModerationLevel
      );
    },

    canRejectInCurrentModerationLevel() {
      return this.filters.moderationLevel !== CollaborationState.Rejected;
    },

    actions() {
      return [
        {
          icon: "delete",
          tooltip: "Excluir colaborações",
          canShow: true,
          handle: this.bulkDelete,
          disabled: Boolean(false)
        },
        {
          icon: "swap_horiz",
          tooltip: "Mover colaborações",
          canShow: true,
          handle: this.openDialog,
          disabled: Boolean(false)
        },
        {
          icon: "mdi-cloud-download",
          tooltip: "Download das colaborações",
          canShow: true,
          handle: this.downloadCollaborations,
          disabled: Boolean(false)
        },
        {
          icon: "done",
          tooltip: "Aprovar colaborações",
          canShow: true,
          handle: this.approveCollaborations,
          disabled: Boolean(!this.canApproveInCurrentModerationLevel)
        },
        {
          icon: "close",
          tooltip: "Reprovar colaborações",
          canShow: true,
          handle: this.rejectCollaborations,
          disabled: Boolean(!this.canRejectInCurrentModerationLevel)
        }
      ];
    }
  },

  methods: {
    checkAllCollaborations() {
      this.toggleAll = !this.toggleAll;
      this.$store.dispatch(
        "CAMPAIGN_COLLABORATIONS_TOGGLE_ALL",
        this.toggleAll
      );
    },
    openPanel() {
      clearTimeout(this.idTimeOut);
      this.isShowPanel = true;
      if (!this.sidePanel) {
        this.closePanel();
      }
    },
    closePanel() {
      this.idTimeOut = setTimeout(() => {
        this.isShowPanel = false;
      }, 4000);
    },
    // dialog

    listCampaignToMove() {
      return this.campaigns
        .filter(c => parseInt(c.id) !== parseInt(this.campaignId))
        .sort(sorting.orderByName);
    },

    // actions for
    openDialog() {
      this.$store.dispatch("LC_DATA_LOAD").then(() => {
        this.dialog = true;
      });
    },
    bulkDelete() {
      this.$store.dispatch("CAMPAIGN_COLLABORATIONS_BULK_DELETE");
    },
    async handleMoveCollaboration() {
      this.loadingMove = true;
      await this.$store.dispatch(
        "CAMPAIGN_COLLABORATIONS_MOVE",
        this.campaignSelect
      );

      this.dialog = false;
      this.loadingMove = false;
    },
    async approveCollaborations() {
      await this.$store.dispatch("CAMPAIGN_COLLABORATIONS_APPROVE_MULTIPLE");
    },
    async downloadCollaborations() {
      await this.$store.dispatch("CAMPAIGN_COLLABORATIONS_DOWNLOAD");
    },
    async rejectCollaborations() {
      await this.$store.dispatch("CAMPAIGN_COLLABORATIONS_REJECT_MULTIPLE");
    }
  }
};
</script>

<style lang="scss" scope>
@import "@/scss/theme.scss";
@import "@/scss/toggles.scss";

.panelbatchActions {
  cursor: pointer;
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: calc(100% - 380px);
  background-color: transparent;
  height: 24px;
  z-index: 20;
  .tabView {
    height: 0px;
  }
}
.batch_actions {
  display: block;
  position: absolute;
  left: 24px;
  bottom: 24px;
  z-index: 19;
  color: #fff;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  background-color: #333333 !important;
  align-items: center;
  line-height: 1.8;

  .v-btn {
    margin: 0;
    background-color: transparent !important;
    color: #fff !important;
  }
  .all {
    background-color: #222222;
    display: flex;
    text-transform: capitalize !important;
    padding: 18px 16px 18px 0px;
  }
  .act {
    display: flex;
    padding: 0 8px;
    .v-btn {
      margin: 4px;
      padding: 16px 0;
      &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background: transparent !important;
      }
      &.v-btn--disabled {
        border: none !important;
        i {
          color: rgba($color-gray-lighter, 0.25) !important;
        }
      }
    }
  }

  &.hasSnackbar {
    bottom: 96px;
  }
}
</style>
