import { render, staticRenderFns } from "./TwitterDrawer.vue?vue&type=template&id=38181178&scoped=true&"
import script from "./TwitterDrawer.vue?vue&type=script&lang=js&"
export * from "./TwitterDrawer.vue?vue&type=script&lang=js&"
import style0 from "./TwitterDrawer.vue?vue&type=style&index=0&id=38181178&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38181178",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VChip,VCombobox,VForm,VSwitch})
