<!-- Observer.vue -->
<template>
  <div class="observer" />
</template>

<script>
export default {
  props: ["options"],
  data: () => ({
    observer: null
  }),
  mounted() {
    const options = this.options || {};
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.$emit("intersect");
      }
    }, options);

    this.observer.observe(this.$el);
  },
  destroyed() {
    this.observer.disconnect();
  }
};
</script>

<style scoped lang="scss">
.observer {
  height: 16px;
}
</style>
