import { render, staticRenderFns } from "./selectThirdUserInstagram.vue?vue&type=template&id=b08ba1f0&scoped=true&"
import script from "./selectThirdUserInstagram.vue?vue&type=script&lang=js&"
export * from "./selectThirdUserInstagram.vue?vue&type=script&lang=js&"
import style0 from "./selectThirdUserInstagram.vue?vue&type=style&index=0&id=b08ba1f0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b08ba1f0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAvatar,VBtn,VChip,VCombobox,VDivider,VForm,VIcon,VImg,VTextField})
