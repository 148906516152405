<template>
  <div>
    <v-text-field outlined label="Assunto" v-model="subject" />
    <v-textarea
      outlined
      label="Mensagem"
      :value="message"
      @change="message = $event"
    />
    <v-subheader v-text="'Rodapé'" />
    <v-subheader v-text="defaultFooterMessage" />
    <v-switch
      v-model="applyOptionalFooterMessage"
      label="Deseja adicionar o texto abaixo em conjunto com o rodapé?"
    ></v-switch>
    <v-subheader v-text="optionalMessageFooter" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { COLLABORATOR_SEND_EMAIL_FIELD_HANDLE } from "@/store/actions";

export default {
  name: "send_email_modal_fields",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      storeSubject: state =>
        state.collaborators.collaboratorSendEmailFields.subject,
      storeMessage: state =>
        state.collaborators.collaboratorSendEmailFields.message,
      optionalMessageFooterApplied: state =>
        state.collaborators.collaboratorSendEmailFields
          .optionalMessageFooterApplied,
      defaultFooterMessage: state =>
        state.collaborators.collaboratorSendEmailFields.defaultFooterMessage
    }),
    ...mapGetters(["optionalMessageFooter"]),
    subject: {
      get() {
        return this.storeSubject;
      },
      set(value) {
        this.sendEmailFieldSet({ field: "subject", value: value });
      }
    },
    message: {
      get() {
        return this.storeMessage;
      },
      set(value) {
        this.sendEmailFieldSet({
          field: "message",
          value: `${value}`
        });
      }
    },
    applyOptionalFooterMessage: {
      get() {
        return this.optionalMessageFooterApplied;
      },
      set(value) {
        this.sendEmailFieldSet({
          field: "optionalMessageFooterApplied",
          value
        });
      }
    }
  },
  methods: {
    ...mapActions({
      sendEmailFieldSet: COLLABORATOR_SEND_EMAIL_FIELD_HANDLE
    })
  }
};
</script>

<style lang="scss" scoped>
::v-deep label,
::v-deep .v-input.v-input--is-label-active:not(.v-input--checkbox) label {
  text-transform: inherit !important;
}
</style>
