export const UI_DRAWER_TOGGLE = "UI_DRAWER_TOGGLE";
export const UI_DRAWER_SET = "UI_DRAWER_SET";
export const UI_DRAWER_TAB_SET = "UI_CURRENT_TAB_SET";

export const UI_CAMPAIGN_DRAWER_TOGGLE = "UI_CAMPAIGN_DRAWER_TOGGLE";
export const UI_CAMPAIGN_DRAWER_SET = "UI_CAMPAIGN_DRAWER_SET";

export const UI_COLLABORATION_CREATION_DRAWER_TOGGLE =
  "UI_COLLABORATION_CREATION_DRAWER_TOGGLE";
export const UI_COLLABORATION_CREATION_DRAWER_SET =
  "UI_COLLABORATION_CREATION_DRAWER_SET";
export const UI_CAMPAIGN_DRAWER_ITEM_SET = "UI_CAMPAIGN_DRAWER_ITEM_SET";
export const UI_SHOW_VIEW_TABLE_SET = "UI_SHOW_VIEW_TABLE_SET";

export const UI_COLLABORATION_DRAWER_TOGGLE = "UI_COLLABORATION_DRAWER_TOGGLE";
export const UI_COLLABORATION_DRAWER_SET = "UI_COLLABORATION_DRAWER_SET";
export const UI_COLLABORATION_DRAWER_ITEM_SET =
  "UI_COLLABORATION_DRAWER_ITEM_SET";

export const UI_COLLABORATION_EDIT_DRAWER_SET =
  "UI_COLLABORATION_EDIT_DRAWER_SET";

export const UI_FILTER_CAMPAIGN_SET = "UI_FILTER_CAMPAIGN_SET";
export const UI_FILTER_COLLABORATION_SET_MUTATION =
  "UI_FILTER_COLLABORATION_SET_MUTATION";
export const UI_CAMPAIGN_FILTER_DRAWER_SET = "UI_CAMPAIGN_FILTER_DRAWER_SET";
export const UI_SNACKBAR_SET = "UI_SNACKBAR_SET";
export const UI_WHATSAPP_DRAWER_SET = "UI_WHATSAPP_DRAWER_SET";
export const UI_YOUTUBE_DRAWER_SET = "UI_YOUTUBE_DRAWER_SET";
export const UI_TWITTER_DRAWER_SET = "UI_TWITTER_DRAWER_SET";
export const UI_INSTAGRAM_DRAWER_SET = "UI_INSTAGRAM_DRAWER_SET";
export const UI_FORM_DRAWER_SET = "UI_FORM_DRAWER_SET";
export const UI_UPLOADER_DRAWER_SET = "UI_UPLOADER_DRAWER_SET";
export const UI_DESTINATIONS_DRAWER_SET = "UI_DESTINATIONS_DRAWER_SET";
export const UI_METADATA_DRAWER_SET = "UI_METADATA_DRAWER_SET";
export const UI_FILE_FORMATS_DRAWER_SET = "UI_FILE_FORMATS_DRAWER_SET";
export const UI_NEW_COLLABORATIONS_SET = "UI_NEW_COLLABORATIONS_SET";
export const UI_VIEW_MODE_SET = "UI_VIEW_MODE_SET";
export const UI_COLLABORATIONS_FILTERS_DRAWER_SET =
  "UI_COLLABORATIONS_FILTERS_DRAWER_SET";
export const UI_CAMPAIGNS_FILTERS_DRAWER_SET =
  "UI_CAMPAIGNS_FILTERS_DRAWER_SET";
export const UI_DENSITY_SET = "UI_DENSITY_SET";
export const UI_CAMPAIGN_ACTION_DIALOG_SET = "UI_CAMPAIGN_ACTION_DIALOG_SET";
export const UI_NOTIFICATION_INFO_SET = "UI_NOTIFICATION_INFO_SET";
export const UI_CLEAR_NOTIFICATION_INFO = "UI_CLEAR_NOTIFICATION_INFO";
export const UI_COLLABORATION_OVERLAYED_SET = "UI_COLLABORATION_OVERLAYED_SET";

export const AUTH_USER_SET = "AUTH_USER_SET";
export const AUTH_LOGGEDIN_SET = "AUTH_LOGGEDIN_SET";
export const AUTH_ISLOADING_SET = "AUTH_ISLOADING_SET";

export const LC_CAMPAIGNS_SET = "LC_CAMPAIGNS_SET";
export const MT_CAMPAIGNS_SET_FILTER = "MT_CAMPAIGNS_SET_FILTER";
export const LC_CAMPAIGNS_REFRESH_MUTATION = "LC_CAMPAIGNS_REFRESH_MUTATION";
export const LC_CAMPAIGNS_RESTORE_STATE_MUTATION =
  "LC_CAMPAIGNS_RESTORE_STATE_MUTATION";
export const LC_CAMPAIGNS_SAVE_STATE_MUTATION =
  "LC_CAMPAIGNS_SAVE_STATE_MUTATION";
export const MT_CAMPAIGNS_CLEAR_SEARCH = "MT_CAMPAIGNS_CLEAR_SEARCH";

export const CC_CAMPAIGN_SET = "CC_CAMPAIGN_SET";
export const CC_COLLABORATIONS_SET = "CC_COLLABORATIONS_SET";
export const CC_COLLABORATION_REFRESH = "CC_COLLABORATION_REFRESH";
export const CC_COLLABORATION_PUT = "CC_COLLABORATION_PUT";
// export const CC_COLLABORATION_IDX = "CC_COLLABORATION_IDX";

export const CC_COLLABORATION_REMOVE_COLLABORATION =
  "CC_COLLABORATION_REMOVE_COLLABORATION";

export const MT_COLLABORATION_FAVORITE = "MT_COLLABORATION_FAVORITE";

export const CC_COLLABORATION_ISLASTPAGE_SET =
  "CC_COLLABORATION_ISLASTPAGE_SET";
export const CC_COLLABORATION_FILTER_DATA_SET =
  "CC_COLLABORATION_FILTER_DATA_SET";
export const LC_CAMPAIGNS_ERROR_SET = "LC_CAMPAIGNS_ERROR_SET";

export const CAMPAIGN_COLLABORATIONS_SET = "CAMPAIGN_COLLABORATIONS_SET";

export const SET_PLAYING_VIDEO = "SET_PLAYING_VIDEO";
export const SET_PLAYING_AUDIO = "SET_PLAYING_AUDIO";

export const CC_COLABORATION_EDIT = "CC_COLABORATION_EDIT";
export const CC_SELECTED_COLLABORATIONS_SET = "CC_SELECTED_COLLABORATIONS_SET";
export const CC_CAMPAIGN_CREATE_OR_UPDATE_FORMDATA_SET =
  "CC_CAMPAIGN_CREATE_OR_UPDATE_FORMDATA_SET";
export const CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_SET =
  "CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_SET";
export const CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_RESET =
  "CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_RESET";
export const CC_CAMPAIGN_CREATE_OR_UPDATE_FORMDATA_ITEM_SET =
  "CC_CAMPAIGN_CREATE_OR_UPDATE_FORMDATA_ITEM_SET";
export const CC_CAMPAIGN_CREATE_OR_UPDATE_FORMDATA_NESTED_ITEM_SET =
  "CC_CAMPAIGN_CREATE_OR_UPDATE_FORMDATA_NESTED_ITEM_SET";

export const LC_DATA_SORT = "LC_DATA_SORT";

export const UI_MODAL_SET = "UI_MODAL_SET";

export const LC_SET_CURRENT_CAMPAIGN = "SET_CURRENT_CAMPAIGN";

export const SETTINGS_FLUXUX_SET = "SETTINGS_FLUXUX_SET";
export const MT_CHANGE_INSTAGRAM_ACCESS_TOKEN =
  "MT_CHANGE_INSTAGRAM_ACCESS_TOKEN";
export const MT_CAMPAIGN_COLLABORATORS_COLLABORATION_REJECT =
  "MT_CAMPAIGN_COLLABORATORS_COLLABORATION_REJECT";
export const MT_CAMPAIGN_COLLABORATORS_COLLABORATION_APPROVE =
  "MT_CAMPAIGN_COLLABORATORS_COLLABORATION_APPROVE";
export const MT_COLLABORATION_REFRESH = "MT_COLLABORATION_REFRESH";
export const MT_COLLABORATION_GROUPED_BY_COLLABORATORS_REFRESH =
  "MT_COLLABORATION_GROUPED_BY_COLLABORATORS_REFRESH";
export const MT_CAMPAIGN_COLLABORATIONS_FILTER_SET =
  "MT_CAMPAIGN_COLLABORATIONS_FILTER_SET";
export const MT_COLABORATION_EDIT = "MT_COLABORATION_EDIT";
export const MT_COLABORATION_GROUPED_BY_COLLABORATORS_EDIT =
  "MT_COLABORATION_GROUPED_BY_COLLABORATORS_EDIT";

export const MT_CAMPAIGN_SET_TOTAL_CAMPAIGNS_AVAILABLE =
  "MT_CAMPAIGN_SET_TOTAL_CAMPAIGNS_AVAILABLE";

export const CAMPAIGN_COLLABORATIONS_ERROR_SET =
  "CAMPAIGN_COLLABORATIONS_ERROR_SET";
export const CAMPAIGN_COLLABORATIONS_COUNT_SET =
  "CAMPAIGN_COLLABORATIONS_COUNT_SET";
export const CAMPAIGN_COLLABORATIONS_GROUPED_SET_COLLABORATORS =
  "CAMPAIGN_COLLABORATIONS_GROUPED_SET_COLLABORATORS";
export const CAMPAIGN_COLLABORATIONS_GROUPED_SET_COLLABS =
  "CAMPAIGN_COLLABORATIONS_GROUPED_SET_COLLABS";
export const CAMPAIGN_UPDATE_SELECTED_GROUPED_ID =
  "CAMPAIGN_UPDATE_SELECTED_GROUPED_ID";
export const CAMPAIGN_COLLABORATIONS_GROUPED_SET_ANSWERS =
  "CAMPAIGN_COLLABORATIONS_GROUPED_SET_ANSWERS";
export const CAMPAIGN_GROUPED_CLEAN_ANSWERS = "CAMPAIGN_GROUPED_CLEAN_ANSWERS";

export const COLLABORATOR_SEND_EMAIL_FIELD_SET =
  "COLLABORATOR_SEND_EMAIL_FIELD_SET";
export const COLLABORATOR_SEND_EMAIL_FIELDS_SET_DEFAULT =
  "COLLABORATOR_SEND_EMAIL_FIELDS_SET_DEFAULT";
