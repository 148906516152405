<template>
  <Drawer
    @toggle-drawer="toggleDrawerInputSourceYoutube"
    :drawer="isYoutubeDrawerVisible"
    @reset-enabler="resetEnabler"
    @hard-update="hardUpdate"
    @reset-changes="reset"
    title="configurar youtube"
    :isFullHeight="true"
    drawerName="youtube"
    formRef="youtube-drawer-form"
    :areAllFieldsEmpty="areAllFieldsEmpty"
  >
    <template v-slot:drawer-content>
      <div class="modal-content__item">
        <CardHeader
          title="FILTRAR CONTEÚDO"
          subtitle="Cadastre usuários ou canais para receber conteúdo"
        ></CardHeader>
      </div>
      <div class="modal-content__item">
        <div class="input-item full">
          <v-combobox
            @change="normalizeText($event, 'channels', false)"
            v-model="localYoutubeConfig.channels"
            :rules="rules"
            deletable-chips
            multiple
            clearable
            outlined
            label="Adicione um ou mais ID's de canal do Youtube"
            color="graydark"
            prepend-inner-icon="fab fa-youtube"
            append-icon
          >
            <template v-slot:selection="{ attrs, item, selected, parent }">
              <v-chip
                label
                v-bind="attrs"
                :input-value="selected"
                close
                @click:close="parent.selectItem(item)"
                >{{ item }}
              </v-chip>
            </template>
            <div class="help" slot="append">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-information-outline</v-icon>
                </template>
                <span>
                  <strong>Exemplo</strong>
                  <br />
                  https://www.youtube.com/channel/<strong class="primary"
                    >UCzshJ2mSjxhqKFBUXqP49Uw</strong
                  >
                  <br />
                </span>
              </v-tooltip>
            </div>
          </v-combobox>
        </div>
      </div>
      <div class="modal-content__item">
        <div class="input-item full">
          <v-combobox
            @change="normalizeText($event, 'users', true)"
            v-model="localYoutubeConfig.users"
            :rules="rules"
            deletable-chips
            multiple
            outlined
            clearable
            label="Adicione um ou mais nomes de usuário do Youtube"
            color="graydark"
            prepend-inner-icon="mdi-account-box"
            append-icon
          >
            <template v-slot:selection="{ attrs, item, selected, parent }">
              <v-chip
                label
                v-bind="attrs"
                :input-value="selected"
                close
                @click:close="parent.selectItem(item)"
                >{{ item }}
              </v-chip>
            </template>
            <div class="help" slot="append">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-information-outline</v-icon>
                </template>
                <span>
                  <strong>Exemplo</strong>
                  <br />
                  https://www.youtube.com/user/<strong class="primary"
                    >vcsabiavideos</strong
                  >
                  <br />
                </span>
              </v-tooltip>
            </div>
          </v-combobox>
        </div>
      </div>
    </template>
  </Drawer>
</template>

<script>
import { mapState } from "vuex";

import CardHeader from "@/components/Commons/CardHeader";
import Drawer from "@/components/Commons/Dialogs/Drawer";
import { UI_YOUTUBE_DRAWER_HANDLE } from "@/store/actions";
import { updateCampaignItem } from "@/util/campaign";

const validateNoEmptySpace = val =>
  (!!val && !/\s/.test(val)) ||
  "Não inclua valores contendo espaço em branco neste campo.";

export default {
  name: "YoutubeDrawer",
  components: {
    CardHeader,
    Drawer
  },
  data() {
    return {
      localYoutubeConfig: {}
    };
  },
  computed: {
    ...mapState({
      formData: state => state.listCampaign.createOrEditCampaignParams.formData,
      isYoutubeDrawerVisible: state => state.ui.isYoutubeDrawerVisible,
      youtubeConfig: state =>
        state.listCampaign.createOrEditCampaignParams.formData
          .youtube_configuration
    }),
    areAllFieldsEmpty() {
      return (
        !this.localYoutubeConfig.channels?.length &&
        !this.localYoutubeConfig.users?.length
      );
    },

    rules() {
      return [validateNoEmptySpace];
    }
  },
  watch: {
    youtubeConfig: {
      handler() {
        this.localYoutubeConfig = { ...this.youtubeConfig };
      },
      deep: true
    }
  },
  methods: {
    hardUpdate() {
      updateCampaignItem(
        {
          ...this.formData.youtube_configuration,
          ...this.localYoutubeConfig
        },
        "youtube_configuration"
      );
    },
    resetEnabler(value) {
      this.localYoutubeConfig.is_enabled = value;
    },
    reset() {
      this.localYoutubeConfig = this.youtubeConfig;
    },
    toggleDrawerInputSourceYoutube(value) {
      this.$store.dispatch(UI_YOUTUBE_DRAWER_HANDLE, value ? value : false);
    },
    normalizeText(items, field, needLowerCase) {
      this.localYoutubeConfig[field] = [
        ...new Set(
          items.map(item =>
            needLowerCase ? item.trim().toLowerCase() : item.trim()
          )
        )
      ];
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/theme.scss";
@import "@/scss/modal.scss";
@import "@/scss/form-inputs.scss";

::v-deep .v-select__selections {
  margin-top: 0 !important;
  .v-chip {
    background-color: $color-primary-chip !important;
  }
}
.help {
  height: 24px;
  display: flex;
  align-items: center;
}
::v-deep .mdi-close::before {
  padding-right: 12px;
}
::v-deep .v-icon {
  font-size: 16px;
}
::v-deep .v-select__slot .v-input__append-inner:nth-child(3) {
  border: 0 !important;
}
</style>
