<template>
  <v-img class="grey lighten-4" :src="url" :aspect-ratio="180 / 296" contain />
</template>

<script>
export default {
  props: {
    posterUrl: {
      type: String,
      default: null
    }
  },

  computed: {
    url() {
      return this.posterUrl?.startsWith("data")
        ? this.posterUrl
        : this.posterUrl + "?CMS_CACHE_KEY=_";
    }
  }
};
</script>
