import { COLLABORATOR_SEND_EMAIL_FIELD_HANDLE } from "@/store/actions";
import {
  COLLABORATOR_SEND_EMAIL_FIELD_SET,
  COLLABORATOR_SEND_EMAIL_FIELDS_SET_DEFAULT
} from "@/store/mutations";

const defaultCollaboratorEmailState = () => {
  return {
    subject: "",
    message: "",
    defaultFooterMessage:
      "Não responda este e-mail. Esta mensagem foi enviada de uma caixa de correio não monitorada.",
    defaultOptionalMessageFooter: `Quer fazer novo envio de conteúdo? Acesse: ${process.env.VUE_APP_COLABORATIVO_FORM_URI}`,
    optionalMessageFooterApplied: false
  };
};

const state = {
  collaboratorSendEmailFields: defaultCollaboratorEmailState()
};

const getters = {
  message: (state, getters) =>
    `${state.collaboratorSendEmailFields.message}<br><br><br>${
      state.collaboratorSendEmailFields.defaultFooterMessage
    }${
      state.collaboratorSendEmailFields.optionalMessageFooterApplied
        ? `<br>${getters.optionalMessageFooter}`
        : ""
    }`,
  optionalMessageFooter: (state, rootGetters) =>
    `${state.collaboratorSendEmailFields.defaultOptionalMessageFooter}${rootGetters.campaign.id}`
};

const actions = {
  [COLLABORATOR_SEND_EMAIL_FIELD_HANDLE]: ({ commit }, { field, value }) => {
    commit(COLLABORATOR_SEND_EMAIL_FIELD_SET, { field, value });
  }
};

const mutations = {
  [COLLABORATOR_SEND_EMAIL_FIELD_SET]: (state, { field, value }) => {
    state.collaboratorSendEmailFields[field] = value;
  },
  [COLLABORATOR_SEND_EMAIL_FIELDS_SET_DEFAULT]: state => {
    state.collaboratorSendEmailFields = defaultCollaboratorEmailState();
  }
};

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};
