import { render, staticRenderFns } from "./TheCollaborationCreationNavDrawer.vue?vue&type=template&id=86325ab4&scoped=true&"
import script from "./TheCollaborationCreationNavDrawer.vue?vue&type=script&lang=js&"
export * from "./TheCollaborationCreationNavDrawer.vue?vue&type=script&lang=js&"
import style0 from "./TheCollaborationCreationNavDrawer.vue?vue&type=style&index=0&id=86325ab4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86325ab4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBadge,VBtn,VFileInput,VForm,VIcon,VNavigationDrawer,VSubheader,VTab,VTabItem,VTabs,VTextField,VTextarea})
