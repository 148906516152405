import Vue from "vue";
import VueRouter from "vue-router";

import Campaign from "@/views/Campaign";
import CampaignList from "@/views/CampaignList";
import UserPreferences from "@/views/UserPreferences";

import { tokenManager } from "./services/auth";
import CampaignEditor from "./views/CampaignEditor.vue";
import Login from "./views/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/callback",
    name: "callback",
    component: Login,
    props: { isCallback: true }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    props: { isCallback: false }
  },
  {
    path: "/",
    name: "campaignlist",
    component: CampaignList
  },
  {
    path: "/campaign_details/:campaignId",
    name: "campaignDetails",
    props: true,
    component: Campaign
  },
  {
    path: "/campaign_editor/:campaignId?",
    name: "campaignEditor",
    props: true,
    component: CampaignEditor
  },
  {
    path: "/user_preferences",
    name: "userPreferences",
    props: true,
    component: UserPreferences
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = router.app
    ? router.app.$options.store.state.auth.isLoggedIn
    : false;
  if (to.path === "/callback") next();
  else if (to.path === "/login" && isLoggedIn) next("/");
  else if (to.path !== "/login" && !isLoggedIn) {
    tokenManager
      .refresh()
      .then(({ expiration, server_time }) => {
        tokenManager.scheduleNextRefresh(expiration, server_time);
        next();
      })
      .catch(() => next("/login"));
  } else next();
});

export default router;
