import { EncryptorManager } from "./encryptor";

export const decryptWhatsappConfigurationData = dataForDecrypt => {
  const encryptManager = new EncryptorManager();
  if (Array.isArray(dataForDecrypt)) {
    const decryptedArray = [];
    if (dataForDecrypt.length === 0) {
      return decryptedArray;
    }
    if (dataForDecrypt[0].phoneNumber) {
      for (let whatsapp of dataForDecrypt) {
        if (isNaN(whatsapp.phoneNumber)) {
          const number = encryptManager.decrypt(whatsapp.phoneNumber);
          decryptedArray.push({
            ...whatsapp,
            phoneNumber: number
          });
        }
      }
      return decryptedArray;
    }
    for (let item of dataForDecrypt) {
      const itemDecrypted = encryptManager.decrypt(item);
      decryptedArray.push(itemDecrypted);
    }
    return decryptedArray;
  } else {
    const decryptedString = encryptManager.decrypt(dataForDecrypt);
    return decryptedString;
  }
};
