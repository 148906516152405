<template>
  <div class="container">
    <!-- Topo -->
    <div class="topo">
      <div class="title"><span>Lista</span> de Campanhas recuperáveis</div>
    </div>

    <div class="main">
      <v-data-table
        :fixed-header="true"
        :headers="headers"
        :items="snapshots"
        class="table-main"
        height="100%"
        :mobile-breakpoint="1200"
        sort-by="name"
        :search="search"
        :loading="isLoading"
        loading-text="Carregando Snapshots..."
      >
        <template v-slot:[`item.deleted_at`]="{ item }">
          {{ convert_timestamp_to_string(item.deleted_at) }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-2"
                v-on="on"
                @click="restoreCampaign(item)"
              >
                published_with_changes
              </v-icon>
            </template>
            <span>Restaurar Campanha</span>
          </v-tooltip>
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" @click="deleteItem(item)" v-on="on">
                delete
              </v-icon>
            </template>
            <span>Remover snapshot</span>
          </v-tooltip> -->
        </template>
        <template v-slot:[`item.deleted_by_user`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                {{ item.deleted_by_user.name }}
              </span>
            </template>
            <span>
              <strong>Id:</strong>{{ item.deleted_by_user.id }}<br />
              <strong>E-mail:</strong>{{ item.deleted_by_user.email }}
            </span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
    <div class="footer search">
      <!-- Search -->
      <div class="slot left">
        <v-text-field
          v-model="search"
          solo
          rounded
          clearable
          flat
          hide-details
          label="Pesquise por campanhas excluídas..."
          append-icon="search"
          color="primary"
          clear-icon="close"
          background-color="#F5F5F5"
        ></v-text-field>
      </div>

      <!-- Actions -->
      <div class="slot right">
        <v-btn large color="seccondary" @click="$emit('cancelClickedEvent')">
          <v-icon>mdi-close</v-icon>
          Cancelar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import {
  // LC_CAMPAIGNS_SNAPSHOT_DELETE,
  LC_CAMPAIGNS_SNAPSHOT_GET_ALL,
  LC_CAMPAIGNS_SNAPSHOT_RESTORE
} from "@/store/actions";
import Bus from "@/util/EventBus";

export default {
  data: () => ({
    isLoading: false,
    search: "",
    snapshots: [],
    headers: [
      {
        text: "ID da Campanha",
        align: "center",
        sortable: true,
        value: "id",
        width: "1%"
      },
      {
        text: "Nome",
        align: "center",
        sortable: true,
        value: "name"
      },
      {
        text: "Excluído por",
        align: "center",
        sortable: true,
        value: "deleted_by_user"
      },
      {
        text: "Excluído em",
        align: "center",
        sortable: true,
        value: "deleted_at"
      },
      { text: "Ações", value: "action", align: "right", sortable: false }
    ]
  }),
  created() {
    if (this.drawer) this.fetchData();
  },
  computed: {
    ...mapState({ currentTab: state => state.ui.currentTab }),
    ...mapState({ drawer: state => state.ui.drawer })
  },
  watch: {
    drawer() {
      if (this.drawer && this.currentTab === "campaign-snapshot")
        this.fetchData();
    },
    currentTab() {
      if (this.drawer && this.currentTab === "campaign-snapshot")
        this.fetchData();
    }
  },
  methods: {
    convert_timestamp_to_string(timestamp) {
      const date = new Date(timestamp * 1000);
      return date.toLocaleString("pt-br");
    },
    async fetchData() {
      this.isLoading = true;
      this.snapshots = await this.$store.dispatch(
        LC_CAMPAIGNS_SNAPSHOT_GET_ALL
      );
      this.isLoading = false;
    },
    async restoreCampaign(snapshot) {
      const index = this.snapshots.indexOf(snapshot);
      const configMessage = {
        icon: "check",
        title: {
          featured: "Restaurar",
          text: "Campanha"
        },
        message: {
          featured: `Você tem certeza que deseja restaurar a campanha ${snapshot.name} ?`,
          text: `A campanha voltará a ficar disponível para todos os usuários que possuiam acesso a ela.`
        },
        action: {
          color: "success",
          title: "Restaurar"
        }
      };
      Bus.$emit("openModalConfirm", configMessage, async callback => {
        if (callback) {
          if (
            await this.$store.dispatch(
              LC_CAMPAIGNS_SNAPSHOT_RESTORE,
              snapshot.id
            )
          ) {
            this.snapshots.splice(index, 1);
          }
        }
      });
    }
    // async deleteItem(snapshot) {
    //   const index = this.snapshots.indexOf(snapshot);
    //   const configMessage = {
    //     icon: "delete_forever",
    //     title: {
    //       featured: "Remover",
    //       text: "Snapshot da Campanha"
    //     },
    //     message: {
    //       featured: "",
    //       text: `Você tem certeza que deseja apagar este snapshot ? Não será mais possível restaurar a campanha por métodos convencionais.`
    //     },
    //     action: {
    //       color: "error",
    //       title: "Remover"
    //     }
    //   };
    //   Bus.$emit("openModalConfirm", configMessage, async callback => {
    //     if (callback) {
    //       if (
    //         await this.$store.dispatch(
    //           LC_CAMPAIGNS_SNAPSHOT_DELETE,
    //           snapshot.id
    //         )
    //       ) {
    //         this.snapshots.splice(index, 1);
    //       }
    //     }
    //   });
    // }
  }
};
</script>

<style lang="scss">
@import "@/scss/theme.scss";
@import "@/scss/navigation-drawer.scss";
</style>
