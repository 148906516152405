<template>
  <div class="users">
    <div class="item margin">
      <v-form v-model="candidateIsOK">
        <v-text-field
          :loading="loading"
          :disabled="loading"
          v-model="candidate"
          :rules="[onlyUniqueUserRule]"
          @keydown.enter.prevent.stop="addNewUser"
          label="Nome do usuário a ser monitorado"
          placeholder="Digite o exato nome de um usuário do instagram."
        ></v-text-field>
      </v-form>
      <v-btn
        large
        light
        color="primary"
        :loading="loading"
        :disabled="!candidate || !candidateIsOK"
        @click="addNewUser"
      >
        <v-icon left>add_task</v-icon>
        Adicionar</v-btn
      >
    </div>
    <v-divider vertical></v-divider>
    <div class="item margin">
      <v-combobox
        v-model="localUsernames"
        prepend-icon="people"
        :rules="[moreThanZeroItemsRule]"
        filled
        :items="instagramUsernames"
        rounded
        chips
        readonly
        deletable-chips
        multiple
        item-text="name"
        item-value="id"
        placeholder="Digite o nome do usuário no campo acima para adicionar novos usuários."
        label="Usuários selecionados"
        color="primary"
        class="textfield"
        append-icon
      >
        <template v-slot:selection="{ attrs, item, selected }">
          <v-chip
            v-bind="attrs"
            :input-value="selected"
            close
            @click:close="removeUser(item)"
            color="primary"
          >
            <v-avatar left>
              <v-img :src="item.profile_picture_url"> </v-img>
            </v-avatar>

            <strong>{{ item.name }} - ({{ item.username }})</strong>&nbsp;
          </v-chip>
        </template>
      </v-combobox>
    </div>
  </div>
</template>

<script>
import { CC_INSTAGRAM_CHECK_IF_USERNAME_EXIST } from "@/store/actions";
export default {
  props: {
    instagramUsernames: {
      type: Array
    },
    totalInstagramUsersFollows: {
      type: Number
    },
    ig_user_id: {
      type: String
    }
  },
  mounted() {
    this.localUsernames = [...this.instagramUsernames];
  },
  data: () => ({
    candidateIsOK: false,
    localUsernames: [],
    loading: false,
    candidate: ""
  }),
  computed: {
    moreThanZeroItemsRule() {
      return this.totalInstagramUsersFollows
        ? true
        : "Selecione pelo menos um usuário  válido ou hashtag.";
    }
  },
  methods: {
    async addNewUser() {
      if (!this.candidate || !this.candidateIsOK) {
        return;
      }
      this.loading = true;
      try {
        const userData = await this.$store.dispatch(
          CC_INSTAGRAM_CHECK_IF_USERNAME_EXIST,
          {
            ig_user_id: this.ig_user_id,
            username: this.candidate
          }
        );
        this.localUsernames.push(userData);
        this.candidate = "";
        this.$emit("watchedUsernamesChanged", this.localUsernames);
      } finally {
        this.loading = false;
      }
    },
    removeUser(item) {
      this.localUsernames = this.localUsernames.filter(
        el => el.user_id !== item.user_id
      );
      this.$emit("watchedUsernamesChanged", this.localUsernames);
    },
    onlyUniqueUserRule() {
      const username_already_exist = this.instagramUsernames.some(
        account => account.username === this.candidate
      );
      return !username_already_exist || "Usuário já cadastrado.";
    }
  }
};
</script>
<style lang="scss" scoped>
.users {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  height: 100%;
  .item {
    flex-grow: 1;
    flex-direction: column;
  }
}
</style>
