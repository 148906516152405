<template>
  <BaseCard>
    <CardHeader :title="headerTitle" :subtitle="headerSubtitle">
      <template v-slot:right>
        <ButtonLink
          @click-handler="openDrawer()"
          icon="info_outline"
          label="Avançados"
        ></ButtonLink>
      </template>
    </CardHeader>

    <div class="modal-content__item">
      <div class="input-item full">
        <div class="select-divisor"></div>
        <div class="select-placeholder">
          Adicione um destino ou escolha entre as opções mais comuns...
        </div>
        <AutocompleteGroup
          @update="updateOutputs($event)"
          :items="clients"
          :itemText="item => item.name"
          prependIcon="mdi-map-marker-plus"
          :selectedItems="formData.outputs"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import AutocompleteGroup from "@/components/Commons/AutocompleteGroup";
import BaseCard from "@/components/Commons/BaseCard";
import ButtonLink from "@/components/Commons/Buttons/ButtonLink";
import CardHeader from "@/components/Commons/CardHeader";
import { UI_DESTINATIONS_DRAWER_HANDLE } from "@/store/actions";
import { updateCampaignItem } from "@/util/campaign";

export default {
  components: {
    AutocompleteGroup,
    BaseCard,
    ButtonLink,
    CardHeader
  },
  data() {
    return {
      headerTitle: "DESTINOS",
      headerSubtitle:
        "Configure opções de saída para enviar o conteúdo da sua campanha"
    };
  },
  computed: {
    ...mapState({
      formData: state => state.listCampaign.createOrEditCampaignParams.formData
    }),
    ...mapGetters({
      clients: "createOrUpdateSortedApiClients"
    })
  },
  methods: {
    updateOutputs(value) {
      updateCampaignItem(value, "outputs");
    },
    openDrawer() {
      this.$store.dispatch(UI_DESTINATIONS_DRAWER_HANDLE, true);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";
@import "@/scss/form-inputs.scss";

.box {
  font-family: $secondary-fontface;
  background-color: #fff;

  &.full {
    width: 100%;
  }

  .box-title {
    margin: 0 0 8px 0;
    font-size: 0.88em;
    font-weight: 600;
    color: $color-black-80;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  .box-subtitle {
    margin: 0 0 16px 0;
    font-size: 0.8em;
    font-weight: 400;
    color: $color-black-64;
    text-transform: capitalize;
    letter-spacing: 0.1em;
  }

  .divisor {
    flex: 1;
    height: 1px;
    margin: 16px 0;
    // background-color: $color-black-08;
  }

  .box-description {
    font-size: 1em;
    font-weight: 400;
    color: $color-black-80;
  }

  .box-body {
    display: flex;
  }
}
</style>
