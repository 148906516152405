<template>
  <div class="wrapper">
    <GradientTopBackground />

    <AppBar :leftButton="leftButton" title="Preferências de usuário">
      <template v-slot:secondary-actions>
        <ButtonSecondary
          @click-handler="resetPreferences"
          label="Resetar"
          icon="mdi-backspace-reverse-outline"
        />
      </template>
      <template v-slot:primary-actions>
        <ButtonPrimary
          @click-handler="hardUpdate"
          color="primary"
          label="Salvar"
          icon="done"
        />
      </template>
    </AppBar>

    <div class="form-wrapper">
      <v-form class="form" v-model="formValid" ref="form">
        <CampaignForm :favoriteCampaigns.sync="favoriteCampaignsHandler" />
        <CollaborationForm :preferredView.sync="preferredView" />
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import AppBar from "@/components/AppBars/AppBar";
import ButtonPrimary from "@/components/Commons/Buttons/ButtonPrimary";
import ButtonSecondary from "@/components/Commons/Buttons/ButtonSecondary";
import GradientTopBackground from "@/components/Commons/GradientTopBackground";
import CampaignForm from "@/components/UserPreferences/CampaignForm";
import CollaborationForm from "@/components/UserPreferences/CollaborationForm";
import { AUTH_PREFERENCES_HANDLER } from "@/store/actions";
import Bus from "@/util/EventBus";
export default {
  components: {
    AppBar,
    ButtonPrimary,
    ButtonSecondary,
    CampaignForm,
    CollaborationForm,
    GradientTopBackground
  },
  data() {
    return {
      defaultPreferences: {
        campaigns: [],
        view: "card"
      },
      leftButton: {
        icon: "arrow_back",
        clickHandler: () => {
          if (
            JSON.stringify(this.preferences) !==
            JSON.stringify(this._preferences)
          ) {
            const configMessage = {
              icon: "mdi-alert-outline",
              title: {
                featured: "Atenção",
                text: "Existem alterações"
              },
              message: {
                featured: "Tem certeza que deseja sair?",
                text:
                  "Ao prosseguir todas as alterações realizadas NÃO serão salvas"
              },
              action: {
                color: "primary",
                title: "sair"
              }
            };
            Bus.$emit("openModalConfirm", configMessage, async callback => {
              if (callback) {
                this.$router.go("-1");
              }
            });
          } else {
            this.$router.go("-1");
          }
        }
      },
      formValid: false,
      preferences: {
        favorite_campaigns: [],
        preferred_view: ""
      }
    };
  },
  computed: {
    ...mapState({
      _preferences: state => state.auth.user.preferences,
      _favorite_campaigns: state =>
        state.auth.user.preferences.favorite_campaigns,
      _preferred_view: state => state.auth.user.preferences?.preferred_view
    }),
    favoriteCampaignsHandler: {
      get() {
        return this.preferences.favorite_campaigns;
      },
      set(value) {
        this.softUpdate("favorite_campaigns", value);
      }
    },
    preferredView: {
      get() {
        return this.preferences.preferred_view;
      },
      set(value) {
        this.softUpdate("preferred_view", value);
      }
    }
  },
  watch: {
    _favorite_campaigns: {
      handler(value) {
        this.softUpdate("favorite_campaigns", value);
      },
      immediate: true
    },
    _preferred_view: {
      handler(value) {
        this.softUpdate("preferred_view", value);
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions([AUTH_PREFERENCES_HANDLER]),
    softUpdate(field, value) {
      this.preferences[field] = value;
    },
    hardUpdate() {
      this.AUTH_PREFERENCES_HANDLER({
        campaigns: this.preferences.favorite_campaigns,
        view: this.preferences.preferred_view
      });
      this.$router.push("/");
    },
    resetPreferences() {
      const configMessage = {
        icon: "mdi-alert-outline",
        title: {
          featured: "Atenção",
          text: "Resetar para o modelo padrão"
        },
        message: {
          featured: "Tem certeza que deseja resetar as preferências?",
          text: "Ao prosseguir todas as suas preferências voltarão a ser padrão"
        },
        action: {
          color: "primary",
          title: "resetar"
        }
      };
      Bus.$emit("openModalConfirm", configMessage, async callback => {
        if (callback) {
          this.AUTH_PREFERENCES_HANDLER(this.defaultPreferences);
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/theme.scss";
@import "@/scss/modal.scss";
@import "@/scss/form-inputs.scss";

.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: $color-background;
  min-height: 100vh;

  > .form-wrapper {
    margin: 105px 10vw 24px;

    @include for-size(tablet-landscape-up) {
      margin: 105px 2vw 24px;
    }
    @include for-size(phone-only) {
      margin: 105px 0 0;
    }
  }
}
</style>
