import { UI_DRAWER_SET } from "../store/mutations";

export default {
  data() {
    return {
      showList: true,
      editItemData: null,
      reauthenticateItemData: null
    };
  },
  computed: {
    drawer: {
      get() {
        return this.$store.state.ui.drawer;
      },
      set(value) {
        this.$store.commit(UI_DRAWER_SET, value);
      }
    }
  }
};
