// GA DIMENSION5 PERSONAS
const PERSONA_CAMPAIGN_MANAGER = "CAMPAIGN_MANAGER";
const PERSONA_CAMPAIGN_MODERATOR = "CAMPAIGN_MODERATOR";

// GA ACTIONS
const CAMPAIGN_CREATE_BEGAN = "CAMPAIGN_CREATE_BEGAN";
const CAMPAIGN_CREATE_COMPLETED = "CAMPAIGN_CREATE_COMPLETED";
const CAMPAIGN_EDIT_BEGAN = "CAMPAIGN_EDIT_BEGAN";
const CAMPAIGN_EDIT_COMPLETED = "CAMPAIGN_EDIT_COMPLETED";
const CAMPAIGN_ENABLED = "CAMPAIGN_ENABLED";
const CAMPAIGN_DISABLED = "CAMPAIGN_DISABLED";
const CAMPAIGN_URL_COPIED = "CAMPAIGN_URL_COPIED";
const CAMPAIGN_URL_OPENED = "CAMPAIGN_URL_OPENED";
const CAMPAIGN_URL_QRCODEGENERATED = "CAMPAIGN_URL_QRCODEGENERATED";
const CAMPAIGN_DELETED = "CAMPAIGN_DELETED";
const CAMPAIGN_METRICSREPORT_GENERATED = "CAMPAIGN_METRICSREPORT_GENERATED";
const CAMPAIGN_COLLABORATION_TABLEVIEW_SET =
  "CAMPAIGN_COLLABORATION_TABLEVIEW_SET";
const CAMPAIGN_COLLABORATION_CARDVIEW_SET =
  "CAMPAIGN_COLLABORATION_CARDVIEW_SET";
const CAMPAIGN_COLLABORATION_CONVERSATIONVIEW_SET =
  "CAMPAIGN_COLLABORATION_CONVERSATIONVIEW_SET";
const CAMPAIGN_CONTENT_FILTERED = "CAMPAIGN_CONTENT_FILTERED";
const CAMPAIGN_COLLABORATOR_FILTERED = "CAMPAIGN_COLLABORATOR_FILTERED";
const CAMPAIGN_COLLABORATION_CREATED = "CAMPAIGN_COLLABORATION_CREATED";

const COLLABORATION_MEDIA_DOWNLOADED = "COLLABORATION_MEDIA_DOWNLOADED";
const COLLABORATION_APPROVED = "COLLABORATION_APPROVED";
const COLLABORATION_REJECTED = "COLLABORATION_REJECTED";
const COLLABORATION_EDITED = "COLLABORATION_EDITED";
const COLLABORATION_COPIED = "COLLABORATION_COPIED";
const COLLABORATION_FAVORITED = "COLLABORATION_FAVORITED";
const COLLABORATION_REMOVED = "COLLABORATION_REMOVED";
const COLLABORATION_BATCH_APPROVED = "COLLABORATION_BATCH_APPROVED";
const COLLABORATION_BATCH_REMOVED = "COLLABORATION_BATCH_REMOVED";
const COLLABORATION_BATCH_MOVED = "COLLABORATION_BATCH_MOVED";
const COLLABORATION_BATCH_DOWNLOADED = "COLLABORATION_BATCH_DOWNLOADED";
const COLLABORATION_BATCH_REJECTED = "COLLABORATION_BATCH_REJECTED";
const COLLABORATION_AUDIO_PLAYED = "COLLABORATION_AUDIO_PLAYED";
const COLLABORATION_VIDEO_PLAYED = "COLLABORATION_VIDEO_PLAYED";

class Analytics {
  _vue;

  setVue(vue) {
    this._vue = vue;
  }

  _sendEvent(action, category = null, label = null, dimensions = null) {
    if (!this._vue) {
      throw "vue not set";
    }
    this._vue.$gtag.customMap({
      dimension1: "productId1",
      dimension2: "campaignId1",
      dimension3: "productId2",
      dimension4: "campaignId2",
      dimension5: "persona"
    });
    this._vue.$gtag.event(action, {
      event_category: category,
      event_label: label,
      ...dimensions
    });
  }

  _sendCampaignEvent(campaignId, persona, action, label = null) {
    this._sendEvent(action, "CAMPAIGN", label, {
      campaignId1: campaignId,
      persona: persona
    });
  }

  _sendCollaborationEvent(campaignId, persona, action, label = null) {
    this._sendEvent(action, "COLLABORATION", label, {
      campaignId1: campaignId,
      persona: persona
    });
  }

  //
  // Campaign events
  //

  createCampaignBegan() {
    this._sendCampaignEvent(0, PERSONA_CAMPAIGN_MANAGER, CAMPAIGN_CREATE_BEGAN);
  }
  createCampaignCompleted() {
    this._sendCampaignEvent(
      0,
      PERSONA_CAMPAIGN_MANAGER,
      CAMPAIGN_CREATE_COMPLETED
    );
  }

  editCampaignBegan(campaignId) {
    this._sendCampaignEvent(
      campaignId,
      PERSONA_CAMPAIGN_MANAGER,
      CAMPAIGN_EDIT_BEGAN
    );
  }
  editCampaignCompleted(campaignId) {
    this._sendCampaignEvent(
      campaignId,
      PERSONA_CAMPAIGN_MANAGER,
      CAMPAIGN_EDIT_COMPLETED
    );
  }

  enableCampaign(campaignId) {
    this._sendCampaignEvent(
      campaignId,
      PERSONA_CAMPAIGN_MANAGER,
      CAMPAIGN_ENABLED
    );
  }

  disableCampaign(campaignId) {
    this._sendCampaignEvent(
      campaignId,
      PERSONA_CAMPAIGN_MANAGER,
      CAMPAIGN_DISABLED
    );
  }

  copiedUrlForCampaign(url, campaignId) {
    this._sendCampaignEvent(
      campaignId,
      PERSONA_CAMPAIGN_MANAGER,
      CAMPAIGN_URL_COPIED,
      `Copied url ${url} for ${campaignId}`
    );
  }
  generateUrlQrCodeForCampaign(url, campaignId) {
    this._sendCampaignEvent(
      campaignId,
      PERSONA_CAMPAIGN_MANAGER,
      CAMPAIGN_URL_QRCODEGENERATED,
      `Generated QR code ${url} for ${campaignId}`
    );
  }
  openUrlForCampaign(url, campaignId) {
    this._sendCampaignEvent(
      campaignId,
      PERSONA_CAMPAIGN_MANAGER,
      CAMPAIGN_URL_OPENED,
      `Openned url ${url} for ${campaignId}`
    );
  }

  deleteCampaign(campaignId) {
    this._sendCampaignEvent(
      campaignId,
      PERSONA_CAMPAIGN_MANAGER,
      CAMPAIGN_DELETED
    );
  }

  generateCampaignMetricsReport(campaignId) {
    this._sendCampaignEvent(
      campaignId,
      PERSONA_CAMPAIGN_MANAGER,
      CAMPAIGN_METRICSREPORT_GENERATED
    );
  }

  setCollaborationCardViewForCampaign(campaignId) {
    this._sendCampaignEvent(
      campaignId,
      PERSONA_CAMPAIGN_MODERATOR,
      CAMPAIGN_COLLABORATION_CARDVIEW_SET
    );
  }
  setCollaborationTableViewForCampaign(campaignId) {
    this._sendCampaignEvent(
      campaignId,
      PERSONA_CAMPAIGN_MODERATOR,
      CAMPAIGN_COLLABORATION_TABLEVIEW_SET
    );
  }
  setCollaborationConversationViewForCampaign(campaignId) {
    this._sendCampaignEvent(
      campaignId,
      PERSONA_CAMPAIGN_MODERATOR,
      CAMPAIGN_COLLABORATION_CONVERSATIONVIEW_SET
    );
  }

  filterCollaborations(campaignId) {
    this._sendCampaignEvent(
      campaignId,
      PERSONA_CAMPAIGN_MODERATOR,
      CAMPAIGN_CONTENT_FILTERED
    );
  }

  filterCollaborator(campaignId) {
    this._sendCampaignEvent(
      campaignId,
      PERSONA_CAMPAIGN_MODERATOR,
      CAMPAIGN_COLLABORATOR_FILTERED
    );
  }

  sendCollaboration(campaignId) {
    this._sendCampaignEvent(
      campaignId,
      PERSONA_CAMPAIGN_MODERATOR,
      CAMPAIGN_COLLABORATION_CREATED
    );
  }

  //
  // Collaboration events
  //

  downloadCollaborationMedia(campaignId) {
    this._sendCollaborationEvent(
      campaignId,
      PERSONA_CAMPAIGN_MODERATOR,
      COLLABORATION_MEDIA_DOWNLOADED
    );
  }

  approveCollaboration(campaignId) {
    this._sendCollaborationEvent(
      campaignId,
      PERSONA_CAMPAIGN_MODERATOR,
      COLLABORATION_APPROVED
    );
  }

  rejectCollaboration(campaignId) {
    this._sendCollaborationEvent(
      campaignId,
      PERSONA_CAMPAIGN_MODERATOR,
      COLLABORATION_REJECTED
    );
  }

  editCollaboration(campaignId) {
    this._sendCollaborationEvent(
      campaignId,
      PERSONA_CAMPAIGN_MODERATOR,
      COLLABORATION_EDITED
    );
  }

  copyCollaboration(campaignId) {
    this._sendCollaborationEvent(
      campaignId,
      PERSONA_CAMPAIGN_MODERATOR,
      COLLABORATION_COPIED
    );
  }

  favoriteCollaboration(campaignId) {
    this._sendCollaborationEvent(
      campaignId,
      PERSONA_CAMPAIGN_MODERATOR,
      COLLABORATION_FAVORITED
    );
  }

  removeCollaboration(campaignId) {
    this._sendCollaborationEvent(
      campaignId,
      PERSONA_CAMPAIGN_MODERATOR,
      COLLABORATION_REMOVED
    );
  }

  downloadCollaborationBatch(campaignId) {
    this._sendCollaborationEvent(
      campaignId,
      PERSONA_CAMPAIGN_MODERATOR,
      COLLABORATION_BATCH_DOWNLOADED
    );
  }

  approveCollaborationBatch(campaignId) {
    this._sendCollaborationEvent(
      campaignId,
      PERSONA_CAMPAIGN_MODERATOR,
      COLLABORATION_BATCH_APPROVED
    );
  }

  rejectCollaborationBatch(campaignId) {
    this._sendCollaborationEvent(
      campaignId,
      PERSONA_CAMPAIGN_MODERATOR,
      COLLABORATION_BATCH_REJECTED
    );
  }

  moveCollaborationBatch(campaignId) {
    this._sendCollaborationEvent(
      campaignId,
      PERSONA_CAMPAIGN_MODERATOR,
      COLLABORATION_BATCH_MOVED
    );
  }

  removeCollaborationBatch(campaignId) {
    this._sendCollaborationEvent(
      campaignId,
      PERSONA_CAMPAIGN_MODERATOR,
      COLLABORATION_BATCH_REMOVED
    );
  }

  playCollaborationAudio(campaignId) {
    this._sendCollaborationEvent(
      campaignId,
      PERSONA_CAMPAIGN_MODERATOR,
      COLLABORATION_AUDIO_PLAYED
    );
  }

  playCollaborationVideo(campaignId) {
    this._sendCollaborationEvent(
      campaignId,
      PERSONA_CAMPAIGN_MODERATOR,
      COLLABORATION_VIDEO_PLAYED
    );
  }
}

const analytics = new Analytics();
export default analytics;
