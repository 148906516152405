<template>
  <v-navigation-drawer
    class="drawerBox"
    v-model="drawer"
    app
    temporary
    right
    overlay-opacity="0.88"
    stateless
    v-click-outside="closeDrawer"
  >
    <div class="box_wrapper" v-if="collaboration">
      <GradientTopBackground />
      <AppBar :leftButton="leftButton" :title="appbarTitle">
        <template v-slot:secondary-actions>
          <v-menu bottom content-class="elevation-4">
            <template v-slot:activator="{ on }">
              <Button v-on="on" label="Mais Ações" icon="more_vert"></Button>
            </template>
            <v-list min-width="240">
              <v-list-item link @click.prevent="deleteCollaborationHandler">
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Remover</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link @click.prevent="navigateToPrevious">
                <v-list-item-icon>
                  <v-icon>keyboard_arrow_up</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Anterior</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link @click.prevent="navigateToNext">
                <v-list-item-icon>
                  <v-icon>keyboard_arrow_down</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Próxima</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <Button
            label="Salvar"
            @click-handler="editCollaboration"
            :disabled="!hadChanges"
            icon="save"
          ></Button>
        </template>

        <template v-slot:primary-actions>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                large
                dark
                color="refuse"
                :class="{ square: true, '--disabled': !canReject }"
                @click="rejectCollaboration(collaborationIdx, collaboration)"
              >
                <v-icon> close </v-icon>
              </v-btn>
            </template>
            <span>Reprovar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                large
                dark
                color="approve"
                :class="{ square: true, '--disabled': !canApprove }"
                @click="approveCollaboration(collaborationIdx, collaboration)"
              >
                <v-icon> check </v-icon>
              </v-btn>
            </template>
            <span>Aprovar</span>
          </v-tooltip>
        </template>
      </AppBar>
      <div class="wrapper">
        <div class="media_drawer">
          <div
            class="media_item"
            v-if="hasPreviewableMedia"
            @mouseover="isCycling = true"
            @mouseleave="isCycling = false"
          >
            <MediaCarousel
              :isCard="false"
              :medias="collaboration.medias"
              :isCycling="isCycling"
              :campaignId="collaboration.campaign_id"
            />
          </div>
          <div class="noImage" v-else>
            <v-icon>image_not_supported</v-icon>
            <span>Sem mídia vinculada</span>
            <span class="subtitle"
              >Essa colaboração só possui conteúdo de texto</span
            >
          </div>
        </div>
        <div class="container_box">
          <div class="title--medium d-flex justify-space-between">
            <div>
              Edição
              <span>de Conteúdo</span>
            </div>
            <div class="dateAndTime">
              <div class="date">{{ collaborationDate }}</div>
              <div class="time">{{ collaborationTime }}</div>
            </div>
          </div>

          <div class="title--description">
            Gerencie as informações textuais da colaboração
          </div>

          <v-row no-gutters class="mt-6">
            <v-col class="mr-3">
              <div class="input-item">
                <v-text-field
                  color="graydark"
                  label="Nome"
                  placeholder="Adicionar nome de colaborador"
                  outlined
                  hide-details
                  prepend-inner-icon="person"
                  v-model="formData.name"
                />
              </div>
            </v-col>
            <v-col>
              <div class="input-item">
                <v-text-field
                  color="graydark"
                  :label="formData.complements && formData.complements.label"
                  placeholder="Segue dados de E-mail"
                  outlined
                  readonly
                  hide-details
                  disabled
                  :prepend-inner-icon="formData.complements.icon"
                  :value="formData.complements.value"
                />
              </div>
            </v-col>
          </v-row>

          <v-row no-gutters v-if="typeIsForm" class="mt-6">
            <v-col class="mr-3">
              <div class="input-item">
                <v-text-field
                  color="graydark"
                  label="País"
                  placeholder="País"
                  outlined
                  readonly
                  hide-details
                  prepend-inner-icon="pin_drop"
                  :value="formData.complements.country"
                />
              </div>
            </v-col>
            <v-col class="mr-3">
              <div class="input-item">
                <v-text-field
                  color="graydark"
                  label="Estado"
                  placeholder="Estado"
                  outlined
                  readonly
                  hide-details
                  prepend-inner-icon="pin_drop"
                  :value="formData.complements.state"
                />
              </div>
            </v-col>
            <v-col>
              <div class="input-item">
                <v-text-field
                  color="graydark"
                  label="Cidade"
                  placeholder="Cidade"
                  outlined
                  readonly
                  hide-details
                  prepend-inner-icon="pin_drop"
                  :value="formData.complements.city"
                />
              </div>
            </v-col>
          </v-row>

          <div class="box-body mt-6">
            <div class="column full">
              <div class="input-item">
                <v-text-field
                  color="graydark"
                  label="Retranca"
                  placeholder="Adicionar Retranca"
                  outlined
                  hide-details
                  prepend-inner-icon="label"
                  v-model="formData.title"
                />
              </div>
            </div>
          </div>

          <div class="box-body mt-6 mb-6">
            <div class="column full">
              <div class="input-item">
                <v-textarea
                  color="graydark"
                  label="Descrição"
                  placeholder="Fale um pouco sobre sua colaboração..."
                  outlined
                  hide-details
                  no-resize
                  height="116px"
                  prepend-inner-icon="mdi-comment-text"
                  v-model="formData.message"
                />
              </div>
            </div>
          </div>

          <div class="drawer_tab">
            <v-tabs>
              <v-tab>Metadados</v-tab>
              <v-tab>Comentários</v-tab>

              <v-tab-item>
                <v-row>
                  <v-col
                    :class="{
                      'd-flex': true,
                      'justify-center': !getMetadados
                    }"
                  >
                    <div class="box-body" v-if="!!getMetadados">
                      <div class="column flex">
                        <div class="input-item">
                          <v-chip
                            class="group"
                            label
                            v-for="(item, i) in getMetadados"
                            :key="i"
                          >
                            <div class="group-content">
                              <div class="group-metadata">
                                <div class="group-metadata--name">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <span v-on="on"> {{ item.value }}</span>
                                    </template>
                                    <span>{{ item.value }}</span>
                                  </v-tooltip>
                                </div>
                                <div class="group-metadata--tag">
                                  {{ item.field }}
                                </div>
                              </div>
                            </div>
                          </v-chip>
                        </div>
                      </div>
                    </div>
                    <template v-else>
                      <Feedback
                        feedbackType="colaboration-view-feedback"
                        :emptyState="emptyState"
                        :isVisible="true"
                      />
                    </template>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <v-row>
                  <v-col>
                    <div class="box-body">
                      <div class="column full">
                        <div class="input-item">
                          <v-textarea
                            color="graydark"
                            label="Comentários"
                            placeholder="Registre aqui seu comentário sobre a colaboração..."
                            outlined
                            hide-details
                            no-resize
                            height="116px"
                            prepend-inner-icon="mdi-comment"
                            v-model="formData.comment"
                          />
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs>
          </div>
          <!-- // -->
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import * as R from "ramda";
import { mapGetters, mapState } from "vuex";

import AppBar from "@/components/AppBars/AppBar";
import Button from "@/components/Commons/Buttons/Button";
import GradientTopBackground from "@/components/Commons/GradientTopBackground";
import Feedback from "@/components/Feedback.vue";
import MediaCarousel from "@/components/MediaCarousel";
import { CollaborationState } from "@/enums";
import collaborationMixin from "@/mixins/collaboration";
import {
  CAMPAIGN_COLLABORATION_REFRESH,
  CAMPAIGN_COLLABORATIONS_SHOW_NEXT,
  CAMPAIGN_COLLABORATIONS_SHOW_PREVIOUS,
  CAMPAIGN_COLLABORATIONS_UPDATE,
  COLLABORATION_MEDIA_PLAYER_HANDLER
} from "@/store/actions";
import { UI_COLLABORATION_DRAWER_SET } from "@/store/mutations";
import {
  approveCollaboration,
  deleteCollaboration,
  rejectCollaboration
} from "@/util/collaborations";

export const defaultFormData = () => ({
  title: "",
  body: null,
  files: [],
  inputtype_id: null
});

export default {
  mixins: [collaborationMixin],
  components: {
    MediaCarousel,
    AppBar,
    Feedback,
    GradientTopBackground,
    Button
  },
  data() {
    return {
      approveCollaboration: approveCollaboration,
      rejectCollaboration: rejectCollaboration,
      isCycling: false,
      formData: defaultFormData(),
      collaboration_: null,
      appbarTitle: "Detalhes da Colaboração",
      leftButton: {
        icon: "arrow_back",
        clickHandler: () => (this.drawer = false)
      }
    };
  },
  computed: {
    ...mapState({
      collaborationDrawer: state => state.ui.collaborationDrawer
    }),
    ...mapGetters({
      collaboratorInfo: "curCollaboratorInfo",
      collaboration: "collaborationEdit",
      collaborationIdx: "collaborationIdx",
      viewMode: "viewMode"
    }),
    drawer: {
      get() {
        return this.collaborationDrawer;
      },
      set(value) {
        if (value) {
          this.$store.dispatch(CAMPAIGN_COLLABORATION_REFRESH);
        } else {
          this.$store.dispatch(COLLABORATION_MEDIA_PLAYER_HANDLER);
        }
        this.$store.commit(UI_COLLABORATION_DRAWER_SET, value);
      }
    },
    collaborator() {
      return this.viewMode === "conversation"
        ? { ...this.collaboratorInfo?.collaborator }
        : { ...this.collaboration?.collaborator };
    },
    emptyState() {
      return {
        label: "Tão vazio por aqui...",
        description: "Essa colaboração não possui metadados vinculados",
        bg: "icon bored"
      };
    },
    hadChanges() {
      const formDataCompare = R.pick(
        ["name", "title", "message", "comment", "email"],
        this.formData
      );
      const collaborationCompare = R.pick(
        ["name", "title", "message", "comment", "email"],
        this.extractFields(this.collaboration)
      );
      return !R.equals(collaborationCompare, formDataCompare);
    },
    getMetadados() {
      if (!Array.isArray(this.collaboration?.body?.metadatas)) {
        const metadatasArray = [];

        for (const key in this.collaboration?.body?.metadatas) {
          if (
            Object.prototype.hasOwnProperty.call(
              this.collaboration?.body?.metadatas,
              key
            )
          ) {
            const metadataObj = {
              field: key,
              value: this.collaboration?.body?.metadatas[key]
            };

            metadatasArray.push(metadataObj);
          }
        }

        const metadatas = metadatasArray.filter(item => item.value);
        return metadatasArray.length ? metadatas : false;
      }

      const metadatas = this.collaboration?.body?.metadatas?.filter(
        item => item.value
      );
      return Array.isArray(metadatas) && metadatas.length ? metadatas : false;
    },
    canReject() {
      return this.collaborationState !== CollaborationState.Rejected;
    },
    canApprove() {
      return (
        this.collaborationState !== CollaborationState.Rejected &&
        this.collaborationState !== CollaborationState.Approved
      );
    },
    typeIsForm() {
      return this.collaborator?.type === "form";
    },
    collaborationDate() {
      const d = new Date(this.formData.created_at);
      return d.toLocaleDateString("pt-br");
    },
    collaborationTime() {
      return new Date(this.formData.created_at).toLocaleTimeString("pt-br", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      });
    }
  },
  watch: {
    drawer() {
      this.refreshData();
    },
    collaboration() {
      this.refreshData();
    }
  },
  methods: {
    closeDrawer(event) {
      if (event.target.className.includes("v-overlay")) {
        this.drawer = false;
      }
    },
    refreshData() {
      this.collaboration_ = this.collaboration;
      this.formData = {
        ...this.formData,
        ...this.extractFields(this.collaboration)
      };
      // log para suporte
      // eslint-disable-next-line no-console
      console.log(
        "** COLABORAÇÃO **\n",
        JSON.parse(JSON.stringify(this.collaboration))
      );
    },

    extractFields(collaboration) {
      const {
        body: { title, message, comment },
        created_at
      } = collaboration;
      let complements = {};

      switch (this.collaborator?.type) {
        case "youtube":
          complements = {
            icon: "mdi-youtube",
            label: "ID do canal",
            value: this.collaborator.yt_channel_id
          };
          break;
        case "twitter":
          complements = {
            icon: "mdi-twitter",
            label: "username",
            value: this.collaborator.tw_user_screen_name
          };
          break;
        case "whatsapp":
          complements = {
            icon: "mdi-whatsapp",
            label: "Telefone",
            value: this.collaborator.phone
          };
          break;
        case "form":
        case "cms":
          complements = {
            icon: "email",
            label: "Email",
            value: this.collaborator.email
          };
          if (this.collaborator?.type == "form") {
            complements = {
              ...complements,
              country: this.collaborator.country,
              state: this.collaborator.state,
              city: this.collaborator.city
            };
          }
          break;

        case "uploader":
          complements.value = this.collaborator.email;
          break;

        case "instagram":
          complements = {
            icon: "mdi-instagram",
            label: "Username do Instagram",
            value: this.collaborator.insta_username
          };
          break;

        default:
          break;
      }

      return {
        name: this.collaborator.name,
        title,
        message,
        comment,
        complements,
        created_at
      };
    },
    deleteCollaborationHandler() {
      deleteCollaboration(this.collaborationIdx, this.collaboration);
      this.navigateToNext();
    },
    navigateToPrevious() {
      this.$store.dispatch(CAMPAIGN_COLLABORATIONS_SHOW_PREVIOUS);
    },
    navigateToNext() {
      this.$store.dispatch(CAMPAIGN_COLLABORATIONS_SHOW_NEXT);
    },
    async editCollaboration() {
      const payload = R.pick(
        ["name", "title", "message", "comment"],
        this.formData
      );
      await this.$store.dispatch(CAMPAIGN_COLLABORATIONS_UPDATE, payload);
    }
  }
};
</script>
<style lang="scss">
@import "@/scss/navigation-drawer.scss";
@import "@/scss/theme.scss";
@import "@/scss/theme.scss";
@import "@/scss/form-inputs.scss";

.drawerBox {
  width: 848px !important;
  @media only screen and (max-width: 800px) {
    width: 100% !important;
  }
}

.group {
  &:not(:last-child) {
    margin-right: 8px;
  }
}

.drawerBox aside {
  z-index: 10 !important;
  overflow: none;
}
.box_wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: $color-background;
  overflow: hidden;

  .base-card.header {
    width: calc(100% - 24px);
  }
  .wrapper {
    margin-top: 130px;
    width: 100%;
    // medias
    .media_drawer {
      width: 100%;
      flex: 1;
      min-height: 350px !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .v-window__container,
      .carousel-media,
      .mediaCarousel,
      .v-carousel__item {
        width: 100% !important;
        max-width: 100% !important;

        video.item_display,
        .item_display.audio {
          width: 100%;
          height: 350px !important;
        }
      }

      .v-window__container,
      .carousel-media,
      .v-carousel__item {
        height: 350px !important;
      }

      .media_item {
        width: 100%;
        flex: 1;
        min-height: 350px;
      }
      .noImage {
        width: 100%;
        min-height: 350px;
        background-color: #222;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: $primary-fontface !important;
        font-size: 18px !important;
        font-style: italic;
        font-weight: 700;
        color: $color-primary;
        text-align: center;

        i.v-icon {
          color: #fff;
          text-align: center;
          text-shadow: 2px 2px 0 $color-primary;
          font-size: 65px;
        }

        span.subtitle {
          color: #fff;
          font-family: $secondary-fontface !important;
          font-size: 14px !important;
          font-style: italic;
          font-weight: 400;
        }
      }
    }
    .container_box {
      .v-window__container {
        width: 100%;
      }
    }
  }
  .drawer_tab {
    .v-tabs {
      .v-tabs-bar__content {
        border-bottom: 1px solid rgba(#000, 0.08);
      }
    }
    .v-tab {
      outline: none !important;
      font-family: $secondary-fontface !important;
      font-size: 16px !important;
      font-weight: 500 !important;
      color: rgba(#000, 0.24) !important;
    }
    .v-tabs-slider {
      background-color: rgba(#000, 0.64) !important;
      height: 4px;
    }
    .v-tab--active {
      outline: none !important;
      font-size: 16px !important;
      color: rgba(#000, 0.8) !important;
    }
  }

  .dateAndTime {
    display: flex;
    font-size: 10px;
    font-family: $primary-fontface !important;
    font-size: 10px;
    font-weight: 400;
    vertical-align: center;
    border-radius: 4px;

    .date {
      height: 24px;
      display: flex;
      align-items: center;
      padding: 6px 5px;
      background-color: rgba(#000, 0.08);
    }

    .time {
      height: 24px;
      display: flex;
      align-items: center;
      padding: 6px 5px;
      background-color: rgba(#000, 0.04);
    }
  }
}

label {
  text-transform: uppercase !important;
}
</style>
