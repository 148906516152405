<template>
  <div class="container">
    <!-- Topo -->
    <div class="topo">
      <div class="title">
        <span>{{ isEditMode ? "Editar" : "Adicionar" }}</span> servidor SFTP
      </div>
    </div>
    <div class="main">
      <v-form class="form" v-model="formValid">
        <div class="item">
          <v-text-field
            placeholder="Informe o nome"
            primary
            v-model="formData.name"
            :rules="nameRules"
            counter
            maxlength="50"
            class="textfield"
            required
          >
            <template slot="label">
              Nome
              <span class="text-red">*</span>
            </template>
          </v-text-field>
        </div>

        <div class="item margin">
          <v-text-field
            placeholder="Informe a descrição do servidor SFTP"
            primary
            v-model="formData.description"
            maxlength="320"
            counter
            class="textfield"
          >
            <template slot="label">
              Descrição
              <span class="text-red"></span>
            </template>
          </v-text-field>
        </div>

        <div class="item">
          <v-text-field
            placeholder="Ex: JBPACEDSR001"
            primary
            size="40"
            v-model="formData.host"
            :rules="serverRules"
            counter
            maxlength="50"
            class="textfield"
            required
          >
            <template slot="label">
              Servidor
              <span class="text-red">*</span>
            </template>
          </v-text-field>
        </div>

        <div class="item">
          <v-text-field
            placeholder="Porta"
            primary
            v-model="formData.port"
            :rules="portRules"
            counter
            maxlength="5"
            class="textfield"
            required
          >
            <template slot="label">
              Porta
              <span class="text-red">*</span>
            </template>
          </v-text-field>
        </div>

        <div class="item">
          <v-text-field
            placeholder="Exemplo: /home/folder/"
            primary
            v-model="formData.path"
            :rules="pathRules"
            counter
            maxlength="100"
            class="textfield"
            required
          >
            <template slot="label">
              Diretório
              <span class="text-red">*</span>
            </template>
          </v-text-field>
        </div>

        <div class="item">
          <v-text-field
            placeholder="Usuário"
            primary
            v-model="formData.username"
            :rules="userRules"
            counter
            maxlength="30"
            class="textfield"
            required
          >
            <template slot="label">
              Usuário
              <span class="text-red">*</span>
            </template>
          </v-text-field>
        </div>

        <div class="item">
          <v-text-field
            placeholder="Informe a senha"
            primary
            v-model="formData.password"
            :rules="passwordRules"
            counter
            maxlength="100"
            class="textfield"
            hide-details
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            @click:append="showPassword = !showPassword"
            autocomplete="off"
            required
          >
            <template slot="label">
              Senha
              <span class="text-red">*</span>
            </template>
          </v-text-field>
        </div>
      </v-form>
    </div>

    <!-- Footer -->
    <div class="footer">
      <div class="slot left"></div>

      <!-- Actions -->
      <div class="slot right">
        <v-btn
          large
          color="seccondary"
          class="mr-4"
          @click="$emit('cancelCreateClickedEvent')"
        >
          <v-icon>mdi-close</v-icon>
          Cancelar
        </v-btn>
        <v-btn
          large
          color="primary"
          @click="saveDataHandler"
          :disabled="!formValid && !isSavingData"
          :loading="isSavingData"
        >
          <v-icon>mdi-content-save</v-icon>
          Salvar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { omit, pick } from "ramda";

import { SftpServer } from "../../../gateways";
import { UI_SNACKBAR_SHOW } from "../../../store/actions";

const validateRequired = val => !!val || "Campo obrigatório.";

const validateNameLength = name =>
  (!!name && name.length <= 50) || "O nome deve ter no máximo 50 caracteres.";
const validateServerLength = host =>
  (!!host && host.length <= 50) ||
  "O nome do servidor deve ter no máximo 50 caracteres.";
const validatePortLength = port =>
  (!!port && port.toString().length <= 5) ||
  "A porta deve ter no máximo 5 caracteres.";
const validatePathLength = path =>
  (!!path && path.length <= 100) ||
  "O diretório deve ter no máximo 100 caracteres.";
const validateUserLength = username =>
  (!!username && username.length <= 100) ||
  "O nome do usuário deve ter no máximo 30 caracteres.";
const validatePasswordLength = password =>
  (!!password && password.length <= 100) ||
  "A senha deve ter no máximo 100 caracteres.";

export default {
  data() {
    return {
      showPassword: false,
      formValid: false,
      formData: {
        id: -1,
        name: "",
        description: "",
        host: "",
        port: "",
        username: "",
        password: "",
        path: ""
      },
      isEditMode: false,
      isSavingData: false
    };
  },
  props: ["editItem"],
  async created() {
    if (this.editItem) {
      this.formData = pick(
        [
          "id",
          "name",
          "description",
          "host",
          "port",
          "path",
          "username",
          "password"
        ],
        this.editItem
      );
      this.isEditMode = true;
    }
  },
  computed: {
    nameRules: {
      get() {
        return [validateRequired, validateNameLength];
      }
    },
    serverRules: {
      get() {
        return [validateRequired, validateServerLength];
      }
    },
    portRules: {
      get() {
        return [validateRequired, validatePortLength];
      }
    },
    pathRules: {
      get() {
        return [validateRequired, validatePathLength];
      }
    },
    userRules: {
      get() {
        return [validateRequired, validateUserLength];
      }
    },
    passwordRules: {
      get() {
        return [validateRequired, validatePasswordLength];
      }
    }
  },
  methods: {
    async saveDataHandler() {
      this.isSavingData = true;
      const data = omit("id", this.formData);
      if (this.isEditMode) {
        await this.editItemHandler(this.formData.id, data);
      } else {
        await this.registerItemHandler(data);
      }
      this.isSavingData = false;
    },
    async registerItemHandler(data) {
      try {
        await SftpServer.register(data);
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "O servidor sftp foi registrado com sucesso!"
        });
        this.$emit("itemRegisteredEvent");
      } catch {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "Erro tentando cadastrar o servidor sftp :(!"
        });
      }
    },
    async editItemHandler(id, data) {
      try {
        await SftpServer.edit(id, data);
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "O servidor sftp foi editado com sucesso!"
        });
        this.$emit("itemEditedEvent");
      } catch {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "Erro tentando editar o servidor sftp :(!"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/theme.scss";
@import "../../../scss/navigation-drawer.scss";
</style>
