var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"card",class:[
    'card collaboration-card',
    {
      mediaCard: _vm.hasMedia,
      active: _vm.isCardActive,
      answer: _vm.isAnswer
    }
  ]},[(_vm.hasMedia)?_c('div',{staticClass:"thumb card_media"},[_c('MediaCarousel',{attrs:{"medias":_vm.collaboration.medias}})],1):_vm._e(),(!_vm.isAnswer)?_c('div',{staticClass:"card_content"},[_c('div',{staticClass:"content-text",on:{"click":_vm.showDetails}},[_c('div',{staticClass:"message",style:(_vm.messageLineStyling)},[(_vm.collaboration.body.title)?_c('span',{staticClass:"collaboration-title"},[_vm._v(_vm._s(((_vm.collaboration.body.title) + " ")))]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(_vm.messageBody)}})]),(_vm.collaboration.body.metadatas)?_c('div',{staticClass:"mt-2"},_vm._l((_vm.collaboration.body.metadatas),function(item,i){return _c('v-chip',{key:i,staticClass:"group",attrs:{"label":""}},[_c('div',{staticClass:"group-content"},[_c('div',{staticClass:"group-metadata"},[_c('div',{staticClass:"group-metadata--name"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.value))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.value))])])],1),_c('div',{staticClass:"group-metadata--tag"},[_vm._v(" "+_vm._s(item.field)+" ")])])])])}),1):_vm._e()])]):_c('div',{staticClass:"card_content"},[_c('div',{staticClass:"content-text"},[_c('div',{staticClass:"message"},[_c('span',{staticClass:"collaboration-title"},[_vm._v("COLABORATIVO: ")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.collaboration.message)}})])])]),(!_vm.isAnswer)?_c('div',{staticClass:"card_footer"},[_c('div',{staticClass:"createdAt"},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.collaborationDate))]),_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.collaborationTime))]),_c('div',{staticClass:"social",class:['ml-5', _vm.collaborationInputBadgeStyle]},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.collaborationInputBadgeIcon)+" ")])]}}],null,false,3583412500)},[_c('span',[_vm._v(_vm._s(_vm.collaborationTypeDescription))])])],1)]),(_vm.flagInfo)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-3",attrs:{"color":_vm.flagInfo.color}},on),[_vm._v(_vm._s(_vm.flagInfo.icon))])]}}],null,false,873270095)},[_c('span',[_vm._v(_vm._s(_vm.flagInfo.tooltip))])]):_vm._e(),_c('div',{staticClass:"actions"},[_c('CollaborationActions',{ref:"actions",attrs:{"collaboration":_vm.collaboration_,"currentCollaborator":_vm.curCollaborator,"index":_vm.index},on:{"handle-active-item":function($event){_vm.isCardActive = $event}}})],1)],1):_c('div',{staticClass:"card_footer"},[_c('div',{staticClass:"createdAt"},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.collaborationDate))]),_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.collaborationTime))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }