var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Drawer',{attrs:{"drawer":_vm.isYoutubeDrawerVisible,"title":"configurar youtube","isFullHeight":true,"drawerName":"youtube","formRef":"youtube-drawer-form","areAllFieldsEmpty":_vm.areAllFieldsEmpty},on:{"toggle-drawer":_vm.toggleDrawerInputSourceYoutube,"reset-enabler":_vm.resetEnabler,"hard-update":_vm.hardUpdate,"reset-changes":_vm.reset},scopedSlots:_vm._u([{key:"drawer-content",fn:function(){return [_c('div',{staticClass:"modal-content__item"},[_c('CardHeader',{attrs:{"title":"FILTRAR CONTEÚDO","subtitle":"Cadastre usuários ou canais para receber conteúdo"}})],1),_c('div',{staticClass:"modal-content__item"},[_c('div',{staticClass:"input-item full"},[_c('v-combobox',{attrs:{"rules":_vm.rules,"deletable-chips":"","multiple":"","clearable":"","outlined":"","label":"Adicione um ou mais ID's de canal do Youtube","color":"graydark","prepend-inner-icon":"fab fa-youtube","append-icon":""},on:{"change":function($event){return _vm.normalizeText($event, 'channels', false)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var selected = ref.selected;
var parent = ref.parent;
return [_c('v-chip',_vm._b({attrs:{"label":"","input-value":selected,"close":""},on:{"click:close":function($event){return parent.selectItem(item)}}},'v-chip',attrs,false),[_vm._v(_vm._s(item)+" ")])]}}]),model:{value:(_vm.localYoutubeConfig.channels),callback:function ($$v) {_vm.$set(_vm.localYoutubeConfig, "channels", $$v)},expression:"localYoutubeConfig.channels"}},[_c('div',{staticClass:"help",attrs:{"slot":"append"},slot:"append"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_c('strong',[_vm._v("Exemplo")]),_c('br'),_vm._v(" https://www.youtube.com/channel/"),_c('strong',{staticClass:"primary"},[_vm._v("UCzshJ2mSjxhqKFBUXqP49Uw")]),_c('br')])])],1)])],1)]),_c('div',{staticClass:"modal-content__item"},[_c('div',{staticClass:"input-item full"},[_c('v-combobox',{attrs:{"rules":_vm.rules,"deletable-chips":"","multiple":"","outlined":"","clearable":"","label":"Adicione um ou mais nomes de usuário do Youtube","color":"graydark","prepend-inner-icon":"mdi-account-box","append-icon":""},on:{"change":function($event){return _vm.normalizeText($event, 'users', true)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var selected = ref.selected;
var parent = ref.parent;
return [_c('v-chip',_vm._b({attrs:{"label":"","input-value":selected,"close":""},on:{"click:close":function($event){return parent.selectItem(item)}}},'v-chip',attrs,false),[_vm._v(_vm._s(item)+" ")])]}}]),model:{value:(_vm.localYoutubeConfig.users),callback:function ($$v) {_vm.$set(_vm.localYoutubeConfig, "users", $$v)},expression:"localYoutubeConfig.users"}},[_c('div',{staticClass:"help",attrs:{"slot":"append"},slot:"append"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_c('strong',[_vm._v("Exemplo")]),_c('br'),_vm._v(" https://www.youtube.com/user/"),_c('strong',{staticClass:"primary"},[_vm._v("vcsabiavideos")]),_c('br')])])],1)])],1)])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }