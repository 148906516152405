<template>
  <div>
    <v-app-bar
      app
      hide-on-scroll
      inverted-scroll
      :class="[
        {
          isInCollaborationsView:
            isInCollaborationsView && viewMode !== 'conversation'
        },
        'floating-buttons'
      ]"
      scroll-target=".main-content"
      width="52px"
      height="140px"
    >
      <v-tooltip top max-width="56px">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" class="fab-button primary" @click="actualize">
            <v-icon dark>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Atualizar</span>
      </v-tooltip>

      <v-tooltip top max-width="56px" v-if="canShowBatchActions">
        <template v-slot:activator="on">
          <v-btn v-on="on" class="fab-button primary" @click="openBatchActions">
            <v-icon dark>mdi-select-all</v-icon>
          </v-btn>
        </template>
        <span>Ações em lote</span>
      </v-tooltip>

      <v-tooltip top max-width="56px" v-if="canShowBackToTop">
        <template v-slot:activator="{ on }">
          <v-btn class="fab-button secondary" v-on="on" @click="goToTop">
            <v-icon dark>mdi-arrow-up-bold-hexagon-outline</v-icon>
          </v-btn>
        </template>
        <span>voltar ao topo</span>
      </v-tooltip>
    </v-app-bar>
    <CollaborationBatchActions
      ref="batchActions"
      :campaignId="batchActionsOptions.campaignId"
      :disabled="batchActionsOptions.disabled"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import CollaborationBatchActions from "@/components/collaborations/CollaborationBatchActions";
export default {
  name: "FloatingButtons",
  components: {
    CollaborationBatchActions
  },
  props: ["batchActionsOptions"],
  computed: {
    ...mapGetters(["viewMode"]),
    isInCollaborationsView() {
      return this.$route.name === "campaignDetails";
    },
    canShowBatchActions() {
      return (
        this.batchActionsOptions.isVisible && this.viewMode !== "conversation"
      );
    },
    canShowBackToTop() {
      return this.viewMode !== "conversation";
    }
  },
  methods: {
    actualize() {
      this.$emit("actualize");
    },
    openBatchActions() {
      this.$refs.batchActions.isShowPanel
        ? (this.$refs.batchActions.isShowPanel = false)
        : this.$refs.batchActions.openPanel();
    },
    goToTop() {
      this.$emit("go-to-top");
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/floating-actions.scss";
</style>
