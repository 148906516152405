<template>
  <!-- <v-img :src="url" :aspect-ratio="178 / 264" contain /> -->
  <v-img :src="url" :aspect-ratio="178 / 264" contain />
</template>

<script>
export default {
  props: {
    posterUrl: {
      type: String,
      default: null
    }
  },

  computed: {
    url() {
      return this.posterUrl?.startsWith("data")
        ? this.posterUrl
        : this.posterUrl + "?CMS_CACHE_KEY=_";
    }
  }
};
</script>
