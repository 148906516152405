<template>
  <Drawer
    @toggle-drawer="toggleDrawer($event)"
    :drawer="isDrawerVisible"
    title="Formatos Suportados"
    drawerName="FileFormats"
    formRef="file-formats-drawer-form"
  >
    <template v-slot:drawer-actions>
      <ButtonSecondary
        icon="mdi-arrow-left"
        label="Voltar"
        @click-handler="toggleDrawer(false)"
      ></ButtonSecondary>
    </template>
    <template v-slot:drawer-content>
      <div class="modal-content__item">
        <div class="title--small">Fotos</div>
        <div class="input-item">
          <template>
            <v-chip v-for="item in imageFormats" :key="item" filter label>
              {{ item }}
            </v-chip>
          </template>
        </div>
      </div>
      <!-- // -->
      <div class="modal-content__item">
        <div class="title--small">Vídeos</div>

        <div class="input-item">
          <template>
            <v-chip v-for="item in videoFormats" :key="item" filter label>
              {{ item }}
            </v-chip>
          </template>
        </div>
      </div>
      <div class="modal-content__item">
        <div class="title--small">Áudio</div>
        <div class="input-item">
          <template>
            <v-chip v-for="item in audioFormats" :key="item" filter label>
              {{ item }}
            </v-chip>
          </template>
        </div>
      </div>
      <div class="modal-content__item">
        <div class="title--small">Texto</div>
        <div class="input-item">
          <template>
            <v-chip v-for="item in textFormats" :key="item" filter label>
              {{ item }}
            </v-chip>
          </template>
        </div>
      </div>
      <div class="modal-content__item">
        <div class="title--small">Binários</div>
        <div class="input-item">
          <template>
            <v-chip v-for="item in applicationFormats" :key="item" filter label>
              {{ item }}
            </v-chip>
          </template>
        </div>
      </div>
    </template>
  </Drawer>
</template>

<script>
import { mapState } from "vuex";

import ButtonSecondary from "@/components/Commons/Buttons/ButtonSecondary";
import Drawer from "@/components/Commons/Dialogs/Drawer";
import {
  APPLICATION_FORMATS,
  AUDIO_FORMATS,
  IMAGE_FORMATS,
  TEXT_FORMATS,
  VIDEO_FORMATS
} from "@/enums";
import { UI_FILE_FORMATS_DRAWER_HANDLE } from "@/store/actions";

export default {
  name: "FileFormatsDrawer",
  components: {
    ButtonSecondary,
    Drawer
  },
  data() {
    return {
      audioFormats: AUDIO_FORMATS,
      imageFormats: IMAGE_FORMATS,
      videoFormats: VIDEO_FORMATS,
      textFormats: TEXT_FORMATS,
      applicationFormats: APPLICATION_FORMATS
    };
  },
  computed: {
    ...mapState({
      isDrawerVisible: state => state.ui.isFileFormatsDrawerVisible
    })
  },
  methods: {
    toggleDrawer(value) {
      this.$store.dispatch(UI_FILE_FORMATS_DRAWER_HANDLE, value);
    }
  }
};
</script>

<style scoped lang="scss">
.v-chip {
  margin: 0 4px 4px 0;
}
</style>
