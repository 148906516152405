<template>
  <v-carousel
    v-model="carouselItem"
    :show-arrows="showableArrows"
    height="310px"
    hide-delimiters
    :cycle="isCycling"
    :interval="3000"
    class="carousel-media"
  >
    <template v-if="medias">
      <MediaCarouselItem
        :imagesCounter="imagesCounter"
        :videosCounter="videosCounter"
        :isCard="isCard"
        :media="item"
        v-for="(item, i) in medias"
        :key-index="i"
        :key="item.id"
        :campaignId="campaignId"
      />
    </template>
  </v-carousel>
</template>

<script>
import { COLLABORATION_MEDIA_PLAYER_HANDLER } from "@/store/actions";
import { getAndFormatCounter } from "@/util/collaborations";

import MediaCarouselItem from "./MediaCarouselItem";
export default {
  name: "MediaCarousel",
  components: { MediaCarouselItem },
  props: {
    isCycling: {
      type: Boolean,
      default: false
    },
    medias: {
      type: Array,
      default: () => []
    },
    isCard: {
      type: Boolean,
      default: true
    },
    campaignId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      carouselItem: 0
    };
  },
  computed: {
    showableArrows() {
      const isExternal = this.medias.some(media =>
        !media.media_type
          ? false
          : media.media_type.toUpperCase() === "EXTERNAL"
      );
      if (isExternal) return false;
      return this.medias.length > 1;
    },
    imagesCounter() {
      return getAndFormatCounter("image", this.medias);
    },
    videosCounter() {
      return getAndFormatCounter("video", this.medias);
    }
  },
  watch: {
    carouselItem() {
      this.pauseMedias();
    }
  },
  methods: {
    pauseMedias() {
      this.$store.dispatch(COLLABORATION_MEDIA_PLAYER_HANDLER);
    }
  }
};
</script>

<style lang="scss">
.v-window__prev,
.v-window__next {
  z-index: 0;
  justify-self: center;
}
</style>
