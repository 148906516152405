<template>
  <v-btn
    large
    text
    color="primary"
    class="link"
    :disabled="!!disabled"
    @click.stop="clickHandler"
  >
    {{ label }}
    <v-icon v-if="icon" right dark>{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "ButtonLink",
  data() {
    return {};
  },
  props: {
    label: String,
    icon: String,
    disabled: Boolean
  },
  computed: {},
  methods: {
    clickHandler() {
      this.$emit("click-handler");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";
@import "@/scss/form-inputs.scss";
</style>
