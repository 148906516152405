<template>
  <v-navigation-drawer
    v-model="drawerControl"
    app
    right
    temporary
    overlay-opacity="0.88"
    width="680"
  >
    <div class="modal full fixed">
      <div class="modal-header">
        <div class="title--max">
          Filtros
        </div>
        <div class="modal-header__actions">
          <ButtonSecondary
            icon="mdi-arrow-left"
            label="Voltar"
            class="mr-4"
            @click-handler="toggleDrawer(false)"
          ></ButtonSecondary>
          <ButtonPrimary
            icon="mdi-filter-variant-remove"
            label="Limpar"
            @click-handler="cleanFilter"
          ></ButtonPrimary>
        </div>
      </div>
      <div class="modal-content fullHeight">
        <div
          class="modal-content__item mb-6"
          v-for="(filter, index) in campaignFilters"
          :key="index"
        >
          <div class="title--small mb-1">
            {{ filter.title }}
          </div>
          <template v-if="filter.items">
            <div class="subtitle--small mb-3">
              Quantidade de itens disponíveis
              <span>{{ quantifyItems(filter.drawerItems) }}</span> /
              {{ quantifyItems(filter.items) }}
            </div>
            <v-text-field
              label="Digite aqui para encontrar uma opção"
              prepend-inner-icon="mdi-magnify"
              v-model="searchControllers[filter.searchController]"
            />
            <div class="input-item">
              <v-chip-group
                column
                multiple
                v-model="filter_data[filter.controller]"
              >
                <v-chip
                  v-for="({ id, name }, i) in selectItems(filter.drawerItems)"
                  :key="i"
                  :value="id"
                  filter
                >
                  {{ name }}
                </v-chip>
              </v-chip-group>
              <v-chip
                color="primary"
                v-if="quantifyRestOfItems(filter) > 0"
                @click="toggleOptionsQuantity(filter)"
              >
                + {{ quantifyRestOfItems(filter) }}
              </v-chip>
              <v-chip
                color="primary"
                v-else-if="$data[filter.seeOption]"
                @click="toggleOptionsQuantity(filter)"
              >
                Mostrar menos
              </v-chip>
            </div>
          </template>
          <div class="input-item" v-else>
            <v-text-field
              outlined
              :label="filter.label"
              v-model="filter_data[filter.controller]"
            />
          </div>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";

import ButtonPrimary from "@/components/Commons/Buttons/ButtonPrimary";
import ButtonSecondary from "@/components/Commons/Buttons/ButtonSecondary";
import campaignsFilters from "@/mixins/campaigns_filters";
import { UI_CAMPAIGNS_FILTERS_DRAWER_HANDLE } from "@/store/actions";
import { campaignFilters } from "@/util/campaigns";

export default {
  components: {
    ButtonPrimary,
    ButtonSecondary
  },
  mixins: [campaignsFilters],
  data() {
    return {
      campaignFilters: campaignFilters,
      searchControllers: {
        outputsSearchParam: "",
        inputsSearchParam: "",
        productsSearchParam: ""
      },
      showAllProducts: false,
      showAllInputs: false,
      showAllOutputs: false
    };
  },
  computed: {
    ...mapState({
      isCampaignsFiltersDrawerVisible: state =>
        state.ui.isCampaignsFiltersDrawerVisible
    }),
    drawerControl: {
      get() {
        return this.isCampaignsFiltersDrawerVisible;
      },
      set(value) {
        this.toggleDrawer(value);
      }
    },
    filteredOutputs() {
      const defaultReturn = this.getFilteredItems("outputs");
      return this.showAllOutputs ? defaultReturn : defaultReturn?.slice(0, 15);
    },
    filteredInputs() {
      const defaultReturn = this.getFilteredItems("inputs");
      return this.showAllInputs ? defaultReturn : defaultReturn?.slice(0, 15);
    },
    filteredProducts() {
      const defaultReturn = this.getFilteredItems("products");
      return this.showAllProducts ? defaultReturn : defaultReturn?.slice(0, 15);
    }
  },
  methods: {
    toggleDrawer(value) {
      this.$store.dispatch(UI_CAMPAIGNS_FILTERS_DRAWER_HANDLE, value);
    },
    getFilteredItems(type) {
      return this.searchControllers[`${type}SearchParam`]
        ? this[type].filter(iterator =>
            iterator.name
              .toLowerCase()
              .includes(
                this.searchControllers[`${type}SearchParam`].toLowerCase()
              )
          )
        : this[type];
    },
    selectItems(filter) {
      return this[filter];
    },
    quantifyItems(filter) {
      return this[filter]?.length;
    },
    quantifyRestOfItems(filter) {
      return (
        this.getFilteredItems(filter.items)?.length -
        this[filter.drawerItems]?.length
      );
    },
    toggleOptionsQuantity(filter) {
      this[filter.seeOption] = !this[filter.seeOption];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/headers.scss";
@import "@/scss/theme.scss";
@import "@/scss/modal.scss";
@import "@/scss/form-inputs.scss";

.v-navigation-drawer {
  width: 500px !important;
  ::v-deep .filters-section {
    height: auto;
    .filters-items {
      align-items: initial;
      height: auto;
      flex-direction: column;
      justify-content: initial;
    }
  }
  ::v-deep .v-input__prepend-inner {
    margin-top: 0 !important;
  }
  .v-list-item {
    border-top: 1px solid rgba($color-gray-main, 0.2);
    &:last-child {
      border-bottom: 1px solid rgba($color-gray-main, 0.2);
    }
  }
  .v-item-group {
    flex-direction: column;
  }
  .v-icon {
    color: $color-gray-main;
    font-size: 16px;
  }
  .v-list-item__title {
    color: $color-gray-main;
    font-size: 14px !important;
  }
}
.subtitle--small {
  span {
    font-size: 15px;
    font-weight: 900;
  }
}
::v-deep .v-menu__content {
  min-width: initial !important;
}
</style>
