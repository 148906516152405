<template>
  <v-tooltip bottom :disabled="!actionsDisabled">
    <template v-slot:activator="{ on, attrs }">
      <div class="dropMenu" v-bind="attrs" v-on="on">
        <v-menu
          top
          open-on-hover
          content-class="elevation-4"
          transition="scale-transition"
          :disabled="actionsDisabled"
          v-model="isMenuActive"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> more_vert </v-icon>
          </template>
          <v-list min-width="240">
            <v-list-item
              v-for="(item, index) in itemActions.filter(act => act.show)"
              :key="index"
              link
              @click.prevent="item.action()"
              :class="{ divider: item.divider, [item.class]: true }"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
    <span
      >As ações são desabilitadas no modo de busca em múltiplas campanhas.</span
    >
  </v-tooltip>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import collaborationsMixin from "@/mixins/collaboration";
import collaborationsActionsValidator from "@/mixins/collaborations_actions_validator";
import { UI_COLLABORATION_OVERLAYED_SET } from "@/store/mutations";
import {
  approveCollaboration,
  copyCollaboration,
  deleteCollaboration,
  favoriteCollaboration,
  handleBlockUser,
  openQRCodeModal,
  recycleCollaboration,
  rejectCollaboration,
  resendCollaborationOutput,
  showCollaboration
} from "@/util/collaborations";
export default {
  mixins: [collaborationsActionsValidator, collaborationsMixin],
  props: {
    index: {
      required: true
    },
    collaboration: {
      required: true
    },
    currentCollaborator: {
      required: true
    }
  },
  data() {
    return {
      //eslint-disable-next-line
      collaboration_: this.collaboration,
      isMenuActive: false
    };
  },
  computed: {
    ...mapState({
      collaborationsOverlayed: state => state.ui.isCollaborationOverlayed,
      filters: state => state.campaign.tabs[0].filters,
      blockedPhones: state =>
        state.campaign.campaign.whatsapp_configuration.blocked_phones,
      blockedEmails: state =>
        state.campaign.campaign.form_configuration.blocked_emails
    }),
    ...mapGetters(["viewMode"]),
    actionsDisabled() {
      return this.filters?.campaign_ids.length > 1;
    },
    canShowQrGenerator() {
      return (
        this.collaboration_.collaborator.type === "whatsapp" &&
        this.viewMode !== "conversation"
      );
    },
    canShowBlockUserAction() {
      return (
        (this.currentCollaborator?.type === "whatsapp" &&
          !this.blockedPhones.includes(this.currentCollaborator.phone)) ||
        (this.collaborator?.type === "canal-globo" &&
          !this.blockedEmails.includes(this.currentCollaborator.email))
      );
    },
    canShowUnblockUserAction() {
      return (
        (this.currentCollaborator?.type === "whatsapp" &&
          this.blockedPhones.includes(this.currentCollaborator.phone)) ||
        (this.collaborator?.type === "canal-globo" &&
          this.blockedEmails.includes(this.currentCollaborator.email))
      );
    },
    itemActions() {
      const isFavorited = this.collaboration.favorite_of_users.length === 0;
      return [
        {
          action: () => approveCollaboration(this.index, this.collaboration),
          title: "Aprovar",
          icon: "check",
          class: "act_approve",
          show: this.canApprove,
          divider: false
        },
        {
          action: () => rejectCollaboration(this.index, this.collaboration),
          title: "Reprovar",
          icon: "close",
          class: "act_reject",
          show: this.canReject,
          divider: false
        },
        {
          action: () => openQRCodeModal(this.currentCollaborator.phone),
          title: "Conversar (QRCODE)",
          icon: "qr_code_2",
          class: "show-qr-user",
          show: this.canShowQrGenerator,
          divider: true
        },
        {
          title: "Bloquear",
          icon: "mdi-account-lock",
          action: async () =>
            handleBlockUser(
              this.currentCollaborator.phone,
              this.currentCollaborator.email,
              this.currentCollaborator.type,
              true
            ),
          class: "act-block-user",
          show: this.canShowBlockUserAction,
          divider: false
        },
        {
          title: "Desbloquear",
          icon: "mdi-account-reactivate",
          action: async () =>
            handleBlockUser(
              this.currentCollaborator.phone,
              this.currentCollaborator.email,
              this.currentCollaborator.type,
              false
            ),
          class: "act-unblock-user",
          show: this.canShowUnblockUserAction,
          divider: false
        },
        {
          title: "Enviar",
          icon: "send",
          action: () =>
            resendCollaborationOutput(this.collaboration.collaboration_id),
          class: "act_send",
          show: this.collaboration.was_approved && !this.hasMediaExternal,
          divider: false
        },
        {
          title: isFavorited ? "Favoritar" : "Desfavoritar",
          icon: isFavorited ? "mdi-heart-outline" : "mdi-heart-off-outline",
          action: () => favoriteCollaboration(this.index, this.dataFavorites),
          class: isFavorited ? "color-primary" : "color-red",
          show: this.viewMode !== "conversation",
          divider: true
        },
        {
          title: "Mover",
          icon: "swap_horiz",
          action: "move",
          class: "",
          show: false,
          divider: false
        },
        {
          title: "Copiar",
          icon: "content_copy",
          action: () => copyCollaboration(this.collaboration.collaboration_id),
          class: "",
          show: true,
          divider: false
        },
        {
          title: "Remover",
          icon: "delete_forever",
          action: () => deleteCollaboration(this.index, this.collaboration),
          class: "",
          show: this.canDelete,
          divider: true
        },
        {
          title: "Reciclar",
          icon: "autorenew",
          action: () => recycleCollaboration(this.index, this.collaboration),
          class: "",
          show: this.canRecycle,
          divider: false
        },
        {
          title: "Editar",
          icon: "edit",
          action: () => showCollaboration(this.index, this.collaboration),
          class: "",
          show: true,
          divider: false
        }
      ];
    },
    hasMediaExternal() {
      const { medias } = this.collaboration;
      if (!Array.isArray(medias)) return false;
      return medias.some(media => {
        const { media_type } = media;
        if (typeof media_type === "undefined" || !media_type) return false;
        return media_type === "external";
      });
    },
    areCollaborationsOverlayed: {
      get() {
        return this.collaborationsOverlayed;
      },
      set(value) {
        this.$store.commit(UI_COLLABORATION_OVERLAYED_SET, value);
      }
    }
  },
  watch: {
    isMenuActive(value) {
      this.areCollaborationsOverlayed = value;
      this.$emit("handle-active-item", value);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/theme.scss";
@import "@/scss/cards.scss";

.v-list {
  width: 240px;
  font-size: 14px;
  font-weight: 500;
  padding: 0 !important;

  .v-list-item {
    padding: 8px auto;
    color: #000000;

    &.color-red {
      color: #f66969 !important;

      .v-icon {
        color: #f66969 !important;
      }

      .v-list-item-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .v-list-item__icon {
      margin-right: 16px !important;
    }

    .v-icon {
      color: #000000 !important;
    }

    &.divider {
      border-top: 1px solid rgba(#000000, 0.08);
    }

    &.act_approve {
      @include colorItem(#0cce14);
    }

    &.act_reject,
    &.act-block-user {
      @include colorItem(#f66969);
    }

    &.act_send,
    &.act-unblock-user,
    &.show-qr-user {
      @include colorItem(#6998f6);
    }
  }
}
</style>
