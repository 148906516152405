import Vue from "vue";

import logo from "@/assets/logo.png";
import Router from "@/router";
import Analytics from "@/services/analytics";
import {
  CAMPAIGN_BLOCK_USER,
  CAMPAIGN_COLLABORATION_REFRESH,
  CAMPAIGN_COLLABORATIONS_GROUPED_LOAD_ANSWERS,
  CAMPAIGN_GROUPED_CLEAN_COLLABORATIONS,
  CAMPAIGN_GROUPED_CLEAN_COLLABORATORS,
  CAMPAIGN_GROUPED_LOAD_COLLABORATIONS,
  CAMPAIGN_GROUPED_LOAD_COLLABORATORS,
  CAMPAIGN_GROUPED_UPDATE_SELECTED_ID,
  CAMPAIGN_LOAD,
  CAMPAIGN_NOTIFICATION_HANDLER,
  CAMPAIGN_NOTIFICATION_START_MONITORING,
  CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_HANDLE,
  COLLABORATION_MEDIA_PLAYER_HANDLER,
  UI_SNACKBAR_SHOW
} from "@/store/actions";
import {
  CAMPAIGN_COLLABORATIONS_ERROR_SET,
  CAMPAIGN_COLLABORATIONS_GROUPED_SET_ANSWERS,
  CAMPAIGN_COLLABORATIONS_GROUPED_SET_COLLABORATORS,
  CAMPAIGN_COLLABORATIONS_GROUPED_SET_COLLABS,
  CAMPAIGN_GROUPED_CLEAN_ANSWERS,
  CAMPAIGN_UPDATE_SELECTED_GROUPED_ID,
  MT_CAMPAIGN_COLLABORATORS_COLLABORATION_APPROVE,
  MT_CAMPAIGN_COLLABORATORS_COLLABORATION_REJECT,
  MT_COLABORATION_EDIT,
  MT_COLABORATION_GROUPED_BY_COLLABORATORS_EDIT,
  MT_COLLABORATION_GROUPED_BY_COLLABORATORS_REFRESH,
  MT_COLLABORATION_REFRESH,
  SET_PLAYING_AUDIO,
  SET_PLAYING_VIDEO,
  UI_CLEAR_NOTIFICATION_INFO,
  UI_NEW_COLLABORATIONS_SET,
  UI_NOTIFICATION_INFO_SET
} from "@/store/mutations";
import {
  getDefaultFilter,
  handleTabTitleChange,
  time_polling_count
} from "@/util";
import Bus from "@/util/EventBus";
import { decryptWhatsappConfigurationData } from "@/util/whatsapp";

import { Campaign } from "../gateways";

const MESSAGE_ERROR_DEFAULT =
  "Ops... Tivemos um problema no servidor. Por favor, tente novamente em alguns instantes :)";

const BULK_SHART_LENGTH = 100;

const defaultGroupedCollaboratorsState = () => {
  return {
    data: [],
    pageToken: null,
    hasError: false,
    isLoading: false
  };
};

const defaultGroupedCollaborationsState = () => {
  return {
    data: [],
    hasError: false,
    isLoading: false
  };
};

const defaultGroupedAnswersState = () => {
  return {
    data: [],
    hasError: false,
    isLoading: false
  };
};

export const CollaborationsOrdering = {
  Descending: "D",
  Ascending: "A"
};

const createShardByArray = (array, max_by_array = 100) => {
  const array_tam = array.length;
  const array_result = [];
  for (let i = 0; i < array_tam; i += max_by_array) {
    const start = i;
    const end = start + max_by_array;
    array_result.push(array.slice(start, end));
  }
  return array_result;
};

const removeCollaborationView = (array, commit) => {
  Object.values(array)
    .sort((a, b) => b.index - a.index)
    .forEach(({ index }) => {
      commit("MT_CAMPAIGN_COLLABORATIONS_REMOVE", index);
    });
};

const tabDefaultState = function(campaign) {
  return {
    collaborations: [],
    // searchTexts: [],
    filters: getDefaultFilter(campaign),
    collaborationCount: 0,
    // COLABORAÇÕES SELECIONADAS
    loading: true,
    collaborationSelected: {},
    isLastPage: false,
    isFirstPage: true,
    polling_id: null,
    hasError: false
  };
};

// UTEIS

const filterPayload = (
  filter,
  collaborations,
  isAllCampaigns,
  ordering = CollaborationsOrdering.Descending,
  page = 1,
  isViewTable = false
) => {
  let reference = getReference(collaborations, ordering, isViewTable, page);

  const payload = {
    collaborator_name: filter.collaborator_name,
    collaborator_email: filter.collaborator_email,
    collaborator_phone: filter.collaborator_phone,
    collaborator_insta_owner_id: filter.collaborator_insta_owner_id,
    initial_date:
      filter.initial_date && filter.initial_time
        ? parseDateTimeStamp(filter.initial_date, filter.initial_time)
        : null,
    final_date:
      filter.final_date && filter.final_time
        ? parseDateTimeStamp(filter.final_date, filter.final_time)
        : null,
    collaborator_country: filter.collaborator_country,
    collaborator_state: filter.collaborator_state,
    collaborator_city: filter.collaborator_city,
    collaborator_twitter_id: filter.collaborator_twitter_id,
    collaborator_screen_user_name: filter.collaborator_screen_user_name,
    collaborator_yt_channel_id: filter.collaborator_yt_channel_id,
    collaborator_verified: filter.collaborator_verified,
    inputtype: filter.inputtype,
    media_type: filter.media_type,
    approval_level: filter.moderationLevel,
    order_by: filter.order_by,
    retweet: filter.retweet,
    tweetOriginal: filter.tweetOriginal,
    search_text: filter.search_text,
    size: filter.size,
    campaign_ids: filter.campaign_ids,
    all_campaigns: isAllCampaigns,
    order_desc: ordering === CollaborationsOrdering.Descending
  };

  if (reference) {
    payload.search_after_ts = reference.updated_at;
    payload.search_after_id = `${reference.campaign_id}_${reference.collaboration_id}`;
  }

  return payload;
};

const parseDateTimeStamp = (stringDate, stringTime) => {
  const date = new Date(`${stringDate} ${stringTime}`);
  return Math.floor(date.getTime() / 1000);
};

const parseDateTimeWithTimezone = stringDate => {
  return Math.floor(new Date(stringDate).getTime() / 1000);
};

const getReference = (collaborations, ordering, isViewTable, page) => {
  const colaborationsSize = collaborations.length;
  let reference;
  if (colaborationsSize !== 0) {
    if (
      ordering == CollaborationsOrdering.Descending ||
      (isViewTable && page > 1)
    ) {
      reference = collaborations[colaborationsSize - 1];
    } else if (ordering == CollaborationsOrdering.Ascending) {
      reference = collaborations[0];
    }
  }
  return reference;
};

export default {
  state: {
    audioPlaying: null,
    campaign: {
      id: null,
      name: null,
      description: null,
      is_enabled: true,
      moderators: [],
      inputs: [],
      product: null,
      required_approvals: 0
    },
    collaborationEdit: null,
    collaborationIdx: null,
    hasNewCollaborations: false,
    notificationInfo: {},
    polling: [],
    tabs: [
      {
        ...tabDefaultState()
      }
    ],
    collaborationsGroupedByCollaborator: defaultGroupedCollaboratorsState(),
    selectedGroupedCollaboratorID: null,
    selectedGroupedCollaborations: defaultGroupedCollaborationsState(),
    selectedGroupedAnswers: defaultGroupedAnswersState(),
    tab_active: 0,
    videoPlaying: null
  },
  getters: {
    campaign: state => state.campaign,
    getTabs: state => state.tabs,
    tabsLength: state => state.tabs.length,
    tab_active: state => state.tab_active,
    collaborations: state => state.tabs[state.tab_active].collaborations,
    collaborations_loading: state => state.tabs[state.tab_active].loading,
    collaborations_count: state =>
      state.tabs[state.tab_active].collaborationCount,
    filters: state => state.tabs[state.tab_active || 0].filters,
    search_terms: state =>
      state.tabs[state.tab_active || 0].filters?.search_text || [],

    collaborationEdit: state => state.collaborationEdit || null,
    collaborationIdx: state => state.collaborationIdx || null,
    collaborationSelected: state =>
      state.tabs[state.tab_active].collaborationSelected || {},
    collaborationsAllSelectedCount: state =>
      Object.keys(state.tabs[state.tab_active].collaborationSelected).length,
    isLastPage: state => state.tabs[state.tab_active].isLastPage,
    moderationLevel: state =>
      state.tabs[state.tab_active || 0].filters.moderationLevel,
    curCollaboratorInfo: state =>
      state.collaborationsGroupedByCollaborator.data.find(
        el => el.collaborator.id === state.selectedGroupedCollaboratorID
      ),
    collaboratorCollaborationsAndAnswers: state => {
      const getDate = date =>
        new Date(typeof date === "string" ? date : date * 1000);

      const collaborations = [
        ...state.selectedGroupedCollaborations.data,
        ...state.selectedGroupedAnswers.data
      ].sort((x, y) => getDate(y.created_at) - getDate(x.created_at));
      return collaborations;
    }
  },
  actions: {
    [COLLABORATION_MEDIA_PLAYER_HANDLER]: ({ commit, state }, payload) => {
      for (let media of ["videoPlaying", "audioPlaying"]) {
        if (payload && payload[payload.type] === state[media]) {
          return;
        }
        if (state[media]) {
          state[media].pause();
        }
      }
      if (payload && payload[payload.type]) {
        commit(
          `SET_PLAYING_${payload.type.toUpperCase()}`,
          payload[payload.type]
        );
      }
    },
    [CAMPAIGN_LOAD]: async ({ commit }, campaignId) => {
      const campaign = (await Campaign.get(campaignId)).data;
      const newTabs = [tabDefaultState(campaign)];
      commit("MT_CAMPAIGN_SET", campaign);
      commit("TAB_SET_ALL", newTabs);
    },
    [CAMPAIGN_NOTIFICATION_START_MONITORING]: ({ commit, state }) => {
      Notification.requestPermission();
      setInterval(() => {
        const lastQuantity =
          state.notificationInfo.lastNewCollaborationsQuantity || 0;
        const newQuantity = state.notificationInfo.newCollaborationsQuantity;
        if (newQuantity > lastQuantity) {
          commit(UI_NOTIFICATION_INFO_SET, {
            canDispatchNotification: true
          });
        }
      }, 120000);
    },
    [CAMPAIGN_NOTIFICATION_HANDLER]: ({ commit, state }) => {
      new Notification(state.notificationInfo.title, {
        body: state.notificationInfo.body,
        icon: logo
      });
      commit(UI_NOTIFICATION_INFO_SET, {
        canDispatchNotification: false,
        lastNewCollaborationsQuantity:
          state.notificationInfo.newCollaborationsQuantity
      });
    },
    // TABS-------------------------------------------------------
    ["TAB_RESET"]: async ({ commit }) => {
      commit("TAB_RESET");
      commit("TAB_ACTIVE_SET", 0);
      Bus.$root.$emit("lastPage");
    },
    ["TAB_ADD"]: async ({ commit, state, dispatch }, loadStart = true) => {
      dispatch("UI_SNACKBAR_HIDE");

      commit("TAB_ADD", tabDefaultState(state.campaign));

      const indexActive = state.tabs.length - 1;
      commit("TAB_ACTIVE_SET", indexActive);

      Bus.$root.$emit("newTab");

      if (loadStart) {
        dispatch("CAMPAIGN_COLLABORATIONS_LOAD", {
          tryPaginating: false
        });
      }
    },
    ["TAB_REMOVE"]: async ({ commit, state, dispatch }, indexTab) => {
      dispatch("UI_SNACKBAR_HIDE");
      if (state.tabs.length >= 2) {
        const polling_id = state.tabs[indexTab].polling_id;
        commit("MT_POLLING_REMOVE", polling_id);
        commit("TAB_REMOVE", indexTab);
        Bus.$root.$emit("removeTab", indexTab);
      } else {
        dispatch("UI_SNACKBAR_SHOW", {
          message: "Não possível excluir quando tem somente 1 aba.",
          bind: {
            center: true
          },
          update: false
        });
      }
    },
    ["TAB_ACTIVE_SET"]: async ({ dispatch, commit }, indexTab) => {
      dispatch("UI_SNACKBAR_HIDE");
      commit("TAB_ACTIVE_SET", indexTab);
      dispatch(COLLABORATION_MEDIA_PLAYER_HANDLER);
      Bus.$root.$emit("updateTerms");
    },
    // END TABS-----------------------------------------------------
    // SEARCH ------------------------------------------------------

    ["COLLABORATION_SEARCH_TERMS"]: async ({ commit, dispatch }, terms) => {
      (async function() {
        commit("SEARCH_TERMS_SET", terms);
        dispatch("CAMPAIGN_COLLABORATIONS_LOAD", {
          tryPaginating: false
        });
      })();
    },
    // END SEARCH --------------------------------------------------
    [CAMPAIGN_GROUPED_LOAD_COLLABORATORS]: async (
      { dispatch, commit, state, getters },
      { loadMoreCollaborators }
    ) => {
      const { filters } = state.tabs[0];
      const collaborations = getters.curCollaboratorInfo?.collaborations || [];
      if (!loadMoreCollaborators) {
        commit(CAMPAIGN_COLLABORATIONS_GROUPED_SET_COLLABORATORS, {
          data: [],
          pageToken: null,
          hasError: false,
          isLoading: true
        });
      }
      const payload = {
        ...filterPayload(filters, collaborations, filters.isAllCampaigns),
        ...(loadMoreCollaborators && {
          pagination_token: state.collaborationsGroupedByCollaborator.pageToken
        })
      };
      try {
        const request = await Campaign.getCollaborationsGroupByCollaborators(
          payload
        );
        if (request && request.data.collaborators) {
          commit(CAMPAIGN_COLLABORATIONS_GROUPED_SET_COLLABORATORS, {
            data: loadMoreCollaborators
              ? [
                  ...state.collaborationsGroupedByCollaborator.data,
                  ...request.data.collaborators
                ]
              : request.data.collaborators,
            pageToken: request.data.pagination_token,
            hasError: false,
            isLoading: false
          });

          if (!loadMoreCollaborators) {
            commit(CAMPAIGN_UPDATE_SELECTED_GROUPED_ID, 0);
          }

          // If no collaborator is selected, select the first one and load its collaborations
          if (!state.selectedGroupedCollaboratorID) {
            commit(
              CAMPAIGN_UPDATE_SELECTED_GROUPED_ID,
              request.data.collaborators[0].collaborator.id
            );
            dispatch(CAMPAIGN_GROUPED_LOAD_COLLABORATIONS, {
              loadMoreCollaborations: false
            });
          }
        } else {
          commit(CAMPAIGN_COLLABORATIONS_GROUPED_SET_COLLABORATORS, {
            pageToken: null,
            hasError: true,
            isLoading: false
          });
        }
      } catch (error) {
        commit(CAMPAIGN_COLLABORATIONS_GROUPED_SET_COLLABORATORS, {
          data: [],
          pageToken: null,
          hasError: true,
          isLoading: false
        });
      }
    },
    [CAMPAIGN_GROUPED_LOAD_COLLABORATIONS]: async (
      { commit, state, getters, dispatch },
      { loadMoreCollaborations }
    ) => {
      const { filters } = state.tabs[0];
      const collaborations = state.selectedGroupedCollaborations.data;

      if (!loadMoreCollaborations) {
        commit(CAMPAIGN_COLLABORATIONS_GROUPED_SET_COLLABS, {
          data: [],
          hasError: false,
          isLoading: true
        });
      }
      const payload = {
        ...filterPayload(filters, collaborations, filters.isAllCampaigns),
        ...{
          collaborator_id: state.selectedGroupedCollaboratorID,
          final_date: parseDateTimeWithTimezone(
            getters.curCollaboratorInfo?.collaborations[0].created_at
          ),
          approval_level: null,
          order_by: "created_at"
        }
      };
      try {
        const collaborationsRequest = await Campaign.getCollaborations(payload);

        commit(CAMPAIGN_COLLABORATIONS_GROUPED_SET_COLLABS, {
          data: loadMoreCollaborations
            ? [
                ...state.selectedGroupedCollaborations.data,
                ...collaborationsRequest.data
              ]
            : collaborationsRequest.data,
          hasError: false,
          isLoading: false
        });
        dispatch(CAMPAIGN_COLLABORATIONS_GROUPED_LOAD_ANSWERS);
      } catch (error) {
        commit(CAMPAIGN_COLLABORATIONS_GROUPED_SET_COLLABS, {
          data: [],
          hasError: true,
          isLoading: false
        });
      }
    },
    [CAMPAIGN_COLLABORATIONS_GROUPED_LOAD_ANSWERS]: async ({
      commit,
      getters
    }) => {
      const collaboratorId = getters.curCollaboratorInfo?.collaborator.id;
      commit(CAMPAIGN_COLLABORATIONS_GROUPED_SET_ANSWERS, {
        data: [],
        hasError: false,
        isLoading: true
      });
      try {
        const emails_received = collaboratorId
          ? await Campaign.getCollaboratorEmailHistoric({
              campaign_id: Router.currentRoute.params.campaignId,
              collaborator_id: collaboratorId
            })
          : null;

        if (emails_received && emails_received.data) {
          commit(CAMPAIGN_COLLABORATIONS_GROUPED_SET_ANSWERS, {
            data: emails_received.data,
            hasError: false,
            isLoading: false
          });
        }
      } catch {
        commit(CAMPAIGN_COLLABORATIONS_GROUPED_SET_ANSWERS, {
          data: [],
          hasError: true,
          isLoading: false
        });
      }
    },
    [CAMPAIGN_GROUPED_CLEAN_COLLABORATORS]: async ({ commit }) => {
      commit(
        CAMPAIGN_COLLABORATIONS_GROUPED_SET_COLLABORATORS,
        defaultGroupedCollaboratorsState()
      );
    },
    [CAMPAIGN_GROUPED_CLEAN_COLLABORATIONS]: async ({ commit }) => {
      commit(
        CAMPAIGN_COLLABORATIONS_GROUPED_SET_COLLABS,
        defaultGroupedCollaborationsState()
      );
    },
    [CAMPAIGN_GROUPED_CLEAN_ANSWERS]: async ({ commit }) => {
      commit(
        "CAMPAIGN_COLLABORATIONS_GROUPED_SET_ANSWERS",
        defaultGroupedAnswersState()
      );
    },
    [CAMPAIGN_GROUPED_UPDATE_SELECTED_ID]: async (
      { dispatch, commit },
      newId
    ) => {
      commit(CAMPAIGN_UPDATE_SELECTED_GROUPED_ID, newId);
      dispatch(CAMPAIGN_GROUPED_CLEAN_COLLABORATIONS);
      dispatch(CAMPAIGN_GROUPED_LOAD_COLLABORATIONS, {
        loadMoreCollaborations: false
      });
    },
    ["CAMPAIGN_COLLABORATIONS_LOAD"]: async (
      { dispatch, commit, state },
      {
        tryPaginating,
        size = null,
        refresh = false,
        updateCollaborations = false
      }
    ) => {
      let { collaborations, filters } = state.tabs[state.tab_active];
      if (!tryPaginating) {
        commit("TAB_LOADING_SET", true);
        collaborations = [];
      }
      if (size) {
        filters.size = size;
      }
      if (updateCollaborations) {
        commit(UI_NEW_COLLABORATIONS_SET, false);
      }
      if (refresh) {
        const fdate = null; //currentDate.format("YYYY-MM-DD");
        const ftime = null; //currentDate.format("HH:mm");
        filters.final_date = fdate;
        filters.final_time = ftime;
        Bus.$root.$emit("refresh");
      }
      if (!tryPaginating) {
        dispatch("CAMPAIGN_COLLABORATIONS_COUNT", {
          polling: false
        });
      }
      const collaborationsRequest = await Campaign.getCollaborations(
        filterPayload(filters, collaborations, filters.isAllCampaigns)
      );
      commit(
        "CAMPAIGN_COLLABORATIONS_SET",
        tryPaginating
          ? [...collaborations, ...collaborationsRequest.data]
          : collaborationsRequest.data
      );
      commit(
        CAMPAIGN_COLLABORATIONS_ERROR_SET,
        !(collaborationsRequest && collaborationsRequest.data)
      );

      commit(
        "MT_CAMPAIGN_COLLABORATIONS_ISLASTPAGE_SET",
        collaborations.length === 0
      );

      if (!tryPaginating) {
        commit("TAB_LOADING_SET", false);
      }
    },
    ["CAMPAIGN_COLLABORATIONS_COUNT"]: async (
      { state, commit, dispatch },
      { polling = false }
    ) => {
      if (state.campaign?.id) {
        const { collaborationCount, filters, collaborations } = state.tabs[
          state.tab_active
        ];

        const { total } = await (
          await Campaign.collaborations.count(
            filterPayload(filters, collaborations, filters.isAllCampaigns)
          )
        ).data;

        const isShowCountMessage = () => {
          const { final_time, final_date } = filters;
          return !final_time && !final_date;
        };

        const showNotification = polling && total > collaborationCount;
        const AllowedPolling = () => {
          const { final_time, final_date } = filters;
          const dateNow = new Date().toLocaleString("default", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
          });
          const formattedDateNow = dateNow.replaceAll("/", "-");
          const c1 = !final_date;
          const c2 =
            !!final_date && final_date === formattedDateNow && !final_time;
          return c1 || c2;
        };
        if (showNotification && AllowedPolling()) {
          dispatch("UI_SNACKBAR_HIDE");
          const countDiff = parseInt(total) - parseInt(collaborationCount);
          const message =
            countDiff > 1 ? "Novas colaborações" : "Nova colaboração";

          dispatch("UI_SNACKBAR_SHOW", {
            message: isShowCountMessage() ? `${countDiff} ${message}` : message,
            bind: {
              left: true,
              style: {
                left: "16px",
                top: "-16px"
              }
            },
            update: true,
            polling: true
          });
          commit(UI_NEW_COLLABORATIONS_SET, true);
          const activeTerms = state.tabs[0].filters.search_text;
          const SONotificationMessage = activeTerms.length
            ? `Termos: ${activeTerms.join(", ")}`
            : `Campanha: ${state.campaign.name}`;

          handleTabTitleChange(activeTerms, true, countDiff);
          commit(UI_NOTIFICATION_INFO_SET, {
            title: `Colaborativo: Atualização de colaborações`,
            body: `Você possui: ${countDiff} ${message}\n${SONotificationMessage}`,
            newCollaborationsQuantity: countDiff
          });
        }
        if (!polling) {
          commit("CAMPAIGN_COLLABORATIONS_COUNT_SET", total);
        }
      }
    },
    ["CAMPAIGN_COLLABORATIONS_TOGGLE_ALL"]: (
      { state, commit },
      toggleValue
    ) => {
      const { collaborations } = state.tabs[state.tab_active];

      const newCollaborationSelected = {};
      if (toggleValue) {
        Object.values(collaborations).forEach((collaboration, index) => {
          newCollaborationSelected[collaboration.collaboration_id] = {
            index,
            collaboration
          };
        });
      }
      commit("MT_CAMPAIGN_COLLABORATIONS_SELECTED", newCollaborationSelected);
    },
    ["CAMPAIGN_COLLABORATION_SELECT"]: (
      { state, commit },
      { toggleValue, collaboration, index }
    ) => {
      const { collaborationSelected } = state.tabs[state.tab_active];

      const newCollaborationSelected = { ...collaborationSelected };
      if (toggleValue)
        Vue.set(newCollaborationSelected, collaboration.collaboration_id, {
          index,
          collaboration
        });
      else Vue.delete(newCollaborationSelected, collaboration.collaboration_id);
      commit("MT_CAMPAIGN_COLLABORATIONS_SELECTED", newCollaborationSelected);
    },
    [CAMPAIGN_COLLABORATION_REFRESH]: async ({ commit, rootGetters }) => {
      rootGetters.viewMode !== "conversation"
        ? commit(MT_COLLABORATION_REFRESH)
        : commit(MT_COLLABORATION_GROUPED_BY_COLLABORATORS_REFRESH);
    },
    // CARDS
    ["CAMPAIGN_COLLABORATIONS_FAVORITE"]: async (
      { state, dispatch, commit },
      { index, collaboration, user }
    ) => {
      try {
        await Campaign.collaborations.favorite(
          state.campaign.id,
          collaboration.collaboration_id,
          collaboration.total_approvals,
          collaboration.is_rejected,
          collaboration.was_approved
        );
        if (collaboration.favorite_of_users.length !== 0) {
          dispatch("UI_SNACKBAR_SHOW", {
            message: "Colaboração desfavoritada com sucesso"
          });
        } else {
          dispatch("UI_SNACKBAR_SHOW", {
            message: "Colaboração favoritada com sucesso"
          });
        }
        const { id: user_id } = user;
        commit("MT_CAMPAIGN_COLLABORATIONS_FAVORITE", {
          index,
          user_id
        });
        Analytics.favoriteCollaboration(state.campaign.id);
      } catch (err) {
        const { response } = err;
        switch (response.status) {
          case 403:
            dispatch("UI_SNACKBAR_SHOW", {
              message:
                "Você não possui permissão para favoritar esta colaboração."
            });
            break;
          default:
            dispatch("UI_SNACKBAR_SHOW", {
              message: MESSAGE_ERROR_DEFAULT
            });
            break;
        }
      }
    },

    ["CAMPAIGN_COLLABORATIONS_SHOW"]: ({ dispatch, commit }, collaboration) => {
      commit("MT_COLLABORATION_SELECTED", collaboration);
      dispatch("CAMPAIGN_COLLABORATION_REFRESH", collaboration.index);
    },
    ["CAMPAIGN_COLLABORATIONS_SHOW_PREVIOUS"]: ({
      state,
      dispatch,
      commit,
      rootGetters
    }) => {
      const newIndex = state.collaborationIdx - 1;
      if (newIndex >= 0) {
        const collaboration =
          rootGetters.viewMode !== "conversation"
            ? state.tabs[state.tab_active].collaborations[newIndex]
            : state.selectedGroupedCollaborations.data[newIndex];
        commit("MT_COLLABORATION_SELECTED", { collaboration, index: newIndex });
      } else {
        dispatch("UI_SNACKBAR_SHOW", {
          message: "Esta é a colaboração mais recente."
        });
      }
    },
    ["CAMPAIGN_COLLABORATIONS_SHOW_NEXT"]: ({
      state,
      dispatch,
      commit,
      rootGetters
    }) => {
      const newIndex = state.collaborationIdx + 1;
      const collaborations =
        rootGetters.viewMode !== "conversation"
          ? state.tabs[state.tab_active].collaborations
          : state.selectedGroupedCollaborations.data;
      if (newIndex < collaborations.length) {
        const collaboration = collaborations[newIndex];
        commit("MT_COLLABORATION_SELECTED", { collaboration, index: newIndex });
      } else {
        dispatch("UI_SNACKBAR_SHOW", {
          message: "Esta é a colaboração mais antiga."
        });
      }
    },
    ["CAMPAIGN_COLLABORATIONS_UPDATE"]: async (
      { state, dispatch, commit, rootGetters, getters },
      collaboration
    ) => {
      try {
        await Campaign.editCollaborations(
          state.collaborationEdit.campaign_id,
          state.collaborationEdit.collaboration_id,
          collaboration
        );
        rootGetters.viewMode !== "conversation"
          ? commit(MT_COLABORATION_EDIT, collaboration)
          : commit(MT_COLABORATION_GROUPED_BY_COLLABORATORS_EDIT, {
              collaboration: collaboration,
              collaborations: state.selectedGroupedCollaborations.data,
              collaborator: getters.curCollaboratorInfo.collaborator
            });
        commit("UI_COLLABORATION_EDIT_DRAWER_SET", false);

        dispatch("UI_SNACKBAR_SHOW", {
          message: "A colaboração foi editada com sucesso!"
        });
        Analytics.editCollaboration(state.collaborationEdit.campaign_id);
      } catch {
        dispatch("UI_SNACKBAR_SHOW", {
          message: "Erro ao tentar editar a colaboração :(!"
        });
      }
    },
    ["CAMPAIGN_COLLABORATIONS_DELETE"]: async (
      { state, dispatch, commit, rootGetters },
      { index, collaboration }
    ) => {
      const configMessage = {
        icon: "delete_forever",
        title: {
          featured: "Remover",
          text: "Colaboração"
        },
        message: {
          featured: "Você tem certeza que deseja Remover essa colaboração?",
          text: `Essa ação irá Remover a colaboração`
        },
        action: {
          color: "error",
          title: "Remover"
        }
      };
      Bus.$emit("openModalConfirm", configMessage, async callback => {
        if (callback) {
          try {
            await Campaign.collaborations.delete(
              state.campaign.id,
              collaboration.collaboration_id,
              collaboration.total_approvals,
              collaboration.is_rejected,
              collaboration.was_approved
            );
            dispatch("UI_SNACKBAR_SHOW", {
              message: "Colaboração apagada com sucesso"
            });
            if (rootGetters.viewMode !== "conversation") {
              const { collaborations } = state.tabs[state.tab_active];
              if (collaborations.length === 1) {
                dispatch("CAMPAIGN_COLLABORATIONS_LOAD", {
                  tryPaginating: false
                });
              }
              commit("MT_CAMPAIGN_COLLABORATIONS_REMOVE", index);
              commit("MT_CAMPAIGN_COLLABORATIONS_SELECTED", {});
            } else {
              dispatch(CAMPAIGN_GROUPED_LOAD_COLLABORATIONS, {
                loadMoreCollaborations: false
              });
              commit("UI_COLLABORATION_DRAWER_SET", false);
            }
          } catch (err) {
            const { response } = err;
            switch (response.status) {
              case 403:
                dispatch("UI_SNACKBAR_SHOW", {
                  message:
                    "Você não possui permissão para apagar esta colaboração."
                });
                break;
              default:
                dispatch("UI_SNACKBAR_SHOW", {
                  message: MESSAGE_ERROR_DEFAULT
                });
                break;
            }
          }
        }
      });
    },

    [CAMPAIGN_BLOCK_USER]: (
      { dispatch, rootState, state, rootGetters },
      payload
    ) => {
      dispatch(CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_HANDLE, {
        isEditMode: true,
        campaignEditId: state.campaign.id
      });
      const message = payload.block ? "bloquear" : "reativar";
      const configMessage = {
        icon: payload.block ? "mdi-account-lock" : "mdi-account-reactivate",
        title: {
          featured: message,
          text: "Usuário"
        },
        message: {
          featured: `Você tem certeza que deseja ${message} este usuário?`,
          text: `Essa ação irá ${message} o recebimento de mensagens deste usuário para esta colaboração`
        },
        action: {
          color: payload.block ? "error" : "primary",
          title: message
        }
      };
      Bus.$emit("openModalConfirm", configMessage, async callback => {
        let data = null;
        if (callback) {
          let formData =
            rootState.listCampaign.createOrEditCampaignParams.formData;

          if (payload.type === "canal-globo") {
            const blockedEmails =
              state.campaign.form_configuration.blocked_emails;
            const updatedBlockedEmais =
              payload.block && payload.email
                ? [...new Set([...blockedEmails, payload.email])]
                : blockedEmails.filter(email => email !== payload.email);

            data = {
              ...formData,
              form_configuration: {
                ...formData.form_configuration,
                blocked_emails: updatedBlockedEmais
              }
            };
          } else {
            const blockedPhones =
              state.campaign.whatsapp_configuration.blocked_phones;
            const updatedBlockedPhones =
              payload.block && payload.phone
                ? [...new Set([...blockedPhones, payload.phone])]
                : blockedPhones.filter(phone => phone !== payload.phone);

            data = {
              ...formData,
              whatsapp_configuration: {
                ...formData.whatsapp_configuration,
                blocked_phones: updatedBlockedPhones
              }
            };
          }

          try {
            await Campaign.edit(formData.id, data);
            dispatch(CAMPAIGN_LOAD, formData.id);
            if (rootGetters.viewMode === "conversation") {
              dispatch(CAMPAIGN_GROUPED_LOAD_COLLABORATORS, {
                loadMoreCollaborators: false
              });
            }
            dispatch(UI_SNACKBAR_SHOW, {
              message: `Usuário ${
                payload.block ? "bloquado" : "reativado"
              } com sucesso`
            });
          } catch (error) {
            dispatch(UI_SNACKBAR_SHOW, {
              message: `Erro ao tentar ${message} usuário`
            });
          }
        }
      });
    },
    ["CAMPAIGN_COLLABORATIONS_REJECT"]: (
      { state, dispatch, commit, rootGetters },
      { index, collaboration }
    ) => {
      const configMessage = {
        icon: "close",
        title: {
          featured: "Reprovar",
          text: "Colaboração"
        },
        message: {
          featured: "Você tem certeza que deseja rejeitar essa colaboração?",
          text: `Essa ação irá rejeitar a colaboração`
        },
        action: {
          color: "error",
          title: "Reprovar"
        }
      };
      Bus.$emit("openModalConfirm", configMessage, async callback => {
        if (callback) {
          try {
            await Campaign.collaborations.reject(
              state.campaign.id,
              collaboration.collaboration_id,
              collaboration.total_approvals,
              collaboration.is_rejected,
              collaboration.was_approved
            );
            dispatch("UI_SNACKBAR_SHOW", {
              message: "Colaboração rejeitada com sucesso"
            });
            if (rootGetters.viewMode !== "conversation") {
              commit("MT_CAMPAIGN_COLLABORATIONS_REMOVE", index);
              commit("MT_CAMPAIGN_COLLABORATIONS_SELECTED", {});
            } else {
              commit(MT_CAMPAIGN_COLLABORATORS_COLLABORATION_REJECT, index);
            }
            commit("UI_COLLABORATION_DRAWER_SET", false);
            Analytics.rejectCollaboration(state.campaign.id);
          } catch (err) {
            const { response } = err;
            switch (response.status) {
              case 403:
                dispatch("UI_SNACKBAR_SHOW", {
                  message:
                    "Você não possui permissão para rejeitar esta colaboração."
                });
                break;
              default:
                dispatch("UI_SNACKBAR_SHOW", {
                  message: MESSAGE_ERROR_DEFAULT
                });
                break;
            }
          }
        }
      });
    },
    ["CAMPAIGN_COLLABORATIONS_APPROVE"]: async (
      { state, dispatch, commit, rootGetters },
      { index, collaboration }
    ) => {
      const configMessage = {
        icon: "check",
        title: {
          featured: "Aprovar",
          text: "Colaboração"
        },
        message: {
          featured: "Você tem certeza que deseja aprovar essa colaboração?",
          text: `Essa ação irá aprovar a colaboração`
        },
        action: {
          color: "success",
          title: "Aprovar"
        }
      };
      Bus.$emit("openModalConfirm", configMessage, async callback => {
        if (callback) {
          try {
            await Campaign.collaborations.approve(
              state.campaign.id,
              collaboration.collaboration_id,
              collaboration.total_approvals,
              collaboration.is_rejected,
              collaboration.was_approved
            );
            dispatch("UI_SNACKBAR_SHOW", {
              message: "Colaboração aprovada com sucesso."
            });
            if (rootGetters.viewMode !== "conversation") {
              commit("MT_CAMPAIGN_COLLABORATIONS_REMOVE", index);
              commit("MT_CAMPAIGN_COLLABORATIONS_SELECTED", {});
            } else {
              const moderationLevel = state.campaign.required_approvals;
              const totalApprovals = collaboration.total_approvals + 1;

              commit(MT_CAMPAIGN_COLLABORATORS_COLLABORATION_APPROVE, {
                totalApprovals: totalApprovals,
                canApprove: totalApprovals === 2 || moderationLevel === 0,
                canUpdateTotal: moderationLevel === 0 || totalApprovals < 2,
                index: index
              });
            }
            commit("UI_COLLABORATION_DRAWER_SET", false);
            Analytics.approveCollaboration(state.campaign.id);
          } catch (err) {
            const { response } = err;
            switch (response.status) {
              case 403:
                dispatch("UI_SNACKBAR_SHOW", {
                  message:
                    "Você não possui permissão para aprovar esta colaboração."
                });
                break;
              default:
                dispatch("UI_SNACKBAR_SHOW", {
                  message: MESSAGE_ERROR_DEFAULT
                });
                break;
            }
          }
        }
      });
    },
    ["CAMPAIGN_COLLABORATIONS_RECYCLE"]: async (
      { state, dispatch, commit, rootGetters },
      { index, collaboration }
    ) => {
      const configMessage = {
        icon: "autorenew",
        title: {
          featured: "Reciclar",
          text: "Colaboração"
        },
        message: {
          featured: "Você tem certeza que deseja reciclar essa colaboração?",
          text: `Essa ação irá reciclar a colaboração`
        },
        action: {
          color: "primary",
          title: "Reciclar"
        }
      };
      Bus.$emit("openModalConfirm", configMessage, async callback => {
        if (callback) {
          try {
            await Campaign.collaborations.recycle(
              state.campaign.id,
              collaboration.collaboration_id,
              collaboration.total_approvals,
              collaboration.is_rejected,
              collaboration.was_approved
            );
            dispatch("UI_SNACKBAR_SHOW", {
              message: "Colaboração reciclada com sucesso."
            });
            if (rootGetters.viewMode !== "conversation") {
              commit("MT_CAMPAIGN_COLLABORATIONS_REMOVE", index);
              commit("MT_CAMPAIGN_COLLABORATIONS_SELECTED", {});
            } else {
              dispatch(CAMPAIGN_GROUPED_LOAD_COLLABORATIONS, {
                loadMoreCollaborations: false
              });
            }
          } catch (err) {
            const { response } = err;
            switch (response.status) {
              case 403:
                dispatch("UI_SNACKBAR_SHOW", {
                  message:
                    "Você não possui permissão para reciclar esta colaboração."
                });
                break;
              default:
                dispatch("UI_SNACKBAR_SHOW", {
                  message: MESSAGE_ERROR_DEFAULT
                });
                break;
            }
          }
        }
      });
    },
    ["CAMPAIGN_COLLABORATIONS_COPY"]: async ({ state, dispatch }, data) => {
      const { campaign } = state;
      const limitBulk = 100;
      try {
        const totalCampaignsSelected = Object.keys(data["to_campaigns"]).length;
        for (let i = 0; i < totalCampaignsSelected; i += limitBulk) {
          const end =
            i + limitBulk < totalCampaignsSelected
              ? i + limitBulk
              : totalCampaignsSelected;

          const batch = data["to_campaigns"].slice(i, end);

          const payload = {
            target_campaigns_ids: batch,
            collaboration_id: data["collaboration"]
          };

          await Campaign.copyCollaboration(campaign.id, payload);
        }

        dispatch("UI_SNACKBAR_SHOW", {
          message: "Colaboração copiada com sucesso!"
        });
        Analytics.copyCollaboration(campaign.id);
      } catch {
        dispatch("UI_SNACKBAR_SHOW", {
          message: MESSAGE_ERROR_DEFAULT
        });
      }
    },
    ["CAMPAIGN_COLLABORATIONS_BULK_DELETE"]: async ({
      state,
      dispatch,
      commit
    }) => {
      const { collaborationSelected } = state.tabs[state.tab_active];
      const { campaign } = state;

      const collaboration_ids = Object.keys(collaborationSelected);
      const collaboration_ids_sharded = createShardByArray(
        collaboration_ids,
        BULK_SHART_LENGTH
      );
      const campaignId = campaign.id;

      const configMessage = {
        icon: "delete_forever",
        title: {
          featured: "Remover",
          text: "Colaboração"
        },
        message: {
          featured: "Deseja realmente remover as colaborações ?",
          text: `Essa ação irá remover a colaboração e não poderá ser desfeita.`
        },
        action: {
          color: "error",
          title: "Remover"
        }
      };
      Bus.$emit("openModalConfirm", configMessage, async callback => {
        if (callback) {
          try {
            for (let lessthen100 of collaboration_ids_sharded) {
              await Campaign.bulkDeleteCollaboration(campaignId, {
                collaboration_ids: lessthen100
              });
            }
            dispatch("UI_SNACKBAR_SHOW", {
              message: "Colaborações excluídas com sucesso!"
            });
            removeCollaborationView(collaborationSelected, commit);
            commit("MT_CAMPAIGN_COLLABORATIONS_SELECTED", {});
            Analytics.removeCollaborationBatch(campaignId);
          } catch {
            dispatch("UI_SNACKBAR_SHOW", {
              message: MESSAGE_ERROR_DEFAULT
            });
          }
        }
      });
    },
    ["CAMPAIGN_COLLABORATIONS_MOVE"]: async (
      { state, dispatch, commit },
      to_campaign
    ) => {
      const { collaborationSelected } = state.tabs[state.tab_active];
      const { campaign } = state;
      try {
        const collaboration_ids = Object.keys(collaborationSelected);
        const collaboration_ids_sharded = createShardByArray(
          collaboration_ids,
          BULK_SHART_LENGTH
        );
        for (let lessThen100 of collaboration_ids_sharded) {
          const payload = {
            target_campaign_id: to_campaign,
            collaboration_ids: lessThen100
          };
          await Campaign.moveCollaborations(campaign.id, payload);
        }
        dispatch("UI_SNACKBAR_SHOW", {
          message: "Colaborações movidas com sucesso!"
        });
        removeCollaborationView(collaborationSelected, commit);
        commit("MT_CAMPAIGN_COLLABORATIONS_SELECTED", {});
        Analytics.moveCollaborationBatch(campaign.id);
      } catch {
        dispatch("UI_SNACKBAR_SHOW", {
          message: MESSAGE_ERROR_DEFAULT
        });
      }
    },
    ["CAMPAIGN_COLLABORATIONS_REPORTING"]: async ({ dispatch, state }) => {
      const { campaign } = state;
      try {
        const response = await Campaign.campaignReporting(campaign.id);
        if (response.status == 204) {
          dispatch("UI_SNACKBAR_SHOW", {
            message:
              "Ops... Não é possível gerar um relatório de uma campanha sem colaborações :)"
          });
        } else {
          dispatch("UI_SNACKBAR_SHOW", {
            message:
              "O relatório da campanha está sendo preparado e o link será enviado para seu e-mail"
          });
        }
      } catch (e) {
        const { response } = e;
        let msg;
        if (response.status == 409) {
          msg =
            "Ops...  você fez uma solicitação recentemente e ela ainda está sendo processada, aguarde alguns instantes e tente novamente :)";
        } else {
          msg = MESSAGE_ERROR_DEFAULT;
        }

        dispatch("UI_SNACKBAR_SHOW", {
          message: msg
        });
      }
    },
    ["CAMPAIGN_COLLABORATIONS_DOWNLOAD"]: async ({ dispatch, state }) => {
      const { collaborationSelected } = state.tabs[state.tab_active];
      const { campaign } = state;
      const selectedCount = Object.keys(collaborationSelected).length;
      if (selectedCount > 100) {
        dispatch("UI_SNACKBAR_SHOW", {
          message: `É permitido o download de no máximo 100 colaborações por ação e você tem ${selectedCount} selecionadas`
        });
      } else {
        const configMessage = {
          icon: "mdi-cloud-download",
          title: {
            featured: "Downloads",
            text: "das colaborações"
          },
          message: {
            featured: "Você deseja fazer download das colaborações?",
            text: `Essa ação irá enviar um link de download para seu email."
        `
          },
          action: {
            color: "primary",
            title: "Download"
          }
        };
        Bus.$emit("openModalConfirm", configMessage, async callback => {
          if (callback) {
            try {
              const payload = {
                collaboration_ids: Object.keys(collaborationSelected)
              };
              await Campaign.downloadCollaborations(campaign.id, payload);
              dispatch("UI_SNACKBAR_SHOW", {
                message:
                  "O Download das colaborações está sendo preparado e o link será enviado para seu e-mail"
              });
              Analytics.downloadCollaborationBatch(campaign.id);
            } catch (e) {
              const { response } = e;
              let msg;
              if (response.status == 409) {
                msg =
                  "Ops...  Já existe uma solicitação de download sendo processada, aguarde alguns instantes :)";
              } else {
                msg = MESSAGE_ERROR_DEFAULT;
              }

              dispatch("UI_SNACKBAR_SHOW", {
                message: msg
              });
            }
          }
        });
      }
    },
    ["CAMPAIGN_COLLABORATIONS_APPROVE_MULTIPLE"]: async ({
      dispatch,
      state,
      commit
    }) => {
      const { collaborationSelected } = state.tabs[state.tab_active];
      const { campaign } = state;

      const configMessage = {
        icon: "check",
        title: {
          featured: "Aprovar",
          text: "colaborações"
        },
        message: {
          featured:
            "Você tem certeza que deseja aprovar as colaborações selecionadas ?",
          text: `Essa ação aprovar as colaborações selecionadas."
      `
        },
        action: {
          color: "success",
          title: "Aprovar"
        }
      };
      Bus.$emit("openModalConfirm", configMessage, async callback => {
        if (callback) {
          try {
            const selected = Object.values(collaborationSelected).map(
              ({ collaboration }) => {
                return {
                  campaign_id: collaboration.campaign_id,
                  collaboration_id: collaboration.collaboration_id,
                  total_approvals: collaboration.total_approvals,
                  is_rejected: collaboration.is_rejected,
                  was_approved: collaboration.was_approved
                };
              }
            );
            await Campaign.approveCollaborations(campaign.id, selected);
            removeCollaborationView(collaborationSelected, commit);
            commit("MT_CAMPAIGN_COLLABORATIONS_SELECTED", {});
            dispatch("UI_SNACKBAR_SHOW", {
              message: "Colaborações aprovadas!"
            });
            Analytics.approveCollaborationBatch(campaign.id);
          } catch (e) {
            const { response } = e;
            let msg;
            if (response.status == 409) {
              msg =
                "Algumas colaborações já foram moderadas. As colaborações restantes foram aprovadas.";
            } else {
              msg = MESSAGE_ERROR_DEFAULT;
            }

            dispatch("UI_SNACKBAR_SHOW", {
              message: msg
            });
          }
        }
      });
    },
    ["CAMPAIGN_COLLABORATIONS_REJECT_MULTIPLE"]: async ({
      dispatch,
      state,
      commit
    }) => {
      const { collaborationSelected } = state.tabs[state.tab_active];
      const { campaign } = state;

      const selectedIds = Object.keys(collaborationSelected);

      if (selectedIds.length > BULK_SHART_LENGTH) {
        dispatch("UI_SNACKBAR_SHOW", {
          message: `É permitido rejeitar no máximo 100 colaborações por ação e você tem ${selectedIds.length} selecionadas.`
        });
        return;
      }

      const configMessage = {
        icon: "close",
        title: {
          featured: "Reprovar",
          text: "Colaboração"
        },
        message: {
          featured:
            "Você tem certeza que deseja rejeitar as colaborações selecionadas?",
          text: `Essa ação irá rejeitar TODAS as colaborações selecionadas.`
        },
        action: {
          color: "error",
          title: "Reprovar"
        }
      };
      Bus.$emit("openModalConfirm", configMessage, async callback => {
        if (callback) {
          try {
            await Campaign.rejectCollaborations(campaign.id, {
              collaboration_ids: selectedIds
            });
            removeCollaborationView(collaborationSelected, commit);
            commit("MT_CAMPAIGN_COLLABORATIONS_SELECTED", {});
            dispatch("UI_SNACKBAR_SHOW", {
              message: "Colaborações rejeitadas!"
            });
            Analytics.rejectCollaborationBatch(campaign.id);
          } catch (e) {
            const { response } = e;
            let msg;
            if (response.status == 409) {
              msg =
                "Algumas colaborações já foram moderadas. As colaborações restantes foram rejeitadas.";
            } else {
              msg = MESSAGE_ERROR_DEFAULT;
            }

            dispatch("UI_SNACKBAR_SHOW", {
              message: msg
            });
          }
        }
      });
    },
    ["CAMPAIGN_COLLABORATIONS_PUT_FILTER_ATTR"]: async (
      { commit, state, dispatch },
      attributes
    ) => {
      const filters = {
        ...getDefaultFilter(state.campaign),
        ...attributes
      };
      await dispatch("TAB_ADD", false);
      commit("MT_CAMPAIGN_COLLABORATIONS_FILTER_SET", filters);

      dispatch("CAMPAIGN_COLLABORATIONS_LOAD", { tryPaginating: false });
    },
    ["CAMPAIGN_COLLABORATIONS_LOAD_PAGE"]: async (
      { state, commit, dispatch },
      { tabIndex = 0, isNextPage = true }
    ) => {
      const { collaborations: currentCollaborations, filters } = state.tabs[
        tabIndex
      ];

      const collaborationsRequest = await Campaign.getCollaborations(
        filterPayload(filters, currentCollaborations, filters.isAllCampaigns)
      );

      if (collaborationsRequest.data.length === 0) {
        Bus.$root.$emit("lastPage");
        dispatch("UI_SNACKBAR_SHOW", {
          message: "Você está na ultima pagina.",
          bind: {
            center: true
          },
          update: false
        });

        return;
      }

      const newCollaborations = isNextPage
        ? collaborationsRequest.data
        : collaborationsRequest.data.reverse();

      const isEmptyOrLastPage = newCollaborations.length === 0;
      const newTab = {
        ...state.tabs[tabIndex],
        isLastPage: isEmptyOrLastPage,
        collaborations: newCollaborations
      };

      commit("MT_TAB_SET", { index: tabIndex, tab: newTab });
    },
    ["CAMPAIGN_COLLABORATIONS_POLL"]: async ({ dispatch, commit }) => {
      let timeOut = setInterval(
        () =>
          dispatch("CAMPAIGN_COLLABORATIONS_COUNT", {
            polling: true
          }),
        time_polling_count
      );
      commit("MT_POLLING_ADD", {
        id: timeOut,
        tab: 0
      });
    },
    ["CAMPAIGN_COLLABORATIONS_POLL_CLEANNER"]: async ({ commit }) => {
      commit("MT_POLLING_CLEANNER");
    },
    ["CAMPAIGN_COLLABORATIONS_LOAD_TABLE_VIEW"]: async (
      { commit, state },
      { sortBy, sortDesc, viewTable, page }
    ) => {
      const { filters } = state.tabs[0];
      filters.order_by = sortBy[0];
      filters.order_desc = sortDesc;

      const { collaborations: currentCollaborations } = state.tabs[0];

      const collaborationsRequest = await Campaign.getCollaborations(
        filterPayload(
          filters,
          currentCollaborations,
          filters.isAllCampaigns,
          filters.order_desc
            ? CollaborationsOrdering.Descending
            : CollaborationsOrdering.Ascending,
          page,
          viewTable
        )
      );
      if (collaborationsRequest) {
        commit("CAMPAIGN_COLLABORATIONS_SET", collaborationsRequest.data);
      }
    }
  },
  // # MUTATIONS ----------------------------
  mutations: {
    [UI_CLEAR_NOTIFICATION_INFO]: state => {
      state.notificationInfo = {};
    },
    [UI_NOTIFICATION_INFO_SET]: (state, payload) => {
      state.notificationInfo = { ...state.notificationInfo, ...payload };
    },
    [UI_NEW_COLLABORATIONS_SET]: (state, value) => {
      state.hasNewCollaborations = value;
    },
    ["MT_TAB_SET"]: (state, { index, tab }) => {
      Vue.set(state.tabs, index, tab);
    },
    ["MT_CAMPAIGN_SET"]: (state, campaign) => {
      state.campaign = {
        ...campaign,
        whatsapp_configuration: {
          ...campaign.whatsapp_configuration,
          blocked_phones: decryptWhatsappConfigurationData(
            campaign.whatsapp_configuration.blocked_phones
          ),
          accepted_phones: decryptWhatsappConfigurationData(
            campaign.whatsapp_configuration.accepted_phones
          )
        }
      };
    },
    ["TAB_SET_ALL"]: (state, tabs) => {
      state.tabs = tabs;
    },
    ["TAB_ADD"]: (state, tab) => {
      state.tabs.push(tab);
    },
    ["TAB_REMOVE"]: (state, index) => {
      state.tabs.splice(index, 1);
      state.tab_active = state.tabs.length - 1;
    },
    ["TAB_ACTIVE_SET"]: (state, tab) => {
      state.tab_active = tab;
    },
    ["TAB_LOADING_SET"]: (state, value, tabIndex = "") => {
      const idx = tabIndex || state.tab_active;
      state.tabs[idx].loading = value;
    },
    [CAMPAIGN_COLLABORATIONS_ERROR_SET]: (state, value) => {
      state.tabs[0].hasError = value;
    },
    ["TAB_RESET"]: state => {
      state.tabs = [{ ...tabDefaultState() }];
    },
    ["CAMPAIGN_COLLABORATIONS_SET"]: (state, collaborations) => {
      state.tabs[state.tab_active].collaborations = collaborations;
    },
    ["MT_CAMPAIGN_COLLABORATIONS_SELECTED"]: (state, newSelected) => {
      state.tabs[state.tab_active].collaborationSelected = newSelected;
    },
    ["CAMPAIGN_COLLABORATIONS_COUNT_SET"]: (state, total) => {
      state.tabs[state.tab_active].collaborationCount = total;
    },
    ["SEARCH_TERMS_SET"]: (state, terms) => {
      state.tabs[state.tab_active].filters.search_text = terms;
    },
    ["MT_CAMPAIGN_COLLABORATIONS_FILTER_SET"]: (state, newFilter) => {
      state.tabs[state.tab_active].filters = newFilter;
    },
    // CARDS
    ["MT_CAMPAIGN_COLLABORATIONS_FAVORITE"]: (state, { index, user_id }) => {
      const { collaborations } = state.tabs[state.tab_active];
      if (index !== -1) {
        const is_favorited = collaborations[index].favorite_of_users.includes(
          user_id
        );
        Vue.set(
          collaborations[index],
          "favorite_of_users",
          is_favorited ? [] : [user_id]
        );
      }
    },
    ["MT_COLLABORATION_SELECTED"]: (state, DataCollaboration) => {
      state.collaborationEdit = DataCollaboration.collaboration;
      state.collaborationIdx = DataCollaboration.index;
    },
    [MT_COLLABORATION_REFRESH]: state => {
      Vue.set(
        state.tabs[state.tab_active].collaborations,
        state.collaborationIdx,
        state.tabs[state.tab_active].collaborations[state.collaborationIdx]
      );
    },
    [MT_COLLABORATION_GROUPED_BY_COLLABORATORS_REFRESH]: state => {
      Vue.set(
        state.selectedGroupedCollaborations.data,
        state.collaborationIdx,
        state.selectedGroupedCollaborations.data[state.collaborationIdx]
      );
    },
    [MT_COLABORATION_EDIT]: (state, data) => {
      const collaborations = state.tabs[state.tab_active].collaborations;

      const pos = state.collaborationIdx;
      const newCollaboration = {
        ...collaborations[pos]
      };
      newCollaboration.body = {
        ...newCollaboration.body,
        title: data.title,
        message: data.message,
        comment: data.comment
      };
      newCollaboration.collaborator.name = data.name;
      Vue.set(collaborations, pos, newCollaboration);
      Vue.set(state, "collaborationEdit", newCollaboration);
    },
    [MT_COLABORATION_GROUPED_BY_COLLABORATORS_EDIT]: (
      state,
      { collaboration, collaborations, collaborator }
    ) => {
      const pos = state.collaborationIdx;
      const newCollaboration = {
        ...collaborations[pos]
      };
      newCollaboration.body = {
        ...newCollaboration.body,
        title: collaboration.title,
        message: collaboration.message,
        comment: collaboration.comment
      };
      Vue.set(collaborator, "name", collaboration.name);
      Vue.set(collaborations, pos, newCollaboration);
      Vue.set(state, "collaborationEdit", newCollaboration);
    },
    ["MT_CAMPAIGN_COLLABORATIONS_REMOVE"]: (state, collaboration_idx) => {
      state.tabs[state.tab_active].collaborationCount =
        state.tabs[state.tab_active].collaborationCount - 1;
      state.tabs[state.tab_active].collaborations.splice(collaboration_idx, 1);
    },
    [MT_CAMPAIGN_COLLABORATORS_COLLABORATION_REJECT]: (
      state,
      collaboration_idx
    ) => {
      const collaboration =
        state.selectedGroupedCollaborations.data[collaboration_idx];
      collaboration.was_approved = false;
      collaboration.is_rejected = true;
    },
    [MT_CAMPAIGN_COLLABORATORS_COLLABORATION_APPROVE]: (
      state,
      { totalApprovals, canApprove, canUpdateTotal, index }
    ) => {
      const collaboration = state.selectedGroupedCollaborations.data[index];
      if (canUpdateTotal) {
        collaboration.total_approvals = totalApprovals;
      }
      if (canApprove) {
        collaboration.was_approved = true;
      }
    },
    ["MT_CAMPAIGN_COLLABORATIONS_PUT"]: (state, collaboration) => {
      state.tabs[state.tab_active].collaborations.unshift(collaboration);
    },
    // paginação
    ["MT_CAMPAIGN_COLLABORATIONS_ISLASTPAGE_SET"]: (state, isLastPage) => {
      state.tabs[state.tab_active].isLastPage = isLastPage;
    },

    ["MT_POLLING_ADD"]: (state, { id }) => {
      state.polling.push(id);
      state.tabs[0].polling_id = id;
    },
    ["MT_POLLING_REMOVE"]: (state, id) => {
      const nId = parseInt(id);
      const i = state.polling.indexOf(nId);
      clearInterval(nId);
      state.polling.splice(i, 1);
    },
    ["MT_POLLING_CLEANNER"]: state => {
      state.polling.map(v => clearInterval(v));
      state.polling = [];
    },
    [CAMPAIGN_COLLABORATIONS_GROUPED_SET_COLLABORATORS]: (state, payload) => {
      state.collaborationsGroupedByCollaborator = {
        ...state.collaborationsGroupedByCollaborator,
        ...payload
      };
    },
    ["CAMPAIGN_COLLABORATIONS_GROUPED_SET_ANSWERS"]: (state, payload) => {
      state.selectedGroupedAnswers = {
        ...state.selectedGroupedAnswers,
        ...payload
      };
    },
    [CAMPAIGN_COLLABORATIONS_GROUPED_SET_COLLABS]: (state, payload) => {
      state.selectedGroupedCollaborations = {
        ...state.selectedGroupedCollaborations,
        ...payload
      };
    },
    [CAMPAIGN_UPDATE_SELECTED_GROUPED_ID]: (state, new_id) => {
      state.selectedGroupedCollaboratorID = new_id;
    },
    [SET_PLAYING_VIDEO]: (state, video) => {
      state.videoPlaying = video;
    },
    [SET_PLAYING_AUDIO]: (state, audio) => {
      state.audioPlaying = audio;
    }
  }
};
