<template>
  <v-navigation-drawer
    v-model="drawer"
    fixed
    temporary
    right
    width="100%"
    overlay-opacity="0.88"
    stateless
  >
    <!-- Aviso de funcionalidade restrita -->
    <Feedback
      feedbackType="administration-nav-drawer-feedback"
      :emptyState="emptyState"
      :isVisible="true"
    >
      <template v-slot:footer>
        <div class="footer">
          <v-btn large color="primary" @click="drawer = !drawer">
            Voltar
          </v-btn>
        </div>
      </template>
    </Feedback>

    <v-tabs
      v-model="tab"
      dark
      background-color="primary"
      vertical
      fixed
      grow
      icons-and-text
      active-class="tab-active-class"
    >
      <v-tabs-slider></v-tabs-slider>
      <div class="side-navigation">
        <v-tab href="#users" :disabled="!isAdmin">
          Lista de <br />Usuários
          <v-icon>group</v-icon>
        </v-tab>

        <v-tab href="#instagrams" v-if="shouldShowInstagram">
          Lista de Credenciais<br />
          do Instagram
          <v-icon>facebook</v-icon>
        </v-tab>

        <v-tab href="#products" :disabled="!isAdmin">
          Lista de <br />Grupos
          <v-icon>live_tv</v-icon>
        </v-tab>

        <v-tab href="#whatsapp" :disabled="!isAdmin">
          Lista de <br />whatsapp
          <v-icon>mdi-whatsapp</v-icon>
        </v-tab>

        <!-- <v-tab href="#inputs" :disabled="!isAdmin">
          Lista de <br />Entradas
          <v-icon>compare_arrows</v-icon>
        </v-tab> -->

        <v-tab href="#apis" :disabled="!isAdmin">
          Lista de <br />
          clientes de API
          <v-icon>swap_horiz</v-icon>
        </v-tab>

        <v-tab href="#media-policy" :disabled="!isAdmin">
          Lista de <br />políticas de mídia
          <v-icon>movie</v-icon>
        </v-tab>

        <v-tab href="#sftp-server" :disabled="!isAdmin">
          Lista de servidores <br />SFTP
          <v-icon>storage</v-icon>
        </v-tab>

        <v-tab href="#campaign-snapshot" :disabled="!isAdmin">
          Campanhas <br />Recuperáveis
          <v-icon>restore</v-icon>
        </v-tab>
      </div>

      <v-tab-item
        value="users"
        v-if="isAdmin"
        transition="scroll-y-transition"
        reverse-transition="scroll-y-reverse-transition"
      >
        <TheUserTab />
      </v-tab-item>
      <v-tab-item
        value="products"
        v-if="isAdmin"
        transition="scroll-y-transition"
        reverse-transition="scroll-y-reverse-transition"
      >
        <TheProductTab />
      </v-tab-item>

      <v-tab-item
        value="instagrams"
        transition="scroll-y-transition"
        reverse-transition="scroll-y-reverse-transition"
      >
        <TheInstagramTab />
      </v-tab-item>

      <v-tab-item
        value="whatsapp"
        v-if="isAdmin"
        transition="scroll-y-transition"
        reverse-transition="scroll-y-reverse-transition"
      >
        <TheWhatsAppInputTab />
      </v-tab-item>

      <!-- <v-tab-item
        value="inputs"
        v-if="isAdmin"
        transition="scroll-y-transition"
        reverse-transition="scroll-y-reverse-transition"
      >
        <TheInputTab />
      </v-tab-item> -->
      <v-tab-item
        value="apis"
        v-if="isAdmin"
        transition="scroll-y-transition"
        reverse-transition="scroll-y-reverse-transition"
      >
        <TheConsumerApiTab />
      </v-tab-item>

      <v-tab-item
        value="media-policy"
        v-if="isAdmin"
        transition="scroll-y-transition"
        reverse-transition="scroll-y-reverse-transition"
      >
        <TheMediaPolicyTab />
      </v-tab-item>

      <v-tab-item
        value="sftp-server"
        v-if="isAdmin"
        transition="scroll-y-transition"
        reverse-transition="scroll-y-reverse-transition"
      >
        <TheSftpServerTab />
      </v-tab-item>

      <v-tab-item
        value="campaign-snapshot"
        v-if="isAdmin"
        transition="scroll-y-transition"
        reverse-transition="scroll-y-reverse-transition"
      >
        <TheCampaignSnapshot />
      </v-tab-item>

      <!-- <v-tab-item
        value="medias"
        v-if="isAdmin"
        transition="scroll-y-transition"
        reverse-transition="scroll-y-reverse-transition"
      >
        <TheMediaTab />
      </v-tab-item> -->
    </v-tabs>
  </v-navigation-drawer>
</template>
<script>
import { mapState } from "vuex";

import Feedback from "@/components/Feedback.vue";
import { COLLABORATION_MEDIA_PLAYER_HANDLER } from "@/store/actions";
import { UI_DRAWER_SET, UI_DRAWER_TAB_SET } from "@/store/mutations";

import TheCampaignSnapshot from "./TheCampaignSnapshot";
// import TheMediaTab from "./TheMediaTab";
// import TheInputTab from "./TheInputTab";
import TheConsumerApiTab from "./TheConsumerApiTab";
import TheInstagramTab from "./TheInstagramTab";
import TheMediaPolicyTab from "./TheMediaPolicyTab";
import TheProductTab from "./TheProductTab";
import TheSftpServerTab from "./TheSftpServerTab";
import TheUserTab from "./TheUserTab";
import TheWhatsAppInputTab from "./TheWhatsAppInputTab";

export default {
  components: {
    Feedback,
    TheUserTab,
    TheProductTab,
    // TheInputTab,
    // TheMediaTab,
    TheConsumerApiTab,
    TheMediaPolicyTab,
    TheSftpServerTab,
    TheWhatsAppInputTab,
    TheInstagramTab,
    TheCampaignSnapshot
  },
  computed: {
    ...mapState({
      isAdmin: state => state.auth.user.is_superuser
    }),
    emptyState() {
      return {
        label: "Houston, we have a problem!",
        description:
          "Essa área ou funcionalidade não está disponível nesse dispositivo. Por favor, tente acessar através do seu notebook / desktop :)",
        bg: "icon"
      };
    },
    shouldShowInstagram() {
      return process.env.VUE_APP_INSTAGRAM_ENABLED === "1";
    },
    drawer: {
      get() {
        return this.$store.state.ui.drawer;
      },
      set(value) {
        this.$store.commit(UI_DRAWER_SET, value);
      }
    },
    tab: {
      get() {
        return this.$store.state.ui.currentTab;
      },
      set(value) {
        this.$store.commit(UI_DRAWER_TAB_SET, value);
      }
    }
  },
  watch: {
    drawer(value) {
      if (value) {
        this.$store.dispatch(COLLABORATION_MEDIA_PLAYER_HANDLER);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";
@import "@/scss/buttons.scss";

.v-tabs {
  padding: 0;
  height: 100vh;

  .v-tabs-slider {
    width: 4px;
  }

  ::v-deep .v-slide-group__wrapper {
    height: 100%;
  }

  ::v-deep .v-item-group {
    display: initial !important;
  }

  ::v-deep .v-slide-group {
    width: auto !important;
  }
  ::v-deep .item {
    .v-input {
      &.textfield label,
      &.textfield .v-input.v-input--is-label-active,
      &.textfield .v-input.v-input--is-label-focused {
        text-transform: uppercase !important;
        font-weight: 500 !important;
        font-size: 0.88em !important;
      }
    }
  }

  .side-navigation {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .v-tab {
      font-family: "Exo 2", sans-serif;
      font-size: 0.8em;
      font-weight: 600;
      font-style: italic;
      letter-spacing: 0;
      color: $color-gray-lighter !important;

      &.soon {
        opacity: 0.48;
      }

      .v-icon {
        margin: 0 0 8px 0;
        font-size: 2em;
        opacity: 0.8;
      }

      &.tab-active-class {
        border-left-width: 8px;
        font-weight: 600;
        color: $color-gray-lighter !important;
      }
    }
  }

  .v-tabs-items {
    padding: 0;
  }

  ::v-deep .footer.search {
    .v-btn {
      margin: 0 8px !important;
      .v-btn__content {
        line-height: inherit;
      }
      &:last-of-type {
        margin-right: 0 !important;
      }
    }
  }
}

// SLOT DO FEEDBACK

::v-deep .administration-nav-drawer-feedback .footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 56px;
  box-shadow: 0 -2px 8px rgba($color-gray-darker, 0.16);
}
::v-deep .administration-nav-drawer-feedback .footer .v-btn {
  width: 100%;
  height: 100%;
  border-radius: 0;
}
</style>
