<template>
  <v-app-bar
    class="header-actions"
    scroll-target=".main-wrapper"
    app
    fixed
    hide-on-scroll
    elevation="0"
    text
    height="56"
    v-show="show"
    :class="hasChips ? '--has-chips' : '--no-chips'"
  >
    <Search v-on:hasChipsHandler="hasChipsControler" />
    <CollaborationFilters v-if="isCollaborationsView" />
    <CampaignFilters v-else />
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";

import CampaignFilters from "@/components/AppBars/filters/CampaignFilters";
import CollaborationFilters from "@/components/AppBars/filters/CollaborationFilters";
import Search from "@/components/AppBars/search/Search";

export default {
  name: "HeaderActions",
  components: {
    Search,
    CollaborationFilters,
    CampaignFilters
  },
  mounted() {
    this.show = true;
  },
  data() {
    return {
      show: false,
      hasChips: false
    };
  },
  computed: {
    ...mapState({
      campaigns: state => state.listCampaign.campaigns
    }),
    isCollaborationsView() {
      return !!this.$route.params.campaignId;
    }
  },
  methods: {
    hasChipsControler() {
      this.hasChips = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/headers.scss";
</style>
