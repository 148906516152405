import "@fortawesome/fontawesome-free/css/all.min.css";
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure you are using css-loader
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@/scss/typography.scss";
import "animate.css";

import Vue from "vue";
import VueGtag from "vue-gtag";

import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import Analytics from "./services/analytics";
import store from "./store";

Vue.use(
  VueGtag,
  {
    config: {
      id: process.env.VUE_APP_GTAG
    }
  },
  router
);

Vue.config.productionTip = false;

const vue = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

Analytics.setVue(vue);
export default vue;
