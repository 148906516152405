import { Instagram } from "../gateways";
import {
  CC_INSTAGRAM_ADD_NEW_USER_ON_CREDENTIAL,
  CC_INSTAGRAM_CHECK_IF_USERNAME_EXIST,
  CC_INSTAGRAM_DELETE_INSTAGRAM_USER,
  CC_INSTAGRAM_EDIT_INSTAGRAM_USER,
  CC_INSTAGRAM_GET_FACEBOOK_PAGES,
  CC_INSTAGRAM_GET_HASHTAG_CURRENTLY_ACTIVED_ON_FB_CONTEXT,
  CC_INSTAGRAM_GET_INSTAGRAM_CREDENTIALS,
  CC_INSTAGRAM_GET_INSTAGRAM_CREDENTIALS_BY_PRODUCT_ID,
  CC_INSTAGRAM_POST_INSTAGRAM_USER,
  CC_INSTAGRAM_REAUTHORIZE_ACCOUNT,
  CC_INSTAGRAM_SET_UPDATE_LONG_ACCESS_TOKEN,
  UI_SNACKBAR_SHOW
} from "../store/actions";
import { MT_CHANGE_INSTAGRAM_ACCESS_TOKEN } from "../store/mutations";

export default {
  state: {
    longAccessToken: null
  },
  getters: {},
  actions: {
    [CC_INSTAGRAM_GET_HASHTAG_CURRENTLY_ACTIVED_ON_FB_CONTEXT]: async (
      { dispatch, state },
      ig_user_id
    ) => {
      try {
        const {
          data
        } = await Instagram.getHashtagCurrentlyActivedOnFacebookContext(
          ig_user_id,
          state.longAccessToken
        );
        return data;
      } catch (e) {
        const error = { ...e };
        const specificMessage = error?.response?.data?.detail;
        dispatch(UI_SNACKBAR_SHOW, {
          message:
            specificMessage || "Houve um problema ao adicionar o novo usuário."
        });
        return false;
      }
    },
    [CC_INSTAGRAM_ADD_NEW_USER_ON_CREDENTIAL]: async (
      { dispatch },
      { instagramCredentialId, username }
    ) => {
      try {
        const { data } = await Instagram.addUserOnCredential(
          instagramCredentialId,
          username
        );
        return data;
      } catch (e) {
        const error = { ...e };
        const specificMessage = error?.response?.data?.detail;
        dispatch(UI_SNACKBAR_SHOW, {
          message:
            specificMessage || "Houve um problema ao adicionar o novo usuário."
        });
        return false;
      }
    },
    [CC_INSTAGRAM_GET_FACEBOOK_PAGES]: async (
      { dispatch, commit },
      accessToken
    ) => {
      try {
        const { data } = await Instagram.getFacebookPages(accessToken);
        const { access_token } = data.long_access_token;
        commit(MT_CHANGE_INSTAGRAM_ACCESS_TOKEN, access_token);
        return data.pages;
      } catch (e) {
        const error = { ...e };
        const specificMessage = error?.response?.data?.detail;
        dispatch(UI_SNACKBAR_SHOW, {
          message:
            specificMessage ||
            "Houve um problema ao obter suas páginas do Facebook."
        });
      }
    },
    [CC_INSTAGRAM_GET_INSTAGRAM_CREDENTIALS]: async ({ dispatch }) => {
      try {
        const { data } = await Instagram.getInstagramCredentials();

        data.forEach(user => {
          if (!user.hashtags) {
            return;
          }
          user.hashtags.forEach(hash => {
            hash.name = `#${hash.name}`;
          });
        });
        return data;
      } catch (e) {
        const error = { ...e };
        const specificMessage = error?.response?.data?.detail;
        dispatch(UI_SNACKBAR_SHOW, {
          message:
            specificMessage ||
            "Houve um problema ao obter a lista de usuários do Instagram cadastrados."
        });
      }
    },
    [CC_INSTAGRAM_GET_INSTAGRAM_CREDENTIALS_BY_PRODUCT_ID]: async (
      { dispatch },
      productId
    ) => {
      try {
        const { data } = await Instagram.getInstagramCredentialsByProduct(
          productId
        );
        return data;
      } catch (e) {
        const error = { ...e };
        const specificMessage = error?.response?.data?.detail;
        dispatch(UI_SNACKBAR_SHOW, {
          message:
            specificMessage ||
            "Houve um problema ao obter a lista de usuários do Instagram cadastrados."
        });
      }
    },
    [CC_INSTAGRAM_POST_INSTAGRAM_USER]: async (
      { state, dispatch },
      {
        ig_user_id,
        facebook_page_id,
        name,
        description,
        users_watched,
        hashtags,
        group_allow_ids
      }
    ) => {
      const user_data = {
        ig_id: ig_user_id,
        facebook_page_id: facebook_page_id,
        name: name,
        description: description,
        access_token: state.longAccessToken,
        user_watched: users_watched,
        hashtags: hashtags,
        group_allow_ids: group_allow_ids
      };
      try {
        const data = await Instagram.postInstagramUser(user_data);
        return data;
      } catch (e) {
        const error = { ...e };
        const specificMessage = error?.response?.data?.detail;
        dispatch(UI_SNACKBAR_SHOW, {
          message:
            specificMessage ||
            "Houve um problema ao tentar cadastrar as informações do seu usuário."
        });
      }
    },
    [CC_INSTAGRAM_EDIT_INSTAGRAM_USER]: async (
      { state, dispatch },
      {
        ig_user_id,
        facebook_page_id,
        name,
        description,
        users_watched,
        hashtags,
        group_allow_ids,
        logged_in_id
      }
    ) => {
      const user_data = {
        ig_id: ig_user_id,
        facebook_page_id: facebook_page_id,
        name: name,
        description: description,
        access_token: state.longAccessToken,
        user_watched: users_watched,
        hashtags: hashtags,
        group_allow_ids: group_allow_ids
      };
      try {
        const data = await Instagram.editInstagramUser(user_data, logged_in_id);
        return data;
      } catch (e) {
        const error = { ...e };
        const specificMessage = error?.response?.data?.detail;
        dispatch(UI_SNACKBAR_SHOW, {
          message: specificMessage || "Houve um problema ao tentar editar."
        });
      }
    },
    [CC_INSTAGRAM_DELETE_INSTAGRAM_USER]: async ({ dispatch }, user_id) => {
      try {
        const data = await Instagram.deleteInstagramUser(user_id);
        return data;
      } catch (e) {
        const error = { ...e };
        const specificMessage = error?.response?.data?.detail;
        dispatch(UI_SNACKBAR_SHOW, {
          message:
            specificMessage ||
            "Houve um problema ao tentar deletar esse usuário."
        });
      }
    },
    [CC_INSTAGRAM_REAUTHORIZE_ACCOUNT]: async (
      { state, dispatch },
      { reauthorization_data, user_id }
    ) => {
      reauthorization_data.access_token = state.longAccessToken;
      try {
        const data = await Instagram.reauthorizeAccount(
          user_id,
          reauthorization_data
        );
        return data;
      } catch (e) {
        const error = { ...e };
        const specificMessage = error?.response?.data?.detail;
        dispatch(UI_SNACKBAR_SHOW, {
          message:
            specificMessage ||
            "Houve um problema ao reautenticar credenciais do Instagram."
        });
      }
    },
    [CC_INSTAGRAM_CHECK_IF_USERNAME_EXIST]: async (
      { state, dispatch },
      { ig_user_id, username }
    ) => {
      if (state.longAccessToken === null) {
        const message =
          "Você precisa se autenticar no Facebook antes de fazer essa operação.";
        dispatch(UI_SNACKBAR_SHOW, {
          message
        });
        throw new Error(message);
      }
      try {
        const { data } = await Instagram.businessDiscovery(
          ig_user_id,
          username,
          state.longAccessToken
        );
        return data;
      } catch (e) {
        const error = { ...e };
        const specificMessage = error?.response?.data?.detail;
        dispatch(UI_SNACKBAR_SHOW, {
          message: specificMessage || "Erro ao adicionar usuário."
        });
        throw e;
      }
    },
    [CC_INSTAGRAM_SET_UPDATE_LONG_ACCESS_TOKEN]: (
      { commit },
      received_token
    ) => {
      commit(MT_CHANGE_INSTAGRAM_ACCESS_TOKEN, received_token);
    }
  },
  mutations: {
    [MT_CHANGE_INSTAGRAM_ACCESS_TOKEN]: (state, accessToken) => {
      state.longAccessToken = accessToken;
    }
  }
};
