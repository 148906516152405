var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseCard',[_c('CardHeader',{attrs:{"title":"METADADOS","subtitle":"Configure campos específicos para sua campanha (Formulário ou Uploader)"}}),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","text":"","large":"","disabled":_vm.disabled},on:{"click":_vm.createItem}},[_vm._v(" NOVO CAMPO "),_c('v-icon',{staticClass:"pl-2"},[_vm._v("mdi-plus-circle")])],1),_c('v-data-table',{staticClass:"metadata-table",attrs:{"headers":_vm.metadataHeaders,"items":_vm.metadataArray,"disable-sort":"","item-key":"field","show-select":""},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){return undefined}},{key:"item.data-table-select",fn:function(ref){return [_c('v-icon',{staticClass:"metadata-item-handle"},[_vm._v("mdi-drag")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v("edit")])]}}],null,true)},[_c('span',[_vm._v("Editar campo")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.softDelete(item)}}},on),[_vm._v("delete")])]}}],null,true)},[_c('span',[_vm._v("Remover campo")])])]}},{key:"item.required",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.required ? "Sim" : "Não"))])]}}],null,true)}),_c('Dialog',{attrs:{"dialog":_vm.isModalVisible,"title":"Excluir metadado","subtitle":"Ao prosseguir o metadado será excluído, confirma a exclusão?"},on:{"toggle-dialog":function($event){return _vm.toggleDialog($event)}},scopedSlots:_vm._u([{key:"modal-actions",fn:function(){return [_c('ButtonSecondary',{attrs:{"icon":"mdi-close-circle-outline","label":"Cancelar"},on:{"click-handler":_vm.cancelDeleteOperation}}),_c('ButtonPrimary',{staticClass:"ml-4",attrs:{"color":"reprove","icon":"mdi-delete-outline","label":"Excluir"},on:{"click-handler":_vm.hardDelete}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }