<template>
  <Drawer
    @toggle-drawer="toggleDrawer"
    :drawer="isDestinationsDrawerVisible"
    @hard-update="hardUpdate"
    title="Configurar integrações "
    :isFullHeight="true"
    drawerName="Destinations"
    formRef="destinations-drawer-form"
  >
    <template v-slot:drawer-content>
      <div class="modal-content__item">
        <div class="title--small mb-1">CLIENTES DE ENTRADA</div>
        <div class="subtitle--small mb-4">
          Cadastre aplicações clientes de entrada da campanha
        </div>
        <div class="input-item full">
          <div class="select-divisor"></div>
          <div class="select-placeholder">
            Adicione um cliente ou escolha entre as opções mais comuns...
          </div>
          <AutocompleteGroup
            @update="softUpdate($event, 'localInputs')"
            :items="clients"
            :itemText="item => item.name"
            prependIcon="mdi-swap-horizontal"
            :selectedItems="formData.inputs"
          />
        </div>
      </div>

      <div class="modal-content__item">
        <div class="title--small mb-1">CLIENTES DE ESTATÍSTICAS</div>
        <div class="subtitle--small mb-4">
          Cadastre aplicações clientes de estatísticas da campanha
        </div>
        <div class="input-item full">
          <div class="select-divisor"></div>
          <div class="select-placeholder">
            Adicione um cliente ou escolha entre as opções mais comuns...
          </div>
          <AutocompleteGroup
            @update="softUpdate($event, 'localApiStatistics')"
            :items="clients"
            :itemText="item => item.name"
            prependIcon="mdi-swap-horizontal"
            :selectedItems="formData.api_statistics"
          />
        </div>
      </div>

      <div class="modal-content__item">
        <div class="title--small mb-1">SERVIDORES SFTP</div>
        <div class="subtitle--small mb-4">
          Escolha o servidor SFTP que deve ser usado com essa campanha no fluxo
          Kyosk
        </div>
        <div class="input-item full">
          <div class="select-divisor"></div>
          <div class="select-placeholder">
            Selecione um servidor ou escolha entre as opções mais comuns...
          </div>
          <AutocompleteGroup
            @update="softUpdate($event, 'localSftpId')"
            :items="sftpServers"
            :itemText="item => item.name"
            prependIcon="mdi-swap-horizontal"
            :selectedItems="formData.sftp_id"
            :multiple="false"
          />
        </div>
      </div>
    </template>
  </Drawer>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import AutocompleteGroup from "@/components/Commons/AutocompleteGroup";
import Drawer from "@/components/Commons/Dialogs/Drawer";
import { UI_DESTINATIONS_DRAWER_HANDLE } from "@/store/actions";
import { updateCampaignItem } from "@/util/campaign";

export default {
  name: "DestinationsDrawer",
  components: {
    AutocompleteGroup,
    Drawer
  },
  data() {
    return {
      localSftpId: null,
      localInputs: [],
      localApiStatistics: []
    };
  },
  computed: {
    ...mapState({
      formData: state => state.listCampaign.createOrEditCampaignParams.formData,
      isDestinationsDrawerVisible: state => state.ui.isDestinationsDrawerVisible
    }),
    ...mapGetters({
      sftpServers: "createOrUpdateSortedSftpServers",
      clients: "createOrUpdateSortedApiClients"
    }),

    currentFormDataSftpId() {
      return this.formData.sftp_id;
    },

    currentFormDataInputClients() {
      return this.formData.inputs;
    },

    currentFormDataStatisticsClients() {
      return this.formData.api_statistics;
    }
  },
  watch: {
    currentFormDataSftpId(value) {
      this.localSftpId = value;
    },

    currentFormDataInputClients(value) {
      this.localInputs = [...value];
    },

    currentFormDataStatisticsClients(value) {
      this.localApiStatistics = [...value];
    }
  },
  methods: {
    toggleDrawer(value) {
      this.$store.dispatch(
        UI_DESTINATIONS_DRAWER_HANDLE,
        value ? value : false
      );
    },
    hardUpdate() {
      updateCampaignItem(this.localSftpId, "sftp_id");
      updateCampaignItem(this.localInputs, "inputs");
      updateCampaignItem(this.localApiStatistics, "api_statistics");
    },
    softUpdate(value, item) {
      this[item] = value;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/theme.scss";
@import "@/scss/form-inputs.scss";
</style>
