<template>
  <div class="filters-section --collaboration">
    <div class="filters-items--mobile" v-show="$vuetify.breakpoint.smAndDown">
      <v-chip label ripple @click="openDrawer" :color="drawerFilterColor">
        filtros
        <v-icon right>mdi-sort-variant</v-icon>
      </v-chip>
    </div>

    <div class="filters-items" v-show="$vuetify.breakpoint.mdAndUp">
      <FilterBox
        title="Social"
        headerTitle="Fontes de captura"
        chipTitle="captura"
        :items="socialFilters.principal"
        :selectedItems="localFilters.toggle_captureSocial"
        :subBoxSelectedItems="localFilters.toggle_captureOthers"
        :subBoxItems="socialFilters.others"
        :filterColor="captureFilterColor"
        @update="localFilters.toggle_captureSocial = $event"
        @update-sub-box="localFilters.toggle_captureOthers = $event"
      />

      <FilterBox
        title="Mídias"
        headerTitle="Formatos de arquivo"
        chipTitle="formatos"
        :selectedItems="localFilters.toggle_fileFormatMedia"
        :subBoxSelectedItems="localFilters.toggle_fileFormatOthers"
        :items="mediaFilters.principal"
        :subBoxItems="mediaFilters.others"
        :filterColor="fileFormatsFilterColor"
        @update="localFilters.toggle_fileFormatMedia = $event"
        @update-sub-box="localFilters.toggle_fileFormatOthers = $event"
      />

      <!-- DATA / HORA -->
      <v-menu
        v-model="menuDateTime"
        min-width="auto"
        offset-y
        left
        transition="slide-y-transition"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-chip label ripple v-on="on" :color="dateColor">
            data / hora
            <v-icon right>mdi-chevron-down</v-icon>
          </v-chip>
        </template>

        <div class="dropdown">
          <div class="dropdown-header --full-space">
            <v-date-picker
              v-model="localFilters.datePicker"
              range
              show-current
              full-width
              locale="pt-br"
            />
          </div>
          <div class="dropdown-content">
            <div class="list-item">
              <v-text-field
                v-model="localFilters.initial_time"
                label="Hora inicial"
                outlined
                type="time"
                prepend-inner-icon="mdi-clock-time-four"
              />
              <v-text-field
                v-model="localFilters.final_time"
                label="Hora final"
                outlined
                type="time"
                prepend-inner-icon="mdi-clock-time-four"
              />
            </div>
          </div>
        </div>
      </v-menu>

      <!-- PERFIL DO USUÁRIO -->
      <v-menu
        v-model="menuUserProfile"
        offset-y
        left
        transition="slide-y-transition"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-chip label ripple v-on="on" :color="profileFilterColor">
            perfil do colaborador
            <v-icon right>mdi-chevron-down</v-icon>
          </v-chip>
        </template>

        <div class="dropdown --big">
          <div class="dropdown-content --column">
            <div class="dropdown-content-column --left">
              <div class="dropdown-content-column__title">Identificação</div>
              <template
                v-for="({ label, icon, controller, dataType },
                i) in collaboratorFilters.principal"
              >
                <div class="dropdown-content-column__item" :key="i">
                  <v-text-field
                    v-if="dataType === 'str'"
                    @change="localFilters[controller] = $event"
                    :value="localFilters[controller]"
                    color="graydark"
                    :label="label"
                    outlined
                    single-line
                    hide-details
                    :prepend-inner-icon="icon"
                  />
                  <v-text-field
                    v-else-if="dataType === 'bool'"
                    color="graydark"
                    :label="label"
                    outlined
                    readonly
                    disabled
                    single-line
                    hide-details
                    :prepend-inner-icon="icon"
                  >
                    <template v-slot:append>
                      <v-switch
                        @change="localFilters[controller] = $event"
                        :value="localFilters[controller]"
                      />
                    </template>
                  </v-text-field>
                </div>
              </template>
            </div>

            <div class="dropdown-content-column__divisor"></div>

            <div class="dropdown-content-column --right">
              <div class="dropdown-content-column__title">Localização</div>
              <div
                class="dropdown-content-column__item"
                v-for="({ label, icon, controller },
                i) in collaboratorFilters.others"
                :key="i"
              >
                <v-text-field
                  @change="localFilters[controller] = $event"
                  :value="localFilters[controller]"
                  color="graydark"
                  :label="label"
                  outlined
                  single-line
                  hide-details
                  :prepend-inner-icon="icon"
                />
              </div>
            </div>
          </div>
        </div>
      </v-menu>
    </div>
    <div class="filters-clear" v-show="$vuetify.breakpoint.mdAndUp">
      <v-tooltip bottom max-width="56px">
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            ripple
            class="--back-white --local-btn"
            v-on="on"
            @click="cleanFilters"
          >
            <v-icon>mdi-filter-variant-remove</v-icon>
          </v-btn>
        </template>
        <span>limpar filtros</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import FilterBox from "@/components/AppBars/filters/FilterBox";
import collaborationsFilters from "@/mixins/collaborations_filters";
import { UI_COLLABORATIONS_FILTERS_DRAWER_HANDLE } from "@/store/actions";
import {
  collaborationCollaboratorFilter,
  collaborationMediaFilter,
  collaborationSocialFilter
} from "@/util/collaborations";

export default {
  name: "CollaborationFilters",
  mixins: [collaborationsFilters],
  components: {
    FilterBox
  },
  data() {
    return {
      menuDateTime: false,
      menuUserProfile: false,
      socialFilters: collaborationSocialFilter,
      mediaFilters: collaborationMediaFilter,
      collaboratorFilters: collaborationCollaboratorFilter
    };
  },
  computed: {
    captureFilterColor() {
      return this.filters.inputtype.length ? "primary" : "";
    },
    fileFormatsFilterColor() {
      return this.filters.media_type.length ? "primary" : "";
    },
    dateColor() {
      return this.filters.initial_date ||
        this.filters.final_date ||
        this.filters.initial_time ||
        this.filters.final_time
        ? "primary"
        : "";
    },
    profileFilterColor() {
      return this.filters.collaborator_city ||
        this.filters.collaborator_country ||
        this.filters.collaborator_email ||
        this.filters.collaborator_name ||
        this.filters.collaborator_phone ||
        this.filters.collaborator_state ||
        this.filters.collaborator_verified
        ? "primary"
        : "";
    },
    drawerFilterColor() {
      return this.captureFilterColor === "primary" ||
        this.fileFormatsFilterColor === "primary" ||
        this.dateColor === "primary" ||
        this.profileFilterColor === "primary"
        ? "primary"
        : "";
    }
  },
  methods: {
    openDrawer() {
      this.$store.dispatch(UI_COLLABORATIONS_FILTERS_DRAWER_HANDLE, true);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/headers.scss";

.dropdown-content-column__item {
  .v-input--switch {
    margin-top: 0px !important;
    ::v-deep .v-input__slot {
      margin-bottom: 0px !important;
    }
  }
}
</style>
