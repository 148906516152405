<template>
  <v-btn
    height="40"
    class="white--text"
    :color="color"
    :class="{ 'btn--disabled': !!disabled }"
    @click.stop="clickHandler"
  >
    <v-icon v-if="icon" left dark>{{ icon }}</v-icon>
    {{ displayLabel }}
  </v-btn>
</template>

<script>
export default {
  name: "ButtonPrimary",
  data() {
    return {};
  },
  props: {
    label: String,
    icon: String,
    disabled: Boolean,
    color: {
      type: String,
      default: "primary",
      validator: function(value) {
        return ["primary", "approve", "reprove"].indexOf(value) !== -1;
      }
    }
  },
  computed: {
    displayLabel() {
      return this.$vuetify.breakpoint.xs ? "" : this.label;
    }
  },
  methods: {
    clickHandler() {
      this.$emit("click-handler");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";
@import "@/scss/form-inputs.scss";

@include for-size(phone-only) {
  .v-btn {
    max-width: fit-content !important;
    min-width: fit-content !important;
  }
}
</style>
