<template>
  <div class="main-wrapper">
    <GradientTopBackground />
    <HeaderMain>
      <template v-slot:header-left-brand>
        <div class="header-brand" v-if="$vuetify.breakpoint.smAndUp"></div>
        <div
          class="header-brand--mobile"
          v-if="$vuetify.breakpoint.xsOnly"
        ></div>
      </template>

      <template v-slot:header-right-primary>
        <div class="header-actions--primary">
          <v-btn
            large
            color="primary"
            class="white--text"
            @click="createCampaignClicked"
          >
            <v-icon left>mdi-folder-plus</v-icon>
            <div class="text">Criar Campanha</div>
          </v-btn>
        </div>
      </template>
    </HeaderMain>

    <HeaderActions />
    <CampaignsFiltersDrawer />

    <div class="main-content" ref="main">
      <CampaignFloatingActions />
      <FloatingButtons
        @go-to-top="goToTop"
        @actualize="actualizePage"
        :batchActionsOptions="{
          isVisible: false,
          campaignId: '',
          disabled: false
        }"
      />

      <template v-if="!listCampaignsError && !isLoadingData">
        <PeriodSegmenter
          :visibleItems="campaigns.length"
          :totalItems="totalCampaignsAvaialable"
          viewTypeText="campanhas"
        />

        <transition-group name="cards" tag="div" class="campaign-list">
          <v-lazy :key="c.id" v-for="(c, i) in this.campaigns">
            <CardCampaign :campaign="c" :index="i" class="card-item" />
          </v-lazy>
        </transition-group>
      </template>

      <Feedback
        feedbackType="campaign-list-feedback"
        :emptyState="emptyState"
        :isVisible="listCampaignsError"
      />

      <div class="loading" v-show="isLoadingData">
        <div class="loading-item">
          <img src="@/assets/images/colaborativo-icon-colors.svg" />
        </div>
      </div>
      <Observer @intersect="showNextPage" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import CampaignsFiltersDrawer from "@/components/AppBars/filters/CampaignsFiltersDrawer";
import HeaderActions from "@/components/AppBars/HeaderActions";
import HeaderMain from "@/components/AppBars/HeaderMain";
import CardCampaign from "@/components/Campaigns/Cards/CardCampaign";
import GradientTopBackground from "@/components/Commons/GradientTopBackground";
import PeriodSegmenter from "@/components/Commons/PeriodSegmenter.vue";
import Feedback from "@/components/Feedback.vue";
import CampaignFloatingActions from "@/components/FloatingActions/CampaignFloatingActions";
import FloatingButtons from "@/components/FloatingActions/FloatingButtons";
import Observer from "@/components/Observer.vue";
import {
  LC_CAMPAIGNS_CLEAR_FILTER,
  LC_CAMPAIGNS_GET_BY_FILTER
} from "@/store/actions";

const CampaignFilterMode = {
  All: null,
  Active: true,
  Inactive: false,
  Favortite: 3
};

export default {
  components: {
    HeaderMain,
    CampaignsFiltersDrawer,
    HeaderActions,
    GradientTopBackground,
    CardCampaign,
    CampaignFloatingActions,
    FloatingButtons,
    Feedback,
    Observer,
    PeriodSegmenter
  },
  async created() {
    this.isInitiating = true;
    await this.fetchInitialData();
    this.isInitiating = false;
  },
  mounted() {
    this.$store.dispatch("CAMPAIGN_COLLABORATIONS_POLL_CLEANNER");
    this.$store.dispatch("UI_SNACKBAR_HIDE");
  },
  data() {
    return {
      isLoadingData: false,
      isInitiating: true
    };
  },
  computed: {
    ...mapState({
      listCampaignsError: state => state.listCampaign.listCampaignsError
    }),
    ...mapGetters(["totalCampaignsAvaialable", "campaigns", "filter_data"]),
    ...mapGetters({ _sortedCampaigns: "sortedCampaigns" }),
    emptyState() {
      switch (this.filter_data.is_enabled) {
        case CampaignFilterMode.All:
          return {
            label: "Tão vazio por aqui!",
            description:
              "Você ainda não possui campanhas associadas ao seu usuário. Por favor, entre em contato com o administrador do seu grupo para visualizar as campanhas por aqui :)",
            bg: "bored"
          };
        case CampaignFilterMode.Active:
          return {
            label: "Tudo tranquilo, suave...",
            description:
              "Não existem campanhas ativas no momento. Por favor, escolha outra opção de visualização :)",
            bg: "easy"
          };
        case CampaignFilterMode.Inactive:
          return {
            label: "Tudo tranquilo, suave...",
            description:
              "Não existem campanhas inativas no momento. Por favor, escolha outra opção de visualização :)",
            bg: "easy"
          };
        default:
          throw "Unknown CampaignFilterMode";
      }
    }
  },
  methods: {
    async actualizePage() {
      await this.fetchInitialData();
    },
    createCampaignClicked() {
      this.$router.push({ name: "campaignEditor" });
    },
    async fetchInitialData() {
      this.isLoadingData = true;
      this.$store.dispatch(LC_CAMPAIGNS_CLEAR_FILTER);
      const messageError =
        "Não foi possível carregar a lista de campanhas disponíveis. Por favor, tente novamente em alguns minutos.";
      await this.$store.dispatch(LC_CAMPAIGNS_GET_BY_FILTER, { messageError });

      this.isLoadingData = false;
      this.$vuetify.goTo(0);
    },
    goToTop() {
      this.$refs.main.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    },
    showNextPage() {
      if (
        this.campaigns.length < this.totalCampaignsAvaialable &&
        !this.isInitiating
      ) {
        this.$store.dispatch(LC_CAMPAIGNS_GET_BY_FILTER, {
          tryPagination: true
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/commons.scss";

.campaign-list {
  animation: fadeIn 0.8s ease-in-out;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fit, minmax(458px, 1fr));
  grid-auto-flow: row;
  grid-auto-rows: auto;

  @media only screen and (max-width: $floating-actions-width-breakpoint) {
    margin-bottom: calc(1.25 * #{$floating-actions-slot-base-height});
  }
}
</style>
