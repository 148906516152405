import { mapGetters, mapState } from "vuex";

import { CollaborationState } from "@/enums";

export default {
  computed: {
    ...mapGetters(["collaborationSelected", "campaign"]),
    ...mapState({
      store_user: state => state.auth.user
    }),
    dataFavorites() {
      return {
        collaboration: this.collaboration,
        user: this.store_user
      };
    },
    getCollaborationAndIndex() {
      return { collaboration: this.collaboration, index: this.index };
    },
    favoriteBadgeStyle() {
      return this.collaboration.favorite_of_users.includes(this.store_user.id)
        ? "favorite"
        : "";
    },
    isSelected: {
      get() {
        return !!this.collaborationSelected[
          this.collaboration.collaboration_id
        ];
      },
      set(value) {
        this.$store.dispatch("CAMPAIGN_COLLABORATION_SELECT", {
          toggleValue: value,
          collaboration: this.collaboration,
          index: this.index
        });
      }
    },
    canDelete() {
      return true;
    },
    canReject() {
      return this.collaborationState !== CollaborationState.Rejected;
    },
    canApprove() {
      return (
        this.collaborationState !== CollaborationState.Rejected &&
        this.collaborationState !== CollaborationState.Approved
      );
    },
    canRecycle() {
      return this.collaborationState === CollaborationState.Rejected;
    },
    approvalIcon() {
      return this.collaborationState === CollaborationState.FirstModerationLevel
        ? "done"
        : "done_all";
    },

    messageLineStyling() {
      return {
        "-webkit-line-clamp":
          this.collaboration.body.title &&
          this.collaboration.body.title.length > 0
            ? 3
            : 5
      };
    }
  },
  methods: {
    getTheBestReferenceByType(sourceInput, collaborator) {
      switch (sourceInput) {
        case "cms":
        case "uploader":
        case "form":
          return collaborator.email;
        case "twitter":
          return collaborator.tw_user_id;
        case "whatsapp":
          return collaborator.phone;
        case "youtube":
          return collaborator.yt_channel_id;
        default:
          throw new Error("SourceInput invalid.");
      }
    },
    bestFilterByInputType(inputtype) {
      switch (inputtype) {
        case "cms":
        case "uploader":
        case "canal-globo":
        case "form":
          return {
            referenceQuery: "collaborator_email",
            referenceField: "email"
          };
        case "twitter":
          return {
            referenceQuery: "collaborator_name",
            referenceField: "name"
          };
        case "whatsapp":
          return {
            referenceQuery: "collaborator_phone",
            referenceField: "phone"
          };
        case "youtube":
          return {
            referenceQuery: "collaborator_yt_channel_id",
            referenceField: "yt_channel_id"
          };
        case "instagram":
          return {
            referenceQuery: "collaborator_insta_owner_id",
            referenceField: "insta_owner_id"
          };
        default:
          throw {
            message: `Não é possível filtrar por colaborações oriundas da entrada "${inputtype}".`
          };
      }
    }
  }
};
