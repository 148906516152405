<template>
  <!-- DIALOG -->
  <v-dialog
    @click:outside="clickHandler"
    v-model="dialogControl"
    overlay-opacity="0.88"
    max-width="600"
  >
    <div class="modal">
      <div class="modal-header">
        <div class="title--max">{{ title }}</div>
      </div>

      <div class="modal-content">
        <div class="modal-content__title">{{ subtitle }}</div>
        <div class="modal-content__text mb-4">{{ description }}</div>
        <slot name="content"></slot>
      </div>

      <div class="modal-actions">
        <slot name="modal-actions"></slot>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "Dialog",
  data() {
    return {};
  },
  props: {
    dialog: Boolean,
    title: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    }
  },
  mounted() {
    // this.theme = this.$root.$vuetify.theme.defaults.light;
  },
  computed: {
    dialogControl: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("toggle-dialog", value);
      }
    }
  },
  methods: {
    clickHandler() {
      this.$emit("toggle-dialog");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";
@import "@/scss/modal.scss";
@import "@/scss/form-inputs.scss";
</style>
