<template>
  <div class="container">
    <!-- Topo -->
    <div class="topo">
      <div class="title "><span>Lista</span> de Grupos</div>
    </div>
    <div class="main">
      <v-data-table
        :fixed-header="true"
        :headers="headers"
        :items="products"
        class="table-main"
        :mobile-breakpoint="1200"
        :search="search"
        :loading="isLoading"
        sort-by="name"
        loading-text="Carregando grupos..."
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip bottom v-if="item.poster_url">
            <template v-slot:activator="{ on }">
              <a target="_blank" :href="item.poster_url">
                <v-icon small class="mr-2" v-on="on">
                  photo
                </v-icon>
              </a>
            </template>
            <span>Ver pôster do grupo</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template
              v-slot:activator="{ on }"
              v-if="isAdmin || item.created_by == currentUserId"
            >
              <v-icon small class="mr-2" @click="editItem(item)" v-on="on">
                edit
              </v-icon>
            </template>
            <span>Editar grupo</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
    <div class="footer search">
      <!-- Search -->
      <div class="slot left">
        <v-text-field
          v-model="search"
          solo
          rounded
          clearable
          flat
          hide-details
          label="Pesquise por grupos..."
          append-icon="search"
          color="primary"
          clear-icon="close"
          background-color="#F5F5F5"
        ></v-text-field>
      </div>

      <!-- Actions -->
      <div class="slot right">
        <v-btn large color="seccondary" @click="$emit('cancelClickedEvent')">
          <v-icon>mdi-close</v-icon>
          Cancelar
        </v-btn>

        <v-btn large color="primary" @click="$emit('createClickedEvent')">
          <v-icon>add_circle</v-icon>
          Adicionar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { Product } from "../../../gateways";
import { UI_SNACKBAR_SHOW } from "../../../store/actions";
import { sorting } from "../../../util/sorting";

export default {
  data: () => ({
    isLoading: false,
    search: "",
    products: [],
    headers: [
      {
        text: "Nome",
        align: "left",
        sortable: true,
        value: "name"
      },
      {
        text: "Descrição",
        align: "left",
        sortable: true,
        value: "description_pretty"
      },
      {
        text: "Área",
        align: "left",
        sortable: true,
        value: "area"
      },
      { text: "Ações", value: "action", align: "right", sortable: false }
    ]
  }),
  created() {
    if (this.drawer) this.fetchData();
  },
  computed: {
    ...mapState({ currentTab: state => state.ui.currentTab }),
    ...mapState({ drawer: state => state.ui.drawer }),
    ...mapState({ isAdmin: state => state.auth.user.is_superuser }),
    ...mapState({ currentUserId: state => state.auth.user.id })
  },
  watch: {
    drawer() {
      if (this.drawer && this.currentTab === "products") this.fetchData();
    },
    currentTab() {
      if (this.drawer && this.currentTab === "products") this.fetchData();
    }
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      this.products = [];

      try {
        this.products = (await Product.get()).data;
        //Ordem alfabética
        this.products.sort(sorting.orderByName);
        this.products.forEach(product => {
          product.description_pretty = !product.description
            ? "-"
            : product.description;
        });
      } catch {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message:
            "Não foi possível carregar a lista de grupos. Por favor, tente novamente em alguns minutos."
        });
      }
      this.isLoading = false;
    },
    editItem(item) {
      this.$emit("editClickedEvent", item);
    }
  }
};
</script>

<style lang="scss">
@import "../../../scss/theme.scss";
@import "../../../scss/navigation-drawer.scss";
</style>
