var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"main"},[_c('v-data-table',{staticClass:"table-main",attrs:{"fixed-header":true,"headers":_vm.headers,"items":_vm.users,"mobile-breakpoint":1200,"sort-by":"name","search":_vm.search,"loading":_vm.isLoading,"loading-text":"Carregando credenciais do Instagram..."},scopedSlots:_vm._u([{key:"item.description_short",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getShortDescription(item))+" ")]}},{key:"item.group_names",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mt-1"},[_vm._l((_vm.getGroupNameList(item).slice(
              0,
              _vm.maxProductsShown
            )),function(curGroup,index){return _c('v-chip',{key:index,staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(curGroup)+" ")])}),(item.group_ids.length > _vm.maxProductsShown)?_c('v-chip',{attrs:{"color":"primary","title":_vm.remainderProductTooltip(item)}},[_c('strong',[_vm._v(_vm._s(("+ " + (item.group_ids.length - _vm.maxProductsShown))))])]):_vm._e()],2)]}},{key:"item.followed_accounts",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"mt-1"},[_vm._l((_vm.displayedFollowList(item)),function(account,index){return _c('span',{key:index,attrs:{"title":account.kill
                ? 'Essa hashtag está marcada para deleção. As hashtags ficam no mínimo 7 dias a partir de sua criação atreladas a uma conta Facebook.'
                : account.name}},[_c('v-chip',{staticClass:"mr-1",attrs:{"color":"primary","title":account.name,"disabled":account.kill}},[(account.type === 'user')?_c('v-avatar',{attrs:{"left":""}},[_c('v-img',{attrs:{"src":account.profile_picture_url}})],1):_vm._e(),_c('strong',[_vm._v(_vm._s(account.type === "user" ? account.username : account.name))])],1)],1)}),(_vm.completeFollowList(item).length > _vm.maxChipsShown)?_c('v-chip',{attrs:{"color":"primary","title":_vm.remainderFollowTooltip(item)}},[_c('strong',[_vm._v(_vm._s(_vm.remainderFollowList(item)))])]):_vm._e()],2)]}},{key:"item.status_icon",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":item.status ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.status ? "done" : "close")+" ")])]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","disabled":!_vm.canModifyCredential(item)},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v(" edit ")])]}}],null,true)},[_c('span',[_vm._v("Editar instagram")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","disabled":!_vm.canModifyCredential(item)},on:{"click":function($event){return _vm.reauthenticateItem(item)}}},on),[_vm._v(" published_with_changes ")])]}}],null,true)},[_c('span',[_vm._v("Reautenticar instagram")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","disabled":!_vm.canModifyCredential(item)},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_vm._v(" delete ")])]}}],null,true)},[_c('span',[_vm._v("Remover instagram")])])]}}],null,true)})],1),_c('div',{staticClass:"footer search"},[_c('div',{staticClass:"slot left"},[_c('v-text-field',{attrs:{"solo":"","rounded":"","clearable":"","flat":"","hide-details":"","label":"Pesquise por instagrams...","append-icon":"search","color":"primary","clear-icon":"close","background-color":"#F5F5F5"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"slot right"},[_c('v-btn',{attrs:{"large":"","color":"seccondary"},on:{"click":function($event){return _vm.$emit('cancelClickedEvent')}}},[_c('v-icon',[_vm._v("mdi-close")]),_vm._v(" Cancelar ")],1),_c('v-btn',{attrs:{"large":"","color":"primary"},on:{"click":function($event){return _vm.$emit('createClickedEvent')}}},[_c('v-icon',[_vm._v("add_circle")]),_vm._v(" Adicionar ")],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"topo"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("Lista")]),_vm._v(" de Credenciais do Instagram")])])}]

export { render, staticRenderFns }