<template>
  <div class="card --campaign" @click="goTo(campaign.id)">
    <div class="card-thumb">
      <CardPoster class="left" :posterUrl="src" />
    </div>

    <div class="card-content" data-cy="campaign_card">
      <!-- HEADER -->
      <div class="card-header">
        <div class="card-header-meta">
          <v-tooltip bottom max-width="180px">
            <template v-slot:activator="{ on }">
              <div class="card-header-meta__title" v-on="on">
                {{ campaign.name }}
              </div>
            </template>
            <span>{{ campaign.name }}</span>
          </v-tooltip>

          <div class="card-header-meta__product">
            {{ campaign.product.name }}
          </div>
        </div>

        <div
          class="card-header-status"
          :class="{ status: true, active: campaign.is_enabled }"
        >
          <v-icon>{{
            campaign.is_enabled
              ? "mdi-eye-check-outline"
              : "mdi-eye-minus-outline"
          }}</v-icon>
        </div>
      </div>

      <!-- DESCRIPTION -->
      <v-tooltip bottom max-width="180px">
        <template v-slot:activator="{ on }">
          <div class="card-description" v-on="on">
            {{ campaign.description }}
          </div>
        </template>
        <span>{{ campaign.description }}</span>
      </v-tooltip>

      <!-- CAPTURE -->
      <div class="card-capture">
        <div class="card-capture-title">Captura</div>

        <div class="card-capture-items">
          <template v-for="(iconInfo, index) in campaignCardCaptureIcons">
            <v-tooltip :key="index" top v-if="campaignHasThisCapture(iconInfo)">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" x-small fab elevation="0">
                  <v-icon v-if="iconInfo.icon">{{ iconInfo.icon }}</v-icon>
                  <i
                    v-else-if="iconInfo.iconClass"
                    class="v-icon icon-icon-uploader"
                  ></i>
                </v-btn>
              </template>
              <span
                >{{ iconInfo.label }}
                {{ iconInfo.labelComplemnent ? uploader_label_type : "" }}</span
              >
            </v-tooltip>
          </template>
        </div>
      </div>

      <!-- ACTIONS -->
      <div class="card-actions">
        <div class="card-actions-slot --left">
          <div class="card-date">
            {{ collaborationDateTime(campaign.created_at).date }}
          </div>
          <div class="card-time">
            {{ collaborationDateTime(campaign.created_at).time }}
          </div>
        </div>

        <div class="card-actions-slot --right">
          <div class="card-actions-btn" v-if="campaignUrls.length > 0">
            <v-menu
              left
              open-on-hover
              v-model="link"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  data-cy="campaign_external_actions"
                  >mdi-link</v-icon
                >
              </template>

              <div
                class="dropdown --form-link"
                v-for="(campaignUrl, index) in campaignUrls"
                :key="index"
              >
                <v-tooltip bottom max-width="80px">
                  <template v-slot:activator="{ on }">
                    <div class="dropdown-url" v-on="on">
                      {{ campaignUrl.url }}
                    </div>
                  </template>
                  <span> {{ campaignUrl.info }}</span>
                </v-tooltip>

                <div class="dropdown-actions">
                  <v-tooltip bottom max-width="80px">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="copyUrlHandler(campaignUrl.url)"
                        class="--back-white"
                        icon
                        ripple
                        v-on="on"
                      >
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                    </template>
                    <span>Copiar link</span>
                  </v-tooltip>

                  <v-tooltip bottom max-width="80px">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="
                          downloadUrlQrCodeHandler(
                            campaignUrl.url,
                            'imagem.png'
                          )
                        "
                        class="--back-white"
                        icon
                        ripple
                        v-on="on"
                      >
                        <v-icon>mdi-qrcode</v-icon>
                      </v-btn>
                    </template>
                    <span>Baixar QR code</span>
                  </v-tooltip>

                  <v-tooltip bottom max-width="80px">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="openUrlHandler(campaignUrl.url)"
                        class="--back-white"
                        icon
                        ripple
                        v-on="on"
                      >
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-btn>
                    </template>
                    <span>Abrir em nova aba</span>
                  </v-tooltip>
                </div>
              </div>
            </v-menu>
          </div>

          <div class="card-actions-btn">
            <v-menu top offset-y open-on-hover v-model="menu">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  data-cy="campaign_card_actions"
                >
                  more_horiz
                </v-icon>
              </template>

              <div class="dropdown">
                <div class="dropdown-footer">
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in actionCard.filter(
                        act => act.show
                      )"
                      :key="index"
                      @click.prevent="item.action(campaign.id)"
                      :class="{ divider: item.divider, [item.class]: true }"
                    >
                      <v-icon left>{{ item.icon }}</v-icon>
                      {{ item.title }}
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import Analytics from "@/services/analytics";
import { AUTH_PREFERENCES_HANDLER } from "@/store/actions";
import { downloadQrCode } from "@/util";
import { campaignCardCaptureIcons } from "@/util/campaigns";

import CardPoster from "./CardPoster";

export default {
  name: "CardCampaign",
  components: {
    CardPoster
  },
  props: ["campaign"],
  data() {
    return {
      src: null,
      menu: false,
      link: false,
      campaignCardCaptureIcons: campaignCardCaptureIcons
    };
  },
  computed: {
    ...mapGetters(["userFavoriteCampaigns"]),
    campaignUrls() {
      const urls = [];
      if (this.campaign.form_configuration.is_enabled) {
        if (this.campaign.form_configuration.is_canal_globo_enabled) {
          urls.push({
            info: "Canal Globo",
            url: `${process.env.VUE_APP_COLABORATIVO_CANAL_GLOBO_URI}${this.campaign.id}`
          });
        } else {
          urls.push({
            info: "Formulário",
            url: `${process.env.VUE_APP_COLABORATIVO_FORM_URI}${this.campaign.id}`
          });
        }
      }

      if (this.campaign.whatsapp_configuration.is_enabled) {
        this.campaign.whatsapp_configuration.whatsapps.map(w => {
          urls.push({
            info: `Whatsapp "${w.name}"`,
            url: `https://api.whatsapp.com/send?phone=+${w.phoneNumber.trim()}`
          });
        });
      }
      return urls;
    },
    uploader_label_type() {
      const {
        is_enabled: is_enabled_desktop
      } = this.campaign.uploader_configuration;
      const {
        is_enabled: is_enabled_web
      } = this.campaign.uploader_web_configuration;
      if (is_enabled_web && is_enabled_desktop) {
        return "(WEB e DESKTOP)";
      } else if (is_enabled_web && !is_enabled_desktop) {
        return "(Apenas WEB)";
      } else if (!is_enabled_web && is_enabled_desktop) {
        return "(Apenas DESKTOP)";
      }
      return "";
    },
    isThatAlreadyFavorited() {
      return this.userFavoriteCampaigns.find(
        campaign => campaign === this.campaign.id
      );
    },
    actionCard() {
      return [
        {
          title: "Remover",
          icon: "mdi-delete-forever",
          action: this.remove,
          class: "color-red",
          show: true,
          divider: false,
          chevron: false
        },

        {
          title: "Fixar",
          icon: "push_pin",
          action: this.edit,
          class: "",
          show: false,
          divider: true,
          chevron: false
        },

        {
          title: this.isThatAlreadyFavorited ? "Desfavoritar" : "Favoritar",
          icon: this.isThatAlreadyFavorited
            ? "mdi-heart-off-outline"
            : "mdi-heart-outline",
          action: this.favorite,
          class: this.isThatAlreadyFavorited ? "color-red" : "color-primary",
          show: true,
          divider: true,
          chevron: false
        },

        {
          title: "Editar",
          icon: "mdi-pencil",
          action: this.edit,
          class: "",
          show: true,
          divider: true,
          chevron: false
        },

        {
          title: "Colaborações",
          icon: "mdi-inbox-arrow-down",
          action: this.goTo,
          class: "",
          show: true,
          divider: false,
          chevron: "mdi-chevron-right"
        }
      ];
    }
  },
  created() {
    this.src =
      this.campaign.poster && this.campaign.poster !== ""
        ? this.campaign.poster
        : this.campaign.product.poster_url;
  },
  methods: {
    ...mapActions([AUTH_PREFERENCES_HANDLER]),
    campaignHasThisCapture(info) {
      switch (info.condition) {
        case "form_configuration":
          if (this.campaign[info.condition].is_enabled) {
            if (
              this.campaign[info.condition].is_canal_globo_enabled &&
              info.icon === "v-icon icon-icon-canalglobo"
            ) {
              return true;
            }
            if (
              this.campaign[info.condition].is_canal_globo_enabled &&
              info.icon !== "v-icon icon-icon-canalglobo"
            ) {
              return false;
            }
            if (
              !this.campaign[info.condition].is_canal_globo_enabled &&
              info.icon === "web"
            ) {
              return true;
            }
          }
          break;

        case "whatsapp_configuration":
        case "twitter_configuration":
        case "instagram_configuration":
        case "youtube_configuration":
        case "uploader_configuration":
          if (this.campaign[info.condition].is_enabled) return true;
          break;
        default:
          return false;
      }
    },
    collaborationDateTime(timestamp) {
      const d = new Date(timestamp * 1000);
      const date = d.toLocaleDateString("pt-br");
      const time = d.toLocaleTimeString("pt-br", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      });
      return { date, time, timestamp };
    },
    favorite() {
      this.AUTH_PREFERENCES_HANDLER({
        campaigns: this.campaign.id
      });
    },
    edit() {
      this.$router.push(`/campaign_editor/${this.campaign.id}`);
    },
    remove(id) {
      this.$store.dispatch("LC_CAMPAIGNS_REMOVE", id);
    },
    goTo(id) {
      this.$router.push(`/campaign_details/${id}`);
    },
    openUrlHandler(url) {
      window.open(url, "_blank");
      Analytics.openUrlForCampaign(url, this.campaign.id);
    },
    copyUrlHandler(url) {
      // Create a "hidden" input
      var aux = document.createElement("input");
      aux.setAttribute("value", url);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      Analytics.copiedUrlForCampaign(url, this.campaign.id);
    },

    async downloadUrlQrCodeHandler(url, name) {
      try {
        await downloadQrCode(url, name);
        Analytics.generateUrlQrCodeForCampaign(url, this.campaign.id);
      } catch {
        this.$store.dispatch("UI_SNACKBAR_SHOW", {
          message:
            "Não foi possível gerar o QR code nesse momento... Por favor tente novamente em alguns minutos."
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/cards.scss";

// Variáveis
$thumb-width: 178px;
$thumb-mobile-width: 148px;

.card {
  &.--campaign {
    break-inside: avoid-column;
    // Thumb de produto
    .card-thumb {
      width: $thumb-width;
      height: 100%;
      background-color: rgba($color-black, 0.88);
    }

    .card-content {
      // HEADER
      .card-header {
        position: relative;
        display: flex;
        flex-direction: row;
        flex: 1;
        max-height: 48px;
        cursor: pointer;
        .card-header-meta {
          display: flex;
          flex-flow: column wrap;
          flex: 1;
          width: 0;
          min-width: 0;
          margin: 0 8px 0 0;

          .card-header-meta__title,
          .card-header-meta__product {
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .card-header-meta__title {
            font-size: 0.88em;
            font-weight: 600;
            color: $color-title;
            text-transform: uppercase;
          }

          .card-header-meta__product {
            margin: -2px 0 0 0;
            font-size: 0.72em;
            font-style: italic;
            font-weight: 600;
            color: $color-primary;
            text-transform: uppercase;
          }
        }
        .card-header-status {
          width: 24px;
          height: 24px;

          .v-icon {
            margin: -2px 0 0 0 !important;
            padding: 0 !important;
          }

          &.active {
            .v-icon {
              color: $color-primary;
            }
          }

          &.inactive {
            .v-icon {
              color: rgba($color-black, 0.4);
            }
          }
        }
      }

      // DESCRIPTION
      .card-description {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 52px;
        margin: 16px 0 0 0;
        font-size: 0.88em;
        font-style: italic;
        color: $color-subtitle;
        line-height: 1.2em;
        text-align: justify;
      }

      // CAPTURE
      .card-capture {
        margin: 16px 0 0 0;

        .card-capture-title {
          font-size: 0.72em;
          font-style: italic;
          color: rgba($color-black, 0.4);
          text-transform: uppercase;
        }

        .card-capture-items {
          margin: 4px 0 0 0;

          .v-btn {
            width: 24px;
            height: 24px;
            margin: 0 4px 0 0;
            background-color: $color-border-divisor;

            .v-icon,
            i {
              font-size: 1em;
              color: rgba($color-black, 0.4);
            }
          }
        }
      }
    }
  }
}
</style>
