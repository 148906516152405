<template>
  <v-container fill-height fill-width grid-list-md text-xs-center>
    <div class="color"></div>
    <div class="texture"></div>
    <v-layout column align-center>
      <div class="brand">
        <v-img src="@/assets/images/marca-colaborativo-negativo.svg" />
      </div>
      <v-flex v-if="!accountsConnectLogin">
        <v-text-field
          v-model="auth.username"
          label="E-mail / usuario"
          placeholder="Informe seu e-mail ou usuário corp"
          dark
          color="primary"
          height="48px"
          background-color="transparent"
          class="textfield"
          hide-details
          autocomplete="off"
        />

        <v-text-field
          v-model="auth.password"
          label="Senha"
          placeholder="Informe sua senha corp"
          dark
          color="primary"
          height="48px"
          background-color="transparent"
          class="textfield"
          hide-details
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'visibility' : 'visibility_off'"
          @click:append="showPassword = !showPassword"
          @keyup.enter="submitForm"
          autocomplete="off"
        />
      </v-flex>
      <v-flex>
        <v-btn
          x-large
          block
          elevation="24"
          @click="submitForm"
          :color="btnColor"
          :loading="isLoading"
          :disabled="!submitEnabled"
          >{{ btnText }}</v-btn
        >
        <v-btn class="link" @click="dialog = true" text large block dark
          >Precisa de ajuda para entrar?</v-btn
        >

        <!-- MODAL de ajuda -->
        <v-dialog v-model="dialog" max-width="480">
          <v-card>
            <v-row>
              <v-icon color="gray">help</v-icon>
            </v-row>
            <v-card-title class="headline">Instruções</v-card-title>
            <v-card-text>
              {{ helpModalText }}
              <br />
              <br />Ligue para <span>3911</span> se estiver na Globo <br />Se
              estiver fora, ligue para
              <span>0800 728 4855</span>
            </v-card-text>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="primary" x-large text @click="dialog = false"
                >Entendi</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { oidc } from "../services/auth";
import { AUTH_TRY_LOGIN, AUTH_TRY_LOGIN_OIDC } from "../store/actions";

export default {
  props: ["isCallback"],
  data() {
    return {
      showPassword: false,
      dialog: false,
      auth: {},
      isLoading: false,
      authFailed: false,
      accountsConnectLogin: process.env.VUE_APP_ACCOUNTSCONNECT_ENABLED === "1"
    };
  },

  computed: {
    submitEnabled() {
      if (this.accountsConnectLogin) {
        return !this.isLoading;
      } else {
        return this.auth.password && this.auth.username && !this.isLoading;
      }
    },

    btnColor() {
      return this.authFailed ? "error" : "primary";
    },
    btnText() {
      return this.authFailed ? "Tentar entrar novamente" : "Entrar";
    },
    helpModalText() {
      return this.accountsConnectLogin
        ? "Para acessar o sistema, clique " +
            "no botão e utilize seu email e senha corporativos. Caso precise " +
            "de ajuda, entre em contato com o Help Desk."
        : "Para acessar o sistema, utilize " +
            "seu usuário (ou email) e senha corporativos. Caso precise " +
            "de ajuda, entre em contato com o Help Desk.";
    }
  },
  async mounted() {
    await this.checkCallback();
  },
  watch: {
    async $route() {
      await this.checkCallback();
    }
  },
  methods: {
    async checkCallback() {
      if (this.accountsConnectLogin && this.isCallback) {
        this.isLoading = true;
        await this.$store.dispatch(AUTH_TRY_LOGIN_OIDC);
        this.isLoading = false;
      }
    },
    async submitForm() {
      if (this.accountsConnectLogin) {
        oidc.redirect();
      } else {
        this.isLoading = true;
        await this.$store.dispatch(AUTH_TRY_LOGIN, this.auth);
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  background-color: $color-gray-darker;

  .color,
  .texture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .color {
    z-index: 2;
    background-image: linear-gradient(
      130deg,
      #23d7ff 0%,
      #2dd2e9 11%,
      #51c39d 50%,
      #166484 100%
    );
    opacity: 0.16;
  }

  .texture {
    z-index: 1;
    background-image: url("../assets/images/background-login.png");
    background-size: cover;
    background-position: center;
  }

  .layout {
    z-index: 2;
    width: 324px;
    max-width: 324px;
    max-height: 456px;
    animation: fadeIn 0.8s ease-in-out;

    .flex {
      width: 100%;
      margin: 0;
      padding: 0;

      .v-text-field {
        margin: 24px 0;
        padding: 0;

        .v-input__slot {
          .v-text-field__slot {
            background-color: transparent !important;
          }
          .v-input__append-inner {
            margin-top: 8px;
          }
        }
      }

      .link {
        margin: 8px 0 0 0;
      }

      .login-instructions {
        background-color: antiquewhite;
        border: red;
        border-width: 3px;
        border-style: dashed;
        .v-icon {
          color: red;
        }
        span {
          font-weight: 800;
        }
        p {
          margin: 8px;
          text-align: justify;
        }
      }
    }

    .brand {
      padding: 0 0 24px 0;
    }

    .footer {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 40px 0 auto 0;

      .footer_item {
        margin: 0 16px;
      }

      &::after {
        position: absolute;
        top: 80px;
        left: 50%;
        margin-left: -20px;
        content: "";
        width: 40px;
        height: 4px;
        background-color: rgba($color-primary, 0.4);
      }
    }
  }
}

// Dialog

.v-dialog {
  .v-card {
    padding: 24px 24px 16px 24px;

    // Icon
    .row {
      justify-content: center;

      i {
        font-size: 4em;
        opacity: 0.4;
      }
    }

    // Title
    .v-card__title {
      position: relative;
      text-align: center;
      justify-content: center;
      font-style: italic;
      color: $color-gray-dark;
      margin: -8px 0 24px 0;

      &::after {
        content: "";
        position: absolute;
        top: 56px;
        left: 50%;
        margin-left: -12px;
        width: 24px;
        height: 2px;
        background-color: $color-gray-light;
      }
    }

    // Text
    .v-card__text {
      font-size: 1em;
      font-weight: 400;
      color: $color-gray-main;
      text-align: center;

      span {
        font-weight: 700;
        color: $color-gray-main;
      }
    }

    .disable-events {
      pointer-events: none;
    }
  }
}
</style>
