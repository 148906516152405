import Analytics from "@/services/analytics";

import {
  UI_CAMPAIGN_ACTION_DIALOG_HANDLE,
  UI_CAMPAIGNS_FILTERS_DRAWER_HANDLE,
  UI_COLLABORATIONS_FILTERS_DRAWER_HANDLE,
  UI_CREATE_COLLABORATION,
  UI_DENSITY_HANDLE,
  UI_DESTINATIONS_DRAWER_HANDLE,
  UI_FILE_FORMATS_DRAWER_HANDLE,
  UI_FILTER_COLLABORATION_SET,
  UI_FORM_DRAWER_HANDLE,
  UI_HANDLE_VIEW_MODE,
  UI_INSTAGRAM_DRAWER_HANDLE,
  UI_METADATA_DRAWER_HANDLE,
  UI_MODAL,
  UI_SHOW_COLLABORATION,
  UI_SHOW_EDIT_COLLABORATION,
  UI_SHOW_FILTER_CAMPAIGN,
  UI_SHOW_VIEW_TABLE,
  UI_SNACKBAR_HIDE,
  UI_SNACKBAR_SHOW,
  UI_TWITTER_DRAWER_HANDLE,
  UI_UPLOADER_DRAWER_HANDLE,
  UI_WHATSAPP_DRAWER_HANDLE,
  UI_YOUTUBE_DRAWER_HANDLE
} from "./actions";
import {
  UI_CAMPAIGN_ACTION_DIALOG_SET,
  UI_CAMPAIGN_DRAWER_ITEM_SET,
  UI_CAMPAIGN_DRAWER_SET,
  UI_CAMPAIGN_DRAWER_TOGGLE,
  UI_CAMPAIGN_FILTER_DRAWER_SET,
  UI_CAMPAIGNS_FILTERS_DRAWER_SET,
  UI_COLLABORATION_CREATION_DRAWER_SET,
  UI_COLLABORATION_CREATION_DRAWER_TOGGLE,
  UI_COLLABORATION_DRAWER_SET,
  UI_COLLABORATION_DRAWER_TOGGLE,
  UI_COLLABORATION_EDIT_DRAWER_SET,
  UI_COLLABORATION_OVERLAYED_SET,
  UI_COLLABORATIONS_FILTERS_DRAWER_SET,
  UI_DENSITY_SET,
  UI_DESTINATIONS_DRAWER_SET,
  UI_DRAWER_SET,
  UI_DRAWER_TAB_SET,
  UI_DRAWER_TOGGLE,
  UI_FILE_FORMATS_DRAWER_SET,
  UI_FILTER_CAMPAIGN_SET,
  UI_FILTER_COLLABORATION_SET_MUTATION,
  UI_FORM_DRAWER_SET,
  UI_INSTAGRAM_DRAWER_SET,
  UI_METADATA_DRAWER_SET,
  UI_SHOW_VIEW_TABLE_SET,
  UI_SNACKBAR_SET,
  UI_TWITTER_DRAWER_SET,
  UI_UPLOADER_DRAWER_SET,
  UI_VIEW_MODE_SET,
  UI_WHATSAPP_DRAWER_SET,
  UI_YOUTUBE_DRAWER_SET
} from "./mutations";

export default {
  state: {
    drawer: false,
    campaignDrawer: false,
    campaignFastFilter: 0,
    campaignDrawerItem: null,
    campaignAdvancedFilterDrawer: false,
    collaborationAdvancedFilterDrawer: false,
    collaborationCreationDrawer: false,
    collaborationDrawer: false,
    collaborationDrawerItem: null,
    currentTab: null,
    currentPlaying: null,
    searchBarText: null,
    collaborationDialog: {
      dialog: "",
      isDialogActive: false,
      collaboration_selected: null
    },
    collaborationViewTable: false, // UI_SHOW_VIEW_TYPE_SET
    collaborationViewMode: "",
    densityLevel: 2,
    isWhatsappDrawerVisible: false,
    isYoutubeDrawerVisible: false,
    isInstagramDrawerVisible: false,
    isTwitterDrawerVisible: false,
    isFormDrawerVisible: false,
    isUploaderDrawerVisible: false,
    isDestinationsDrawerVisible: false,
    isCollaborationsFiltersDrawerVisible: false,
    isCollaborationOverlayed: false,
    isCampaignsFiltersDrawerVisible: false,
    isMetadataDrawerVisible: false,
    isFileFormatsDrawerVisible: false,
    snackbar: {
      show: false,
      message: "",
      bind: {},
      update: false,
      polling: false
    }
  },
  getters: {
    campaignFastFilter: state => state.campaignFastFilter,
    viewTable: state => state.collaborationViewTable,
    viewMode: state => state.collaborationViewMode
  },
  actions: {
    [UI_CAMPAIGN_ACTION_DIALOG_HANDLE]: ({ commit }, payload) => {
      commit(
        UI_CAMPAIGN_ACTION_DIALOG_SET,
        payload.close
          ? {
              dialog: "",
              isDialogActive: false,
              collaboration_selected: null
            }
          : payload
      );
    },
    [UI_DENSITY_HANDLE]: ({ commit }, value) => {
      commit(UI_DENSITY_SET, value);
    },
    [UI_SNACKBAR_SHOW]: ({ commit }, snackbarProps) => {
      commit(UI_SNACKBAR_SET, { show: true, polling: false, ...snackbarProps });
    },
    [UI_SNACKBAR_HIDE]: ({ commit }) => {
      commit(UI_SNACKBAR_SET, { show: false, polling: false });
    },
    [UI_MODAL]: ({ commit }) => {
      commit(UI_SNACKBAR_SET, { show: false, polling: false });
    },
    [UI_SHOW_FILTER_CAMPAIGN]: ({ commit }) => {
      commit(UI_CAMPAIGN_FILTER_DRAWER_SET, true);
    },
    [UI_CREATE_COLLABORATION]: ({ commit }) => {
      commit(UI_COLLABORATION_CREATION_DRAWER_SET, true);
    },
    [UI_SHOW_COLLABORATION]: ({ commit }) => {
      commit(UI_COLLABORATION_DRAWER_SET, true);
    },
    [UI_FILTER_COLLABORATION_SET]: ({ commit }, value) => {
      commit(UI_FILTER_COLLABORATION_SET_MUTATION, value);
    },
    [UI_SHOW_EDIT_COLLABORATION]: ({ commit }) => {
      commit(UI_COLLABORATION_EDIT_DRAWER_SET, true);
    },
    [UI_SHOW_VIEW_TABLE]: ({ commit, state }) => {
      commit(UI_SHOW_VIEW_TABLE_SET, !state.collaborationViewTable);
    },
    [UI_HANDLE_VIEW_MODE]: ({ commit, rootState }, payload) => {
      switch (payload) {
        case "card":
          Analytics.setCollaborationCardViewForCampaign(
            rootState.campaign.campaign.id
          );
          break;

        case "table":
          Analytics.setCollaborationTableViewForCampaign(
            rootState.campaign.campaign.id
          );
          break;

        case "conversation":
          Analytics.setCollaborationConversationViewForCampaign(
            rootState.campaign.campaign.id
          );
          break;

        default:
          Analytics.setCollaborationCardViewForCampaign(
            rootState.campaign.campaign.id
          );
          break;
      }
      commit(UI_VIEW_MODE_SET, payload);
    },
    [UI_WHATSAPP_DRAWER_HANDLE]: ({ commit }, payload) => {
      commit(UI_WHATSAPP_DRAWER_SET, payload);
    },
    [UI_YOUTUBE_DRAWER_HANDLE]: ({ commit }, payload) => {
      commit(UI_YOUTUBE_DRAWER_SET, payload);
    },
    [UI_INSTAGRAM_DRAWER_HANDLE]: ({ commit }, payload) => {
      commit(UI_INSTAGRAM_DRAWER_SET, payload);
    },
    [UI_TWITTER_DRAWER_HANDLE]: ({ commit }, payload) => {
      commit(UI_TWITTER_DRAWER_SET, payload);
    },
    [UI_FORM_DRAWER_HANDLE]: ({ commit }, payload) => {
      commit(UI_FORM_DRAWER_SET, payload);
    },
    [UI_UPLOADER_DRAWER_HANDLE]: ({ commit }, payload) => {
      commit(UI_UPLOADER_DRAWER_SET, payload);
    },
    [UI_DESTINATIONS_DRAWER_HANDLE]: ({ commit }, payload) => {
      commit(UI_DESTINATIONS_DRAWER_SET, payload);
    },
    [UI_FILE_FORMATS_DRAWER_HANDLE]: ({ commit }, payload) => {
      commit(UI_FILE_FORMATS_DRAWER_SET, payload);
    },
    [UI_METADATA_DRAWER_HANDLE]: ({ commit }, payload) => {
      commit(UI_METADATA_DRAWER_SET, payload);
    },
    [UI_COLLABORATIONS_FILTERS_DRAWER_HANDLE]: ({ commit }, payload) => {
      commit(UI_COLLABORATIONS_FILTERS_DRAWER_SET, payload);
    },
    [UI_CAMPAIGNS_FILTERS_DRAWER_HANDLE]: ({ commit }, payload) => {
      commit(UI_CAMPAIGNS_FILTERS_DRAWER_SET, payload);
    }
  },
  mutations: {
    [UI_CAMPAIGN_ACTION_DIALOG_SET]: (state, payload) => {
      state.collaborationDialog = payload;
    },
    [UI_COLLABORATION_OVERLAYED_SET]: (state, value) => {
      state.isCollaborationOverlayed = value;
    },
    [UI_DENSITY_SET]: (state, value) => {
      state.densityLevel = value;
    },
    [UI_DRAWER_TOGGLE]: state => {
      state.drawer = !state.drawer;
    },
    [UI_DRAWER_SET]: (state, value) => {
      state.drawer = value;
    },
    [UI_DRAWER_TAB_SET]: (state, value) => {
      state.currentTab = value;
    },
    [UI_CAMPAIGN_DRAWER_TOGGLE]: state => {
      state.campaignDrawer = !state.campaignDrawer;
    },
    [UI_CAMPAIGN_DRAWER_SET]: (state, value) => {
      state.campaignDrawer = value;
    },
    [UI_CAMPAIGN_FILTER_DRAWER_SET]: (state, value) => {
      state.campaignAdvancedFilterDrawer = value;
    },
    [UI_CAMPAIGN_DRAWER_ITEM_SET]: (state, value) => {
      state.campaignDrawerItem = value;
    },
    [UI_COLLABORATION_CREATION_DRAWER_SET]: (state, value) => {
      state.collaborationCreationDrawer = value;
    },
    [UI_COLLABORATION_CREATION_DRAWER_TOGGLE]: state => {
      state.collaborationCreationDrawer = !state.collaborationCreationDrawer;
    },
    [UI_COLLABORATION_DRAWER_SET]: (state, value) => {
      state.collaborationDrawer = value;
    },
    [UI_COLLABORATION_EDIT_DRAWER_SET]: (state, value) => {
      state.collaborationDrawer = value;
    },
    [UI_COLLABORATION_DRAWER_TOGGLE]: state => {
      state.collaborationDrawer = !state.collaborationDrawer;
    },
    [UI_SNACKBAR_SET]: (state, snackbarProps) => {
      const newSnackbar = {
        show: false,
        message: null,
        bind: {},
        update: false,
        ...snackbarProps
      };
      state.snackbar = newSnackbar;
    },
    [UI_FILTER_CAMPAIGN_SET]: (state, index) => {
      state.campaignFastFilter = index;
    },
    [UI_FILTER_COLLABORATION_SET_MUTATION]: (state, value) => {
      state.collaborationAdvancedFilterDrawer = value;
    },
    [UI_SHOW_VIEW_TABLE_SET]: (state, value) => {
      state.collaborationViewTable = value;
    },
    [UI_VIEW_MODE_SET]: (state, value) => {
      state.collaborationViewMode = value;
    },
    [UI_WHATSAPP_DRAWER_SET]: (state, payload) => {
      state.isWhatsappDrawerVisible = payload;
    },
    [UI_YOUTUBE_DRAWER_SET]: (state, payload) => {
      state.isYoutubeDrawerVisible = payload;
    },
    [UI_INSTAGRAM_DRAWER_SET]: (state, payload) => {
      state.isInstagramDrawerVisible = payload;
    },
    [UI_TWITTER_DRAWER_SET]: (state, payload) => {
      state.isTwitterDrawerVisible = payload;
    },
    [UI_FORM_DRAWER_SET]: (state, payload) => {
      state.isFormDrawerVisible = payload;
    },
    [UI_UPLOADER_DRAWER_SET]: (state, payload) => {
      state.isUploaderDrawerVisible = payload;
    },
    [UI_DESTINATIONS_DRAWER_SET]: (state, payload) => {
      state.isDestinationsDrawerVisible = payload;
    },
    [UI_FILE_FORMATS_DRAWER_SET]: (state, payload) => {
      state.isFileFormatsDrawerVisible = payload;
    },
    [UI_METADATA_DRAWER_SET]: (state, payload) => {
      state.isMetadataDrawerVisible = payload;
    },
    [UI_COLLABORATIONS_FILTERS_DRAWER_SET]: (state, payload) => {
      state.isCollaborationsFiltersDrawerVisible = payload;
    },
    [UI_CAMPAIGNS_FILTERS_DRAWER_SET]: (state, payload) => {
      state.isCampaignsFiltersDrawerVisible = payload;
    }
  }
};
