<template>
  <v-form ref="file-formats-form">
    <BaseCard>
      <CardHeader :title="headerTitle" :subtitle="headerSubtitle">
        <template v-slot:right>
          <ButtonLink
            @click-handler="supportedFilesButton.clickHandler"
            :icon="supportedFilesButton.icon"
            :label="supportedFilesButton.label"
          ></ButtonLink>
        </template>
      </CardHeader>

      <div class="box">
        <div class="box-body">
          <div class="column">
            <div class="input-item">
              <EnablerBox label="Fotos" icon="mdi-image">
                <template slot="enabler">
                  <v-switch
                    inset
                    :input-value="formData.image_wanted"
                    @change="updateItem($event, 'image_wanted')"
                  ></v-switch>
                </template>
              </EnablerBox>
            </div>

            <div class="input-item">
              <EnablerBox label="Vídeos" icon="mdi-movie">
                <template slot="enabler">
                  <v-switch
                    inset
                    :input-value="formData.video_wanted"
                    @change="updateItem($event, 'video_wanted')"
                  ></v-switch>
                </template>
              </EnablerBox>
            </div>

            <div class="input-item">
              <EnablerBox label="Áudios" icon="mdi-music-circle">
                <template slot="enabler">
                  <v-switch
                    inset
                    :input-value="formData.audio_wanted"
                    @change="updateItem($event, 'audio_wanted')"
                  ></v-switch>
                </template>
              </EnablerBox>
            </div>

            <div class="input-item">
              <EnablerBox label="Texto" icon="mdi-card-text">
                <template slot="enabler">
                  <v-switch
                    inset
                    :input-value="formData.text_wanted"
                    @change="updateItem($event, 'text_wanted')"
                  ></v-switch>
                </template>
              </EnablerBox>
            </div>

            <div class="input-item">
              <EnablerBox label="Binários" icon="mdi-apps">
                <template slot="enabler">
                  <v-switch
                    inset
                    :input-value="formData.application_wanted"
                    @change="updateItem($event, 'application_wanted')"
                  ></v-switch>
                </template>
              </EnablerBox>
            </div>
          </div>
        </div>

        <div class="divisor"></div>
        <div class="title--small">arquivos por colaboração</div>
        <div class="divisor"></div>

        <div class="box-body">
          <div class="column">
            <div class="input-item">
              <v-text-field
                color="graydark"
                label="Quantidade mínima"
                placeholder=""
                outlined
                type="number"
                prepend-inner-icon="subscriptions"
                append-icon="mdi-unfold-more-horizontal"
                :value="formData.filecount_min"
                :rules="[rules.minValue]"
                min="0"
                :max="formData.filecount_max"
                @change="updateItem(parseInt($event), 'filecount_min')"
              />
            </div>
            <div class="input-item">
              <v-text-field
                color="graydark"
                label="Quantidade máxima"
                placeholder=""
                outlined
                type="number"
                prepend-inner-icon="subscriptions"
                append-icon="mdi-unfold-more-horizontal"
                :rules="[rules.maxValue]"
                :value="formData.filecount_max"
                :min="formData.filecount_min"
                @blur="setMaxFileCount"
                @change="updateItem(parseInt($event), 'filecount_max')"
              />
            </div>
            <div class="input-item">
              <v-text-field
                color="graydark"
                label="Tamanho (MB)"
                placeholder=""
                outlined
                type="number"
                prepend-inner-icon="photo_size_select_large"
                append-icon="mdi-unfold-more-horizontal"
                :value="formData.filesize_max / 1024 / 1024"
                :rules="fileSizeRules"
                min="1"
                max="100000"
                @change="
                  updateItem(Number($event) * 1024 * 1024, 'filesize_max')
                "
              />
            </div>
          </div>
        </div>
      </div>
    </BaseCard>
  </v-form>
</template>

<script>
import { mapState } from "vuex";

import BaseCard from "@/components/Commons/BaseCard";
import ButtonLink from "@/components/Commons/Buttons/ButtonLink";
import CardHeader from "@/components/Commons/CardHeader";
import EnablerBox from "@/components/Commons/EnablerBox";
import {
  UI_FILE_FORMATS_DRAWER_HANDLE,
  UI_SNACKBAR_SHOW
} from "@/store/actions";
import { isPositiveInteger } from "@/util";
import { updateCampaignItem } from "@/util/campaign";

export default {
  components: {
    ButtonLink,
    BaseCard,
    CardHeader,
    EnablerBox
  },
  data() {
    return {
      // drawer: false,
      supportedFilesButton: {
        label: "formatos suportados",
        icon: "trending_flat",
        clickHandler: this.toggleDrawer
      },
      headerTitle: "formato de arquivos",
      headerSubtitle:
        "escolha os tipos de arquivos que deseja receber e configures os limites.",
      rules: {
        minValue: value =>
          value <= this.formData.filecount_max ||
          "O valor deve ser menor que o máximo",
        maxValue: value =>
          value >= this.formData.filecount_min ||
          "O valor deve ser maior que o mínimo",
        lte: rhs => value =>
          value <= rhs || `O valor deve ser menor ou igual a ${rhs}.`
      }
    };
  },
  watch: {
    filecountDisabled(val) {
      if (val) {
        this.updateItem(0, "filecount_min");
        this.updateItem(0, "filecount_max");
      } else {
        this.updateItem(5, "filecount_max");
      }
    },
    filecountMin() {
      this.reValidateRules();
    },
    filecountMax() {
      this.reValidateRules();
    },
    tags() {
      this.reValidateRules();
      this.setMaxFileCount();
    }
  },
  computed: {
    ...mapState({
      formData: state => state.listCampaign.createOrEditCampaignParams.formData
    }),
    filecountDisabled() {
      return !(
        this.formData.text_wanted ||
        this.formData.image_wanted ||
        this.formData.video_wanted ||
        this.formData.audio_wanted ||
        this.formData.application_wanted
      );
    },
    fileSizeRules() {
      return [
        fileSize =>
          (!!fileSize && isPositiveInteger(fileSize)) ||
          "Este campo é obrigatório e deve ser um número entre 1 e 100000!",
        fileSize => parseFloat(fileSize) >= 1 || "O tamanho mínimo é 1 MB!",
        fileSize =>
          parseFloat(fileSize) <= 100000 || "O tamanho máximo é 100 GB!"
      ];
    },
    filecountMin() {
      return this.formData.filecount_min;
    },
    filecountMax() {
      return this.formData.filecount_max;
    },
    tags() {
      return this.formData.tags;
    }
  },
  methods: {
    toggleDrawer() {
      this.$store.dispatch(UI_FILE_FORMATS_DRAWER_HANDLE, true);
    },
    updateItem(value, item) {
      updateCampaignItem(value, item);
    },
    reValidateRules() {
      this.$nextTick(() => {
        this.$refs["file-formats-form"].validate();
      });
    },
    setMaxFileCount() {
      if (this.formData.tags.includes("kyosk")) {
        if (this.formData.filecount_max > 200) {
          this.$store.dispatch(UI_SNACKBAR_SHOW, {
            message: "Esta campanha aceita no máximo 200 arquivos."
          });
          this.updateItem(200, "filecount_max");
        }
      } else if (this.formData.filecount_max > 50) {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "Esta campanha aceita no máximo 50 arquivos."
        });
        return this.updateItem(50, "filecount_max");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";
@import "@/scss/form-inputs.scss";

.box {
  font-family: $secondary-fontface;
  background-color: #fff;

  &.full {
    width: 100%;
  }

  .box-title {
    margin: 0 0 8px 0;
    font-size: 0.88em;
    font-weight: 600;
    color: $color-black-80;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  .box-subtitle {
    margin: 0 0 16px 0;
    font-size: 0.8em;
    font-weight: 400;
    color: $color-black-64;
    text-transform: capitalize;
    letter-spacing: 0.1em;
  }

  .divisor {
    flex: 1;
    height: 1px;
    margin: 16px 0;
    // background-color: $color-black-08;
  }

  .box-description {
    font-size: 1em;
    font-weight: 400;
    color: $color-black-80;
  }
}
</style>
