<template>
  <div class="users">
    <div class="item margin">
      <v-form v-model="candidateIsOK">
        <v-text-field
          :loading="loading"
          :disabled="loading"
          v-model="candidate"
          :rules="[onlyUniqueHashtags, maxFollowsReachedRule]"
          @keydown.enter.prevent.stop="addNewHashtag"
          label="Nome da hashtag a ser monitorada"
          placeholder="Digite o exato nome da hashtag do instagram."
        ></v-text-field>
      </v-form>
      <v-btn
        large
        light
        color="primary"
        :loading="loading"
        :disabled="!candidate || !candidateIsOK"
        @click="addNewHashtag"
      >
        <v-icon left>add_task</v-icon>
        Adicionar</v-btn
      >
    </div>
    <v-divider vertical></v-divider>
    <div class="item margin">
      <div
        class="hashtagsActived"
        v-show="hashtagsCurrentlyActivedOnFacebookContext"
      >
        <p class="title mb-0">HASHTAGS ATUALMENTE ATIVAS</p>
        <p class="title">{{ hashtagsCurrentlyActivedOnFacebookContext }}</p>
        <p class="subtitle">
          Se o limite de hashtags for atingido, será necessário esperar 7 dias
          para o slot ser liberado.
        </p>
      </div>
      <v-combobox
        v-model="localHashtags"
        prepend-inner-icon="people"
        :rules="[moreThanZeroItemsRule]"
        filled
        :items="instagramHashtags"
        chips
        readonly
        deletable-chips
        multiple
        item-text="name"
        placeholder="Digite o nome das hashtags no campo acima para adicionar novas hashtags."
        label="Hashtags selecionadas"
        color="primary"
        class="textfield mt-6"
        append-icon
      >
        <template v-slot:selection="{ attrs, item, selected }">
          <div
            :title="
              item.kill
                ? 'Essa hashtag está marcada para deleção. As hashtags ficam no mínimo 7 dias a partir de sua criação atreladas a uma conta Facebook.'
                : false
            "
          >
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              :disabled="item.kill"
              @click:close="removeHahstag(item)"
              color="primary"
            >
              <strong>{{ item.name }}</strong
              >&nbsp;
            </v-chip>
          </div>
        </template>
      </v-combobox>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    instagramHashtags: {
      type: Array
    },
    totalInstagramHashtagsFollows: {
      type: Number
    },
    maximumInstagramFollows: {
      type: Number
    },
    hashtagsCurrentlyActivedOnFacebookContext: {
      type: String
    }
  },
  mounted() {
    this.localHashtags = [...this.instagramHashtags];
  },
  data: () => ({
    candidateIsOK: false,
    localHashtags: [],
    loading: false,
    candidate: ""
  }),
  computed: {
    hashtagLimitReached() {
      return this.totalInstagramHashtagsFollows == this.maximumInstagramFollows;
    },
    maxFollowsReachedRule() {
      return (
        !this.hashtagLimitReached ||
        `Número máximo de ${this.maximumInstagramFollows} hashtags foi atingido. `
      );
    },
    moreThanZeroItemsRule() {
      return this.totalInstagramHashtagsFollows
        ? true
        : "Selecione pelo menos um usuário  válido ou hashtag.";
    }
  },
  methods: {
    addNewHashtag() {
      if (!this.candidate || !this.candidateIsOK) {
        return;
      }
      const existingDisabled = this.localHashtags.filter(
        el => el.name === this.formatHashtag(this.candidate) && el.kill
      );
      if (existingDisabled.length) {
        existingDisabled[0].kill = false;
      } else {
        this.localHashtags.push({
          name: this.formatHashtag(this.candidate),
          id: null,
          kill: false
        });
      }
      this.candidate = "";
      this.$emit("watchedHashtagsChanged", this.localHashtags);
    },
    removeHahstag(item) {
      let newHashtags = [];
      for (let i = 0; i < this.localHashtags.length; i++) {
        let el = this.localHashtags[i];
        if (JSON.stringify(el) === JSON.stringify(item)) {
          el.kill = true;
          if (el.id) {
            newHashtags.push(el);
          }
        } else {
          newHashtags.push(el);
        }
      }
      this.localHashtags = newHashtags;
      this.$emit("watchedHashtagsChanged", this.localHashtags);
    },
    onlyUniqueHashtags() {
      const hashtag_already_exists = this.instagramHashtags.some(
        item =>
          item.name === this.formatHashtag(this.candidate) &&
          item.kill === false
      );
      return !hashtag_already_exists || "Hashtag já cadastrado.";
    },

    formatHashtag(hashtag) {
      const temp = hashtag.replace("#", "");
      return `#${temp}`;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/variables.scss";

.users {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  height: 100%;
  .item {
    flex-grow: 1;
    flex-direction: column;
  }
}
.hashtagsActived {
  .title,
  .subtitle {
    color: rgba($color-black, 0.64);
    font-size: 0.88em !important;
    font-style: italic;
    font-weight: 400;
  }
  .title {
    color: rgba($color-black, 0.64);
  }
  .subtitle {
    color: rgba($color-black, 0.44);
  }
}
</style>
