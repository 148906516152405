<template>
  <v-autocomplete
    @change="$emit('update', $event)"
    :items="items"
    :disabled="disabled"
    :value="selectedItems"
    :label="label"
    :placeholder="placeholder"
    :prepend-inner-icon="prependIcon"
    :item-text="itemText"
    item-value="id"
    color="graydark"
    outlined
    hide-details
    clearable
    disable-lookup
    chips
    multiple
  >
    <template v-slot:selection="data">
      <v-chip
        class="user"
        label
        ripple
        v-bind="data.attrs"
        :input-value="data.selected"
      >
        <div class="user-content">
          <div class="user-avatar d-flex align-center justify-center">
            {{ getInitials(data.item.name) }}
          </div>
          <div class="user-metadata">
            <template>
              <div class="user-metadata--name">
                {{ data.item.name }}
              </div>
              <div class="user-metadata--email">
                {{ data.item.email }}
              </div>
            </template>
          </div>
          <div class="user-action">
            <v-btn
              @click="removeItem(data.item)"
              small
              color="black"
              title="excluir"
              icon
            >
              <v-icon color="black">mdi-close-circle</v-icon>
            </v-btn>
          </div>
        </div>
      </v-chip>
    </template>

    <template v-slot:item="data">
      <template>
        <v-list-item-avatar color="primary">
          {{ getInitials(data.item.name) }}
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.name"></v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    label: String,
    items: Array,
    selectedItems: Array,
    placeholder: String,
    prependIcon: String,
    disabled: {
      type: Boolean,
      default: false
    },
    itemText: {
      type: Function
    }
  },
  methods: {
    removeItem(item) {
      this.$emit("remove-item", item);
    },
    getInitials(name) {
      const firstChar = name.charAt(0).toUpperCase();
      const secondChar = name
        .split(" ")
        [name.split(" ").length - 1].charAt(0)
        .toUpperCase();
      return name.length >= 2 ? `${firstChar}${secondChar}` : firstChar;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";
@import "@/scss/form-inputs.scss";

.v-chip.user {
  height: 48px !important;
  background-color: $color-primary-chip !important;
}

.v-list-item__avatar {
  color: $color-background;
  justify-content: center;
}
.user-avatar {
  text-transform: uppercase;

  box-shadow: none !important;
}

::v-deep .mdi-close::before {
  padding-right: 12px;
  font-size: 16px;
}

::v-deep .v-select__slot .v-input__append-inner:nth-child(3) {
  border: 0 !important;
}
</style>
