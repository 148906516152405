<template>
  <div
    class="card collaboration-card"
    ref="card"
    :class="{
      opacityHover: opacityHover,
      ...cardTypeClass,
      active: isCardActive
    }"
  >
    <div class="thumb card_media" v-if="hasMedia">
      <MediaCarousel
        :medias="collaboration.medias"
        :campaignId="collaboration.campaign_id"
      />
    </div>
    <div class="card_header">
      <div class="boxName">
        <div class="name" v-if="showCampaign">
          {{ campaignName }}
        </div>
      </div>
      <div class="mediaType">
        <div
          class="social rounded-circle"
          :class="collaborationInputBadgeStyle"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">
                {{ collaborationInputBadgeIcon }}
              </v-icon>
            </template>
            <span>{{ collaborationTypeDescription }}</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <div class="card_content">
      <div class="content-user" @click="filterCollabsByCollaborator">
        <div class="avatar" :style="hasAvatar"></div>
        <div class="user">
          <div class="user-top">
            <div class="user-name" v-html="collaboratorName" />
            <v-icon
              class="ml-1"
              :class="userVerificationBadgeClasses"
              v-show="collaboration.collaborator.verified"
              >mdi-check-decagram</v-icon
            >
          </div>
          <div class="user-handle">{{ collaboratorHandle.value }}</div>
        </div>
      </div>
      <div class="content-text" @click="showDetails">
        <v-tooltip top color="secondary">
          <template v-slot:activator="{ on }">
            <div
              class="message"
              v-bind:style="messageLineStyling"
              @mouseenter="on.mouseenter"
              @mouseleave="on.mouseleave"
            >
              <span
                class="collaboration-title"
                v-if="collaboration.body.title"
                >{{ collaboration.body.title + " " }}</span
              >
              <span v-html="messageBody" />
            </div>
          </template>
          <span v-html="messageBody" />
        </v-tooltip>
      </div>
    </div>
    <div class="card_footer">
      <div class="createdAt">
        <div class="date">{{ collaborationDate }}</div>
        <div class="time">{{ collaborationTime }}</div>
        <div class="comments" v-if="hasComment">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">comment</v-icon>
            </template>
            <span>Comentários</span>
          </v-tooltip>
        </div>
      </div>
      <div class="actions">
        <div class="select">
          <v-simple-checkbox
            v-model="isSelected"
            :disabled="actionsDisabled"
            @input="handleToggle"
          />
        </div>
        <CollaborationActions
          :collaboration="collaboration_"
          :currentCollaborator="collaboration_.collaborator"
          :index="index"
          ref="actions"
          @handle-active-item="isCardActive = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import querystring from "querystring";
import twemoji from "twemoji";
import { Ripple } from "vuetify/lib/directives";
import { mapGetters, mapState } from "vuex";

import CollaborationActions from "@/components/collaborations/CollaborationActions";
import MediaCarousel from "@/components/MediaCarousel/";
import collaborationMixin from "@/mixins/collaboration";
import collaborationsActions from "@/mixins/collaborations_actions.js";
import Analytics from "@/services/analytics";
import {
  CAMPAIGN_COLLABORATION_SELECT,
  UI_SNACKBAR_SHOW
} from "@/store/actions";
import { showCollaboration } from "@/util/collaborations";

const EMOJI_TYPES = ["twitter", "whatsapp"];

export default {
  name: "collaboration_card_item",
  components: { CollaborationActions, MediaCarousel },
  mixins: [collaborationMixin, collaborationsActions],
  directives: {
    Ripple
  },
  props: {
    index: {
      required: true
    },
    collaboration: {
      required: true
    }
  },
  data() {
    return {
      collaboration_: this.collaboration,
      showCampaign: true,
      opacityHover: false,
      isCardActive: false
    };
  },
  computed: {
    ...mapState({
      filters: state => state.campaign.tabs[0].filters
    }),
    ...mapGetters(["campaignNameById"]),
    actionsDisabled() {
      return this.filters?.campaign_ids.length > 1;
    },
    cardTypeClass() {
      return {
        "card-type-media": this.hasMedia,
        "card-type-text": !this.hasMedia
      };
    },
    hasAvatar() {
      if (this.collaborationProfileImage) {
        return { backgroundImage: `url(${this.collaborationProfileImage})` };
      }
      return false;
    },
    campaignName() {
      return (
        this.$store.getters.campaignNameById(this.collaboration.campaign_id) ||
        ""
      );
    },
    messageBody() {
      return EMOJI_TYPES.includes(this.collaboration.collaborator.type)
        ? twemoji.parse(this.collaboration.body.message)
        : this.collaboration.body.message;
    },
    collaboratorName() {
      return EMOJI_TYPES.includes(this.collaboration.collaborator.type)
        ? twemoji.parse(this.collaborator.name)
        : this.collaborator.name;
    },
    userVerificationBadgeClasses() {
      const classes = ["user-verified"];
      if (this.collaboration.collaborator.type === "twitter") {
        classes.push("user-verified--twitter");
      }
      return classes;
    }
  },
  watch: {
    collaboration() {
      this.collaboration_ = this.collaboration;
    }
  },
  methods: {
    showDetails() {
      showCollaboration(this.index, this.collaboration);
    },
    handleToggle(value) {
      this.$store.dispatch(CAMPAIGN_COLLABORATION_SELECT, {
        toggleValue: value,
        collaboration: this.collaboration,
        index: this.index
      });
    },
    filterCollabsByCollaborator() {
      const { collaborator, campaign_id } = this.collaboration;
      const { type } = collaborator;
      Analytics.filterCollaborator(campaign_id);
      try {
        const { referenceQuery, referenceField } = this.bestFilterByInputType(
          type
        );
        const referenceValue = collaborator[referenceField];
        const isValidReference = type && referenceValue;

        if (isValidReference) {
          const query = {
            [referenceQuery]: referenceValue,
            order_by: "created_at",
            size: 100
          };
          const qs = querystring.stringify(query);
          const url = `/campaign_details/${campaign_id}?${qs}`;
          window.open(url, "_blank");
        }
      } catch (err) {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message:
            err.message ||
            "Oops... Não conseguimos processar essa requisição..."
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/theme.scss";
@import "@/scss/cards.scss";

::v-deep .v-window__container {
  width: 100% !important;
}

.card-type-media {
  grid-row-end: span 2;
}
</style>
