import Vue from "vue";
import Vuex from "vuex";

import AuthStore from "./store_auth";
import CampaignsStore from "./store_campaigns";
import collaborations from "./store_collaborations";
import collaborators from "./store_collaborators";
import Instagram from "./store_instagram";
import SettingsStore from "./store_settings";
// import CurrentCampaignStore from "./store_currentCampaign";
// import Filters from "./store_filters";
import UIStore from "./store_ui";

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    ui: UIStore,
    auth: AuthStore,
    listCampaign: CampaignsStore,
    campaign: collaborations,
    settings: SettingsStore,
    instagram: Instagram,
    collaborators: collaborators
  }
});

if (process.env.NODE_ENV === "development") {
  store.subscribeAction(action => {
    // eslint-disable-next-line no-console
    console.log(action.type, action.payload);
  });
}

if (window.Cypress) {
  window.store = store;
}

export default store;
