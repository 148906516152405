<template>
  <BaseCard>
    <CardHeader
      title="moderação"
      subtitle="Ative ou desative a moderação e adicione moderadores específicos"
    >
      <template v-slot:right>
        <ButtonLink
          @click-handler="difference = !difference"
          icon="info_outline"
          label="diferenças"
        ></ButtonLink>
      </template>
    </CardHeader>

    <div class="box">
      <div class="box-body">
        <div class="column flex">
          <!-- :class="{ disabled: this.deactivate }" -->
          <div class="input-item half">
            <EnablerBox
              :label="moderatorEnablerLabel"
              :icon="moderatorEnablerIcon"
            >
              <template slot="enabler">
                <v-switch
                  inset
                  @change="toggleModeratorEnabler"
                  :input-value="isModeratorsEnabled"
                ></v-switch>
              </template>
            </EnablerBox>
          </div>

          <div class="input-item half">
            <v-autocomplete
              :disabled="!isModeratorsEnabled"
              :value="moderationLevels"
              @change="handleLevelChange"
              :items="[1, 2]"
              color="graydark"
              label="Níveis"
              outlined
              hide-details
              disable-lookup
              prepend-inner-icon="mdi-shield-check"
              append-icon="mdi-unfold-more-horizontal"
            ></v-autocomplete>
          </div>

          <div class="input-item full">
            <div class="select-divisor"></div>
            <div class="select-placeholder">
              Clique para adicionar moderadores que não fazem parte do grupo de
              moderação
            </div>

            <AutocompleteUser
              @update="updateItem($event, 'moderators')"
              @remove-item="removeModerator"
              label="Moderadores"
              :itemText="
                item => `id=${item.id} name=${item.name} email=${item.email}`
              "
              :items="users"
              :selectedItems.sync="moderators"
              :disabled="!isModeratorsEnabled"
              placeholder="Adicione moderadores..."
              prependIcon="mdi-shield-account-variant"
            ></AutocompleteUser>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="difference" overlay-opacity="0.88" max-width="600">
      <div class="modal no-title">
        <div class="modal-content left">
          <div class="modal-content__text">
            <div class="title--medium">
              Sem
              <span>Moderação</span>
            </div>
            Desative a moderação em sua campanha para criar fluxos de envio
            automático, sem a necessidade de usuários aprovadores em cada etapa
            de validação do conteúdo recebido.
          </div>
        </div>

        <div class="modal-content center">
          <img src="@/assets/images/shields.svg" alt="shields" border="0" />
        </div>

        <div class="modal-content right">
          <div class="modal-content__text">
            <div class="title--medium">
              Com
              <span>Moderação</span>
            </div>
            Ative a moderação em sua campanha para criar níveis de aprovação do
            conteúdo que é capturado pelo Colaborativo. A moderação é realizada
            pelo criador da campanha, por membros do grupo da campanha e por
            usuários configurados no campo abaixo.
          </div>
          <div class="input-item full">
            <v-menu v-model="menu" bottom right transition="slide-y-transition">
              <div class="dropdown">
                <div class="dropdown-header">
                  Níveis de Moderação
                </div>

                <div class="dropdown-content">
                  <v-list>
                    <v-list-item ripple>
                      <v-checkbox></v-checkbox>
                      Nível 1
                    </v-list-item>

                    <v-list-item ripple>
                      <v-checkbox></v-checkbox>
                      Nível 2
                    </v-list-item>
                  </v-list>
                </div>

                <div class="dropdown-footer">
                  <v-list>
                    <v-list-item ripple>
                      <v-icon left>mdi-close-circle</v-icon>
                      Remover
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </v-menu>
          </div>
        </div>

        <div class="modal-actions">
          <ButtonSecondary
            @click-handler="difference = false"
            label="Voltar"
            icon="mdi-arrow-left"
          ></ButtonSecondary>
        </div>
      </div>
    </v-dialog>
  </BaseCard>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import AutocompleteUser from "@/components/Commons/AutocompleteUser";
import BaseCard from "@/components/Commons/BaseCard";
import ButtonLink from "@/components/Commons/Buttons/ButtonLink";
import ButtonSecondary from "@/components/Commons/Buttons/ButtonSecondary";
import CardHeader from "@/components/Commons/CardHeader";
import EnablerBox from "@/components/Commons/EnablerBox";
import { updateCampaignItem } from "@/util/campaign";

export default {
  components: {
    ButtonLink,
    ButtonSecondary,
    BaseCard,
    CardHeader,
    EnablerBox,
    AutocompleteUser
  },
  data() {
    return {
      difference: false,
      menu: false,
      isModeratorsEnabled: false
    };
  },
  computed: {
    ...mapState({
      formData: state => state.listCampaign.createOrEditCampaignParams.formData
    }),
    ...mapGetters({
      users: "createOrUpdateSortedUser"
    }),
    moderators() {
      return this.formData.moderators;
    },
    moderationLevels() {
      return this.formData.required_approvals;
    },
    moderatorEnablerLabel() {
      return this.isModeratorsEnabled ? "Ativado" : "Desativado";
    },
    moderatorEnablerIcon() {
      return this.isModeratorsEnabled ? "mdi-shield-plus" : "mdi-shield-off";
    }
  },
  watch: {
    isModeratorsEnabled(value) {
      if (!value) {
        this.resetModerationData();
      }
    },
    moderationLevels(value) {
      if (value > 0) {
        this.isModeratorsEnabled = true;
      }
    }
  },
  methods: {
    updateItem(value, item) {
      updateCampaignItem(value, item);
    },
    handleLevelChange(value) {
      this.updateItem(value, "required_approvals");
    },
    toggleModeratorEnabler(value) {
      this.isModeratorsEnabled = value;
    },
    resetModerationData() {
      this.updateItem([], "moderators");
      this.updateItem(0, "required_approvals");
    },
    removeModerator(item) {
      this.updateItem(
        this.moderators.filter(moderatorID => moderatorID !== item.id),
        "moderators"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";
@import "@/scss/modal.scss";
@import "@/scss/form-inputs.scss";

.box {
  font-family: $secondary-fontface;
  background-color: #fff;

  &.full {
    width: 100%;
  }

  @include for-size(tablet-landscape-up) {
    .input-item.half {
      flex: 1 !important;
    }
  }

  .box-title {
    margin: 0 0 8px 0;
    font-size: 0.88em;
    font-weight: 600;
    color: $color-black-80;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  .box-subtitle {
    margin: 0 0 16px 0;
    font-size: 0.8em;
    font-weight: 400;
    color: $color-black-64;
    text-transform: capitalize;
    letter-spacing: 0.1em;
  }

  .divisor {
    flex: 1;
    height: 1px;
    margin: 16px 0;
    // background-color: $color-black-08;
  }

  .box-description {
    font-size: 1em;
    font-weight: 400;
    color: $color-black-80;
  }

  .box-body {
    display: flex;
  }

  ::v-deep input::-webkit-outer-spin-button,
  ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
