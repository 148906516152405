<template>
  <div
    :class="
      `enabler-box ${(appendIcon && 'pr-0', hasClick && 'button-appearence')}`
    "
    @click="clickHandler"
  >
    <div class="label-box">
      <v-icon v-if="icon" class="mr-3">{{ icon }}</v-icon>
      {{ label }}
    </div>
    <div class="icons-box">
      <slot name="enabler"></slot>
      <v-icon v-if="appendIcon" class="mr-3 ml-3 pl-3 append">{{
        appendIcon
      }}</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "EnablerBox",
  props: {
    label: String,
    hasClick: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    appendIcon: {
      type: String,
      default: null
    }
  },
  methods: {
    clickHandler() {
      this.$emit("enabler-was-clicked");
    }
  }
};
</script>

<style scoped lang="scss">
.enabler-box {
  color: #000000a3;
  font-size: 11.2px;

  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #ebebeb;
  display: flex;
  height: 56px;
  padding: 0 12px;
  justify-content: space-between;
  transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

  &:hover {
    border-color: #bdbdbd;
  }
  &.button-appearence:hover {
    cursor: pointer;
  }

  .v-icon::before,
  .v-icon.v-icon {
    font-size: 16px;
  }
  .icons-box {
    align-items: center;
    display: flex;

    .append {
      border-left: 1.5px solid rgba(0, 0, 0, 0.08);
      height: 24px;
      opacity: 0.5;
    }
  }
}
</style>
