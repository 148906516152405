<template>
  <Drawer
    @toggle-drawer="toggleDrawerInputSourceTwitter"
    @reset-enabler="resetEnabler"
    @hard-update="handleHardUpdate"
    @reset-changes="reset"
    :drawer="isTwitterDrawerVisible"
    title="configurar twitter"
    :isFullHeight="true"
    drawerName="twitter"
    formRef="twitter-drawer-form"
    :areAllFieldsEmpty="areAllFieldsEmpty"
  >
    <template v-slot:drawer-content>
      <div class="modal-content__item">
        <CardHeader
          title="FILTRAR CONTEÚDO"
          subtitle="Cadastre termos, hashtags ou @ de usuários do Twitter para receber conteúdo"
        ></CardHeader>
      </div>
      <v-form ref="twitter-drawer-form">
        <div class="modal-content__item">
          <div class="input-item full">
            <v-combobox
              @change="normalizeText"
              v-model="localTwitterTerms"
              deletable-chips
              multiple
              clearable
              outlined
              label='Adicione ao menos um item. Exemplos: "termo", "#hashtag" ou "@usuario"'
              color="graydark"
              prepend-inner-icon="fab fa-twitter"
              append-icon
            >
              <template v-slot:selection="{ attrs, item, selected, parent }">
                <v-chip
                  label
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click:close="parent.selectItem(item)"
                  >{{ item }}
                </v-chip>
              </template>
            </v-combobox>
          </div>
        </div>
        <div class="modal-content__item">
          <div class="input-item">
            <EnablerBox label="Receber retweets" icon="mdi-repeat">
              <template slot="enabler">
                <v-switch v-model="localRetweetEnabler" inset></v-switch>
              </template>
            </EnablerBox>
          </div>
        </div>
        <!-- <div class="modal-content__item mt-6">
        <CardHeader
          title="Restrição de Envio"
          subtitle="Utilize as listas de bloqueio ou exclusividade para restringir o envio de conteúdo"
        ></CardHeader>
      </div>
      <div class="modal-content__item mt-4">
        <div class="title--small">
          BLOQUEIO (Sua campanha não receberá conteúdo dos perfis adicionados)
        </div>
        <div class="input-item" :class="{ disabled: exclusiveProfileEnabled }">
          <v-combobox
            @change="updateTwitterBlacklist($event)"
            color="graydark"
            label="Clique para adicionar ou importe de uma campanha…"
            :disabled="!blockedProfileEnabled"
            outlined
            hide-details
            multiple
            deletable-chips
            append-icon
            prepend-inner-icon="block"
          >
            <template v-slot:selection="{ attrs, item, selected, parent }">
              <v-chip
                label
                v-bind="attrs"
                :input-value="selected"
                close
                @click:close="parent.selectItem(item)"
                >{{ item }}
              </v-chip>
            </template>
          </v-combobox>
          <v-switch inset dense v-model="blockedProfileEnabled"></v-switch>
        </div>
      </div>
      <div class="modal-content__item">
        <div class="title--small">
          Exclusividade (Sua campanha receberá conteúdo apenas dos perfis
          adicionados)
        </div>
        <div class="input-item" :class="{ disabled: blockedProfileEnabled }">
          <v-combobox
            color="graydark"
            label="Clique para adicionar ou importe de uma campanha…"
            :disabled="!exclusiveProfileEnabled"
            outlined
            hide-details
            multiple
            deletable-chips
            append-icon
            prepend-inner-icon="stars"
          >
            <template v-slot:selection="{ attrs, item, selected, parent }">
              <v-chip
                label
                v-bind="attrs"
                :input-value="selected"
                close
                @click:close="parent.selectItem(item)"
                >{{ item }}
              </v-chip>
            </template>
          </v-combobox>
          <v-switch inset dense v-model="exclusiveProfileEnabled"></v-switch>
        </div>
      </div> -->
      </v-form>
    </template>
  </Drawer>
</template>

<script>
import { mapState } from "vuex";

import CardHeader from "@/components/Commons/CardHeader";
import Drawer from "@/components/Commons/Dialogs/Drawer";
import EnablerBox from "@/components/Commons/EnablerBox";
import { UI_TWITTER_DRAWER_HANDLE } from "@/store/actions";
import { updateCampaignItem, updateCampaignNestedItem } from "@/util/campaign";

export default {
  name: "TwitterDrawer",
  components: {
    CardHeader,
    Drawer,
    EnablerBox
  },
  data() {
    return {
      /* blockedProfileEnabled: false,
      exclusiveProfileEnabled: false, */
      localRetweetEnabler: false,
      localTwitterTerms: []
    };
  },
  computed: {
    ...mapState({
      formData: state => state.listCampaign.createOrEditCampaignParams.formData,
      isTwitterDrawerVisible: state => state.ui.isTwitterDrawerVisible,
      retweetIsEnabled: state =>
        state.listCampaign.createOrEditCampaignParams.formData
          .retweet_is_enabled,
      twitterTerms: state =>
        state.listCampaign.createOrEditCampaignParams.formData.twitter_terms
    }),
    areAllFieldsEmpty() {
      return !this.localTwitterTerms.length && !this.localRetweetEnabler;
    }
    /* twitter_blacklist() {
      return this.formData.twitter_blacklist;
    } */
  },
  watch: {
    retweetIsEnabled(value) {
      this.localRetweetEnabler = value;
    },
    twitterTerms(value) {
      this.localTwitterTerms = value;
    }
  },
  methods: {
    hardUpdate(value, field) {
      updateCampaignItem(value, field);
    },
    handleHardUpdate() {
      this.hardUpdate(this.localRetweetEnabler, "retweet_is_enabled");
      this.hardUpdate(this.localTwitterTerms, "twitter_terms");
    },
    resetEnabler(value) {
      updateCampaignNestedItem(value, "twitter_configuration", "is_enabled");
    },
    reset() {
      this.localRetweetEnabler = this.retweetIsEnabled;
      this.localTwitterTerms = this.twitterTerms;
    },
    toggleDrawerInputSourceTwitter(value) {
      this.$store.dispatch(UI_TWITTER_DRAWER_HANDLE, value ? value : false);
    },

    /* updateTwitterBlacklist($event) {
      if (!this.canUpdateTwitterBlacklist()) {
        return;
      }
      this.twitter_blacklist = $event;
      this.$emit("update:blacklist", this.twitter_blacklist);
    },
    canUpdateTwitterBlacklist() {
      if (this.exclusiveProfileEnabled) {
        return false;
      }
      return true;
    } */
    normalizeText(items) {
      this.localTwitterTerms = [
        ...new Set(items.map(item => item.trim().toLowerCase()))
      ];
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/theme.scss";
@import "@/scss/modal.scss";
@import "@/scss/form-inputs.scss";

::v-deep .v-select__selections {
  margin-top: 0 !important;
  .v-chip {
    background-color: $color-primary-chip !important;
  }
}

::v-deep .mdi-close::before {
  font-size: 16px;
}
</style>
