<template>
  <div class="container">
    <!-- Topo -->
    <div class="topo">
      <div class="title"><span>Lista</span> de Credenciais do Instagram</div>
    </div>

    <div class="main">
      <v-data-table
        :fixed-header="true"
        :headers="headers"
        :items="users"
        class="table-main"
        :mobile-breakpoint="1200"
        sort-by="name"
        :search="search"
        :loading="isLoading"
        loading-text="Carregando credenciais do Instagram..."
      >
        <template v-slot:[`item.description_short`]="{ item }">
          {{ getShortDescription(item) }}
        </template>
        <template v-slot:[`item.group_names`]="{ item }">
          <div class="mt-1">
            <v-chip
              v-for="(curGroup, index) in getGroupNameList(item).slice(
                0,
                maxProductsShown
              )"
              :key="index"
              color="primary"
              class="mr-1"
            >
              {{ curGroup }}
            </v-chip>
            <v-chip
              v-if="item.group_ids.length > maxProductsShown"
              color="primary"
              :title="remainderProductTooltip(item)"
            >
              <strong>{{
                `+ ${item.group_ids.length - maxProductsShown}`
              }}</strong>
            </v-chip>
          </div>
        </template>

        <template v-slot:[`item.followed_accounts`]="{ item }">
          <div class="mt-1">
            <span
              v-for="(account, index) in displayedFollowList(item)"
              :key="index"
              :title="
                account.kill
                  ? 'Essa hashtag está marcada para deleção. As hashtags ficam no mínimo 7 dias a partir de sua criação atreladas a uma conta Facebook.'
                  : account.name
              "
            >
              <v-chip
                color="primary"
                :title="account.name"
                :disabled="account.kill"
                class="mr-1"
              >
                <v-avatar v-if="account.type === 'user'" left>
                  <v-img :src="account.profile_picture_url"> </v-img>
                </v-avatar>
                <strong>{{
                  account.type === "user" ? account.username : account.name
                }}</strong>
              </v-chip>
            </span>
            <v-chip
              color="primary"
              v-if="completeFollowList(item).length > maxChipsShown"
              :title="remainderFollowTooltip(item)"
            >
              <strong>{{ remainderFollowList(item) }}</strong>
            </v-chip>
          </div>
        </template>

        <template v-slot:[`item.status_icon`]="{ item }">
          <v-icon small :color="item.status ? 'green' : 'red'">
            {{ item.status ? "done" : "close" }}
          </v-icon>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
                v-on="on"
                :disabled="!canModifyCredential(item)"
              >
                edit
              </v-icon>
            </template>
            <span>Editar instagram</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-2"
                @click="reauthenticateItem(item)"
                :disabled="!canModifyCredential(item)"
                v-on="on"
              >
                published_with_changes
              </v-icon>
            </template>
            <span>Reautenticar instagram</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-2"
                @click="deleteItem(item)"
                v-on="on"
                :disabled="!canModifyCredential(item)"
              >
                delete
              </v-icon>
            </template>
            <span>Remover instagram</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
    <div class="footer search">
      <!-- Search -->
      <div class="slot left">
        <v-text-field
          v-model="search"
          solo
          rounded
          clearable
          flat
          hide-details
          label="Pesquise por instagrams..."
          append-icon="search"
          color="primary"
          clear-icon="close"
          background-color="#F5F5F5"
        ></v-text-field>
      </div>

      <!-- Actions -->
      <div class="slot right">
        <v-btn large color="seccondary" @click="$emit('cancelClickedEvent')">
          <v-icon>mdi-close</v-icon>
          Cancelar
        </v-btn>

        <v-btn large color="primary" @click="$emit('createClickedEvent')">
          <v-icon>add_circle</v-icon>
          Adicionar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import Bus from "@/util/EventBus";

import { Product } from "../../../gateways";
import {
  CC_INSTAGRAM_DELETE_INSTAGRAM_USER,
  CC_INSTAGRAM_GET_INSTAGRAM_CREDENTIALS,
  UI_SNACKBAR_SHOW
} from "../../../store/actions";

export default {
  data: () => ({
    isLoading: false,
    search: "",
    users: [],
    maxProductsShown: 3,
    maxChipsShown: 6,
    products: [],
    headers: [
      {
        text: "Nome",
        align: "left",
        sortable: true,
        value: "name"
      },
      {
        text: "Descrição",
        align: "left",
        sortable: true,
        value: "description_short"
      },
      {
        text: "Grupos",
        align: "center",
        sortable: false,
        value: "group_names"
      },
      {
        text: "Contas/Hashtags Seguidos",
        align: "center",
        sortable: false,
        value: "followed_accounts"
      },
      {
        text: "Status",
        align: "center",
        sortable: false,
        width: "1px",
        value: "status_icon"
      },
      { text: "Ações", value: "action", align: "center", sortable: false }
    ]
  }),
  created() {
    if (this.drawer) this.fetchData();
  },
  computed: {
    ...mapState({ currentUser: state => state.auth.user }),
    ...mapState({ currentTab: state => state.ui.currentTab }),
    ...mapState({ drawer: state => state.ui.drawer }),
    ...mapGetters(["userId", "isSuperUser"])
  },
  watch: {
    drawer() {
      if (this.drawer && this.currentTab === "instagrams") this.fetchData();
    },
    currentTab() {
      if (this.drawer && this.currentTab === "instagrams") this.fetchData();
    }
  },
  methods: {
    canModifyCredential(item) {
      return item.user_id === this.userId || this.isSuperUser;
    },
    async fetchData() {
      this.isLoading = true;
      this.users = [];

      this.users = await this.$store.dispatch(
        CC_INSTAGRAM_GET_INSTAGRAM_CREDENTIALS
      );

      const promises = [Product.get()];
      const results = await Promise.all(promises);
      [this.products] = results.map(r => r.data);
      this.isLoading = false;
    },
    editItem(user) {
      this.$emit("editClickedEvent", user);
    },
    reauthenticateItem(user) {
      this.$emit("reauthenticateClickedEvent", user);
    },
    getShortDescription(item) {
      const character_number = 50;
      return item.description
        ?.slice(0, character_number)
        .concat(item.description.length > character_number ? " ..." : "");
    },
    getGroupNameList(item) {
      return item.group_ids.map(
        el => this.products.filter(group => group.id === el)[0]?.name
      );
    },
    completeFollowList(item) {
      const identifiedAccounts = item.public_accounts.map(el => {
        return { ...el, type: "user" };
      });
      const identifiedHashtags = item.hashtags.map(el => {
        return { ...el, type: "hash" };
      });
      return [...identifiedAccounts, ...identifiedHashtags];
    },
    displayedFollowList(item) {
      return this.completeFollowList(item).slice(0, this.maxChipsShown);
    },
    remainderProductTooltip(item) {
      return this.getGroupNameList(item)
        .slice(this.maxProductsShown)
        .join(", ");
    },
    remainderFollowTooltip(item) {
      const extraChips = this.completeFollowList(item).slice(
        this.maxChipsShown
      );
      return extraChips
        .map(el => {
          return el.type === "user" ? el.username : el.name;
        })
        .join(", ");
    },
    remainderFollowList(item) {
      return `+ ${item.public_accounts.length +
        item.hashtags.length -
        this.maxChipsShown}`;
    },

    async deleteItem(user) {
      const index = this.users.indexOf(user);
      const configMessage = {
        icon: "delete_forever",
        title: {
          featured: "Remover",
          text: "Instagram"
        },
        message: {
          featured: "",
          text: `Você tem certeza que deseja apagar esta conta do Instagram?.`
        },
        action: {
          color: "error",
          title: "Remover"
        }
      };
      Bus.$emit("openModalConfirm", configMessage, async callback => {
        if (callback) {
          const response = await this.$store.dispatch(
            CC_INSTAGRAM_DELETE_INSTAGRAM_USER,
            user.id
          );
          if (response && response.status === 200) {
            this.users.splice(index, 1);
            this.$store.dispatch(UI_SNACKBAR_SHOW, {
              message: "Credencial apagado com sucesso!"
            });
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "../../../scss/theme.scss";
@import "../../../scss/navigation-drawer.scss";
</style>
