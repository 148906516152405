export const AUTH_SAVEUSER = "AUTH_SAVEUSER";
export const AUTH_CLEARUSER = "AUTH_CLEARUSER";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_EXPIRED = "AUTH_EXPIRED";
export const AUTH_TRY_LOGIN = "AUTH_TRY_LOGIN";
export const AUTH_TRY_LOGIN_OIDC = "AUTH_TRY_LOGIN_OIDC";
export const AUTH_PREFERENCES_HANDLER = "AUTH_PREFERENCES_HANDLER";

export const UI_SHOW_VIEW_TABLE = "UI_SHOW_VIEW_TABLE";
export const UI_SNACKBAR_SHOW = "UI_SNACKBAR_SHOW";
export const UI_SNACKBAR_HIDE = "UI_SNACKBAR_HIDE";

export const UI_SHOW_FILTER_CAMPAIGN = "UI_SHOW_FILTER_CAMPAIGN";
export const UI_FILTER_COLLABORATION_SET = "UI_FILTER_COLLABORATION_SET";
export const UI_CREATE_COLLABORATION = "UI_CREATE_COLLABORATION";
export const UI_SHOW_COLLABORATION = "UI_SHOW_COLLABORATION";
export const UI_SHOW_EDIT_COLLABORATION = "UI_SHOW_EDIT_COLLABORATION";
export const UI_WHATSAPP_DRAWER_HANDLE = "UI_WHATSAPP_DRAWER_HANDLE";
export const UI_YOUTUBE_DRAWER_HANDLE = "UI_YOUTUBE_DRAWER_HANDLE";
export const UI_TWITTER_DRAWER_HANDLE = "UI_TWITTER_DRAWER_HANDLE";
export const UI_INSTAGRAM_DRAWER_HANDLE = "UI_INSTAGRAM_DRAWER_HANDLE";
export const UI_FORM_DRAWER_HANDLE = "UI_FORM_DRAWER_HANDLE";
export const UI_UPLOADER_DRAWER_HANDLE = "UI_UPLOADER_DRAWER_HANDLE";
export const UI_DESTINATIONS_DRAWER_HANDLE = "UI_DESTINATIONS_DRAWER_HANDLE";
export const UI_METADATA_DRAWER_HANDLE = "UI_METADATA_DRAWER_HANDLE";
export const UI_FILE_FORMATS_DRAWER_HANDLE = "UI_FILE_FORMATS_DRAWER_HANDLE";
export const UI_HANDLE_VIEW_MODE = "UI_HANDLE_VIEW_MODE";
export const UI_COLLABORATIONS_FILTERS_DRAWER_HANDLE =
  "UI_COLLABORATIONS_FILTERS_DRAWER_HANDLE";
export const UI_CAMPAIGNS_FILTERS_DRAWER_HANDLE =
  "UI_CAMPAIGNS_FILTERS_DRAWER_HANDLE";
export const UI_DENSITY_HANDLE = "UI_DENSITY_HANDLE";
export const UI_CAMPAIGN_ACTION_DIALOG_HANDLE =
  "UI_CAMPAIGN_ACTION_DIALOG_HANDLE";

export const LC_DATA_LOAD = "LC_DATA_LOAD";

export const LC_CAMPAIGN_FILTER_BY_SEARCH_TEXT =
  "LC_CAMPAIGN_FILTER_BY_SEARCH_TEXT";
export const LC_CAMPAIGNS_REFRESH = "LC_CAMPAIGNS_REFRESH";
export const LC_CAMPAIGNS_PUT = "LC_CAMPAIGNS_PUT";
export const LC_CAMPAIGNS_RESTORE_STATE = "LC_CAMPAIGNS_RESTORE_STATE";
export const LC_CAMPAIGNS_SAVE_STATE = "LC_CAMPAIGNS_SAVE_STATE";
export const LC_CAMPAIGNS_SET_RAW = "LC_CAMPAIGNS_SET_RAW";
export const LC_CAMPAIGNS_CLEAR_FILTER = "LC_CAMPAIGNS_CLEAR_FILTER";
export const LC_CAMPAIGNS_CLEAR_SEARCH = "LC_CAMPAIGNS_CLEAR_SEARCH";
export const LC_CAMPAIGNS_REMOVE = "LC_CAMPAIGNS_REMOVE";
export const LC_CAMPAIGNS_HANDLE_FILTER = "LC_CAMPAIGNS_HANDLE_FILTER";
export const LC_CAMPAIGNS_GET_BY_FILTER = "LC_CAMPAIGNS_GET_BY_FILTER";
export const LC_CAMPAIGNS_SNAPSHOT_DELETE = "LC_CAMPAIGNS_SNAPSHOT_DELETE";
export const LC_CAMPAIGNS_SNAPSHOT_GET_ALL = "LC_CAMPAIGNS_SNAPSHOT_GET_ALL";
export const LC_CAMPAIGNS_SNAPSHOT_RESTORE = "LC_CAMPAIGNS_SNAPSHOT_RESTORE";

export const CC_CAMPAIGN_LOAD = "CC_CAMPAIGN_LOAD";
export const CC_COLLABORATIONS_COUNT = "CC_COLLABORATIONS_COUNT";
export const CC_CAMPAIGN_REPORTING = "CC_CAMPAIGN_REPORTING";
export const CC_COLLABORATIONS_LOAD = "CC_COLLABORATIONS_LOAD";
export const CC_DATA_LOAD_PARAMETERS = "CC_DATA_LOAD_PARAMETERS";
export const CC_COLLABORATION_RECYCLE = "CC_COLLABORATION_RECYCLE";
export const CC_COLLABORATION_REJECT = "CC_COLLABORATION_REJECT";
export const CC_COLLABORATION_DELETE = "CC_COLLABORATION_DELETE";
export const CC_COLLABORATION_FAVORITE = "CC_COLLABORATION_FAVORITE";
export const CC_COLLABORATION_APPROVE = "CC_COLLABORATION_APPROVE";
export const CC_COLLABORATION_REFRESH = "CC_COLLABORATION_REFRESH";
export const CC_COLLABORATION_PUT = "CC_COLLABORATION_PUT";
export const CC_COLLABORATION_PUT_FILTER_ATTR =
  "CC_COLLABORATION_PUT_FILTER_ATTR";
export const CC_COLLABORATION_SET = "CC_COLLABORATION_SET";
export const CC_COLLABORATION_SHOW = "CC_COLLABORATION_SHOW";
export const CC_COLLABORATION_SHOW_NEXT = "CC_COLLABORATION_SHOW_NEXT";
export const CC_COLLABORATION_SHOW_PREVIOUS = "CC_COLLABORATION_SHOW_PREVIOUS";
export const CC_COLLABORATION_DATA_LOAD = "CC_COLLABORATION_DATA_LOAD";
export const CC_COLLABORATION_FILTER_DATA_SET =
  "CC_COLLABORATION_FILTER_DATA_SET";
export const CC_COLLABORATIONS_TOGGLE = "CC_COLLABORATIONS_TOGGLE";
export const CC_COLLABORATIONS_MOVE = "CC_COLLABORATIONS_MOVE";
export const CC_COLLABORATIONS_DOWNLOAD = "CC_COLLABORATIONS_DOWNLOAD";
export const CC_COLLABORATIONS_APPROVE_MULTIPLE =
  "CC_COLLABORATIONS_APPROVE_MULTIPLE";
export const CC_COLLABORATION_SELECT = "CC_COLLABORATION_SELECT";
export const CC_COLLABORATION_TOGGLE_ALL = "CC_COLLABORATION_TOGGLE_ALL";
export const CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_HANDLE =
  " CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_HANDLE";
export const CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_CLEAN_HANDLE =
  "CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_CLEAN_HANDLE";
export const CC_CAMPAIGN_CREATE_OR_UPDATE_FORMDATA_HANDLE =
  "CC_CAMPAIGN_CREATE_OR_UPDATE_FORMDATA_HANDLE";

export const CC_INSTAGRAM_GET_FACEBOOK_PAGES =
  "CC_INSTAGRAM_GET_FACEBOOK_PAGES";
export const CC_INSTAGRAM_GET_HASHTAG_CURRENTLY_ACTIVED_ON_FB_CONTEXT =
  "CC_INSTAGRAM_GET_HASHTAG_CURRENTLY_ACTIVED_ON_FB_CONTEXT";
export const CC_INSTAGRAM_CHECK_IF_USERNAME_EXIST =
  "CC_INSTAGRAM_CHECK_IF_USERNAME_EXIST";
export const CC_INSTAGRAM_GET_INSTAGRAM_CREDENTIALS =
  "CC_INSTAGRAM_GET_INSTAGRAM_CREDENTIALS";
export const CC_INSTAGRAM_GET_INSTAGRAM_CREDENTIALS_BY_PRODUCT_ID =
  "CC_INSTAGRAM_GET_INSTAGRAM_CREDENTIALS_BY_PRODUCT_ID";
export const CC_INSTAGRAM_POST_INSTAGRAM_USER =
  "CC_INSTAGRAM_POST_INSTAGRAM_USER";
export const CC_INSTAGRAM_EDIT_INSTAGRAM_USER =
  "CC_INSTAGRAM_EDIT_INSTAGRAM_USER";
export const CC_INSTAGRAM_DELETE_INSTAGRAM_USER =
  "CC_INSTAGRAM_DELETE_INSTAGRAM_USER";
export const CC_INSTAGRAM_REAUTHORIZE_ACCOUNT =
  "CC_INSTAGRAM_REAUTHORIZE_ACCOUNT";
export const CC_INSTAGRAM_ADD_NEW_USER_ON_CREDENTIAL =
  "CC_INSTAGRAM_ADD_NEW_USER_ON_CREDENTIAL";
export const CC_INSTAGRAM_SET_UPDATE_LONG_ACCESS_TOKEN =
  "CC_INSTAGRAM_SET_UPDATE_LONG_ACCESS_TOKEN";
export const UI_MODAL = "UI_MODAL";

export const SETTINGS_FLUXUX_LOAD = "SETTINGS_FLUXUX_LOAD";
export const SETTINGS_FLUXUX_ACCEPT = "SETTINGS_FLUXUX_ACCEPT";
export const SETTINGS_FLUXUX_REJECT = "SETTINGS_FLUXUX_REJECT";
export const SETTINGS_FLUXUX_RESET = "SETTINGS_FLUXUX_RESET";
export const SETTINGS_FLUXUX_REMOVE = "SETTINGS_FLUXUX_REMOVE";
export const SETTINGS_FLUXUX_STARTMONITORING =
  "SETTINGS_FLUXUX_STARTMONITORING";

export const CAMPAIGN_COLLABORATION_REFRESH = "CAMPAIGN_COLLABORATION_REFRESH";
export const CAMPAIGN_COLLABORATIONS_SHOW_NEXT =
  "CAMPAIGN_COLLABORATIONS_SHOW_NEXT";
export const CAMPAIGN_COLLABORATIONS_SHOW_PREVIOUS =
  "CAMPAIGN_COLLABORATIONS_SHOW_PREVIOUS";
export const CAMPAIGN_COLLABORATIONS_UPDATE = "CAMPAIGN_COLLABORATIONS_UPDATE";
export const CAMPAIGN_BLOCK_USER = "CAMPAIGN_BLOCK_USER";
export const CAMPAIGN_GROUPED_LOAD_COLLABORATORS =
  "CAMPAIGN_GROUPED_LOAD_COLLABORATORS";
export const CAMPAIGN_GROUPED_LOAD_COLLABORATIONS =
  "CAMPAIGN_GROUPED_LOAD_COLLABORATIONS";
export const CAMPAIGN_GROUPED_UPDATE_SELECTED_ID =
  "CAMPAIGN_GROUPED_UPDATE_SELECTED_ID";
export const CAMPAIGN_GROUPED_CLEAN_COLLABORATORS =
  "CAMPAIGN_GROUPED_CLEAN_COLLABORATORS";
export const CAMPAIGN_GROUPED_CLEAN_COLLABORATIONS =
  "CAMPAIGN_GROUPED_CLEAN_COLLABORATIONS";
export const CAMPAIGN_COLLABORATIONS_GROUPED_LOAD_ANSWERS =
  "CAMPAIGN_COLLABORATIONS_GROUPED_LOAD_ANSWERS";
export const CAMPAIGN_COLLABORATIONS_POLL = "CAMPAIGN_COLLABORATIONS_POLL";
export const CAMPAIGN_COLLABORATIONS_LOAD = "CAMPAIGN_COLLABORATIONS_LOAD";
export const CAMPAIGN_COLLABORATIONS_LOAD_PAGE =
  "CAMPAIGN_COLLABORATIONS_LOAD_PAGE";
export const CAMPAIGN_COLLABORATIONS_POLL_CLEANNER =
  "CAMPAIGN_COLLABORATIONS_POLL_CLEANNER";
export const CAMPAIGN_COLLABORATIONS_REPORTING =
  "CAMPAIGN_COLLABORATIONS_REPORTING";
export const CAMPAIGN_LOAD = "CAMPAIGN_LOAD";
export const CAMPAIGN_NOTIFICATION_HANDLER = "CAMPAIGN_NOTIFICATION_HANDLER";
export const CAMPAIGN_NOTIFICATION_START_MONITORING =
  "CAMPAIGN_NOTIFICATION_START_MONITORING";

export const COLLABORATION_SEARCH_TERMS = "COLLABORATION_SEARCH_TERMS";
export const COLLABORATION_MEDIA_PLAYER_HANDLER =
  "COLLABORATION_MEDIA_PLAYER_HANDLER";

export const CAMPAIGN_COLLABORATIONS_COUNT = "CAMPAIGN_COLLABORATIONS_COUNT";
export const CAMPAIGN_COLLABORATION_SELECT = "CAMPAIGN_COLLABORATION_SELECT";
export const CAMPAIGN_COLLABORATIONS_LOAD_TABLE_VIEW =
  "CAMPAIGN_COLLABORATIONS_LOAD_TABLE_VIEW";
export const CAMPAIGN_COLLABORATIONS_APPROVE =
  "CAMPAIGN_COLLABORATIONS_APPROVE";
export const CAMPAIGN_COLLABORATIONS_DELETE = "CAMPAIGN_COLLABORATIONS_DELETE";
export const CAMPAIGN_COLLABORATIONS_RECYCLE =
  "CAMPAIGN_COLLABORATIONS_RECYCLE";
export const CAMPAIGN_COLLABORATIONS_FAVORITE =
  "CAMPAIGN_COLLABORATIONS_FAVORITE";
export const CAMPAIGN_COLLABORATIONS_REJECT = "CAMPAIGN_COLLABORATIONS_REJECT";
export const CAMPAIGN_COLLABORATIONS_SHOW = "CAMPAIGN_COLLABORATIONS_SHOW";
export const TAB_ACTIVE_SET = "TAB_ACTIVE_SET";
export const TAB_ADD = "TAB_ADD";
export const TAB_REMOVE = "TAB_REMOVE";

export const COLLABORATOR_SEND_EMAIL_FIELD_HANDLE =
  "COLLABORATOR_SEND_EMAIL_FIELD_HANDLE";
