var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{class:[
      {
        isInCollaborationsView:
          _vm.isInCollaborationsView && _vm.viewMode !== 'conversation'
      },
      'floating-buttons'
    ],attrs:{"app":"","hide-on-scroll":"","inverted-scroll":"","scroll-target":".main-content","width":"52px","height":"140px"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"56px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"fab-button primary",on:{"click":_vm.actualize}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Atualizar")])]),(_vm.canShowBatchActions)?_c('v-tooltip',{attrs:{"top":"","max-width":"56px"},scopedSlots:_vm._u([{key:"activator",fn:function(on){return [_c('v-btn',_vm._g({staticClass:"fab-button primary",on:{"click":_vm.openBatchActions}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-select-all")])],1)]}}],null,false,2353546022)},[_c('span',[_vm._v("Ações em lote")])]):_vm._e(),(_vm.canShowBackToTop)?_c('v-tooltip',{attrs:{"top":"","max-width":"56px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"fab-button secondary",on:{"click":_vm.goToTop}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-arrow-up-bold-hexagon-outline")])],1)]}}],null,false,2094386052)},[_c('span',[_vm._v("voltar ao topo")])]):_vm._e()],1),_c('CollaborationBatchActions',{ref:"batchActions",attrs:{"campaignId":_vm.batchActionsOptions.campaignId,"disabled":_vm.batchActionsOptions.disabled}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }