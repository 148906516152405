import { mapState } from "vuex";

import collaborationsActionsValidator from "@/mixins/collaborations_actions_validator.js";

export default {
  mixins: [collaborationsActionsValidator],
  computed: {
    ...mapState({
      campaigns: state => state.listCampaign.campaigns,
      campaign: state => state.campaign.campaign,
      collaborationsInfo: state => state.campaign.tabs[0]
    }),
    collaborations() {
      return this.collaborationsInfo.collaborations;
    },

    campaign() {
      return this.campaign;
    },

    isThatLastPage() {
      return this.collaborationsInfo.isThatLastPage;
    },

    collaborationsAllSelected() {
      return this.collaborationsInfo.selectedCollaboration || {};
    },
    collaborationsAllSelectedCount() {
      return Object.keys(this.collaborationsInfo.selectedCollaboration).length;
    }
  },
  methods: {
    fetchNextPage() {
      this.$store.dispatch("CAMPAIGN_COLLABORATIONS_LOAD", {
        tryPaginating: true,
        size: 25,
        refresh: false
      });
    }
  }
};
