<template>
  <div class="main d-flex">
    <div class="collaborators">
      <v-list
        three-line
        :class="['pa-0', densityClass]"
        v-if="!isCollaboratorsLoading"
      >
        <template v-for="(collaborator, index) in collaborators">
          <v-list-item
            :key="index"
            v-if="
              collaborationsGroupedByCollaborator.data[index].collaborations
                .length
            "
            :class="{ active: isThatCurrentCollaborator(collaborator) }"
            @click="handleUserClick(index)"
          >
            <v-list-item-avatar height="50px" width="50px">
              <v-img :src="collaboratorImage(index)"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <div class="collaborator-title">
                <div class="collaborator-name">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-list-item-title v-on="on" v-html="collaborator.name" />
                    </template>
                    <span> {{ collaborator.name }}</span>
                  </v-tooltip>
                  <v-icon
                    class="ml-1 pb-1"
                    :class="userVerificationBadgeClasses(collaborator)"
                    v-show="collaborator && collaborator.verified"
                    >mdi-check-decagram</v-icon
                  >
                </div>
                <v-tooltip
                  bottom
                  v-for="(badge, i) of collaboratorBadges(index)"
                  :key="i"
                >
                  <template v-slot:activator="{ on }">
                    <v-chip
                      v-if="badge.show"
                      v-on="on"
                      class="badge"
                      width="fit-content"
                      ><v-icon :color="badge.color">{{
                        `$${badge.icon}`
                      }}</v-icon
                      >{{ badge.counter && badge.counter }}</v-chip
                    >
                  </template>
                  <span>{{ badge.tooltip }} </span>
                </v-tooltip>
              </div>
              <div class="collaborator-content">
                <v-list-item-subtitle>
                  {{
                    collaborationsGroupedByCollaborator.data[index]
                      .collaborations[0].body.message
                  }}
                </v-list-item-subtitle>
                <div class="actions__media-counter">
                  <v-tooltip
                    bottom
                    v-for="(badge, i) of collaborationBadges(index)"
                    :key="i"
                  >
                    <template v-slot:activator="{ on }">
                      <v-chip v-if="badge.show" v-on="on" class="badge"
                        ><v-icon :color="badge.color">{{
                          `$${badge.icon}`
                        }}</v-icon></v-chip
                      >
                    </template>
                    <span>{{ badge.tooltip }} </span>
                  </v-tooltip>
                </div>
              </div>
              <div class="collaboration-time">
                <v-chip class="badge">
                  {{ collaborationDatetime(index) }}
                </v-chip>
              </div>
            </v-list-item-content>
          </v-list-item>
        </template>
        <Observer
          v-if="collaborators.length >= paginationSize"
          @intersect="loadMoreCollaborators"
        />
      </v-list>
      <v-sheet v-else v-for="index in 10" :key="index">
        <v-skeleton-loader
          type="list-item-avatar-three-line"
          class="mb-4 mt-4"
        ></v-skeleton-loader>
        <v-divider></v-divider>
      </v-sheet>
    </div>
    <div :class="['conversation', { overlayedCards: areCardsOverlayed }]">
      <div class="header d-flex align-center">
        <template v-if="!isCollaboratorsLoading">
          <v-avatar>
            <img :src="currentCollaboratorImage()" alt="John" />
          </v-avatar>
          <div class="header__info d-flex ml-4">
            <div class="name">
              {{ collaborator && collaborator.name }}
              <v-icon
                class="ml-1 pb-1"
                :class="userVerificationBadgeClasses(collaborator)"
                v-show="collaborator && collaborator.verified"
                >mdi-check-decagram</v-icon
              >
            </div>
            <div class="collaborations_counter">
              {{ activeCollaboratorCollaborationsCounter }}
              de {{ activeCollaboratorCollaborationsTotalCounter }}
              {{
                activeCollaboratorCollaborationsTotalCounter > 1
                  ? "colaborações"
                  : "colaboração"
              }}
            </div>
          </div>
          <div class="header__info d-flex ml-8">
            <div>
              {{ collaboratorHeaderInfo[0] }}
            </div>
            <div>
              {{ collaboratorHeaderInfo[1] }}
            </div>
          </div>
          <div class="header__info d-flex flex-row ml-auto mr-4">
            <div
              v-for="(action, index) in collaboratorActions"
              :key="index"
              class="collaborator-action ml-4"
              v-show="action.show"
              @click="action.action"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon :color="action.color" v-bind="attrs" v-on="on">{{
                    action.icon
                  }}</v-icon>
                </template>
                <span>{{ action.title }}</span>
              </v-tooltip>
            </div>
          </div>
        </template>
        <v-sheet v-else>
          <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
        </v-sheet>
      </div>
      <div :class="['cards', densityClass]" v-if="!isCollaborationsLoading">
        <CollaborationConversationCard
          v-for="(collaboration, index) in collaborationsAndAnswers"
          :key="
            collaboration.collaboration_id + index || collaboration.id + index
          "
          :collaboration="collaboration"
          :curCollaborator="collaborator"
          :index="index"
          @overlay-cards="areCardsOverlayed = $event"
        />
        <Observer
          v-if="
            activeCollaboratorCollaborationsCounter <
              activeCollaboratorCollaborationsTotalCounter
          "
          @intersect="loadMoreCollaborations"
        />
      </div>
      <v-sheet v-else v-for="index in 3" :key="index">
        <v-skeleton-loader type="image, table-tfoot"></v-skeleton-loader>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import panda from "@/assets/images/avatar-panda-01.svg";
import CollaborationConversationCard from "@/components/collaborations/ConversationView/CollaborationConversationCard";
import Observer from "@/components/Observer";
import {
  CAMPAIGN_GROUPED_LOAD_COLLABORATIONS,
  CAMPAIGN_GROUPED_LOAD_COLLABORATORS
} from "@/store/actions";
import { CAMPAIGN_GROUPED_UPDATE_SELECTED_ID } from "@/store/actions";
import {
  getAndFormatCounter,
  getCollaborationDate,
  getCollaborationTime,
  handleBlockUser,
  openEmailModal,
  openQRCodeModal
} from "@/util/collaborations";

export default {
  components: {
    CollaborationConversationCard,
    Observer
  },
  data: () => ({
    areCardsOverlayed: false,
    paginationSize: 20
  }),
  computed: {
    ...mapState({
      blockedPhones: state =>
        state.campaign.campaign.whatsapp_configuration.blocked_phones,
      collaborationsGroupedByCollaborator: state =>
        state.campaign.collaborationsGroupedByCollaborator,
      selectedGroupedCollaboratorID: state =>
        state.campaign.selectedGroupedCollaboratorID,
      selectedGroupedCollaborations: state =>
        state.campaign.selectedGroupedCollaborations,
      densityLevel: state => state.ui.densityLevel
    }),
    ...mapGetters({
      collaboratorInfo: "curCollaboratorInfo",
      viewMode: "viewMode",
      collaborationsAndAnswers: "collaboratorCollaborationsAndAnswers"
    }),
    densityClass() {
      return `density-level-${this.densityLevel}`;
    },
    collaborator: {
      get() {
        return this.collaboratorInfo?.collaborator;
      },
      set(index) {
        if (
          !this.isThatCurrentCollaborator(
            this.collaborationsGroupedByCollaborator.data[index].collaborator
          )
        ) {
          this.$store.dispatch(
            CAMPAIGN_GROUPED_UPDATE_SELECTED_ID,
            this.collaborationsGroupedByCollaborator.data[index].collaborator.id
          );
        }
      }
    },
    collaborators() {
      return this.collaborationsGroupedByCollaborator.data.map(
        item => item.collaborator
      );
    },
    collaboratorActions() {
      return [
        {
          action: () => openQRCodeModal(this.collaborator.phone),
          title: "Conversar (QRCODE)",
          icon: "qr_code_2",
          color: "primary",
          show: this.canShowQrGenerator
        },
        {
          action: () =>
            openEmailModal({
              ...this.collaborator,
              campaignId: this.$route.params.campaignId
            }),
          title: "Responder (E-mail)",
          icon: "$emailIcon",
          color: "primary",
          show: this.collaborator?.type === "form"
        },
        {
          title: "Bloquear Colaborador",
          icon: "mdi-account-lock",
          action: async () => handleBlockUser(this.collaborator.phone, true),
          color: "reprove",
          show: this.canShowBlockUserAction
        },
        {
          title: "Desbloquear Colaborador",
          icon: "mdi-account-reactivate",
          action: async () => handleBlockUser(this.collaborator.phone, false),
          color: "primary",
          show: this.canShowUnblockUserAction
        }
      ];
    },
    canShowQrGenerator() {
      return this.collaborator?.type === "whatsapp";
    },
    canShowBlockUserAction() {
      return (
        this.collaborator?.type === "whatsapp" &&
        !this.blockedPhones.includes(this.collaborator.phone)
      );
    },
    canShowUnblockUserAction() {
      return (
        this.collaborator?.type === "whatsapp" &&
        this.blockedPhones.includes(this.collaborator.phone)
      );
    },
    activeCollaboratorCollaborationsCounter() {
      return this.selectedGroupedCollaborations.data.length;
    },
    activeCollaboratorCollaborationsTotalCounter() {
      return this.collaboratorInfo?.collaboration_total;
    },
    isCollaboratorsLoading() {
      return this.collaborationsGroupedByCollaborator.isLoading;
    },
    isCollaborationsLoading() {
      return this.selectedGroupedCollaborations.isLoading;
    },
    collaboratorHeaderInfo() {
      let collaboratorInfo = [];
      if (this.collaborator) {
        switch (this.collaborator?.type) {
          case "form":
            collaboratorInfo = [
              this.collaborator.email,
              `${this.collaborator.country && `${this.collaborator.country} - `}
            ${this.collaborator.state && `${this.collaborator.state} - `}
            ${this.collaborator.city}`
            ];
            break;
          case "youtube":
            collaboratorInfo = [
              this.collaborator.yt_username || this.collaborator.yt_channel_id,
              ""
            ];
            break;
          case "twitter":
            collaboratorInfo = [this.collaborator.tw_user_screen_name, ""];
            break;
          case "instagram":
            collaboratorInfo = [this.collaborator.insta_username, ""];
            break;
          case "whatsapp":
            collaboratorInfo = [this.collaborator.phone, ""];
            break;

          default:
            collaboratorInfo = [this.collaborator.email, ""];
            break;
        }
      }
      return collaboratorInfo;
    }
  },
  methods: {
    currentCollaboratorImage() {
      return this.collaborator?.profile_image_url || panda;
    },
    collaboratorImage(index) {
      return (
        this.collaborationsGroupedByCollaborator.data[index].collaborator
          ?.profile_image_url || panda
      );
    },
    handleUserClick(index) {
      this.collaborator = index;
    },
    loadMoreCollaborators() {
      this.$store.dispatch(CAMPAIGN_GROUPED_LOAD_COLLABORATORS, {
        loadMoreCollaborators: true
      });
    },
    loadMoreCollaborations() {
      this.$store.dispatch(CAMPAIGN_GROUPED_LOAD_COLLABORATIONS, {
        loadMoreCollaborations: true
      });
    },
    isThatUserBlocked(index) {
      const collaborator = this.collaborationsGroupedByCollaborator.data[index]
        .collaborator;
      return (
        collaborator.phone && this.blockedPhones.includes(collaborator.phone)
      );
    },
    audiosCounter(index) {
      return getAndFormatCounter(
        "audio",
        this.collaborationsGroupedByCollaborator.data[index].collaborations[0]
          .medias
      );
    },
    imagesCounter(index) {
      return getAndFormatCounter(
        "image",
        this.collaborationsGroupedByCollaborator.data[index].collaborations[0]
          .medias
      );
    },
    videosCounter(index) {
      return getAndFormatCounter(
        "video",
        this.collaborationsGroupedByCollaborator.data[index].collaborations[0]
          .medias
      );
    },
    collaborationsTotalCounter(index) {
      return this.collaborationsGroupedByCollaborator.data[index]
        .collaboration_total;
    },
    collaboratorBadges(index) {
      return [
        {
          counter: this.collaborationsTotalCounter(index),
          icon: "conversation",
          tooltip:
            this.collaborationsTotalCounter(index) > 1
              ? "colaborações"
              : "colaboração",
          show: this.collaborationsTotalCounter(index) > 0
        },
        {
          icon: "blockedUser",
          tooltip: "usuário bloqueado",
          show: this.isThatUserBlocked(index)
        }
      ];
    },
    userVerificationBadgeClasses(collaborator) {
      const classes = ["user-verified"];
      if (collaborator?.type === "twitter") {
        classes.push("user-verified--twitter");
      }
      return classes;
    },

    collaborationBadges(index) {
      return [
        {
          icon: "imageIcon",
          tooltip: this.imagesCounter(index) > 1 ? "imagens" : "imagem",
          show: this.imagesCounter(index) > 0
        },
        {
          icon: "audioIcon",
          tooltip: this.audiosCounter(index) > 1 ? "áudios" : "audio",
          show: this.audiosCounter(index) > 0
        },
        {
          icon: "videoIcon",
          tooltip: this.videosCounter(index) > 1 ? "vídeos" : "vídeo",
          show: this.videosCounter(index) > 0
        }
      ];
    },
    isThatCurrentCollaborator(collaborator) {
      return collaborator.id === this.selectedGroupedCollaboratorID;
    },
    collaborationDatetime(index) {
      return this.collaborationDate(index)
        ? `${this.collaborationDate(index)} - ${this.collaborationTime(index)}`
        : this.collaborationTime(index);
    },
    collaborationDate(index) {
      const collaborationDate = getCollaborationDate(
        this.collaborationsGroupedByCollaborator.data[index].collaborations[0]
          .created_at
      );
      const todayDate = new Date().toLocaleDateString("pt-br");
      return collaborationDate !== todayDate ? collaborationDate : null;
    },
    collaborationTime(index) {
      return getCollaborationTime(
        this.collaborationsGroupedByCollaborator.data[index].collaborations[0]
          .created_at
      );
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/theme.scss";
@import "@/scss/cards.scss";

.main {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 0px 6px 1px rgba($color-gray-main, 0.16);
  height: 100%;
  margin-top: 6px;
  overflow: hidden;
  .collaborators {
    font-family: $globotipo-text;
    background: #fff;
    height: 100%;
    flex: 1;
    min-width: 360px;
    overflow-y: scroll;
    .v-list {
      @for $i from 0 through 2 {
        $height: 85 + (10 * $i);
        $titleFontSize: 0.8 + (0.1 * $i);
        $subtitleFontSize: 0.6 + (0.1 * $i);
        &.density-level-#{$i} {
          .v-list-item {
            transition: all 0.3s ease-in-out;
            .v-list-item__title {
              font-size: #{$titleFontSize}rem;
              transition: all 0.3s ease-in-out;
            }
            .v-list-item__subtitle {
              font-size: #{$subtitleFontSize}rem;
              transition: all 0.3s ease-in-out;
            }
          }
        }
      }
      .v-list-item {
        cursor: pointer !important;
        border-bottom: 1px solid rgba($color-gray-main, 0.16);
        height: auto;
        min-height: auto;
        &:hover,
        &.active {
          background-color: rgba($color-primary, 0.16);
          .v-chip {
            background: #fff !important;
          }
        }
        .collaborator-title {
          justify-content: space-between;
          display: flex;
          max-width: 100%;
          .v-list-item__title {
            max-width: max-content !important;
          }
          .collaborator-name {
            display: flex;
            justify-content: flex-start;
            overflow: hidden;
            margin-right: 4px;
          }
        }
        .collaborator-content {
          justify-content: space-between;
          display: flex;
          max-width: 100%;
          // text-overflow: ellipsis;
          .v-list-item__subtitle {
            padding-right: 4px;
            width: 90% !important ;
            flex: initial;
            text-overflow: ellipsis;
          }
        }
        .collaboration-time {
          font-size: 0.85rem;
          display: flex;
          max-width: 100%;
          justify-content: flex-end;
          padding-bottom: 4px;
        }
        .v-chip {
          color: $color-gray-main !important;
          font-size: 0.7em !important;

          background: rgba($color-gray-main, 0.08) !important;
          border: 1px solid rgba($color-gray-main, 0.16) !important;
          height: 23px !important;
          margin-right: 4px;
          padding: 0 5px 0 5px;
          max-width: fit-content;
          overflow: initial;
        }
        .v-list-item__avatar {
          align-self: center;
        }
        .v-list-item__content {
          align-items: flex-start;
          padding: 12px 0 0;
        }
        .actions__media-counter {
          align-items: center;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  .conversation {
    background: rgba($color-primary, 0.16);
    flex: 3;
    width: 100%;

    .header {
      font-family: $globotipo-text !important;
      font-size: 0.8rem;

      background-color: #fff;
      border-left: 2px solid rgba($color-gray-main, 0.16);
      height: 56px;
      margin-bottom: 8px;
      padding-left: 16px;
      width: 100%;

      &__info {
        flex-direction: column;

        .collaborator-action {
          cursor: pointer;
        }
      }
    }
    .cards {
      height: calc(100% - 81px);
      overflow-y: auto;
    }

    ::v-deep .card,
    .v-sheet {
      margin: 16px;
    }
    ::v-deep .cards {
      @for $i from 0 through 2 {
        $carouselHeight: 150 + (75 * $i);
        $fontSize: 12 + (1 * $i);
        $padding: 2 + (2 * $i);
        $iconSize: 16 + (2 * $i);
        &.density-level-#{$i} {
          .carousel-media,
          .v-window__container,
          .v-carousel__item {
            height: #{$carouselHeight}px !important;
            transition: all 0.3s ease-in-out;
          }
          .message {
            font-size: #{$fontSize}px;
            transition: all 0.3s ease-in-out;
          }
          .date,
          .time {
            padding: #{$padding}px 5px;
            transition: all 0.3s ease-in-out;
          }
          .v-icon {
            font-size: #{$iconSize}px;
            transition: all 0.3s ease-in-out;
          }
        }
      }
      .card {
        &.collaboration-card.answer {
          width: fit-content;
          margin-left: auto !important;
        }
        &:not(.mediaCard) {
          max-width: 75% !important;
        }
        &.mediaCard {
          max-width: 356px;
        }
      }
    }
    .v-sheet {
      max-width: 356px !important;
    }
  }
  .user-verified {
    font-size: 1.2rem;
    &--twitter {
      color: $color-twitter;
    }
  }
}
</style>
