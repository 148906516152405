<template>
  <v-btn
    large
    v-bind="attrs"
    v-on="$listeners"
    :icon="!buttonHasTitle"
    :text="isDefaultColor"
    :color="setColor"
    :class="cssClass"
    :disabled="!!disabled"
    @click="clickHandler"
    @click.stop="clickStopHandler"
  >
    <v-icon v-if="icon" left dark>{{ icon }}</v-icon>
    {{ label }}
  </v-btn>
</template>

<script>
export default {
  name: "Button",
  data() {
    return {
      theme: {},
      graydark: "graydark"
    };
  },
  props: {
    label: String,
    icon: String,
    color: {
      type: String,
      default: "graydark"
    },
    cssClass: String,
    disabled: Boolean
  },
  mounted() {
    this.theme = this.$root.$vuetify.theme.defaults.light;
  },
  computed: {
    setColor() {
      if (!this.color) {
        return this.theme.graydark;
      }
      return this.color;
    },
    buttonHasTitle() {
      return !!this.label;
    },
    isDefaultColor() {
      return this.color === this.graydark;
    }
  },
  methods: {
    clickHandler() {
      this.$emit("click-handler");
    },
    clickStopHandler() {
      this.$emit("click-stop-handler");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";
@import "@/scss/form-inputs.scss";

// .v-btn {
//   &.v-btn--text,
//   &.v-btn--has-bg {
//     .v-icon {
//       margin-right: 8px !important;
//     }
//   }
// }
</style>
