<template>
  <div class="container">
    <!-- Topo -->
    <div class="topo">
      <div class="title"><span>Lista</span> de WhatsApps</div>
    </div>
    <div class="main">
      <v-data-table
        :fixed-header="true"
        :headers="headers"
        :items="whatsapps"
        class="table-main"
        :mobile-breakpoint="1200"
        :search="search"
        :loading="isLoading"
        sort-by="name"
        loading-text="Carregando lista de WhatsApp..."
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-2"
                @click="$emit('editClickedEvent', item)"
                v-on="on"
              >
                edit
              </v-icon>
            </template>
            <span>Editar número</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small @click="deleteItem(item)" v-on="on">
                delete
              </v-icon>
            </template>
            <span>Remover número</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
    <div class="footer search">
      <!-- Search -->
      <div class="slot left">
        <v-text-field
          v-model="search"
          solo
          rounded
          clearable
          flat
          hide-details
          label="Pesquise por WhatsApps..."
          append-icon="search"
          color="primary"
          clear-icon="close"
          background-color="#F5F5F5"
        ></v-text-field>
      </div>

      <!-- Actions -->
      <div class="slot right">
        <v-btn large color="seccondary" @click="$emit('cancelClickedEvent')">
          <v-icon>mdi-close</v-icon>
          Cancelar
        </v-btn>

        <v-btn large color="primary" @click="$emit('createClickedEvent')">
          <v-icon>add_circle</v-icon>
          Adicionar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import Bus from "@/util/EventBus";

import { Whatsapp } from "../../../gateways";
import { UI_SNACKBAR_SHOW } from "../../../store/actions";

export default {
  data: () => ({
    isLoading: false,
    search: "",
    whatsapps: [],
    headers: [
      {
        text: "Nome",
        align: "left",
        sortable: true,
        value: "name"
      },
      {
        text: "Número",
        align: "left",
        sortable: true,
        value: "phoneNumber"
      },
      {
        text: "Descrição",
        align: "left",
        sortable: true,
        value: "description"
      },
      {
        text: "Modo de integração",
        align: "left",
        sortable: true,
        value: "mode"
      },
      {
        text: "Token",
        align: "left",
        sortable: true,
        value: "token"
      },
      { text: "Ações", value: "action", align: "right", sortable: false }
    ]
  }),
  created() {
    if (this.drawer) this.fetchData();
  },
  computed: {
    ...mapState({ currentTab: state => state.ui.currentTab }),
    ...mapState({ drawer: state => state.ui.drawer })
  },
  watch: {
    drawer() {
      if (this.drawer && this.currentTab === "whatsapp") this.fetchData();
    },
    currentTab() {
      if (this.drawer && this.currentTab === "whatsapp") this.fetchData();
    }
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      this.whatsapps = [];

      try {
        this.whatsapps = (await Whatsapp.get()).data;
      } catch {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message:
            "Não foi possível carregar a lista de WhatsApp. Por favor, tente novamente em alguns minutos."
        });
      }

      this.isLoading = false;
    },
    async deleteItem(input) {
      const index = this.whatsapps.indexOf(input);
      const configMessage = {
        icon: "delete_forever",
        title: {
          featured: "Remover",
          text: "Número"
        },
        message: {
          featured: "Você tem certeza que deseja apagar este número?.",
          text: `Essa alteração não poderá ser desfeita.`
        },
        action: {
          color: "error",
          title: "Remover"
        }
      };
      Bus.$emit("openModalConfirm", configMessage, async callback => {
        if (callback) {
          try {
            await Whatsapp.delete(input.id);
            this.whatsapps.splice(index, 1);
            this.$store.dispatch(UI_SNACKBAR_SHOW, {
              message: "Número apagado com sucesso!"
            });
          } catch (err) {
            const { response } = err;
            switch (response.status) {
              case 400:
                this.$store.dispatch(UI_SNACKBAR_SHOW, {
                  message:
                    "Ops... Tivemos um problema no servidor. Por favor, tente novamente em alguns instantes :)"
                });
                break;
              case 401:
                this.$store.dispatch(UI_SNACKBAR_SHOW, {
                  message: "Você não possui permissão para executar essa ação."
                });
                break;
              default:
                this.$store.dispatch(UI_SNACKBAR_SHOW, {
                  message:
                    "Ops... Tivemos um problema no servidor. Por favor, tente novamente em alguns instantes :)"
                });
                break;
            }
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "../../../scss/theme.scss";
@import "../../../scss/navigation-drawer.scss";
</style>
