<template>
  <div :class="['feedback', feedbackType]" v-show="isVisible">
    <div class="icon" :class="emptyState.bg"></div>
    <div class="label">{{ emptyState.label }}</div>
    <div class="description">{{ emptyState.description }}</div>
    <slot name="footer"></slot>
  </div>
</template>

<script>
export default {
  name: "feedback",
  props: ["feedbackType", "emptyState", "isVisible"]
};
</script>

<style scoped lang="scss">
@import "@/scss/theme.scss";
@import "@/scss/theme.scss";

.feedback {
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0px 24px;

  @include for-size(phone-only) {
    padding: 24px;
  }

  .icon {
    z-index: 4;
    width: 220px;
    height: 140px;
    background-size: cover;

    @include for-size(phone-only) {
      width: 180px;
      height: 180px;
      background-size: contain;
      background-position: center bottom;
    }
  }

  .label {
    z-index: 4;
    width: 480px;
    margin: 0;
    font-family: "Exo 2", sans-serif;
    font-size: 2.8em !important;
    font-style: italic;
    font-weight: 600;
    color: $color-primary-light;
    text-align: center;
    word-break: break-word;
    line-height: 1em;

    @include for-size(phone-only) {
      width: calc(100% - 16px);
      font-size: 1.8em !important;
      line-height: 1em;
    }
  }

  .description {
    width: 400px;
    box-sizing: border-box;
    padding: 24px;
    margin: 24px 0 0 0;
    font-size: 1em;
    font-weight: 400;
    font-style: italic;
    line-height: 1.2em;
    text-align: center;
    color: $color-gray-main;
    background-color: $color-gray-lighter;
    box-shadow: 0 8px 40px rgba($color-gray-darker, 0.08);
    border-radius: 8px;

    @include for-size(phone-only) {
      width: calc(100% - 48px);
      padding: 16px;
      font-size: 0.88em;
    }
  }
}

.campaign-feedback {
  @include for-size(tablet-landscape-up) {
    /* margin: 0px auto 40px auto; */
  }

  .icon {
    transition: all 0.4s ease-in-out;
    cursor: pointer;

    // Tipos de Feedback

    // Bored : Utilização nos feedbacks de tela vazia
    &.bored {
      height: 188px;
      background-image: url("../assets/images/bored-panda-01.svg");

      &:hover {
        background-image: url("../assets/images/bored-panda-02.svg");
      }
    }

    // Easy : Utilização nos feedbacks de tela vazia por conta da ação dos usuários
    &.easy {
      background-image: url("../assets/images/hungry-panda-01.svg");

      &:hover {
        background-image: url("../assets/images/hungry-panda-02.svg");
      }
    }
  }
}

.campaign-list-feedback {
  position: relative;
  animation: fadeIn 0.8s ease-in-out forwards;
  transition: all 0.4s ease-in-out;
  justify-content: flex-start !important;
  margin: 0 auto 0 auto;

  .icon {
    transition: all 0.4s ease-in-out;
    cursor: pointer;

    // Tipos de Feedback

    // Bored : Utilização nos feedbacks de tela vazia
    &.bored {
      height: 188px;
      background-image: url("../assets/images/bored-panda-01.svg");

      &:hover {
        background-image: url("../assets/images/bored-panda-02.svg");
      }
    }

    // Easy : Utilização nos feedbacks de tela vazia por conta da ação dos usuários
    &.easy {
      background-image: url("../assets/images/hungry-panda-01.svg");

      &:hover {
        background-image: url("../assets/images/hungry-panda-02.svg");
      }
    }
  }
}

.colaboration-view-feedback {
  margin: 24px 0;
  width: 316px;
  height: 162px;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;

  @include for-size(tablet-landscape-up) {
    /* margin: 0px auto 40px auto; */
  }

  .icon {
    transition: all 0.4s ease-in-out;
    cursor: pointer;
    max-width: 114px !important;
    background-position: center !important;

    // Tipos de Feedback

    // Bored : Utilização nos feedbacks de tela vazia
    &.bored {
      height: 80px;
      font-size: 10px;
      background-image: url("../assets/images/bored-panda-01.svg");
      background-size: 116px;

      &:hover {
        background-image: url("../assets/images/bored-panda-02.svg");
      }
    }

    // Easy : Utilização nos feedbacks de tela vazia por conta da ação dos usuários
    &.easy {
      background-image: url("../assets/images/hungry-panda-01.svg");

      &:hover {
        background-image: url("../assets/images/hungry-panda-02.svg");
      }
    }
  }
  .label {
    font-family: $primary-fontface !important;
    font-weight: 700 !important;
    font-style: italic !important;
    font-size: 18px !important;
    color: rgba(#000, 0.8) !important;
    max-width: 240px !important;
  }
  .description {
    font-family: $secondary-fontface !important;
    font-weight: 400 !important;
    font-style: italic !important;
    font-size: 14px !important;
    color: rgba(#000, 0.48) !important;
    line-height: 1.143 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 0 !important;
    margin: 8px 0 0 0 !important;
    -webkit-box-shadow: 0 8px 40px rgb(34 34 34 / 8%);
    border-radius: 8px;
    max-width: 240px !important;
  }
}

.administration-nav-drawer-feedback {
  display: none;

  background-color: $color-gray-light;

  @include for-size(phone-only) {
    height: calc(100vh - 56px);
    position: fixed;
    top: 0;
    left: 0;
    display: flex !important;

    .icon {
      background-image: url("../assets/images/astronaut-panda-01.svg");
      background-size: contain;
      cursor: pointer;
      height: 130px;
      transition: all 0.4s ease-in-out;
      width: 130px;

      &:hover {
        background-image: url("../assets/images/astronaut-panda-02.svg");
        transition: all 0.4s ease-in-out;
      }
    }
    .label {
      font-size: 1.4em !important;
    }
    .description {
      width: calc(100% - 20px);
    }
  }
}
</style>
