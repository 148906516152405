<template>
  <div class="main-wrapper">
    <GradientTopBackground />
    <CampaignFloatingActions />
    <!-- HEADER -->
    <HeaderMain>
      <template v-slot:header-left-btn>
        <div class="header-back-btn">
          <router-link to="/" class="link" primary>
            <v-btn icon ripple class="--back-white">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </router-link>
        </div>
      </template>

      <template v-slot:header-left-title>
        <div class="header-title">
          <div class="title-main --italic">
            <span>Lista</span> de colaborações
          </div>
          <div class="multi-campaign">
            <v-chip-group
              v-if="$vuetify.breakpoint.mdAndUp"
              show-arrows
              ref="chipGroup"
            >
              <template v-for="(campaign, index) in selectedCampaigns">
                <v-tooltip bottom :key="index">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      label
                      ripple
                      :close="selectedCampaigns.length < 2 ? false : true"
                      @click:close="removeSelectedCampaign(index)"
                      v-on="on"
                      v-bind="attrs"
                    >
                      {{
                        selectedCampaigns.length > 1
                          ? `${getCampaignName(campaign)
                              .substring(0, 14)
                              .trim()}...`
                          : getCampaignName(campaign)
                      }}
                    </v-chip>
                  </template>
                  <span>{{ getCampaignName(campaign) }}</span>
                </v-tooltip>
              </template>
            </v-chip-group>

            <v-menu
              v-if="$vuetify.breakpoint.smAndDown"
              min-width="auto"
              offset-y
              left
              transition="slide-y-transition"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <v-chip label ripple v-on="on">
                  {{ selectedCampaigns.length }}
                  {{ selectedCampaigns.length > 1 ? "campanhas" : "campanha" }}
                  <v-icon right>mdi-chevron-down</v-icon>
                </v-chip>
              </template>

              <div class="dropdown --big header-filter">
                <div class="dropdown-header">
                  Campanhas selecionadas
                </div>
                <div class="dropdown-content">
                  <div class="list-item mt-4">
                    <v-list>
                      <v-list-item-group
                        v-model="selectedCampaigns"
                        multiple
                        mandatory
                        color="primary"
                      >
                        <v-list-item
                          v-for="(campaign, i) in selectedCampaignsObject"
                          :key="i"
                          :value="campaign.id"
                        >
                          <template v-slot:default="{ active }">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ campaign.name }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-checkbox
                                :input-value="active"
                                color="primary"
                              />
                            </v-list-item-action>
                          </template>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </div>
                </div>
              </div>
            </v-menu>

            <v-menu
              min-width="auto"
              offset-y
              left
              transition="slide-y-transition"
              :close-on-content-click="false"
              v-if="canShowMultipleCampaigns"
            >
              <template v-slot:activator="{ on }">
                <v-chip label ripple v-on="on" color="">
                  veja mais
                  <v-icon right>mdi-chevron-down</v-icon>
                </v-chip>
              </template>

              <div class="dropdown --big header-filter">
                <div class="dropdown-header">
                  Selecionar múltiplas campanhas
                </div>
                <div class="dropdown-content">
                  <div class="list-item mt-4">
                    <v-text-field
                      class="pl-4 pr-4"
                      label="Pesquise uma campanha"
                      prepend-inner-icon="mdi-magnify"
                      clearable
                      v-model="searchCampaignParam"
                    />
                    <v-checkbox
                      class="ml-4"
                      color="primary"
                      label="Selecionar tudo"
                      @change="selectAllCampaigns"
                    />
                    <v-list>
                      <v-list-item-group
                        v-model="selectedCampaigns"
                        multiple
                        color="primary"
                      >
                        <v-list-item
                          v-for="(campaign, i) in filteredCampaigns"
                          :key="i"
                          :value="campaign.id"
                          :disabled="isUnique(campaign.id)"
                        >
                          <template v-slot:default="{ active }">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ campaign.name }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-checkbox
                                :input-value="active"
                                color="primary"
                              />
                            </v-list-item-action>
                          </template>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </div>
                </div>
              </div>
            </v-menu>
          </div>
        </div>
      </template>

      <template v-slot:header-right-primary>
        <div class="header-actions--primary">
          <v-btn
            :disabled="isMultipleCampaign"
            large
            color="primary"
            class="white--text"
            @click="editCampaign"
          >
            <v-icon left>mdi-folder-edit</v-icon>
            <div class="text">Editar Campanha</div>
          </v-btn>
        </div>
      </template>

      <template v-slot:header-right-secondary>
        <div class="header-actions--secondary">
          <v-tooltip bottom max-width="80px">
            <template v-slot:activator="{ on }">
              <v-btn
                :disabled="isMultipleCampaign"
                @click="requestCampaignMetricsReport"
                class="--secondary"
                icon
                ripple
                v-on="on"
              >
                <v-icon>mdi-chart-box</v-icon>
              </v-btn>
            </template>
            <span>Baixar relatório</span>
          </v-tooltip>

          <v-tooltip bottom max-width="80px">
            <template v-slot:activator="{ on }">
              <v-btn
                :disabled="isMultipleCampaign"
                @click="toggleNewCollaborationDrawer"
                class="--secondary cloud-icon"
                icon
                ripple
                v-on="on"
              >
                <v-badge color="red" dot>
                  <v-icon large>
                    mdi-cloud-upload
                  </v-icon>
                </v-badge>
              </v-btn>
            </template>
            <span>Enviar Colaboração</span>
          </v-tooltip>
        </div>
      </template>
    </HeaderMain>

    <HeaderActions
      :isCollaboration="true"
      :campaignId="campaignId"
      :actionsDisabled="isMultipleCampaign"
    />

    <CollaborationsFiltersDrawer />

    <div
      :class="['main-content', { noScroll: viewMode === 'conversation' }]"
      ref="main"
      v-if="!canShowFeedback && !dataIsLoading"
    >
      <FloatingButtons
        @go-to-top="goToTop"
        @actualize="updateCollaborations"
        :batchActionsOptions="{
          isVisible: !isMultipleCampaignsActive && viewMode !== 'conversation',
          campaignId: campaignId,
          disabled: isMultipleCampaign
        }"
      />

      <PeriodSegmenter
        v-if="!isConversationViewMode"
        :visibleItems="collaborations.length"
        :totalItems="collaborations_count"
        viewTypeText="colaborações"
      />

      <CollaborationTable v-if="isTableViewMode" />
      <CardCollaboration v-else-if="isCardsViewMode" />
      <ConversationView v-else-if="isConversationViewMode" />

      <Observer
        @intersect="fetchNextPage"
        v-if="collaborations.length && viewMode !== 'conversation'"
      />
    </div>

    <Feedback
      feedbackType="campaign-feedback"
      :emptyState="emptyState"
      :isVisible="canShowFeedback"
    />

    <CopyCollaborationDialog
      v-model="copyDialog"
      :collaborationId="collaboration_selected"
    />
    <ResendCollaborationOutputDialog
      v-model="resendDialog"
      :collaborationId="collaboration_selected"
    />
    <div class="loading" v-show="canShowLoader">
      <div class="loading-item">
        <img src="@/assets/images/colaborativo-icon-colors.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import * as R from "ramda";
import { mapGetters, mapState } from "vuex";

import CollaborationsFiltersDrawer from "@/components/AppBars/filters/CollaborationsFiltersDrawer.vue";
import HeaderActions from "@/components/AppBars/HeaderActions";
import HeaderMain from "@/components/AppBars/HeaderMain";
import CardCollaboration from "@/components/collaborations/Cards/CardCollaboration";
import CollaborationTable from "@/components/collaborations/CollaborationTable";
import ConversationView from "@/components/collaborations/ConversationView";
import CopyCollaborationDialog from "@/components/Commons/Dialogs/CopyCollaboration";
import ResendCollaborationOutputDialog from "@/components/Commons/Dialogs/ResendCollaborationOutput";
import GradientTopBackground from "@/components/Commons/GradientTopBackground";
import PeriodSegmenter from "@/components/Commons/PeriodSegmenter.vue";
import Feedback from "@/components/Feedback.vue";
import CampaignFloatingActions from "@/components/FloatingActions/CampaignFloatingActions";
import FloatingButtons from "@/components/FloatingActions/FloatingButtons";
import Observer from "@/components/Observer.vue";
import { CollaborationState } from "@/enums";
import Analytics from "@/services/analytics";
import {
  CAMPAIGN_COLLABORATIONS_COUNT,
  CAMPAIGN_COLLABORATIONS_LOAD,
  CAMPAIGN_COLLABORATIONS_POLL,
  CAMPAIGN_COLLABORATIONS_POLL_CLEANNER,
  CAMPAIGN_COLLABORATIONS_REPORTING,
  CAMPAIGN_GROUPED_CLEAN_COLLABORATIONS,
  CAMPAIGN_GROUPED_CLEAN_COLLABORATORS,
  CAMPAIGN_GROUPED_LOAD_COLLABORATORS,
  CAMPAIGN_LOAD,
  CAMPAIGN_NOTIFICATION_HANDLER,
  CAMPAIGN_NOTIFICATION_START_MONITORING,
  LC_CAMPAIGNS_SET_RAW,
  LC_DATA_LOAD,
  UI_CAMPAIGN_ACTION_DIALOG_HANDLE,
  UI_CREATE_COLLABORATION,
  UI_HANDLE_VIEW_MODE,
  UI_SNACKBAR_SHOW
} from "@/store/actions";
import {
  CAMPAIGN_COLLABORATIONS_COUNT_SET,
  CAMPAIGN_COLLABORATIONS_ERROR_SET,
  CAMPAIGN_COLLABORATIONS_SET,
  CAMPAIGN_UPDATE_SELECTED_GROUPED_ID,
  MT_CAMPAIGN_COLLABORATIONS_FILTER_SET,
  MT_CAMPAIGN_SET_TOTAL_CAMPAIGNS_AVAILABLE,
  UI_CLEAR_NOTIFICATION_INFO
} from "@/store/mutations";

export default {
  components: {
    HeaderMain,
    HeaderActions,
    GradientTopBackground,
    FloatingButtons,
    CampaignFloatingActions,
    CardCollaboration,
    ConversationView,
    CollaborationTable,
    Feedback,
    PeriodSegmenter,
    CollaborationsFiltersDrawer,
    CopyCollaborationDialog,
    ResendCollaborationOutputDialog,
    Observer
  },
  data() {
    return {
      isMultipleCampaign: false,
      selectedCampaigns: [],
      searchCampaignParam: "",
      copyDialog: false,
      resendDialog: false
    };
  },
  created() {
    this.$store.dispatch(CAMPAIGN_NOTIFICATION_START_MONITORING);
    this.$store.dispatch(UI_HANDLE_VIEW_MODE, this.userFavoriteViewMode);
  },
  watch: {
    collaborationDialog: {
      deep: true,
      handler() {
        this.handleDialog();
      }
    },
    copyDialog(value) {
      if (!value) {
        this.closeDialog();
      }
    },
    resendDialog(value) {
      if (!value) {
        this.closeDialog();
      }
    },
    campaign() {
      this.setSelectedCollaboration();
    },
    selectedCampaigns(newValue, oldValue) {
      if (newValue.length < oldValue.length) {
        this.resizeChipsScroll();
      }
      if (oldValue.length && newValue.length) {
        this.handleMultipleCamapaigns();
      }
    },
    hasNewCollaborations(value) {
      if (!value) {
        this.$store.commit(UI_CLEAR_NOTIFICATION_INFO);
      }
    },
    canDispatchNotification(value) {
      if (value) {
        this.$store.dispatch(CAMPAIGN_NOTIFICATION_HANDLER);
      }
    },
    viewMode: {
      async handler(newViewMode, lastViewMode) {
        this.cleanCollaborationsState();
        this.$store.dispatch(CAMPAIGN_COLLABORATIONS_POLL_CLEANNER);
        this.$store.dispatch(CAMPAIGN_COLLABORATIONS_POLL);
        await this.$store.dispatch(LC_DATA_LOAD);
        if (newViewMode !== "conversation") {
          await this.fetchCampaign();
        }
        if (newViewMode === "conversation") {
          this.$gtag.event("UI_COLLABORATIONS_CONVERSATION_VIEW_APPLIED");
          await this.$store.dispatch(CAMPAIGN_LOAD, this.campaignId);
          this.selectedCampaigns = [parseInt(this.campaignId)];
          this.$store.commit(MT_CAMPAIGN_COLLABORATIONS_FILTER_SET, {
            ...this.filters,
            campaign_ids: [this.campaignId]
          });
          this.$store.dispatch(CAMPAIGN_GROUPED_LOAD_COLLABORATORS, {
            loadMoreCollaborators: false
          });
        }
        if (lastViewMode === "conversation") {
          this.cleanCollaboratorsState();
        }
      },
      immediate: true
    }
  },
  beforeDestroy() {
    this.cleanState();
    this.cleanCollaborationsState();
    this.cleanCollaboratorsState();
  },
  computed: {
    ...mapState({
      campaign: state => state.campaign.campaign,
      campaigns: state => state.listCampaign.campaigns,
      collaborationDialog: state => state.ui.collaborationDialog,
      canDispatchNotification: state =>
        state.campaign.notificationInfo.canDispatchNotification,
      collaborations: state => state.campaign.tabs[0].collaborations,
      collaboratorsError: state =>
        state.campaign.collaborationsGroupedByCollaborator.hasError,
      collaboratorsLoading: state =>
        state.campaign.collaborationsGroupedByCollaborator.isLoading,
      collaborations_count: state => state.campaign.tabs[0].collaborationCount,
      filters: state => state.campaign.tabs[0].filters,
      hasNewCollaborations: state => state.campaign.hasNewCollaborations,
      is_collaborations_loading: state => state.campaign.tabs[0].loading,
      collaborations_has_error: state => state.campaign.tabs[0].hasError,
      defaultOptionalMessageFooter: state =>
        state.collaborators.collaboratorSendEmailFields
          .defaultOptionalMessageFooter
    }),
    ...mapGetters(["viewMode", "userFavoriteViewMode"]),
    collaboration_selected() {
      return this.collaborationDialog.collaboration_selected;
    },
    isTableViewMode() {
      return this.viewMode === "table";
    },
    isCardsViewMode() {
      return this.viewMode === "card";
    },
    isConversationViewMode() {
      return this.viewMode === "conversation";
    },
    isMultipleCampaignsActive() {
      return this.selectedCampaigns.length > 1;
    },
    selectedCampaignsObject() {
      return this.campaigns.filter(c => this.selectedCampaigns.includes(c.id));
    },
    campaignId() {
      return this.$route.params.campaignId;
    },
    canShowFeedback() {
      return this.viewMode === "conversation"
        ? this.collaboratorsError
        : this.collaborations_has_error;
    },
    canShowLoader() {
      return this.dataIsLoading && !this.isConversationViewMode;
    },
    dataIsLoading() {
      return this.viewMode === "conversation"
        ? this.collaboratorsLoading
        : this.is_collaborations_loading;
    },
    filteredCampaigns() {
      return this.searchCampaignParam
        ? this.campaigns.filter(campaign =>
            campaign.name
              .toLowerCase()
              .includes(this.searchCampaignParam.toLowerCase())
          )
        : this.campaigns;
    },
    collaborationModerationView() {
      return this.filters.moderationLevel;
    },
    canShowMultipleCampaigns() {
      return this.viewMode !== "conversation";
    },
    emptyState() {
      switch (this.collaborationModerationView) {
        case CollaborationState.FirstModerationLevel:
          return {
            label: "Tão vazio por aqui...",
            description:
              "Nenhuma colaboração aguardando aprovação no primeiro nível. Pode ser que sua campanha não tenha entradas configuradas! Volte para a lista de campanhas e adicione entradas na edição de campanha :)",
            bg: "bored"
          };
        case CollaborationState.SecondModerationLevel:
          return {
            label: "Tão vazio por aqui...",
            description:
              "Nenhuma colaboração aguardando aprovação no segundo nível. Somente colaborações aprovadas no 1º nível vêm para cá :)",
            bg: "bored"
          };
        case CollaborationState.Approved:
          return {
            label: "Tão vazio por aqui...",
            description:
              "Nenhuma colaboração aprovada para exibir! Somente colaborações aprovadas em todos os níveis anteriores vêm para cá!",
            bg: "bored"
          };
        case CollaborationState.Rejected:
          return {
            label: "Tão vazio por aqui...",
            description: "Nenhuma colaboração rejeitada para exibir!",
            bg: "bored"
          };
        case CollaborationState.Favorite:
          return {
            label: "Tão vazio por aqui...",
            description: "Nenhuma colaboração favorita para exibir!",
            bg: "bored"
          };
        default:
          throw `Unknown CollaborationState: ${this.collaborationModerationView}`;
      }
    }
  },
  methods: {
    handleDialog() {
      this[
        this.collaborationDialog.dialog
      ] = this.collaborationDialog.isDialogActive;
    },
    closeDialog() {
      this.$store.dispatch(UI_CAMPAIGN_ACTION_DIALOG_HANDLE, { close: true });
    },
    fetchNextPage() {
      this.$store.dispatch(CAMPAIGN_COLLABORATIONS_LOAD, {
        tryPaginating: true,
        size: 25,
        refresh: false
      });
    },
    handleMultipleCamapaigns() {
      const firstSelectedCampaignId = this.campaigns.find(
        c => c.id === this.selectedCampaigns[0]
      ).id;
      const currentCampaignId = this.campaign.id;

      if (firstSelectedCampaignId !== currentCampaignId) {
        this.$router.replace({
          path: `/campaign_details/${firstSelectedCampaignId}`
        });
        this.fetchCampaign();
      } else {
        this.applyMoreCampaigns();
      }
    },
    selectAllCampaigns(value) {
      value
        ? (this.selectedCampaigns = this.campaigns.map(c => c.id))
        : this.setSelectedCollaboration();
    },
    setSelectedCollaboration() {
      this.selectedCampaigns = [this.campaign.id];
    },
    applyMoreCampaigns() {
      this.$store.commit(MT_CAMPAIGN_COLLABORATIONS_FILTER_SET, {
        ...this.filters,
        campaign_ids: this.selectedCampaigns
      });
      this.$store.dispatch(CAMPAIGN_COLLABORATIONS_LOAD, {
        tryPaginating: false
      });
      this.$store.dispatch(CAMPAIGN_COLLABORATIONS_COUNT, {
        polling: false
      });
    },
    getCampaignName(id) {
      return this.campaigns.find(c => c.id === id)?.name;
    },
    toggleNewCollaborationDrawer() {
      this.$store.dispatch(UI_CREATE_COLLABORATION);
    },
    requestCampaignMetricsReport() {
      this.$store.dispatch(CAMPAIGN_COLLABORATIONS_REPORTING);
      Analytics.generateCampaignMetricsReport(this.campaignId);
    },
    async fetchCampaign() {
      try {
        await this.$store.dispatch(CAMPAIGN_LOAD, this.campaignId);
        if (!R.isEmpty(this.$route.query)) {
          this.$store.commit(MT_CAMPAIGN_COLLABORATIONS_FILTER_SET, {
            ...this.filters,
            ...this.$route.query
          });
        }
        this.$store.dispatch(CAMPAIGN_COLLABORATIONS_LOAD, {
          tryPaginating: false
        });
      } catch (error) {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message:
            "Não foi possível carregar esta campanha. Por favor, tente novamente em alguns minutos."
        });
      }
    },
    editCampaign() {
      this.$router.push(`/campaign_editor/${this.campaignId}`);
    },
    goToTop() {
      this.$refs.main?.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    },
    updateCollaborations() {
      if (this.viewMode !== "conversation") {
        this.$store.dispatch(CAMPAIGN_COLLABORATIONS_LOAD, {
          tryPaginating: false,
          refresh: true,
          updateCollaborations: true
        });
      } else {
        this.$store.dispatch(CAMPAIGN_GROUPED_LOAD_COLLABORATORS, {
          loadMoreCollaborators: false
        });
      }
      this.$nextTick(() => {
        if (this.viewMode !== "conversation") {
          this.goToTop();
        }
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "Atualização feita com sucesso !"
        });
      });
    },
    removeSelectedCampaign(index) {
      this.selectedCampaigns.splice(index, 1);
      this.resizeChipsScroll();
    },
    resizeChipsScroll() {
      if (this.$refs.chipGroup) {
        this.$refs.chipGroup.scrollOffset = 0;
      }
    },
    isUnique(id) {
      return (
        this.selectedCampaigns.some(cId => cId === id) &&
        this.selectedCampaigns.length === 1
      );
    },
    cleanCollaborationsState() {
      this.$store.commit(CAMPAIGN_COLLABORATIONS_SET, []);
      this.$store.commit(CAMPAIGN_COLLABORATIONS_ERROR_SET, false);
    },
    cleanCollaboratorsState() {
      this.$store.dispatch(CAMPAIGN_GROUPED_CLEAN_COLLABORATORS);
      this.$store.dispatch(CAMPAIGN_GROUPED_CLEAN_COLLABORATIONS);
      this.$store.commit(CAMPAIGN_UPDATE_SELECTED_GROUPED_ID, 0);
    },
    cleanState() {
      this.$store.dispatch(UI_HANDLE_VIEW_MODE, "card");
      this.$store.dispatch(LC_CAMPAIGNS_SET_RAW, []);
      this.$store.commit(CAMPAIGN_COLLABORATIONS_COUNT_SET, 0);
      this.$store.commit(MT_CAMPAIGN_SET_TOTAL_CAMPAIGNS_AVAILABLE, 0);
      this.$store.dispatch(CAMPAIGN_COLLABORATIONS_POLL_CLEANNER);
    }
  }
};
</script>

<style lang="scss" scope>
@import "@/scss/theme.scss";
@import "@/scss/toggles.scss";
@import "@/scss/commons.scss";

.main-content.noScroll {
  overflow-y: hidden !important;
}

.v-chip-group {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto !important;
  max-width: 280px !important;
  height: 24px;
  box-sizing: border-box;

  @media only screen and (min-width: 1080px) {
    max-width: 255px !important;
  }

  &.--has-chips {
    border-right: solid 1px $color-border-divisor;
    margin: 0 8px;
    padding: 0 8px 0 0;
  }

  &.v-slide-group--is-overflowing {
    ::v-deep .v-slide-group__prev,
    ::v-deep .v-slide-group__next {
      min-width: 16px !important;
      flex: none !important;

      .v-icon {
        color: rgba($color-black, 0.4) !important;
        will-change: color;
        transition: color 0.4s ease-in-out;
      }

      // Hover
      &:hover {
        .v-icon {
          color: rgba($color-primary, 1) !important;
          will-change: color;
          transition: color 0.4s ease-in-out;
        }
      }

      // Disabled
      &.v-slide-group__prev--disabled,
      &.v-slide-group__next--disabled {
        .v-icon {
          color: rgba($color-black, 0.16) !important;
        }
      }
    }
  }

  .v-chip {
    flex: none;
    margin: 0 4px 0 0;
  }
}

.dropdown.header-filter {
  flex-flow: column;

  .list-item {
    padding: 0 !important;

    .v-input__prepend-inner {
      margin-top: 0 !important;
    }

    .v-item-group {
      flex-direction: column;
    }

    .v-list {
      height: 225px;
      overflow-y: scroll;

      .v-list-item {
        flex: initial;
        padding: 10px 18px !important;

        &:not(:first-child) {
          border-top: 1px solid rgba($color-gray-main, 0.1);
        }

        .v-list-item__title {
          font-size: 0.8rem;
        }
      }
    }
  }
}

.loading {
  position: relative;
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
}

// -----------
</style>
