<template>
  <div class="tableViewType">
    <v-data-table
      v-model="isSelected"
      :headers="getHeader"
      :items="collaborations"
      :options.sync="options"
      :loading="loading"
      item-key="collaboration_id"
      :items-per-page="collaborationPerPage"
      :server-items-length="totalCollabs"
      hide-default-footer
      class="elevation-0 tableCollaboration"
      id="table-collaboration"
    >
      <template v-slot:item="props">
        <tr
          :id="props.index"
          :class="[
            {
              cardsAreOverlayed: collaborationsOverlayed,
              active: rowStatus.active && rowStatus.index === props.index
            },
            densityClass
          ]"
        >
          <td>
            <v-simple-checkbox
              class="select"
              :value="props.isSelected"
              :readonly="props.item.disabled"
              :disabled="props.item.disabled"
              @input="selectItem(props.item, props.isSelected, props.index)"
            ></v-simple-checkbox>
          </td>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <td v-on="on" v-bind="attrs">
                {{ campaignName(props.item.campaign_id) }}
              </td>
            </template>
            <span>{{ campaignName(props.item.campaign_id) }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <td v-on="on" v-bind="attrs">
                <div class="d-flex justify-center">
                  <v-icon
                    v-if="typeIcon(props.item.collaborator.type).type === 0"
                    :color="typeIcon(props.item.collaborator.type).color"
                    >{{ typeIcon(props.item.collaborator.type).icon }}</v-icon
                  >
                  <span
                    v-else
                    v-on="on"
                    v-bind="attrs"
                    :class="typeIcon(props.item.collaborator.type).icon"
                  />
                </div>
              </td>
            </template>
            <span>{{ props.item.collaborator.type }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <td v-on="on" v-bind="attrs">
                <span>{{ props.item.collaborator.name }}</span>
              </td>
            </template>
            <span>{{ props.item.collaborator.name }}</span>
          </v-tooltip>
          <td>
            {{ getIdentifier(props.item.collaborator) }}
          </td>
          <td>
            {{
              props.item.collaborator.city && `${props.item.collaborator.city},`
            }}
            {{
              props.item.collaborator.state &&
                `${props.item.collaborator.state},`
            }}
            {{
              props.item.collaborator.country && props.item.collaborator.country
            }}
          </td>
          <td>{{ getDate(props.item.updated_at) }}</td>
          <td>
            <v-menu :nudge-width="200" offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  :disabled="!props.item.body.message"
                  elevation="0"
                  icon
                  class="action-button"
                  @click.stop
                >
                  <v-icon color="graymain">mdi-message-bulleted</v-icon>
                </v-btn>
              </template>

              <v-card color="primary" max-width="400">
                <v-list>
                  <v-list-item>
                    <v-list-item-avatar>
                      <img
                        :src="
                          props.item.collaborator.profile_image_url || panda
                        "
                        alt=""
                      />
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        props.item.collaborator.name
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        getIdentifier(props.item.collaborator)
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-card-text class="white--text d-flex">
                  <div class="mr-2 pr-2">
                    <v-icon color="secondary">mdi-message-bulleted</v-icon>
                  </div>
                  <div>{{ props.item.body.message }}</div>
                </v-card-text>
              </v-card>
            </v-menu>
          </td>
          <td>
            <v-btn elevation="0" disabled text class="action-button">
              <v-icon color="graymain">mdi-youtube-subscription</v-icon>
              <span class="ml-2 media-text">{{
                getMediasQuantity(props.item.medias)
              }}</span>
            </v-btn>
          </td>
          <!-- <td v-for="(v, i) in metadatas[props.index]" :key="i"> -->
          <td v-for="(v, i) in headers" :key="i">
            {{
              metadatas[props.index]
                ? metadatas[props.index][i]
                  ? metadatas[props.index][i]["value"]
                  : ""
                : ""
            }}
          </td>

          <td class="action">
            <CollaborationActions
              :collaboration="props.item"
              :currentCollaborator="props.item.collaborator"
              :index="props.index"
              ref="actions"
              @handle-active-item="
                rowStatus = { active: $event, index: props.index }
              "
            />
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { Ripple } from "vuetify/lib/directives";
import { mapGetters, mapState } from "vuex";

import panda from "@/assets/images/avatar-panda-01.svg";
import CollaborationActions from "@/components/collaborations/CollaborationActions.vue";
import collaborationMixin from "@/mixins/collaboration";
import collaborationsActions from "@/mixins/collaborations_actions.js";
import {
  CAMPAIGN_COLLABORATION_SELECT,
  CAMPAIGN_COLLABORATIONS_LOAD_TABLE_VIEW
} from "@/store/actions";
import { collaborationPerPage } from "@/util";

const defaultHeader = () => [
  {
    text: "",
    align: "left",
    hidden: false,
    sortable: false,
    value: ""
  },
  {
    text: "Campanha",
    align: "left",
    hidden: true,
    sortable: false,
    value: "campaign"
  },
  {
    text: "Captura",
    align: "center",
    hidden: true,
    sortable: false,
    value: "capture"
  },
  {
    text: "Colaborador",
    align: "left",
    hidden: true,
    sortable: false,
    value: "user"
  },
  {
    text: "Identificador",
    align: "left",
    hidden: true,
    sortable: false,
    value: "id"
  },
  {
    text: "Localização",
    align: "left",
    hidden: true,
    sortable: false,
    value: "locale"
  },
  {
    text: "Data",
    align: "left",
    hidden: true,
    sortable: true,
    value: "updated_at"
  },
  {
    text: "Mensagem",
    align: "left",
    hidden: true,
    sortable: false,
    value: "message"
  },
  {
    text: "Mídia",
    align: "left",
    hidden: true,
    sortable: false,
    value: "thumb"
  },
  {
    text: "Ações",
    align: "left",
    sortable: false,
    hidden: false,
    value: "actions"
  }
];

export default {
  directives: {
    Ripple
  },
  components: {
    CollaborationActions
  },
  mixins: [collaborationMixin, collaborationsActions],
  data() {
    return {
      totalCollabs: 0,
      loading: true,
      options: {},
      metadatas: {},
      headers: [],
      lastPage: false,
      collaborationPerPage: 20,
      panda: panda,
      rowStatus: {
        active: false,
        index: 0
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.getCollabsFromApi();
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(["collaborationSelected"]),
    ...mapState({
      campaigns: state => state.listCampaign.campaigns,
      campaign: state => state.campaign,
      densityLevel: state => state.ui.densityLevel,
      collaborationsOverlayed: state => state.ui.isCollaborationOverlayed
    }),
    densityClass() {
      return `density-level-${this.densityLevel}`;
    },
    collaborations() {
      this.setDefaultHeader();
      const res = this.campaign.tabs[0].collaborations;
      this.getColumnsMetadados(res);
      this.setLastPage(res.length < collaborationPerPage);
      return res;
    },
    getHeader() {
      let h = defaultHeader();
      const lastItem = h.pop();
      h = [...h, ...this.headers];
      h.push(lastItem);
      return h;
    },
    isSelected: {
      get() {
        return Object.values(this.collaborationSelected).map(
          it => it.collaboration
        );
      },
      set() {}
    }
  },
  methods: {
    setLastPage(value) {
      this.lastPage = value;
    },
    getCollabsFromApi() {
      this.loading = true;
      let { sortBy, sortDesc, page } = this.options;
      if (sortDesc.length) {
        sortDesc = sortDesc[0];
      } else {
        sortDesc = true;
      }
      this.totalCollabs = this.campaign.tabs[0].collaborationCount;

      this.$store.dispatch(CAMPAIGN_COLLABORATIONS_LOAD_TABLE_VIEW, {
        sortBy,
        sortDesc,
        page: page,
        viewTable: true
      });

      this.loading = false;
    },
    getIdentifier(collaborator) {
      const { type } = collaborator;
      switch (type) {
        case "whatsapp":
          return collaborator.phone;

        case "twitter":
          return collaborator.tw_user_screen_name;

        case "youtube":
          return collaborator.yt_channel_id || collaborator.yt_username;

        case "instagram":
          return collaborator.insta_username;

        default:
          return collaborator.email;
      }
    },
    getColumnsMetadados(colaboration) {
      this.metadatas = {};

      colaboration.reduce((acc, curr, i) => {
        if (curr.body.metadatas) {
          return curr.body.metadatas.map(meta => {
            if (
              !this.headers.some(
                el => el.text === meta.field && el.data_type === meta.data_type
              )
            ) {
              this.headers.push({
                ...meta,
                text: meta.field,
                sortable: false,
                align: "left",
                hidden: true
              });
            }
            // if (Object.keys(this.metadatas).length > 0) {
            if (this.metadatas[i]) {
              this.metadatas[i].push(meta);
            } else {
              this.metadatas[i] = [{ ...meta }];
            }
          });
        }
        return acc;
      }, {});

      return;
    },
    setDefaultHeader() {
      this.headers = [];
    },
    selectItem(item, status, index) {
      this.$store.dispatch(CAMPAIGN_COLLABORATION_SELECT, {
        toggleValue: !status,
        collaboration: item,
        index: index
      });
    },
    getDate(date) {
      const d = new Date(date * 1000);
      return `${d.toLocaleDateString(
        "pt-br"
      )} - ${d.getHours()}:${(d.getMinutes() < 10 ? "0" : "") +
        d.getMinutes()}`;
    },
    campaignName(id) {
      return this.campaigns.find(campaign => campaign.id === id)?.name;
    },
    getMediasQuantity(medias) {
      let quantity;
      if (medias.length) {
        quantity = medias.length < 10 ? `0${medias.length}` : medias.length;
      } else {
        quantity = "--";
      }
      return quantity;
    },
    typeIcon(type) {
      switch (type) {
        case "youtube":
          return { type: 0, icon: `mdi-${type}`, color: "reprove" };
        case "whatsapp":
          return { type: 0, icon: `mdi-${type}`, color: "approve" };
        case "twitter":
          return { type: 0, icon: `mdi-${type}`, color: "blue" };
        case "instagram":
          return { type: 0, icon: `mdi-${type}`, color: "pink" };
        case "form":
          return { type: 0, icon: "mdi-page-next", color: "purple" };
        case "canalglobo":
          return { type: 0, icon: "mdi-page-next", color: "blue" };

        case "uploader":
          return { type: 1, icon: "v-icon icon-icon-uploader" };

        case "cms":
          return { type: 1, icon: "v-icon icon-icon-colaborativo" };

        default:
          return;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
.tableCollaboration {
  ::v-deep tr {
    &:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.03) !important;
    }
    .mdi:before,
    .mdi-set,
    .v-icon {
      font-size: 20px !important;
    }

    .icon-icon-uploader {
      color: $color-uploader;
    }
    .icon-icon-colaborativo {
      color: $color-primary;
    }
  }
  th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    padding: 0 !important;
    max-width: 30px;
  }
  @for $i from 0 through 2 {
    $heights: 40px, 56px, 72px;
    ::v-deep .density-level-#{$i} {
      height: nth($heights, $i + 1) !important;
    }
  }
  tr {
    transition: all 0.4s ease-in-out;
    ::v-deep &.cardsAreOverlayed:not(.active) {
      opacity: 0.3 !important;
    }
    &.secondary-row {
      background-color: #fff !important;
    }
    &.secondary-row td {
      height: 0;
      line-height: 0;
      visibility: hidden;
    }
    &.secondary-row.visible td {
      height: 78px;
      line-height: 1.62;
      visibility: visible;
    }
    td {
      font-size: 12px !important;

      overflow: hidden;
      padding: 0 12px !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100px;
      min-width: 70px;
      .action-button {
        background-color: transparent !important;
        min-width: 25px;
        padding: 0 10px;
      }
      .media-text {
        color: $color-gray-main !important;
      }
    }
  }
}
.tableViewType {
  outline: none !important;
  align-items: center;

  @include for-size(desktop-up) {
    margin: auto;
    width: calc(100% - 32px);
  }
}
.flex-box {
  span {
    max-width: 60px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
