var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tableViewType"},[_c('v-data-table',{staticClass:"elevation-0 tableCollaboration",attrs:{"headers":_vm.getHeader,"items":_vm.collaborations,"options":_vm.options,"loading":_vm.loading,"item-key":"collaboration_id","items-per-page":_vm.collaborationPerPage,"server-items-length":_vm.totalCollabs,"hide-default-footer":"","id":"table-collaboration"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{class:[
          {
            cardsAreOverlayed: _vm.collaborationsOverlayed,
            active: _vm.rowStatus.active && _vm.rowStatus.index === props.index
          },
          _vm.densityClass
        ],attrs:{"id":props.index}},[_c('td',[_c('v-simple-checkbox',{staticClass:"select",attrs:{"value":props.isSelected,"readonly":props.item.disabled,"disabled":props.item.disabled},on:{"input":function($event){return _vm.selectItem(props.item, props.isSelected, props.index)}}})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('td',_vm._g(_vm._b({},'td',attrs,false),on),[_vm._v(" "+_vm._s(_vm.campaignName(props.item.campaign_id))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.campaignName(props.item.campaign_id)))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('td',_vm._g(_vm._b({},'td',attrs,false),on),[_c('div',{staticClass:"d-flex justify-center"},[(_vm.typeIcon(props.item.collaborator.type).type === 0)?_c('v-icon',{attrs:{"color":_vm.typeIcon(props.item.collaborator.type).color}},[_vm._v(_vm._s(_vm.typeIcon(props.item.collaborator.type).icon))]):_c('span',_vm._g(_vm._b({class:_vm.typeIcon(props.item.collaborator.type).icon},'span',attrs,false),on))],1)])]}}],null,true)},[_c('span',[_vm._v(_vm._s(props.item.collaborator.type))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('td',_vm._g(_vm._b({},'td',attrs,false),on),[_c('span',[_vm._v(_vm._s(props.item.collaborator.name))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(props.item.collaborator.name))])]),_c('td',[_vm._v(" "+_vm._s(_vm.getIdentifier(props.item.collaborator))+" ")]),_c('td',[_vm._v(" "+_vm._s(props.item.collaborator.city && ((props.item.collaborator.city) + ","))+" "+_vm._s(props.item.collaborator.state && ((props.item.collaborator.state) + ","))+" "+_vm._s(props.item.collaborator.country && props.item.collaborator.country)+" ")]),_c('td',[_vm._v(_vm._s(_vm.getDate(props.item.updated_at)))]),_c('td',[_c('v-menu',{attrs:{"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"action-button",attrs:{"disabled":!props.item.body.message,"elevation":"0","icon":""},on:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"graymain"}},[_vm._v("mdi-message-bulleted")])],1)]}}],null,true)},[_c('v-card',{attrs:{"color":"primary","max-width":"400"}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',[_c('img',{attrs:{"src":props.item.collaborator.profile_image_url || _vm.panda,"alt":""}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(props.item.collaborator.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.getIdentifier(props.item.collaborator)))])],1)],1)],1),_c('v-card-text',{staticClass:"white--text d-flex"},[_c('div',{staticClass:"mr-2 pr-2"},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-message-bulleted")])],1),_c('div',[_vm._v(_vm._s(props.item.body.message))])])],1)],1)],1),_c('td',[_c('v-btn',{staticClass:"action-button",attrs:{"elevation":"0","disabled":"","text":""}},[_c('v-icon',{attrs:{"color":"graymain"}},[_vm._v("mdi-youtube-subscription")]),_c('span',{staticClass:"ml-2 media-text"},[_vm._v(_vm._s(_vm.getMediasQuantity(props.item.medias)))])],1)],1),_vm._l((_vm.headers),function(v,i){return _c('td',{key:i},[_vm._v(" "+_vm._s(_vm.metadatas[props.index] ? _vm.metadatas[props.index][i] ? _vm.metadatas[props.index][i]["value"] : "" : "")+" ")])}),_c('td',{staticClass:"action"},[_c('CollaborationActions',{ref:"actions",attrs:{"collaboration":props.item,"currentCollaborator":props.item.collaborator,"index":props.index},on:{"handle-active-item":function($event){_vm.rowStatus = { active: $event, index: props.index }}}})],1)],2)]}}]),model:{value:(_vm.isSelected),callback:function ($$v) {_vm.isSelected=$$v},expression:"isSelected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }