<template>
  <div class="container">
    <!-- Topo -->
    <div class="topo">
      <div class="title">
        <span>{{ isEditMode ? "Editar" : "Registrar" }}</span> Instagram
      </div>
    </div>
    <div class="main">
      <v-form class="form" v-model="formValid">
        <v-alert outlined type="warning" prominent border="left" class="pl-8">
          Recomendamos que não seja utilizada uma conta oficial da Globo, pois
          pode haver indisponibilidade de uso da mesma. Orientamos também que a
          conta utilizada tenha duplo fator de autenticação
        </v-alert>
        <v-stepper v-model="currentStep">
          <v-stepper-header>
            <v-stepper-step
              :complete="currentStep > 1 && !isReauthorizeMode"
              step="1"
            >
              Informações básicas
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="currentStep > 2 && !isEditMode" step="2">
              Autenticação via Facebook
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="currentStep > 3 && !isEditMode" step="3">
              Seleção de página
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="currentStep > 4 && !isReauthorizeMode"
              step="4"
            >
              Seleção de usuário
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="currentStep > 5 && !isReauthorizeMode"
              step="5"
            >
              Seleção de hashtags
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <div class="item margin ml-1 mr-1">
                <v-text-field
                  label="Nome"
                  placeholder="Nome desse usuário do Instagram"
                  primary
                  v-model="instagramUserInfo.name"
                  maxlength="255"
                  counter
                  class="textfield"
                ></v-text-field>
              </div>
              <div class="item margin ml-1 mr-1">
                <v-text-field
                  label="Descrição"
                  placeholder="Fale um pouco sobre o objetivo desse usuário do Instagram"
                  primary
                  v-model="instagramUserInfo.description"
                  maxlength="255"
                  counter
                  class="textfield"
                ></v-text-field>
              </div>
              <div class="item margin ml-1 mr-1 mb-4">
                <v-autocomplete
                  placeholder="Escolha o grupo para relacionar ao usuário do Instagram"
                  primary
                  no-data-text="Não há grupos."
                  class="textfield"
                  v-model="instagramUserInfo.group"
                  required
                  multiple
                  chips
                  draggable
                  deletable-chips
                  :rules="productRules"
                  :items="products"
                  item-value="id"
                  item-text="name"
                >
                  <template slot="label">
                    Nome do grupo
                    <span class="text-red">*</span>
                  </template>
                </v-autocomplete>
              </div>
              <div class="action-btns">
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  :disabled="
                    !instagramUserInfo.name ||
                      !instagramUserInfo.description ||
                      !instagramUserInfo.group.length
                  "
                  @click="handleUserInfoNext()"
                >
                  {{
                    isEditMode
                      ? "Modificar usuários do Instagram"
                      : "Logar pelo Facebook"
                  }}
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2">
              <div class="buttons-only">
                <v-btn
                  color="seccondary"
                  v-if="!isReauthorizeMode"
                  text
                  @click="currentStep -= 1"
                >
                  Voltar
                </v-btn>
                <v-btn
                  color="primary"
                  dark
                  @click="fbLoginClicked()"
                  :loading="!fbSdk.renderLoginButton"
                >
                  <v-icon left>facebook</v-icon>
                  {{
                    isReauthorizeMode
                      ? "Clique para reautenticar"
                      : "Clique para conectar"
                  }}
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="3">
              <div class="option-area">
                <v-autocomplete
                  v-model="selectedPageID"
                  single-line
                  filled
                  clearable
                  rounded
                  placeholder="Selecione uma das suas páginas do Facebook..."
                  label="Selecione uma página..."
                  prepend-icon="facebook"
                  no-data-text="Sem páginas disponíveis. Associe seu instagram Business ou Creator a uma página do Facebook."
                  color="primary"
                  class="textfield"
                  item-text="name"
                  item-value="id"
                  :loading="isSavingData"
                  :items="fbSdk.pagesAvailable"
                ></v-autocomplete>
              </div>
              <div class="action-btns">
                <v-btn text @click="fbLogoutClicked"> Sair do Facebook </v-btn>
                <v-btn
                  v-if="!isReauthorizeMode"
                  color="primary"
                  :disabled="!selectedPageID"
                  :loading="isSavingData"
                  @click="currentStep = 4"
                >
                  Selecionar usuário do Instagram
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="4">
              <div class="option-area">
                <SelectThirdUserInstagram
                  :instagramUsernames="fbSdk.watchedUsernames"
                  :ig_user_id="fbSdk.ig_id"
                  :totalInstagramUsersFollows="totalInstagramUsersFollows"
                  @watchedUsernamesChanged="updateWatchedUsernames"
                />
              </div>

              <div class="action-btns">
                <v-btn
                  color="seccondary"
                  text
                  @click="handleBackButtonInstagramUsers()"
                >
                  Voltar
                </v-btn>
                <v-btn
                  v-if="!isReauthorizeMode"
                  color="primary"
                  :loading="isSavingData"
                  @click="currentStep = 5"
                >
                  Selecionar hashtags
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="5">
              <div class="option-area">
                <SelectHashtags
                  :instagramHashtags="fbSdk.watchedHashtags"
                  :totalInstagramHashtagsFollows="totalInstagramHashtagsFollows"
                  :maximumInstagramFollows="maximumInstagramFollows"
                  :hashtagsCurrentlyActivedOnFacebookContext="
                    hashtagsCurrentlyActivedOnFacebookContext
                  "
                  @watchedHashtagsChanged="updateWatchedHastags"
                />
              </div>
              <div class="action-btns">
                <v-btn color="seccondary" text @click="currentStep -= 1">
                  Voltar
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-form>
    </div>

    <!-- Footer -->
    <div class="footer">
      <div class="slot left"></div>

      <!-- Actions -->
      <div class="slot right">
        <v-btn
          color="seccondary"
          class="mr-4"
          large
          @click="$emit('cancelCreateClickedEvent')"
        >
          <v-icon>mdi-close</v-icon>Cancelar
        </v-btn>
        <v-btn
          large
          color="primary"
          :disabled="!checkSaveEnabled()"
          @click="saveDataHandler()"
          :loading="isSavingData"
        >
          <v-icon>check</v-icon>Salvar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";

import { Product } from "../../../gateways";
import {
  CC_INSTAGRAM_EDIT_INSTAGRAM_USER,
  CC_INSTAGRAM_GET_FACEBOOK_PAGES,
  CC_INSTAGRAM_GET_HASHTAG_CURRENTLY_ACTIVED_ON_FB_CONTEXT,
  CC_INSTAGRAM_POST_INSTAGRAM_USER,
  CC_INSTAGRAM_REAUTHORIZE_ACCOUNT,
  CC_INSTAGRAM_SET_UPDATE_LONG_ACCESS_TOKEN,
  UI_SNACKBAR_SHOW
} from "../../../store/actions";
import SelectHashtags from "./selectHashtags.vue";
import SelectThirdUserInstagram from "./selectThirdUserInstagram.vue";

export default {
  components: { SelectThirdUserInstagram, SelectHashtags },
  directives: {
    mask
  },
  data() {
    return {
      currentStep: 1,
      formValid: false,
      isEditMode: false,
      isReauthorizeMode: false,
      isSavingData: false,
      products: [],
      maximumInstagramFollows: 30,
      instagramUserInfo: {
        id: "",
        name: "",
        description: "",
        group: []
      },
      selectedPageID: 0,
      fbSdk: {
        watchedUsernames: [],
        watchedHashtags: [],
        ig_id: null,
        loaded: false,
        initialized: false,
        userAuthResponse: null,
        renderLoginButton: false,
        instance: null,
        pagesAvailable: [],
        wantedScopes: [
          "public_profile",
          "instagram_basic",
          "instagram_manage_insights",
          "pages_read_engagement",
          "business_management"
        ]
      },
      hashtagsCurrentlyActivedOnFacebookContext: ""
    };
  },
  props: ["editItem", "reauthenticateItem"],
  mounted() {
    if (window.FB) {
      this.fbSdk.loaded = true;
      this.fbSdk.instance = window.FB;
      window.FB.init({
        appId: process.env.VUE_APP_INSTAGRAM_FACEBOOK_APP_ID,
        status: false,
        xfbml: true,
        version: process.env.VUE_APP_INSTAGRAM_GRAPH_API_VERSION
      });
      this.fbSdk.initialized = true;
      this.fbSdk.renderLoginButton = true;
      this.fbSdk.instance.Event.subscribe(
        "auth.authResponseChange",
        authResponse => {
          // console.log("auth.authResponseChange", authResponse);
          this.fbSdk.userAuthResponse = authResponse;
        }
      );
      window.FB.getLoginStatus(authResponse => {
        // console.log("RESPONSE", authResponse);
        this.fbSdk.userAuthResponse = authResponse;
        if (!this.isEditMode) {
          this.fbLogoutClicked();
        }
      }, true);
    }
  },
  created() {
    if (this.editItem) {
      this.isEditMode = true;
      this.populateEditItem(this.editItem);
    }
    if (this.reauthenticateItem) {
      this.isReauthorizeMode = true;
      this.currentStep = 2;
      this.populateEditItem(this.reauthenticateItem);
    }
    this._fetchData();
  },
  computed: {
    productRules() {
      return [product => !![product] || "Este é um campo obrigatório!"];
    },
    totalInstagramFollows() {
      return (
        this.fbSdk.watchedUsernames.length +
        this.fbSdk.watchedHashtags.filter(el => !el.kill).length
      );
    },
    totalInstagramUsersFollows() {
      return this.fbSdk.watchedUsernames.length;
    },
    totalInstagramHashtagsFollows() {
      return this.fbSdk.watchedHashtags.length;
    }
  },
  methods: {
    async _fetchData() {
      const promises = [Product.get()];
      const results = await Promise.all(promises);
      [this.products] = results.map(r => r.data);
    },
    updateWatchedUsernames(newValue) {
      this.fbSdk.watchedUsernames = [...newValue];
    },
    updateWatchedHastags(newValue) {
      this.fbSdk.watchedHashtags = [...newValue];
    },
    async fbLoginClicked() {
      this.fbSdk.renderLoginButton = false;
      this.fbLogin();
      this.fbSdk.renderLoginButton = true;
    },
    fbLogin() {
      this.fbSdk.instance.login(() => {}, {
        return_scopes: true,
        scope: this.fbSdk.wantedScopes.join(",")
      });
    },
    fbLogoutClicked() {
      if (
        this.fbSdk.userAuthResponse.authResponse &&
        this.fbSdk.userAuthResponse.status === "connected"
      ) {
        this.fbSdk.instance.logout();
      }
      this.fbSdk.authResponse = null;
      this.fbSdk.ig_id = null;
      this.fbSdk.pagesAvailable = [];
      this.selectedPageID = null;
      this.$store.dispatch(CC_INSTAGRAM_SET_UPDATE_LONG_ACCESS_TOKEN, null);
      this.currentStep = this.isReauthorizeMode ? 2 : 1;
    },
    handleUserInfoNext() {
      if (this.isEditMode) {
        this.currentStep = 4;
      } else {
        this.currentStep = 2;
      }
    },
    handleBackButtonInstagramUsers() {
      if (this.isEditMode) {
        this.currentStep = 1;
      } else {
        this.currentStep -= 1;
      }
    },
    populateEditItem(incomingData) {
      this.instagramUserInfo = {
        description: incomingData.description,
        name: incomingData.name,
        group: incomingData.group_ids,
        id: incomingData.id
      };
      this.fbSdk.ig_id = incomingData.ig_id;
      this.selectedPageID = incomingData.facebook_page_id;
      this.fbSdk.watchedUsernames = incomingData.public_accounts;
      this.fbSdk.watchedHashtags = incomingData.hashtags;
      this.$store.dispatch(
        CC_INSTAGRAM_SET_UPDATE_LONG_ACCESS_TOKEN,
        incomingData.access_token
      );
    },
    async saveDataHandler() {
      this.isSavingData = true;
      const users_watched_ids = this.fbSdk.watchedUsernames.map(
        el => el.user_id
      );

      let formatted_hashtags = [];
      for (let i = 0; i < this.fbSdk.watchedHashtags.length; i++) {
        let el = { ...this.fbSdk.watchedHashtags[i] };
        if (!el.kill) {
          el.name = el.name.replace("#", "");
          formatted_hashtags.push(el);
        }
      }

      const user_data = {
        ig_user_id: this.fbSdk.ig_id,
        facebook_page_id: this.selectedPageID,
        name: this.instagramUserInfo.name,
        description: this.instagramUserInfo.description,
        users_watched: users_watched_ids,
        hashtags: formatted_hashtags,
        group_allow_ids: this.instagramUserInfo.group
      };
      if (this.isEditMode) {
        user_data.logged_in_id = this.instagramUserInfo.id;
        const data = await this.$store.dispatch(
          CC_INSTAGRAM_EDIT_INSTAGRAM_USER,
          user_data
        );
        if (data && data.status === 200) {
          this.$store.dispatch(UI_SNACKBAR_SHOW, {
            message: "O usuário foi modificado com sucesso!"
          });
          this.$emit("itemEditedEvent");
        }
      } else if (this.isReauthorizeMode) {
        const reauthorizationData = {
          ig_id: this.fbSdk.ig_id,
          facebook_page_id: this.selectedPageID
        };
        const payload = {
          reauthorization_data: reauthorizationData,
          user_id: this.instagramUserInfo.id
        };
        const response = await this.$store.dispatch(
          CC_INSTAGRAM_REAUTHORIZE_ACCOUNT,
          payload
        );
        if (response && response.status === 200) {
          this.$store.dispatch(UI_SNACKBAR_SHOW, {
            message: "O usuário foi reautenticado com sucesso!"
          });
          this.$emit("itemEditedEvent");
        }
      } else {
        const data = await this.$store.dispatch(
          CC_INSTAGRAM_POST_INSTAGRAM_USER,
          user_data
        );
        if (data && data.status === 200) {
          this.$store.dispatch(UI_SNACKBAR_SHOW, {
            message: "O usuário foi registrado com sucesso!"
          });
          this.$emit("itemRegisteredEvent");
        }
      }
      this.isSavingData = false;
    },
    checkSaveEnabled() {
      const editModeSaveable =
        (this.currentStep == 1 ||
          this.currentStep == 4 ||
          this.currentStep == 5) &&
        this.totalInstagramFollows > 0 &&
        !this.isSavingData;
      const reauthorizeModeSaveable =
        this.currentStep == 3 && this.selectedPageID;
      return this.isReauthorizeMode
        ? reauthorizeModeSaveable
        : editModeSaveable;
    },
    updateSelectedPageID() {
      if (this.fbSdk.pagesAvailable.length > 0 && this.selectedPageID) {
        const selectedPage = this.fbSdk.pagesAvailable.filter(
          el => el.id === this.selectedPageID
        );
        if (selectedPage.length > 0) {
          this.fbSdk.ig_id = selectedPage[0].ig_id;
        }
      }
    }
  },
  watch: {
    "fbSdk.userAuthResponse"(authResponse) {
      if (authResponse.status === "connected" && this.currentStep !== 1) {
        this.currentStep = 3;
      }
    },
    async currentStep(val) {
      if (val === 3) {
        this.isSavingData = true;
        const requestToken = this.fbSdk.userAuthResponse.authResponse
          ? this.fbSdk.userAuthResponse.authResponse.accessToken
          : null;
        this.fbSdk.pagesAvailable =
          (await this.$store.dispatch(
            CC_INSTAGRAM_GET_FACEBOOK_PAGES,
            requestToken
          )) || [];
        this.isSavingData = false;
      } else if (val === 5) {
        const hashtags = await this.$store.dispatch(
          CC_INSTAGRAM_GET_HASHTAG_CURRENTLY_ACTIVED_ON_FB_CONTEXT,
          this.fbSdk.ig_id
        );
        this.hashtagsCurrentlyActivedOnFacebookContext = hashtags
          .map(h => `#${h.name}`)
          .join(", ");
      }
    },
    selectedPageID() {
      this.updateSelectedPageID();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";
@import "@/scss/navigation-drawer.scss";
.v-stepper__header {
  flex-wrap: nowrap;
}
.option-area {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  min-height: 100px;
  padding: 8px;
  flex-grow: 2;
}
.action-btns {
  display: flex;
  padding: 8px;
  justify-content: space-between;
}
.buttons-only {
  margin-top: 24px;
  min-height: 100px;
  display: flex;
  padding: 8px;
  justify-content: space-around;
}
</style>
