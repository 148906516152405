<template>
  <v-banner single-line class="new-campaign-ux-banner" v-if="shouldShowBanner">
    <span v-if="isShowingCampaignEditComponent">
      Quero voltar à página inicial e refazer minha escolha.
    </span>
    <span v-else>
      O <span class="highlight">novo fluxo</span> de {{ fluxTitle }} já está
      disponível! Deseja habilitá-lo?
    </span>
    <template v-slot:actions v-if="isShowingCampaignEditComponent">
      <v-btn color="#BDFEC0" elevation="0" text @click="returnOldUX">
        Sim
      </v-btn>
      <v-btn color="#BDFEC0" elevation="0" text @click="hideBanner">
        Não
      </v-btn>
    </template>
    <template v-slot:actions v-else>
      <v-btn color="#BDFEC0" elevation="0" text @click="acceptNewUX">
        Sim
      </v-btn>
      <v-btn color="#BDFEC0" elevation="0" text @click="rejectNewUX">
        Não
      </v-btn>
    </template>
  </v-banner>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import {
  SETTINGS_FLUXUX_ACCEPT,
  SETTINGS_FLUXUX_REJECT,
  SETTINGS_FLUXUX_REMOVE,
  SETTINGS_FLUXUX_RESET,
  SETTINGS_FLUXUX_STARTMONITORING
} from "@/store/actions";
import { activeFlux } from "@/store/store_settings";

export default {
  data() {
    return {
      hideThis: false
    };
  },

  watch: {
    $route() {
      this.hideThis = false;
    }
  },

  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn
    }),
    ...mapGetters(["shouldAllowChangingPreferences", "hasNewFlux"]),
    shouldShowBanner() {
      return (
        !this.hideThis &&
        (this.isShowingCampaignEditComponent ||
          this.shouldAllowChangingPreferences) &&
        this.hasNewFlux &&
        this.newFluxUxIsAvailable &&
        this.isLoggedIn
      );
    },
    newFluxUxIsAvailable() {
      return process.env.VUE_APP_NEW_FLUX_UX_AVAILABLE === "1";
    },
    isShowingCampaignEditComponent() {
      return this.$route.name === "campaignDetails";
    },
    fluxTitle() {
      return activeFlux?.fluxTitle;
    }
  },
  updated() {
    if (process.env.VUE_APP_NEW_FLUX_UX_AVAILABLE === "1") {
      this.$store.dispatch(SETTINGS_FLUXUX_STARTMONITORING);
      this.$store.dispatch(SETTINGS_FLUXUX_REMOVE);
    }
  },
  methods: {
    acceptNewUX() {
      this.$store.dispatch(SETTINGS_FLUXUX_ACCEPT);
      if (activeFlux) {
        this.$gtag.event(`${activeFlux.gtagEventName}_ACCEPT`);
      }
    },

    rejectNewUX() {
      this.$store.dispatch(SETTINGS_FLUXUX_REJECT);
      if (activeFlux) {
        this.$gtag.event(`${activeFlux.gtagEventName}_REJECT`);
      }
    },

    returnOldUX() {
      this.$store.dispatch(SETTINGS_FLUXUX_RESET);
      if (activeFlux) {
        this.$gtag.event(`${activeFlux.gtagEventName}_RESET`);
      }
      this.$router.push("/");
    },

    hideBanner() {
      this.hideThis = true;
      if (activeFlux) {
        this.$gtag.event(`${activeFlux.gtagEventName}_HIDEBANNER`);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.new-campaign-ux-banner {
  background-color: #0cce14 !important;
  display: flex;
  height: 40px;
  z-index: 11;
  .v-banner__text {
    span {
      color: #fff;
      font-family: "Exo 2", sans-serif !important;
      font-style: italic;
      font-size: 14px !important;

      .highlight {
        color: #bdfec0;
        font-weight: bold;
      }
    }
  }
}
</style>
