var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('transition',{attrs:{"name":"component-fade","mode":"out-in"}},[_c(_vm.view,{tag:"component",attrs:{"editItem":_vm.editItemData,"reauthenticateItem":_vm.reauthenticateItemData},on:{"cancelClickedEvent":function($event){_vm.showList = true;
        _vm.editItemData = false;
        _vm.drawer = false;},"createClickedEvent":function($event){_vm.showList = false;
        _vm.editItemData = false;
        _vm.reauthenticateItemData = false;},"editClickedEvent":function (instagram) {
          _vm.showList = false;
          _vm.editItemData = instagram;
          _vm.reauthenticateItemData = false;
        },"reauthenticateClickedEvent":function (instagram) {
          _vm.showList = false;
          _vm.editItemData = false;
          _vm.reauthenticateItemData = instagram;
        },"cancelCreateClickedEvent":function($event){_vm.showList = true;
        _vm.editItemData = false;
        _vm.reauthenticateItemData = false;},"itemRegisteredEvent":function($event){_vm.showList = true;
        _vm.editItemData = false;
        _vm.reauthenticateItemData = false;},"itemEditedEvent":function($event){_vm.showList = true;
        _vm.editItemData = false;
        _vm.reauthenticateItemData = false;}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }