import { countBy, map, pipe, split } from "ramda";

import { CollaborationState } from "../enums";
import { ext2mediatypeMap, mediatype2iconMap } from "../util";

export default {
  data() {
    return { collaboration_: null };
  },
  computed: {
    collaborationState() {
      const c = this.collaboration_;
      if (!c) {
        throw `Unknown collaboration state.`;
      } else if (c.is_rejected) {
        return CollaborationState.Rejected;
      } else if (!c.is_rejected && c.was_approved) {
        return CollaborationState.Approved;
      } else if (!c.is_rejected && c.total_approvals == 0) {
        return CollaborationState.FirstModerationLevel;
      } else if (!c.is_rejected && c.total_approvals == 1) {
        return CollaborationState.SecondModerationLevel;
      } else {
        throw `Unknown collaboration state for ${this.collaboration_}`;
      }
    },
    hasComment() {
      return (
        this.collaboration_.body.comment &&
        this.collaboration_.body.comment !== ""
      );
    },
    hasMedia() {
      return (
        this.collaboration_?.medias && this.collaboration_?.medias.length > 0
      );
    },
    mediaDistribution() {
      if (this.hasMedia) {
        const getMediaType = pipe(
          m => m.name,
          split("."),
          s => s.pop(),
          ext => ext2mediatypeMap[ext.toLowerCase()]
        );
        const mediaDistribution = pipe(
          map(getMediaType),
          countBy(e => e)
        )(this.collaboration_.medias);
        return mediaDistribution;
      } else return null;
    },
    mediaIcons() {
      const mi = {};
      for (let [k, v] of Object.entries(this.mediaDistribution)) {
        mi[mediatype2iconMap[k]] = v;
      }
      return mi;
    },
    hasPreviewableMedia() {
      return this.hasMedia && !!this.mediaDistribution;
    },
    collaborationProfileImage() {
      switch (this.collaboration_["collaborator"]["type"]) {
        case "youtube":
        case "twitter":
        case "whatsapp":
        case "instagram":
        case "form":
        case "canal-globo":
          return this.collaboration_["collaborator"]["profile_image_url"];
        default:
          return null;
      }
    },
    mediaEntryInfo() {
      const { type, type_ex } = this.collaborator;
      const isWavy = type_ex && type_ex === "WAVY";
      switch (type) {
        case "youtube":
          return {
            icon: "mdi-youtube",
            tooltip: "Youtube",
            style: "social youtube v-icon"
          };
        case "twitter":
          return {
            icon: "mdi-twitter",
            tooltip: "Twitter",
            style: "social twitter v-icon"
          };
        case "colaborativo":
        case "cms":
          return {
            icon: "v-icon icon-icon-colaborativo",
            tooltip: "Colaborativo",
            style: "social colaborativo"
          };
        case "whatsapp":
          return {
            icon: "mdi-whatsapp",
            tooltip: `Whatsapp${isWavy ? " Wavy" : ""}`,
            style: `social whatsapp v-icon${isWavy ? " wavy" : ""}`
          };
        case "instagram":
          return {
            icon: "mdi-instagram",
            tooltip: "Instagram",
            style: "social instagram v-icon"
          };
        case "canal-globo":
          return {
            icon: "v-icon icon-icon-canalglobo",
            tooltip: "Canal Globo",
            style: "social canal-globo"
          };
        case "form":
          return {
            icon: "$formIcon",
            tooltip: "Formulário",
            style: "social form v-icon"
          };
        case "uploader":
          return {
            icon: "v-icon icon-icon-uploader",
            tooltip: "Uploader",
            style: "social uploader v-icon"
          };
        default:
          return {
            icon: "help_outline",
            tooltip: "Outros",
            style: "social others v-icon"
          };
      }
    },
    collaborationInputBadgeIcon() {
      return this.mediaEntryInfo.icon;
    },
    collaborationTypeDescription() {
      return this.mediaEntryInfo.tooltip;
    },
    collaborationInputBadgeStyle() {
      return this.mediaEntryInfo.style;
    },
    cardType() {
      return {
        "card-type-media": this.hasPreviewableMedia,
        "card-type-text": !this.hasPreviewableMedia
      };
    },
    collaborator() {
      return this.collaboration_.collaborator || this.currentCollaborator;
    },
    collaboratorHandle() {
      const appendKey = (value, key) => (value ? { key, value } : "");

      const return_val =
        appendKey(this.collaborator.email, "E-mail") ||
        appendKey(this.collaborator.tw_user_screen_name, "Twitter") ||
        appendKey(this.collaborator.phone, "Telefone") ||
        appendKey(this.collaborator.yt_channel_id, "Canal do Youtube") ||
        appendKey(this.collaborator.insta_username, "Username do Instagram");
      return return_val;
    },
    createdAtLocalTimezone() {
      return this.isAnswer
        ? new Date(this.collaboration_.created_at * 1000)
        : new Date(this.collaboration_.created_at);
    },
    collaborationDate() {
      return this.createdAtLocalTimezone.toLocaleDateString("pt-br");
    },
    collaborationTime() {
      return this.createdAtLocalTimezone.toLocaleTimeString("pt-br", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      });
    }
  }
};
