import axios from "axios";
import storejs from "store";

import { EncryptorManager } from "@/util/encryptor";
import { decryptWhatsappConfigurationData } from "@/util/whatsapp";

import store from "../store";
import { AUTH_EXPIRED, UI_SNACKBAR_SHOW } from "../store/actions";
import { isNumber } from "../util";

const makeAxiosClient = (url, timeout = 15000) => {
  const client = axios.create({
    baseURL: url,
    timeout: timeout,
    withCredentials: true
  });
  client.interceptors.response.use(
    response => response,
    error => {
      if (error.message === "Network Error") {
        store.dispatch(UI_SNACKBAR_SHOW, {
          message: `Erro de rede realizando requisição para ${error.config.baseURL}.`
        });
        return Promise.reject("network");
      } else if (error.response && error.response.status === 401) {
        store.dispatch(AUTH_EXPIRED);
        return Promise.reject("expired");
      } else {
        return Promise.reject(error);
      }
    }
  );
  return client;
};
let colaborativoClientV3 = makeAxiosClient(
  JSON.parse(process.env.VUE_APP_COLABORATIVO_API_URI_V3)[
    window.location.origin
  ]
);

let colaborativoXURLScrapper = makeAxiosClient(
  process.env.VUE_APP_COLABORATIVO_XURL_SCRAPPER
);

export const xURLScrapper = endpoint =>
  colaborativoXURLScrapper.get(endpoint, { timeout: 50000 });

const getUserToken = () => {
  const jwt = storejs.get("userToken");
  if (jwt) return { Authorization: `Bearer ${jwt}` };
  else return {};
};

const makeClientForRoute = (route, client) => {
  const routeClient = {
    client,
    route: route,
    register: data => {
      return client.post(`/${route}`, data, {
        headers: { ...getUserToken() }
      });
    },
    get: (id, query_string = "") => {
      if (query_string.length)
        return client.get(`/${route}?${query_string}`, {
          headers: { ...getUserToken() }
        });

      if (id)
        return client.get(`/${route}/${id}`, {
          headers: { ...getUserToken() }
        });
      return client.get(`/${route}`, {
        headers: { ...getUserToken() }
      });
    },
    edit: (id, data) => {
      return client.put(`/${route}/${id}`, data, {
        headers: { ...getUserToken() }
      });
    },
    delete: id => {
      return client.delete(`/${route}/${id}`, {
        headers: { ...getUserToken() }
      });
    }
  };
  return routeClient;
};

const addSubroute = (obj, subroute) => ({
  ...obj,
  [subroute]: {
    register: (id, data) => {
      return obj.client.post(`/${obj.route}/${id}/${subroute}`, data, {
        headers: { ...getUserToken() }
      });
    },
    get: (id, subId = null, queryString = null) => {
      if (id && !subId && queryString) {
        return obj.client.get(
          `/${obj.route}/${id}/${subroute}${
            queryString ? `?${queryString}` : ""
          }`,
          { headers: { ...getUserToken() } }
        );
      }

      if (id && !subId) {
        return obj.client.get(`/${obj.route}/${id}/${subroute}`, {
          headers: { ...getUserToken() }
        });
      }

      if (id && subId) {
        return obj.client.get(`/${obj.route}/${id}/${subroute}/${subId}`, {
          headers: { ...getUserToken() }
        });
      }
    },
    delete: (id, subId) => {
      return obj.client.delete(`/${obj.route}/${id}/${subroute}/${subId}`, {
        headers: { ...getUserToken() }
      });
    }
  }
});

export const loginCatraca = auth =>
  colaborativoClientV3.post("/login", auth, { timeout: 30000 });

export const loginAccountsConnect = code =>
  colaborativoClientV3.post(
    "/login/auth",
    {
      code,
      app: JSON.parse(process.env.VUE_APP_ACCOUNTSCONNECT_REDIRECT_APP)[
        window.location.origin
      ]
    },
    { timeout: 30000 }
  );

export const loginRefresh = () => colaborativoClientV3.post("/login/refresh");

export const loginLogout = () =>
  colaborativoClientV3.post(
    "/login/logout",
    {},
    {
      headers: {
        ...getUserToken()
      }
    }
  );

export const User = makeClientForRoute("users", colaborativoClientV3);
export const Input = makeClientForRoute("inputs", colaborativoClientV3);
export const InputType = makeClientForRoute(
  "input_types",
  colaborativoClientV3
);
export const Media = makeClientForRoute("media", colaborativoClientV3);
export const Product = makeClientForRoute("products", colaborativoClientV3);
export const Output = makeClientForRoute("outputs", colaborativoClientV3);
export const MediaPolicy = makeClientForRoute(
  "mediapolicy",
  colaborativoClientV3
);
export const Whatsapp = makeClientForRoute("whatsapp", colaborativoClientV3);
export const SftpServer = makeClientForRoute("sftp", colaborativoClientV3);

export const Collaboration = makeClientForRoute(
  "collaborations",
  colaborativoClientV3
);
export const Campaign = addSubroute(
  makeClientForRoute("campaigns", colaborativoClientV3),
  "collaborations"
);

export const getAllSftpServers = async () => {
  const sftpServers = await SftpServer.get();
  const decryptedServers = [];
  for (let server of sftpServers.data) {
    server.username = new EncryptorManager().decrypt(server.username);
    server.password = new EncryptorManager().decrypt(server.password);
    decryptedServers.push(server);
  }
  return decryptedServers;
};

export const Poster = {
  get: (id = "") => {
    if (id && isNumber(id))
      return colaborativoClientV3.get(`/posters/${id}`, {
        headers: { ...getUserToken() }
      });
    else
      return colaborativoClientV3.get(`/posters?title=${id}`, {
        headers: { ...getUserToken() }
      });
  }
};

export const UserPreferences = {
  editUserPreferences: preferences => {
    return colaborativoClientV3.put(`/users_preferences`, preferences, {
      headers: { ...getUserToken() }
    });
  }
};

export const Instagram = {
  addUserOnCredential: (instagramCredentialId, username) => {
    return colaborativoClientV3.post(
      `/instagram/instagram_page/${instagramCredentialId}/public_account/${username}`,
      {},
      {
        headers: { ...getUserToken() }
      }
    );
  },
  getFacebookPages: short_access_token => {
    return colaborativoClientV3.get(`/instagram/facebook_page`, {
      headers: { ...getUserToken(), "short-access-token": short_access_token }
    });
  },
  businessDiscovery: (ig_user_id, username, long_access_token) => {
    return colaborativoClientV3.get(
      `/instagram/${ig_user_id}/third_account/${username}`,
      {
        headers: { ...getUserToken(), "long-access-token": long_access_token }
      }
    );
  },
  postInstagramUser: user_data => {
    return colaborativoClientV3.post("/instagram/instagram_page", user_data, {
      headers: { ...getUserToken() }
    });
  },
  editInstagramUser: (user_data, user_id) => {
    return colaborativoClientV3.put(
      `/instagram/instagram_page/${user_id}`,
      user_data,
      {
        headers: { ...getUserToken() }
      }
    );
  },
  deleteInstagramUser: user_id => {
    return colaborativoClientV3.delete(`/instagram/instagram_page/${user_id}`, {
      headers: { ...getUserToken() }
    });
  },
  getInstagramCredentials: () => {
    return colaborativoClientV3.get("/user/me/instagram_pages", {
      headers: { ...getUserToken() }
    });
  },
  getInstagramCredentialsByProduct: productId => {
    return colaborativoClientV3.get(`/products/${productId}/instagram_pages`, {
      headers: { ...getUserToken() }
    });
  },
  reauthorizeAccount: (user_id, reauthorize_data) => {
    return colaborativoClientV3.put(
      `/instagram/instagram_page/${user_id}/reauthorize`,
      reauthorize_data,
      {
        headers: { ...getUserToken() }
      }
    );
  },
  getHashtagCurrentlyActivedOnFacebookContext: (
    ig_user_id,
    long_access_token
  ) => {
    return colaborativoClientV3.get(
      `/instagram/${ig_user_id}/recently_active_hashtag`,
      {
        headers: { ...getUserToken(), "long-access-token": long_access_token }
      }
    );
  }
};

Product.whatsapp = async productId => {
  const products = await Product.client.get(`/products/${productId}/whatsapp`, {
    headers: { ...getUserToken() }
  });
  products.data = decryptWhatsappConfigurationData(products.data);
  return products;
};

Campaign.get = (id, query_string = "", newFilters = true) => {
  if (query_string.length) {
    return Campaign.client.get(
      `/${newFilters ? "campaigns_filter" : "campaigns"}?${query_string}`,
      {
        headers: { ...getUserToken() }
      }
    );
  }
  return Campaign.client.get(`/${Campaign.route}${id ? `/${id}` : ""}`, {
    headers: { ...getUserToken() }
  });
};

Campaign.getCollaborations = filters => {
  return Campaign.client.post("/campaigns/collaborations/many", filters, {
    headers: { ...getUserToken() }
  });
};
Campaign.getCollaborationsGroupByCollaborators = filters => {
  return Campaign.client.post(
    "/campaigns/collaborations/collapsed/many",
    filters,
    {
      headers: { ...getUserToken() }
    }
  );
};

Campaign.collaborations.count = filters => {
  return Campaign.client.post("/campaigns/collaborations/count/many", filters, {
    headers: { ...getUserToken() }
  });
};

Campaign.collaborations.approve = (
  campaignId,
  collaborationId,
  total_approvals,
  is_rejected,
  was_approved
) => {
  return Campaign.client.post(
    `/campaigns/${campaignId}/collaborations/${collaborationId}/approve?total_approvals=${total_approvals}&is_rejected=${is_rejected}&was_approved=${was_approved}`,
    {},
    {
      headers: { ...getUserToken() }
    }
  );
};

Campaign.collaborations.reject = (
  campaignId,
  collaborationId,
  total_approvals,
  is_rejected,
  was_approved
) =>
  Campaign.client.post(
    `/campaigns/${campaignId}/collaborations/${collaborationId}/reject?total_approvals=${total_approvals}&is_rejected=${is_rejected}&was_approved=${was_approved}`,
    {},
    {
      headers: { ...getUserToken() }
    }
  );

Campaign.collaborations.delete = (
  campaignId,
  collaborationId,
  total_approvals,
  is_rejected,
  was_approved
) =>
  Campaign.client.post(
    `/campaigns/${campaignId}/collaborations/${collaborationId}/delete?total_approvals=${total_approvals}&is_rejected=${is_rejected}&was_approved=${was_approved}`,
    {},
    {
      headers: { ...getUserToken() }
    }
  );

Campaign.collaborations.favorite = (
  campaignId,
  collaborationId,
  total_approvals,
  is_rejected,
  was_approved
) =>
  Campaign.client.post(
    `/campaigns/${campaignId}/collaborations/${collaborationId}/favorite?total_approvals=${total_approvals}&is_rejected=${is_rejected}&was_approved=${was_approved}`,
    {},
    {
      headers: { ...getUserToken() }
    }
  );

Campaign.collaborations.recycle = (
  campaignId,
  collaborationId,
  total_approvals,
  is_rejected,
  was_approved
) =>
  Campaign.client.post(
    `/campaigns/${campaignId}/collaborations/${collaborationId}/recycle?total_approvals=${total_approvals}&is_rejected=${is_rejected}&was_approved=${was_approved}`,
    {},
    {
      headers: { ...getUserToken() }
    }
  );

Campaign.validateCollaboration = (campaignId, data) =>
  Campaign.client.post(
    `/campaigns/validate`,
    { ...data, campaign_ids: [Number(campaignId)] },
    {
      headers: { ...getUserToken() }
    }
  );
Campaign.moveCollaborations = (campaignFrom, data) =>
  Campaign.client.post(
    `/campaigns/${campaignFrom}/bulk_move_collaborations`,
    data,
    {
      headers: { ...getUserToken() }
    }
  );

Campaign.bulkDeleteCollaboration = (campaignId, data) =>
  Campaign.client.post(
    `/campaigns/${campaignId}/bulk_delete_collaborations`,
    data,
    {
      headers: { ...getUserToken() }
    }
  );

Campaign.copyCollaboration = (campaignFrom, data) =>
  Campaign.client.post(`/campaigns/${campaignFrom}/copy`, data, {
    headers: { ...getUserToken() }
  });
Campaign.downloadCollaborations = (campaignFrom, data) =>
  Campaign.client.post(
    `/campaigns/${campaignFrom}/bulk_download_collaborations`,
    data,
    {
      headers: { ...getUserToken() }
    }
  );

Campaign.approveCollaborations = (campaignFrom, data) =>
  Campaign.client.post(
    `/campaigns/${campaignFrom}/bulk_approve_collaborations`,
    data,
    {
      headers: { ...getUserToken() }
    }
  );

Campaign.rejectCollaborations = (campaignId, data) =>
  Campaign.client.post(
    `/campaigns/${campaignId}/bulk_reject_collaborations`,
    data,
    {
      headers: { ...getUserToken() }
    }
  );

Campaign.campaignReporting = (campaignFrom, data) =>
  Campaign.client.post(`/campaigns/${campaignFrom}/reporting`, data, {
    headers: { ...getUserToken() }
  });

Campaign.editCollaborations = (campaignId, collaborationId, data) =>
  Campaign.client.post(
    `/campaigns/${campaignId}/collaborations/${collaborationId}/edit`,
    data,
    {
      headers: { ...getUserToken() }
    }
  );

Campaign.resendCollaboration = (collaborationId, data) =>
  Campaign.client.post(
    `/collaboration/${collaborationId}/resend_to_output`,
    data,
    {
      headers: { ...getUserToken() }
    }
  );

Campaign.sendCollaboratorEmail = ({ campaign_id, message, subject, email }) =>
  Campaign.client.post(
    `/campaigns/${campaign_id}/send_email`,
    { message, subject, email },
    {
      headers: { ...getUserToken() }
    }
  );

Campaign.getCollaboratorEmailHistoric = ({ campaign_id, collaborator_id }) =>
  Campaign.client.get(
    `/campaigns/${campaign_id}/sending_historic/${collaborator_id}`,
    {
      headers: { ...getUserToken() }
    }
  );

Campaign.snapshot = {
  delete: campaign_id =>
    Campaign.client.delete(`campaign_snapshots/${campaign_id}`, {
      headers: { ...getUserToken() }
    }),
  getAll: () =>
    Campaign.client.get("campaign_snapshots", {
      headers: { ...getUserToken() }
    }),
  restore: campaign_id =>
    Campaign.client.post(
      `/campaigns/${campaign_id}/restore`,
      {},
      {
        headers: { ...getUserToken() }
      }
    )
};

export const S3 = {
  uploadPresignedPost: (url, fields, file, progressCallback) => {
    const bodyFormData = new FormData();
    Object.keys(fields).forEach(key => {
      bodyFormData.set(key, fields[key]);
    });
    bodyFormData.append("file", file);
    return axios.post(url, bodyFormData, {
      onUploadProgress: progressCallback
    });
  }
};
