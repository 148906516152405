var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card --campaign",on:{"click":function($event){return _vm.goTo(_vm.campaign.id)}}},[_c('div',{staticClass:"card-thumb"},[_c('CardPoster',{staticClass:"left",attrs:{"posterUrl":_vm.src}})],1),_c('div',{staticClass:"card-content",attrs:{"data-cy":"campaign_card"}},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-header-meta"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"180px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"card-header-meta__title"},on),[_vm._v(" "+_vm._s(_vm.campaign.name)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.campaign.name))])]),_c('div',{staticClass:"card-header-meta__product"},[_vm._v(" "+_vm._s(_vm.campaign.product.name)+" ")])],1),_c('div',{staticClass:"card-header-status",class:{ status: true, active: _vm.campaign.is_enabled }},[_c('v-icon',[_vm._v(_vm._s(_vm.campaign.is_enabled ? "mdi-eye-check-outline" : "mdi-eye-minus-outline"))])],1)]),_c('v-tooltip',{attrs:{"bottom":"","max-width":"180px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"card-description"},on),[_vm._v(" "+_vm._s(_vm.campaign.description)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.campaign.description))])]),_c('div',{staticClass:"card-capture"},[_c('div',{staticClass:"card-capture-title"},[_vm._v("Captura")]),_c('div',{staticClass:"card-capture-items"},[_vm._l((_vm.campaignCardCaptureIcons),function(iconInfo,index){return [(_vm.campaignHasThisCapture(iconInfo))?_c('v-tooltip',{key:index,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","fab":"","elevation":"0"}},on),[(iconInfo.icon)?_c('v-icon',[_vm._v(_vm._s(iconInfo.icon))]):(iconInfo.iconClass)?_c('i',{staticClass:"v-icon icon-icon-uploader"}):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(iconInfo.label)+" "+_vm._s(iconInfo.labelComplemnent ? _vm.uploader_label_type : ""))])]):_vm._e()]})],2)]),_c('div',{staticClass:"card-actions"},[_c('div',{staticClass:"card-actions-slot --left"},[_c('div',{staticClass:"card-date"},[_vm._v(" "+_vm._s(_vm.collaborationDateTime(_vm.campaign.created_at).date)+" ")]),_c('div',{staticClass:"card-time"},[_vm._v(" "+_vm._s(_vm.collaborationDateTime(_vm.campaign.created_at).time)+" ")])]),_c('div',{staticClass:"card-actions-slot --right"},[(_vm.campaignUrls.length > 0)?_c('div',{staticClass:"card-actions-btn"},[_c('v-menu',{attrs:{"left":"","open-on-hover":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"data-cy":"campaign_external_actions"}},'v-icon',attrs,false),on),[_vm._v("mdi-link")])]}}],null,false,2652874493),model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}},_vm._l((_vm.campaignUrls),function(campaignUrl,index){return _c('div',{key:index,staticClass:"dropdown --form-link"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"80px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"dropdown-url"},on),[_vm._v(" "+_vm._s(campaignUrl.url)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(campaignUrl.info))])]),_c('div',{staticClass:"dropdown-actions"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"80px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"--back-white",attrs:{"icon":"","ripple":""},on:{"click":function($event){return _vm.copyUrlHandler(campaignUrl.url)}}},on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Copiar link")])]),_c('v-tooltip',{attrs:{"bottom":"","max-width":"80px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"--back-white",attrs:{"icon":"","ripple":""},on:{"click":function($event){return _vm.downloadUrlQrCodeHandler(
                          campaignUrl.url,
                          'imagem.png'
                        )}}},on),[_c('v-icon',[_vm._v("mdi-qrcode")])],1)]}}],null,true)},[_c('span',[_vm._v("Baixar QR code")])]),_c('v-tooltip',{attrs:{"bottom":"","max-width":"80px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"--back-white",attrs:{"icon":"","ripple":""},on:{"click":function($event){return _vm.openUrlHandler(campaignUrl.url)}}},on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("Abrir em nova aba")])])],1)],1)}),0)],1):_vm._e(),_c('div',{staticClass:"card-actions-btn"},[_c('v-menu',{attrs:{"top":"","offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"data-cy":"campaign_card_actions"}},'v-icon',attrs,false),on),[_vm._v(" more_horiz ")])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('div',{staticClass:"dropdown"},[_c('div',{staticClass:"dropdown-footer"},[_c('v-list',_vm._l((_vm.actionCard.filter(
                      function (act) { return act.show; }
                    )),function(item,index){
                    var _obj;
return _c('v-list-item',{key:index,class:( _obj = { divider: item.divider }, _obj[item.class] = true, _obj ),on:{"click":function($event){$event.preventDefault();return item.action(_vm.campaign.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.title)+" ")],1)}),1)],1)])])],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }