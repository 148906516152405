<template>
  <div class="container">
    <!-- Topo -->
    <div class="topo">
      <div class="title"><span>Lista</span> de Usuários</div>
    </div>

    <div class="main">
      <v-data-table
        :fixed-header="true"
        :headers="headers"
        :items="users"
        class="table-main"
        :mobile-breakpoint="1200"
        sort-by="name"
        :search="search"
        :loading="isLoading"
        loading-text="Carregando usuários..."
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" @click="editItem(item)" v-on="on">
                edit
              </v-icon>
            </template>
            <span>Editar usuário</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" @click="deleteItem(item)" v-on="on">
                delete
              </v-icon>
            </template>
            <span>Remover usuário</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
    <div class="footer search">
      <!-- Search -->
      <div class="slot left">
        <v-text-field
          v-model="search"
          solo
          rounded
          clearable
          flat
          hide-details
          label="Pesquise por usuários..."
          append-icon="search"
          color="primary"
          clear-icon="close"
          background-color="#F5F5F5"
        ></v-text-field>
      </div>

      <!-- Actions -->
      <div class="slot right">
        <v-btn large color="seccondary" @click="$emit('cancelClickedEvent')">
          <v-icon>mdi-close</v-icon>
          Cancelar
        </v-btn>

        <v-btn large color="primary" @click="$emit('createClickedEvent')">
          <v-icon>add_circle</v-icon>
          Adicionar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import Bus from "@/util/EventBus";

import { User } from "../../../gateways";
import { UI_SNACKBAR_SHOW } from "../../../store/actions";

export default {
  data: () => ({
    isLoading: false,
    search: "",
    users: [],
    headers: [
      {
        text: "Nome",
        align: "left",
        sortable: true,
        value: "name"
      },
      {
        text: "E-mail",
        align: "left",
        sortable: true,
        value: "email"
      },
      {
        text: "Perfil",
        align: "left",
        sortable: true,
        value: "profile"
      },
      { text: "Ações", value: "action", align: "right", sortable: false }
    ]
  }),
  created() {
    if (this.drawer) this.fetchData();
  },
  computed: {
    ...mapState({ currentUser: state => state.auth.user }),
    ...mapState({ currentTab: state => state.ui.currentTab }),
    ...mapState({ drawer: state => state.ui.drawer })
  },
  watch: {
    drawer() {
      if (this.drawer && this.currentTab === "users") this.fetchData();
    },
    currentTab() {
      if (this.drawer && this.currentTab === "users") this.fetchData();
    }
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      this.users = [];

      try {
        this.users = (await User.get()).data;
        this.users.forEach(user => {
          user.profile = user.is_superuser ? "Admin" : "Usuário";
        });
      } catch {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message:
            "Não foi possível carregar a lista de usuários. Por favor, tente novamente em alguns minutos."
        });
      }

      this.isLoading = false;
    },
    editItem(user) {
      if (this.currentUser.id === user.id) {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "Você não pode editar seu próprio usuário."
        });
      } else {
        this.$emit("editClickedEvent", user);
      }
    },
    async deleteItem(user) {
      if (this.currentUser.id === user.id) {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "Você não pode apagar seu próprio usuário."
        });
        return;
      }

      const index = this.users.indexOf(user);
      const configMessage = {
        icon: "delete_forever",
        title: {
          featured: "Remover",
          text: "Usuário"
        },
        message: {
          featured: "",
          text: `Você tem certeza que deseja apagar este usuário?.`
        },
        action: {
          color: "error",
          title: "Remover"
        }
      };
      Bus.$emit("openModalConfirm", configMessage, async callback => {
        if (callback) {
          try {
            await User.delete(user.id);
            this.users.splice(index, 1);
            this.$store.dispatch(UI_SNACKBAR_SHOW, {
              message: "Usuário apagado com sucesso!"
            });
          } catch (err) {
            const { response } = err;
            switch (response.status) {
              case 400:
                if (response.data.detail === "Users can't delete themselves.")
                  this.$store.dispatch(UI_SNACKBAR_SHOW, {
                    message: "Você não pode apagar seu próprio usuário."
                  });
                else
                  this.$store.dispatch(UI_SNACKBAR_SHOW, {
                    message:
                      "Ops... Tivemos um problema no servidor. Por favor, tente novamente em alguns instantes :)"
                  });
                break;
              case 401:
                this.$store.dispatch(UI_SNACKBAR_SHOW, {
                  message: "Você não possui permissão para executar essa ação."
                });
                break;
              default:
                this.$store.dispatch(UI_SNACKBAR_SHOW, {
                  message:
                    "Ops... Tivemos um problema no servidor. Por favor, tente novamente em alguns instantes :)"
                });
                break;
            }
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "../../../scss/theme.scss";
@import "../../../scss/navigation-drawer.scss";
</style>
