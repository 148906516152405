<template>
  <div class="mediaCarousel">
    <!-- IMAGE EXPANDER -->
    <CoolLightBox
      v-if="exhibitionReady && mediaIsImage"
      :items="lightboxImages"
      :index="index"
      @close="index = null"
    >
    </CoolLightBox>

    <!-- IMAGE DISPLAY -->
    <v-carousel-item
      class="item_img"
      contain
      v-if="mediaIsImage && (!mediaIsInDCCM || exhibitionReady)"
      :src="thumbUrl"
    >
      <div class="actions">
        <DownloadWidget @download="downloadEventHandler" />
        <div class="actions__media-counter">
          <v-chip v-if="videosCounter > 0"
            ><v-icon>mdi-movie</v-icon>{{ videosCounter }}</v-chip
          >
          <v-chip v-if="imagesCounter > 0"
            ><v-icon>mdi-image</v-icon>{{ imagesCounter }}</v-chip
          >
        </div>

        <template v-if="!isCard">
          <v-btn
            text
            icon
            dark
            v-for="(image, imageIndex) in lightboxImages"
            :key="imageIndex"
            @click="index = imageIndex"
          >
            <v-icon>mdi-fullscreen</v-icon>
          </v-btn>
        </template>
      </div>
    </v-carousel-item>

    <!-- VIDEO PLAYER -->
    <v-carousel-item
      class="video-carousel-item"
      ref="videoItem"
      @hook:updated="onVideoItemUpdate"
      v-else-if="mediaIsVideo && (!mediaIsInDCCM || exhibitionReady)"
    >
      <video
        :ref="thumbUrl"
        class="item_display"
        preload="none"
        :poster="thumbUrl"
        :controls="!videoOnlyHasThumbPreview"
        loop
        @play="playVideoHandler(thumbUrl)"
      >
        <source :src="proxyUrl" v-if="!videoOnlyHasThumbPreview" />
      </video>

      <div class="actions">
        <DownloadWidget @download="downloadEventHandler" />
        <div class="actions__media-counter">
          <v-chip v-if="videosCounter > 0"
            ><v-icon>mdi-movie</v-icon>{{ videosCounter }}</v-chip
          >
          <v-chip v-if="imagesCounter > 0"
            ><v-icon>mdi-image</v-icon>{{ imagesCounter }}</v-chip
          >
        </div>
      </div>
      <v-img
        class="video-processing-badge"
        :src="processing_badge"
        width="100"
        title="Vídeo em processamento"
        v-if="videoOnlyHasThumbPreview"
      />
    </v-carousel-item>

    <!-- AUDIO PLAYER -->
    <v-carousel-item
      class="item_display audio"
      v-else-if="mediaIsAudio && exhibitionReady"
    >
      <div class="actions">
        <DownloadWidget @download="downloadEventHandler" />
      </div>

      <div class="audio">
        <AudioPlayer :media="media" @play="playAudioEventHandler" />
      </div>
    </v-carousel-item>

    <!-- EXTERNAL MEDIA -->
    <v-carousel-item
      class="item_img"
      v-else-if="mediaIsExternal"
      :src="media_external"
      eager
    />

    <!-- OTHER PREVIEWABLES -->
    <v-carousel-item
      v-else-if="mediaIsOtherPreviewableFormats"
      :src="dynamicBackground"
      eager
    >
      <div class="actions">
        <DownloadWidget @download="downloadEventHandler" />
      </div>

      <v-row no-gutters class="fix-v-row">
        <template>
          <v-col>
            <v-card class="pa-2 set-transparency-v-card" outlined light raised>
              Nome: {{ media.name }}
            </v-card>
          </v-col>
        </template>
      </v-row>

      <v-row no-gutters>
        <template>
          <v-col>
            <v-card class="pa-2 set-transparency-v-card" outlined light raised>
              Tamanho: {{ fortmatSize }}
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-carousel-item>

    <!-- NOT PROCESSED YET (DEFAULT CASE)-->
    <v-carousel-item class="item_img" v-else :src="processing" eager>
      <div class="actions">
        <DownloadWidget @download="downloadEventHandler" />
      </div>
    </v-carousel-item>
  </div>
</template>

<script>
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

import { debounce } from "debounce";
import CoolLightBox from "vue-cool-lightbox";

import Analytics from "@/services/analytics";
import { COLLABORATION_MEDIA_PLAYER_HANDLER } from "@/store/actions";
import { ext2mediatypeMap } from "@/util";
import { downloadMediaByURI } from "@/util";

import AudioPlayer from "./AudioPlayer.vue";
import DownloadWidget from "./DownloadWidget";

export default {
  components: {
    CoolLightBox,
    DownloadWidget,
    AudioPlayer
  },
  name: "MediaCarouselItem",
  props: ["media", "isCard", "imagesCounter", "videosCounter", "campaignId"],
  data() {
    return {
      index: null,
      processing: require("@/assets/images/placeholder-midia-carregando.svg"),
      processing_badge: require("@/assets/images/panda-midia-processamento.png"),
      media_external: require("@/assets/images/placeholder-midia-externa.svg")
    };
  },
  computed: {
    mediaIsExternal() {
      return this.media.media_type === "external";
    },
    dynamicBackground() {
      return require("@/assets/images/" +
        this.media.name.split(".")[1].toLowerCase() +
        ".png");
    },
    lightboxImages() {
      return this.media.exhibition ? [this.media.exhibition.original] : null;
    },
    thumbUrl() {
      return this.media.exhibition?.thumb || null;
    },
    originalUrl() {
      return this.media.url || this.media.exhibition?.original || null;
    },
    proxyUrl() {
      return (
        this.media.exhibition?.proxy ||
        (this.mediaType === "video"
          ? this.media.exhibition.video_low_res
          : this.media.exhibition.audio_low_res)
      );
    },
    exhibitionReady() {
      if (this.mediaIsInDCCM) {
        return !!this.media.processing_result;
      } else {
        return (
          !this.media.exhibition.check_processing ||
          (!!this.media.exhibition.check_processing &&
            !!this.media.processing_result)
        );
      }
    },
    videoOnlyHasThumbPreview() {
      return this.mediaIsVideo && !this.mediaIsInDCCM && !this.exhibitionReady;
    },
    mediaIsInDCCM() {
      return this.media.sftp;
    },
    mediaIsAudio() {
      return this.mediaType === "audio";
    },
    mediaIsVideo() {
      return this.mediaType === "video";
    },
    mediaIsImage() {
      return this.mediaType === "image";
    },
    mediaIsOtherPreviewableFormats() {
      return this.mediaType === "text" || this.mediaType === "application";
    },
    fortmatSize() {
      const bytes = this.media.media_size;
      const i = Math.floor(Math.log(bytes) / Math.log(1024));
      const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + " " + sizes[i];
    },
    mediaType() {
      if (this.mediaHasAcceptedMediaType) {
        return this.media.processing_result.media_type;
      }

      return ext2mediatypeMap[
        this.media.name
          .split(".")
          .pop()
          .toLowerCase()
      ];
    },
    mediaHasAcceptedMediaType() {
      return (
        this.media.processing_result?.media_type &&
        this.media.processing_result.media_type !== "unknown"
      );
    }
  },
  methods: {
    playVideoHandler(ref) {
      this.$store.dispatch(COLLABORATION_MEDIA_PLAYER_HANDLER, {
        type: "video",
        video: this.$refs[ref]
      });
      Analytics.playCollaborationVideo(this.campaignId);
    },

    playAudioEventHandler() {
      Analytics.playCollaborationAudio(this.campaignId);
    },

    async downloadEventHandler() {
      await downloadMediaByURI(this.originalUrl, this.media.name);
      Analytics.downloadCollaborationMedia(this.campaignId);
    },

    onVideoItemUpdate() {
      if (this.$refs.videoItem.isActive) {
        this.debouncedForceVideoControlLoad();
      }
    },
    debouncedForceVideoControlLoad: debounce(function() {
      this.forceVideoControlLoad();
    }, 10),
    forceVideoControlLoad() {
      const video = this.$refs[this.thumbUrl];
      const timeUntillForcingControlLoad = 600;

      if (video) {
        setTimeout(() => {
          video.controls = true;
        }, timeUntillForcingControlLoad);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "./src/scss/theme.scss";
// dentro do card

.audio {
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  min-width: 100% !important;
  display: block;
  background: red;
}
.audioPLayer {
  width: 100%;
}

// dentro do drawer
.v-navigation-drawer__content {
  #cardAudioElementId {
    width: 100%;
  }

  .mediaCarousel {
    width: 100%;

    .v-window-item,
    .v-window__container {
      width: 100% !important;
    }

    ::v-deep .v-responsive__content {
      width: 100% !important;
      max-width: 100% !important;
      max-height: 100% !important;
    }
  }
}

.actions {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 12px;
  position: absolute;
  top: 0;
  width: -webkit-fill-available;

  .v-btn {
    width: 48px;
    max-width: 48px;
    height: 48px;
    border-radius: 8px;
    margin: 0 0 0 8px;
    background-color: rgba($color-gray-dark, 0.4) !important;
    box-shadow: 0 4px 8px rgba($color-gray-darker, 0.24);
  }
  .downloadButton {
    z-index: 10;
  }

  .v-chip {
    color: #fff !important;
    font-family: "Exo", sans-serif !important;
    font-size: 10px !important;

    background-color: rgba($color-gray-dark, 0.64) !important;
    border-radius: 4px;
    height: 24px !important;
    width: 45px !important;
    &:not(:last-of-type) {
      margin-right: 4px;
    }
    .v-icon {
      color: #fff !important;
      font-size: 14px !important;

      margin-right: 4px;
    }
  }
}
.fix-v-row {
  margin-top: 57%;
}
.set-transparency-v-card {
  background-color: rgba(255, 255, 255, 0.8) !important;
  border-color: white !important;
}
.item_img {
  background-color: $color-gray-darker;
}

.video-carousel-item {
  .v-image {
    position: absolute;
    left: 16px;
    bottom: 8px;
  }
}
.item_display {
  width: 100%;
  height: 300px;
  background-color: $color-gray-darker;
  border-radius: 4px 4px 0 0;
  z-index: 0;

  &.audio {
    position: relative;
    border: none;

    .play-btn {
      position: absolute;
      bottom: 16px;
      left: 50%;
      margin-left: -24px;
      width: 48px;
      height: 48px;
      z-index: 200;

      i {
        font-size: 1.2em;
      }
    }
  }
}
</style>
