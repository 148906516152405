<template>
  <div class="audio-player-root">
    <!-- Hide the default audio player -->
    <div>
      <audio :ref="media.id" class="sourceAudio" preload="metadata">
        <source :src="audio" type="audio/mpeg" />
      </audio>
    </div>

    <div class="player-kit">
      <div class="player-row">
        <div class="actions" @click="toggleAudio()">
          <div v-show="!isPlaying">
            <v-icon>play_arrow</v-icon>
          </div>
          <div v-show="isPlaying">
            <v-icon>pause</v-icon>
          </div>
        </div>

        <div class="progress-bar">
          <!-- <v-progress-linear
            color="primary"
            height="25"
            v-model="playbackTime"
            value="30"
            max="27"
          >
            <template>
              <strong>{{ playbackTime }}</strong>
            </template> 
          </v-progress-linear>-->
          <div class="info-loader">
            <div v-show="!audioLoaded">Loading please wait...</div>

            <div v-show="audioLoaded">
              <span v-html="elapsedTime().time"> 00:00 </span>
              /
              <span v-html="totalTime()"> 00:00 </span>
            </div>
          </div>
          <div class="progressBar">
            <input
              v-model="playbackTime"
              type="range"
              min="0"
              :max="audioDuration"
              id="position"
              name="position"
            />
          </div>

          <!-- Show loading indicator until audio has been loaded -->
        </div>
      </div>
    </div>

    <!-- outer gray border -->
  </div>
  <!-- white bg -->
  <!-- root -->
</template>

<script>
import { COLLABORATION_MEDIA_PLAYER_HANDLER } from "@/store/actions";
export default {
  // props: ["url", "playerid"],
  props: ["media"],
  /**
   * playbackTime = local var that syncs to audio.currentTime
   * audioDuration = duration of audio file in seconds
   * isPlaying = boolean (true if audio is playing)
   *
   **/

  data() {
    return {
      playbackTime: 0,
      audioDuration: 100,
      audioLoaded: false,
      isPlaying: false,
      progress: 0,
      audio: null
    };
  },
  watch: {
    media(val, oldVal) {
      if (val.id !== oldVal.id) {
        this.cleanupListeners();
        this.getLoaderMedia(val);
      }
    }
  },
  methods: {
    //Set the range slider max value equal to audio duration
    getLoaderMedia(media) {
      this.audio = media.exhibition.proxy;
      document.querySelector(".sourceAudio").load();
    },
    initSlider() {
      var audio = this.$refs[this.media.id];
      if (audio) {
        this.audioDuration = Math.round(audio.duration);
      }
    },
    //Convert audio current time from seconds to min:sec display
    convertTime(seconds) {
      const format = val => `0${Math.floor(val)}`.slice(-2);

      var minutes = (seconds % 3600) / 60;

      return [minutes, seconds % 60].map(format).join(":");
    },

    //Show the total duration of audio file
    totalTime() {
      var audio = this.$refs[this.media.id];
      if (audio) {
        var seconds = audio.duration;
        return this.convertTime(seconds);
      } else {
        return "00:00";
      }
    },

    //Display the audio time elapsed so far
    elapsedTime() {
      var audio = this.$refs[this.media.id];
      if (audio) {
        var seconds = audio.currentTime;
        return {
          time: this.convertTime(seconds),
          seconds
        };
      } else {
        return {
          time: "00:00",
          seconds: 0
        };
      }
    },

    //Playback listener function runs every 100ms while audio is playing
    playbackListener() {
      const audio = this.$refs[this.media.id];

      //Sync local 'playbackTime' var to audio.currentTime and update global state
      if (audio?.currentTime) {
        this.playbackTime = audio?.currentTime;
        this.progress = Math.floor(
          (audio.currentTime * 100) / this.audioDuration
        );

        //console.log("update: " + audio.currentTime);

        //Add listeners for audio pause and audio end events
        audio.addEventListener("ended", this.endListener);
        audio.addEventListener("pause", this.pauseListener);
      }
    },

    //Function to run when audio is paused by user
    pauseListener() {
      this.isPlaying = false;
      this.listenerActive = false;
      this.cleanupListeners();
    },
    //Function to run when audio play reaches the end of file
    endListener() {
      this.isPlaying = false;
      this.listenerActive = false;
      this.cleanupListeners();
    },
    //Remove listeners after audio play stops
    cleanupListeners() {
      var audio = this.$refs[this.media.id];
      if (audio) {
        audio.removeEventListener("timeupdate", this.playbackListener);
        audio.removeEventListener("ended", this.endListener);
        audio.removeEventListener("pause", this.pauseListener);
      }

      //console.log("All cleaned up!");
    },
    async toggleAudio() {
      const audio = this.$refs[this.media.id];
      if (!this.isPlaying) {
        await this.$store.dispatch(COLLABORATION_MEDIA_PLAYER_HANDLER, {
          type: "audio",
          audio: audio
        });
      }
      //var audio = document.getElementById("audio-player");
      if (audio.paused) {
        audio.play();
        this.isPlaying = true;
        this.$emit("play");
      } else {
        audio.pause();
        this.isPlaying = false;
      }
    }
  },
  beforeMount() {
    this.audio = this.media.exhibition.proxy;
  },
  mounted() {
    const self = this;
    // nextTick code will run only after the entire view has been rendered
    self.$nextTick(function() {
      const audio = self.$refs[this.media.id];
      //Wait for audio to load, then run initSlider() to get audio duration and set the max value of our slider
      // "loademetadata" Event https://www.w3schools.com/tags/av_event_loadedmetadata.asp
      audio.addEventListener(
        "loadedmetadata",
        function() {
          self.initSlider();
        }.bind(self)
      );

      // "canplay" HTML Event lets us know audio is ready for play https://www.w3schools.com/tags/av_event_canplay.asp
      audio.addEventListener(
        "canplay",
        function() {
          self.audioLoaded = true;
        }.bind(self)
      );

      //Wait for audio to begin play, then start playback listener function
      self.$watch("isPlaying", function() {
        if (self.isPlaying) {
          const audio = self.$refs[this.media.id];
          self.initSlider();
          //console.log("Audio playback started.");

          //prevent starting multiple listeners at the same time
          if (!self.listenerActive) {
            self.listenerActive = true;

            //for a more consistent timeupdate, include freqtimeupdate.js and replace both instances of 'timeupdate' with 'freqtimeupdate'
            audio.addEventListener("timeupdate", self.playbackListener);
          }
        }
      });

      //Update current audio position when user drags progress slider
      self.$watch("playbackTime", function() {
        var diff = Math.abs(
          self.playbackTime - self.$refs[this.media.id].currentTime
        );

        //Throttle synchronization to prevent infinite loop between playback listener and this watcher
        if (diff > 0.01) {
          self.$refs[this.media.id].currentTime = self.playbackTime;
        }
      });
    });
  }
};
</script>

<style lang="scss">
.audio-player-root {
  position: absolute;
  bottom: 8px;
  width: 100%;
  padding: 8px;
}
/* Play/Pause Button */

.player-row {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;

  /* padding: 8px; */
  height: 35px;

  .actions {
    background-color: #f8f8f8;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    opacity: 0.8;

    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    .v-icon {
      color: #3c8795;
    }
  }
  input[type="range"] {
    margin: auto;
    -webkit-appearance: none;
    position: relative;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
    outline: none;
    height: 10px;
  }

  input[type="range"]:focus {
    outline: none;
    width: 100%;
  }

  .progress-bar {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 8px;
  }
  .info-loader {
    z-index: 100;
    width: 100%;
    height: 15px;
    color: #fff;
  }
}

::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.2);
}

/*
 * 1. Set to 0 width and remove border for a slider without a thumb
 */
::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 0; /* 1 */
  height: 20px;
  /* background: #fff; */
  box-shadow: -100vw 0 0 100vw #3c8795;
  border: none; /* 2px solid #999; */
}

::-moz-range-track {
  height: 20px;
  /* background: #ddd; */
}

::-moz-range-thumb {
  /* background: #fff; */
  height: 20px;
  width: 0; /* 20px; */
  border: none; /* 3px solid #999; */
  border-radius: 0 !important;
  box-shadow: -100vw 0 0 100vw #3c8795;
  box-sizing: border-box;
}

::-ms-fill-lower {
  background: #3c8795;
}

::-ms-thumb {
  background: #fff;
  box-sizing: border-box;
}

::-ms-ticks-after {
  display: none;
}

::-ms-ticks-before {
  display: none;
}

::-ms-track {
  background: #ddd;
  color: transparent;
  height: 20px;
  border: none;
}

::-ms-tooltip {
  display: none;
}
</style>
