<template>
  <v-app-bar
    app
    :class="[
      'floating-actions',
      { collaborations: $route.name === 'campaignDetails' }
    ]"
    scroll-target=".main-wrapper"
    width="fit-content"
    height="fit-content"
    :bottom="$vuetify.breakpoint.mdAndDown"
    :hide-on-scroll="$vuetify.breakpoint.mdAndDown"
  >
    <template v-if="isInCampaignView">
      <!-- Visibilidade de Campanha -->
      <div class="floating-actions-slot">
        <v-btn-toggle
          borderless
          mandatory
          v-model="filterModeCurrent"
          class="--vertical"
        >
          <template
            v-for="(filter, index) in availableCampaignFloatingFilters.filters"
          >
            <v-tooltip top max-width="80px" :key="index">
              <template v-slot:activator="{ on }">
                <v-btn
                  :data-cy="filter.value"
                  v-on="on"
                  :value="filter.value"
                  :disabled="
                    filter.value === 'favorite' &&
                      (!userFavoriteCampaigns || !userFavoriteCampaigns.length)
                  "
                >
                  <v-icon>{{ filter.icon }}</v-icon>
                </v-btn>
              </template>
              <span>{{ filter.label }}</span>
            </v-tooltip>
          </template>
        </v-btn-toggle>
      </div>

      <div class="floating-actions-divisor"></div>

      <!-- Ordenação -->
      <div class="floating-actions-slot">
        <v-btn-toggle
          borderless
          mandatory
          v-model="orderByAlphabet"
          class="--vertical"
        >
          <v-tooltip
            top
            max-width="80px"
            v-for="(filter,
            index) in availableCampaignFloatingFilters.orderings"
            :key="index"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :value="filter.value"
                :disabled="filterModeCurrent === 'favorite'"
              >
                <v-icon>{{ filter.icon }}</v-icon>
              </v-btn>
            </template>
            <span>{{ filter.label }}</span>
          </v-tooltip>
        </v-btn-toggle>
      </div>
    </template>

    <template v-else>
      <!-- Visibilidade de Campanha -->
      <div class="floating-actions-slot">
        <v-btn-toggle
          v-if="viewMode !== 'conversation'"
          borderless
          mandatory
          v-model="collaborationModeFilter"
          class="--vertical"
        >
          <v-tooltip
            top
            max-width="80px"
            v-for="(filter,
            index) in availableCollaborationFloatingFilters.filters"
            :key="index"
            v-show="false"
          >
            <template v-slot:activator="{ on }">
              <v-btn v-on="on">
                <v-icon>{{ filter.icon }}</v-icon>
              </v-btn>
            </template>
            <span>{{ filter.label }}</span>
          </v-tooltip>
        </v-btn-toggle>
      </div>

      <div
        class="floating-actions-divisor"
        v-if="viewMode !== 'conversation'"
      ></div>

      <div class="floating-actions-slot">
        <v-menu offset-x transition="slide-x-transition">
          <template v-slot:activator="{ on: onMenu, attrs }">
            <v-tooltip top max-width="80px">
              <template v-slot:activator="{ on: onTooltip }">
                <v-btn
                  v-bind="attrs"
                  v-on="{ ...onMenu, ...onTooltip }"
                  class="v-item--active"
                >
                  <v-icon>{{ exibitionModeActiveInfo.icon }}</v-icon>
                </v-btn>
              </template>
              <span
                >Modo de exibição - {{ exibitionModeActiveInfo.label }}</span
              >
            </v-tooltip>
          </template>
          <v-btn-toggle borderless mandatory v-model="toggle_exibitionMode">
            <v-tooltip
              top
              max-width="80px"
              v-for="(filter,
              index) in availableCollaborationFloatingFilters.viewModes"
              :key="index"
            >
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" :value="filter.value">
                  <v-icon>{{ filter.icon }}</v-icon>
                </v-btn>
              </template>
              <span>{{ filter.label }}</span>
            </v-tooltip>
          </v-btn-toggle>
        </v-menu>
      </div>

      <div class="floating-actions-divisor"></div>

      <div class="floating-actions-slot">
        <v-menu offset-x transition="slide-x-transition">
          <template v-slot:activator="{ on: onMenu, attrs }">
            <v-tooltip top max-width="80px">
              <template v-slot:activator="{ on: onTooltip }">
                <v-btn
                  v-bind="attrs"
                  v-on="{ ...onMenu, ...onTooltip }"
                  class="v-item--active"
                >
                  <v-icon>{{ densityLevelActiveInfo.icon }}</v-icon>
                </v-btn>
              </template>
              <span
                >Nível de densidade - {{ densityLevelActiveInfo.label }}</span
              >
            </v-tooltip>
          </template>

          <v-btn-toggle borderless mandatory v-model="toggle_densityMode">
            <v-tooltip
              bottom
              max-width="80px"
              v-for="(filter,
              index) in availableCollaborationFloatingFilters.densityLevels"
              :key="index"
            >
              <template v-slot:activator="{ on }">
                <v-btn v-on="on">
                  <v-icon>{{ filter.icon }}</v-icon>
                </v-btn>
              </template>
              <span>{{ filter.label }}</span>
            </v-tooltip>
          </v-btn-toggle>
        </v-menu>
      </div>
    </template>
  </v-app-bar>
</template>
<script>
import { mapGetters, mapState } from "vuex";

import {
  LC_CAMPAIGNS_HANDLE_FILTER,
  UI_DENSITY_HANDLE,
  UI_HANDLE_VIEW_MODE
} from "@/store/actions";
import { availableFloatingFilters } from "@/util/filters";

export default {
  name: "CampaignFloatingActions",

  data() {
    return {
      availableCampaignFloatingFilters: availableFloatingFilters.find(
        c => c.type === "campaign"
      ),
      availableCollaborationFloatingFilters: availableFloatingFilters.find(
        c => c.type === "collaborations"
      )
    };
  },
  computed: {
    ...mapState({
      filters: state => state.campaign.tabs[0].filters,
      campaign: state => state.campaign.campaign,
      densityLevel: state => state.ui.densityLevel
    }),
    ...mapGetters(["filter_data", "viewMode", "userFavoriteCampaigns"]),
    exibitionModeActiveInfo() {
      return this.availableCollaborationFloatingFilters.viewModes.find(
        view => view.value === this.viewMode
      );
    },
    densityLevelActiveInfo() {
      return this.availableCollaborationFloatingFilters.densityLevels.find(
        density => density.level === this.densityLevel
      );
    },
    filterModeCurrent: {
      get() {
        return this.filter_data.favorite
          ? "favorite"
          : this.filter_data.is_enabled;
      },
      set(param) {
        if (param === 0) param = null;
        param === "favorite"
          ? this.$store.dispatch(LC_CAMPAIGNS_HANDLE_FILTER, {
              newFilter: {
                ...this.filter_data,
                is_enabled: null,
                size: 99999,
                favorite: true,
                sort_by: null
              }
            })
          : this.$store.dispatch(LC_CAMPAIGNS_HANDLE_FILTER, {
              newFilter: {
                ...this.filter_data,
                is_enabled: param,
                size: 20,
                favorite: false,
                sort_by: "updated_at"
              }
            });
      }
    },
    collaborationModeFilter: {
      get() {
        if (!this.campaign.required_approvals) {
          return 2;
        }
        return this.filters.moderationLevel;
      },
      set(value) {
        this.setCollaborationFilterMode(value);
      }
    },
    isInCampaignView() {
      return this.$route.name === "campaignlist";
    },
    toggle_exibitionMode: {
      get() {
        return this.viewMode;
      },
      set(value) {
        this.$store.dispatch(UI_HANDLE_VIEW_MODE, value);
      }
    },
    toggle_densityMode: {
      get() {
        return this.densityLevel;
      },
      set(value) {
        this.$store.dispatch(UI_DENSITY_HANDLE, value);
      }
    },
    orderByAlphabet: {
      get() {
        return this.filter_data.sort_by === "updated_at" ? 0 : 1;
      },
      set(value) {
        const sort_by = value === 0 ? "updated_at" : "name";
        this.$store.dispatch(LC_CAMPAIGNS_HANDLE_FILTER, {
          newFilter: {
            ...this.filter_data,
            sort_by
          }
        });
      }
    }
  },
  methods: {
    setCollaborationFilterMode(value) {
      this.$store.commit("MT_CAMPAIGN_COLLABORATIONS_FILTER_SET", {
        ...this.filters,
        moderationLevel: value
      });
      this.$store.dispatch("CAMPAIGN_COLLABORATIONS_LOAD", {
        tryPaginating: false
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/floating-actions.scss";
.v-menu__content {
  box-shadow: 3px 0px 5px -1px $color-gray-main !important;
  margin: 0;
  .v-btn {
    height: 52px;
  }
}
.floating-actions-slot {
  .v-btn {
    min-width: 52px !important;
    min-height: 52px !important;
    border-radius: 0 !important;
    background-color: $color-white !important;
    font-size: 1em !important;

    &.v-btn--disabled.v-item--active {
      .v-icon {
        color: rgba($color-black, 0.26) !important;
      }
    }

    &:hover {
      background-color: rgba($color-black, 0.08) !important;

      .v-icon {
        color: rgba($color-black, 0.8) !important;
      }
    }

    &.v-item--active {
      background-color: rgba($color-primary, 0.24) !important;

      .v-icon {
        color: rgba($color-primary, 1) !important;
      }

      // Hover toggle ativo
      &:hover {
        background-color: rgba($color-primary, 0.16) !important;
        will-change: background-color;
        transition: background-color 0.4s ease-in-out;

        .v-icon {
          color: rgba($color-primary, 0.64) !important;
          will-change: color;
          transition: color 0.4s ease-in-out;
        }
      }
    }
  }
}
</style>
