import { mapGetters, mapState } from "vuex";

import Analytics from "@/services/analytics";
import {
  CAMPAIGN_COLLABORATIONS_COUNT,
  CAMPAIGN_COLLABORATIONS_LOAD,
  CAMPAIGN_GROUPED_LOAD_COLLABORATORS
} from "@/store/actions";
import { MT_CAMPAIGN_COLLABORATIONS_FILTER_SET } from "@/store/mutations";
import { collaborationFiltersDefaultState } from "@/util/collaborations";

export default {
  data() {
    return {
      localFilters: collaborationFiltersDefaultState()
    };
  },
  computed: {
    ...mapGetters(["viewMode"]),
    ...mapState({
      filters: state => state.campaign.tabs[0].filters
    }),
    formatedFilters() {
      return {
        inputtype: [
          ...this.localFilters.toggle_captureSocial,
          ...this.localFilters.toggle_captureOthers
        ],
        media_type: [
          ...this.localFilters.toggle_fileFormatMedia,
          ...this.localFilters.toggle_fileFormatOthers
        ],
        initial_date: this.lowerDate,
        initial_time: this.localFilters.initial_time,
        final_date: this.higherDate,
        final_time: this.localFilters.final_time,
        collaborator_city: this.localFilters.collaborator_city,
        collaborator_country: this.localFilters.collaborator_country,
        collaborator_email: this.localFilters.collaborator_email,
        collaborator_name: this.localFilters.collaborator_name,
        collaborator_phone: this.localFilters.collaborator_phone,
        collaborator_state: this.localFilters.collaborator_state,
        collaborator_verified: this.localFilters.collaborator_verified
          ? true
          : null
      };
    },
    higherDate() {
      if (!this.localFilters.datePicker[1]) {
        return null;
      }
      return this.localFilters.datePicker[
        this.isFinalDateHigherThanInitial() ? 1 : 0
      ];
    },
    lowerDate() {
      if (!this.localFilters.datePicker[1]) {
        return this.localFilters.datePicker[0];
      }
      return this.localFilters.datePicker[
        this.isFinalDateHigherThanInitial() ? 0 : 1
      ];
    }
  },
  watch: {
    formatedFilters: {
      handler() {
        this.formatHour();
        this.applyFilter();
      },
      deep: true
    }
  },
  methods: {
    cleanFilters() {
      this.cleanNeeded = true;
      this.localFilters = collaborationFiltersDefaultState();
    },
    applyFilter() {
      Analytics.filterCollaborations(
        this.filters.campaign_ids.length === 1
          ? this.filters.campaign_ids[0]
          : "MANY"
      );
      this.$store.commit(MT_CAMPAIGN_COLLABORATIONS_FILTER_SET, {
        ...this.filters,
        ...this.formatedFilters,
        ...this.$route.query
      });
      if (this.viewMode !== "conversation") {
        this.$store.dispatch(CAMPAIGN_COLLABORATIONS_LOAD, {
          tryPaginating: false
        });
        this.$store.dispatch(CAMPAIGN_COLLABORATIONS_COUNT, {
          polling: false
        });
      } else {
        this.$store.dispatch(CAMPAIGN_GROUPED_LOAD_COLLABORATORS, {
          loadMoreCollaborators: false
        });
      }
    },
    formatHour() {
      if (
        this.formatedFilters.initial_date &&
        !this.localFilters.initial_time
      ) {
        this.localFilters.initial_time = "00:00";
      }
      if (this.formatedFilters.final_date && !this.localFilters.final_time) {
        this.localFilters.final_time = "23:59";
      }
    },
    isFinalDateHigherThanInitial() {
      const initialDate = this.localFilters.datePicker[0]?.split("-");
      const finalDate = this.localFilters.datePicker[1]?.split("-");
      let value = false;
      if (finalDate) {
        for (const [index, date] of finalDate.entries()) {
          value = date > initialDate[index];
        }
      }
      return value;
    }
  }
};
