export const CollaborationState = {
  FirstModerationLevel: 0,
  SecondModerationLevel: 1,
  Approved: 2,
  Rejected: 3,
  Favorite: 4
};

export const CollaborationView = {
  CardMode: 0,
  MosaicMode: 1
};

export const TEXT_FORMATS = ["txt"];
export const IMAGE_FORMATS = ["jpg", "jpeg", "png", "bmp", "gif"];
export const VIDEO_FORMATS = [
  "mp4",
  "ogg",
  "ogv",
  "avi",
  "mxf",
  "mkv",
  "mpeg",
  "mpg",
  "mpe",
  "webm",
  "mov",
  "wmv"
];
export const AUDIO_FORMATS = [
  "mp3",
  "oga",
  "ac3",
  "wav",
  "amr",
  "aac",
  "opus",
  "m4a"
];
export const APPLICATION_FORMATS = ["zip", "pdf"];

export const MEDIA_TYPES = ["AUDIO", "VIDEO"];
export const BITRATE_MODES = ["IGNORAR", "Q", "CQ", "VBR", "CBR"];
export const FRAMERATE_MODES = ["IGNORAR", "CFR", "VFR"];
export const INTERLACING_MODES = [
  "PROGRESSIVE",
  "INTERLACED",
  "INTERLACED_TFF",
  "INTERLACED_BFF"
];
export const COLORSPACE_MODES = [
  "XYZ",
  "YUV",
  "RGB",
  "YUVK",
  "Y",
  "YA",
  "YUVA",
  "CMYK"
];

export const WhatsappModes = { ANBOX: "ANBOX", WAVY: "WAVY" };

export const CollaborationExpirationOptions = {
  Daily: {
    id: "DAILY",
    name: "Um dia"
  },
  Weekly: {
    id: "WEEKLY",
    name: "Uma semana"
  },
  Semimonthly: {
    id: "SEMIMONTHLY",
    name: "Quinze dias"
  },
  Monthly: {
    id: "MONTHLY",
    name: "Um mês"
  },
  Bimonthly: {
    id: "BIMONTHLY",
    name: "Dois meses"
  },
  Semiannually: {
    id: "SEMIANNUALLY",
    name: "Seis meses"
  },
  Annually: {
    id: "ANNUALLY",
    name: "Um ano"
  }
};
