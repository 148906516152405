<template>
  <div class="wrapper">
    <GradientTopBackground />

    <AppBar :leftButton="leftButton" :title="appbarTitle">
      <template v-slot:secondary-actions>
        <ButtonSecondary
          @click-handler="resetForm"
          :label="cleanButton.label"
          :icon="cleanButton.icon"
        />
      </template>
      <template v-slot:primary-actions>
        <ButtonPrimary
          @click-handler="saveDataHandler"
          color="primary"
          :label="publishButton.label"
          :icon="publishButton.icon"
          :disabled="!formValid || isSavingData || formDisabledNoData"
        />
      </template>
    </AppBar>

    <div class="loading" v-show="isEditMode && !formData.name">
      <div class="loading-item">
        <img src="@/assets/images/colaborativo-icon-colors.svg" />
      </div>
    </div>

    <div
      v-show="(isEditMode && formData.name) || !isEditMode"
      class="form-wrapper"
    >
      <v-form class="form" v-model="formValid" ref="form">
        <CampaignCreateBasicInfo :disabled="formDisabledNoData" />

        <CampaignCreateFileFormats :disabled="formDisabledNoData" />

        <CampaignCreateModeration :disabled="formDisabledNoData" />

        <CampaignCreateInputSource
          :disabled="formDisabledNoData"
          :currentCampaignId="campaignEditId"
        />

        <CampaignCreateMetadata
          :disabled="formDisabledNoData"
          @set-current-metadata="setCurrentMetadata"
          @clean-current-metadata="cleanCurrentMetadata"
        />

        <CampaignCreateOutputSource :disabled="formDisabledNoData" />

        <CampaignCreateAdminArea
          :disabled="formDisabledNoData"
          :isEditMode="isEditMode"
        />
      </v-form>
      <!-- drawers -->
      <FileFormatsDrawer />

      <WhatsappDrawer />

      <YoutubeDrawer />

      <TwitterDrawer />

      <InstagramDrawer />

      <FormDrawer :isEditMode="isEditMode" />

      <UploaderDrawer />

      <DestinationsDrawer />

      <MetadataDrawer
        :currentMetadata="currentMetadata"
        @clean-current-metadata="cleanCurrentMetadata"
        :isEditMode="isMetadataEditingMode"
      />
    </div>
  </div>
</template>

<script>
import { omit } from "ramda";
import { mapState } from "vuex";

import AppBar from "@/components/AppBars/AppBar";
import CampaignCreateAdminArea from "@/components/Campaigns/CampaignCreateAdminArea";
import CampaignCreateBasicInfo from "@/components/Campaigns/CampaignCreateBasicInfo";
import DestinationsDrawer from "@/components/Campaigns/CampaignCreateDrawers/DestinationsDrawer";
import FileFormatsDrawer from "@/components/Campaigns/CampaignCreateDrawers/FileFormatsDrawer";
import FormDrawer from "@/components/Campaigns/CampaignCreateDrawers/FormDrawer";
import InstagramDrawer from "@/components/Campaigns/CampaignCreateDrawers/InstagramDrawer";
import MetadataDrawer from "@/components/Campaigns/CampaignCreateDrawers/MetadataDrawer";
import TwitterDrawer from "@/components/Campaigns/CampaignCreateDrawers/TwitterDrawer";
import UploaderDrawer from "@/components/Campaigns/CampaignCreateDrawers/UploaderDrawer";
import WhatsappDrawer from "@/components/Campaigns/CampaignCreateDrawers/WhatsappDrawer";
import YoutubeDrawer from "@/components/Campaigns/CampaignCreateDrawers/YoutubeDrawer";
import CampaignCreateFileFormats from "@/components/Campaigns/CampaignCreateFileFormats";
import CampaignCreateInputSource from "@/components/Campaigns/CampaignCreateInputSource";
import CampaignCreateMetadata from "@/components/Campaigns/CampaignCreateMetadata";
import CampaignCreateModeration from "@/components/Campaigns/CampaignCreateModeration";
import CampaignCreateOutputSource from "@/components/Campaigns/CampaignCreateOutputSource";
import ButtonPrimary from "@/components/Commons/Buttons/ButtonPrimary";
import ButtonSecondary from "@/components/Commons/Buttons/ButtonSecondary";
import GradientTopBackground from "@/components/Commons/GradientTopBackground";
import { Campaign } from "@/gateways";
import Analytics from "@/services/analytics";
import {
  CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_CLEAN_HANDLE,
  CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_HANDLE,
  LC_CAMPAIGNS_PUT,
  LC_CAMPAIGNS_REFRESH,
  LC_CAMPAIGNS_SET_RAW,
  LC_DATA_LOAD,
  UI_SNACKBAR_SHOW
} from "@/store/actions";
import { MT_CAMPAIGN_SET_TOTAL_CAMPAIGNS_AVAILABLE } from "@/store/mutations";
import { defaultMetadata } from "@/util/campaign";

export default {
  components: {
    AppBar,
    GradientTopBackground,
    ButtonPrimary,
    ButtonSecondary,
    CampaignCreateAdminArea,
    CampaignCreateBasicInfo,
    CampaignCreateFileFormats,
    CampaignCreateModeration,
    CampaignCreateInputSource,
    CampaignCreateOutputSource,
    CampaignCreateMetadata,
    FileFormatsDrawer,
    DestinationsDrawer,
    WhatsappDrawer,
    InstagramDrawer,
    YoutubeDrawer,
    MetadataDrawer,
    UploaderDrawer,
    TwitterDrawer,
    FormDrawer
  },
  data() {
    return {
      leftButton: {
        icon: "arrow_back",
        clickHandler: () => {
          this.$router.go("-1");
        }
      },
      publishButton: {
        label: "Salvar",
        icon: "done"
      },
      cleanButton: {
        label: "limpar",
        icon: "mdi-backspace-reverse-outline"
      },
      formValid: false,
      isSavingData: false,
      isLoadingData: false,
      dataLoaded: false,
      isEditMode: false,
      campaignEditId: null,
      firstWhatsappLoad: true,
      currentMetadata: defaultMetadata(),
      isMetadataEditingMode: false,
      isCampaignEnabledOriginally: null
    };
  },
  computed: {
    ...mapState({
      formData: state => state.listCampaign.createOrEditCampaignParams.formData
    }),
    appbarTitle() {
      return `${this.isEditMode ? "editar" : "criar"} campanha`;
    },
    formDisabledNoData() {
      return this.isLoadingData || !this.dataLoaded;
    }
  },
  async created() {
    this.resetForm();
    this.campaignEditId = this.$route.params.campaignId;
    await this.fetchData();
  },
  beforeDestroy() {
    this.$store.dispatch(LC_CAMPAIGNS_SET_RAW, []);
    this.$store.commit(MT_CAMPAIGN_SET_TOTAL_CAMPAIGNS_AVAILABLE, 0);
  },
  methods: {
    resetForm() {
      this.$store.dispatch(CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_CLEAN_HANDLE);
      this.firstWhatsappLoad = true;
      this.$nextTick(() => {
        this.$refs.form.resetValidation();
      });
    },
    setCurrentMetadata(item) {
      this.currentMetadata = item;
      this.isMetadataEditingMode = true;
    },
    cleanCurrentMetadata() {
      this.currentMetadata = defaultMetadata();
      this.isMetadataEditingMode = false;
    },
    async fetchData() {
      this.isEditMode = !!this.campaignEditId;
      if (this.isEditMode) {
        Analytics.editCampaignBegan(this.campaignEditId);
      } else {
        Analytics.createCampaignBegan();
      }
      this.isLoadingData = true;
      this.dataLoaded = false;
      this.firstWhatsappLoad = true;

      try {
        await Promise.all([
          this.$store.dispatch(LC_DATA_LOAD),
          this.$store.dispatch(CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_HANDLE, {
            isEditMode: this.isEditMode,
            campaignEditId: this.campaignEditId
          })
        ]);

        if (this.isEditMode) {
          this.isCampaignEnabledOriginally = this.formData.is_enabled;
        }

        this.dataLoaded = true;
      } catch (err) {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message:
            "Não foi possível carregar os dados para montagem do formulário. Por favor, tente novamente em alguns minutos."
        });
      }
      this.isLoadingData = false;
    },
    async saveDataHandler() {
      this.isSavingData = true;
      const data = omit(["id"], this.formData);

      const success = this.isEditMode
        ? await this.editItemHandler(this.formData.id, data)
        : await this.registerItemHandler(data);

      this.isSavingData = false;
      if (success) {
        if (this.isEditMode) {
          Analytics.editCampaignCompleted(this.formData.id);
          const campaignStatusChanged =
            this.formData.is_enabled !== this.isCampaignEnabledOriginally;
          if (campaignStatusChanged && this.formData.is_enabled) {
            Analytics.enableCampaign(this.formData.id);
          } else if (campaignStatusChanged && !this.formData.is_enabled) {
            Analytics.disableCampaign(this.formData.id);
          }
        } else {
          Analytics.createCampaignCompleted();
        }
        this.$router.push("/");
      }
    },
    async registerItemHandler(data) {
      try {
        const campaign = (await Campaign.register(data)).data;
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: `A campanha "${data.name}" foi registrada com sucesso!`
        });
        this.$store.dispatch(LC_CAMPAIGNS_PUT, campaign);
        return true;
      } catch (error) {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "Erro tentando registrar a campanha :(!"
        });
        return false;
      }
    },
    async editItemHandler(id, data) {
      try {
        await Campaign.edit(id, data);
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: `A campanha "${data.name}" foi editada com sucesso!`
        });
        this.$store.dispatch(LC_CAMPAIGNS_REFRESH, id);
        return true;
      } catch (err) {
        const { response } = err;
        switch (response.status) {
          case 400:
            this.$store.dispatch(UI_SNACKBAR_SHOW, {
              message: response.data.detail
            });
            break;
          default:
            this.$store.dispatch(UI_SNACKBAR_SHOW, {
              message: "Erro tentando editar a campanha :(!"
            });
            break;
        }
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";
@import "@/scss/modal.scss";
@import "@/scss/form-inputs.scss";

.gradient:not(.gradient.hasBanner) + .base-card {
  top: 0;
}
.gradient {
  transition: 0.5s ease-in-out;
}

.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: $color-background;
  min-height: 100vh;
  // overflow: hidden;

  ::v-deep input::-webkit-outer-spin-button,
  ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::v-deep .input-item .v-input .v-input__control .v-text-field__details {
    top: 3px !important;
  }

  > .form-wrapper {
    margin: 105px 10vw 24px;

    @include for-size(tablet-landscape-up) {
      margin: 105px 2vw 24px;
    }
    @include for-size(phone-only) {
      margin: 105px 0 0;
    }
  }

  .modal-content__item {
    .input-item {
      .v-chip {
        margin-right: 4px;
      }
    }
  }
}
</style>
