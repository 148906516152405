var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-wrapper"},[_c('GradientTopBackground'),_c('CampaignFloatingActions'),_c('HeaderMain',{scopedSlots:_vm._u([{key:"header-left-btn",fn:function(){return [_c('div',{staticClass:"header-back-btn"},[_c('router-link',{staticClass:"link",attrs:{"to":"/","primary":""}},[_c('v-btn',{staticClass:"--back-white",attrs:{"icon":"","ripple":""}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)],1)],1)]},proxy:true},{key:"header-left-title",fn:function(){return [_c('div',{staticClass:"header-title"},[_c('div',{staticClass:"title-main --italic"},[_c('span',[_vm._v("Lista")]),_vm._v(" de colaborações ")]),_c('div',{staticClass:"multi-campaign"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-chip-group',{ref:"chipGroup",attrs:{"show-arrows":""}},[_vm._l((_vm.selectedCampaigns),function(campaign,index){return [_c('v-tooltip',{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"label":"","ripple":"","close":_vm.selectedCampaigns.length < 2 ? false : true},on:{"click:close":function($event){return _vm.removeSelectedCampaign(index)}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.selectedCampaigns.length > 1 ? ((_vm.getCampaignName(campaign) .substring(0, 14) .trim()) + "...") : _vm.getCampaignName(campaign))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getCampaignName(campaign)))])])]})],2):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-menu',{attrs:{"min-width":"auto","offset-y":"","left":"","transition":"slide-y-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"label":"","ripple":""}},on),[_vm._v(" "+_vm._s(_vm.selectedCampaigns.length)+" "+_vm._s(_vm.selectedCampaigns.length > 1 ? "campanhas" : "campanha")+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,2144643585)},[_c('div',{staticClass:"dropdown --big header-filter"},[_c('div',{staticClass:"dropdown-header"},[_vm._v(" Campanhas selecionadas ")]),_c('div',{staticClass:"dropdown-content"},[_c('div',{staticClass:"list-item mt-4"},[_c('v-list',[_c('v-list-item-group',{attrs:{"multiple":"","mandatory":"","color":"primary"},model:{value:(_vm.selectedCampaigns),callback:function ($$v) {_vm.selectedCampaigns=$$v},expression:"selectedCampaigns"}},_vm._l((_vm.selectedCampaignsObject),function(campaign,i){return _c('v-list-item',{key:i,attrs:{"value":campaign.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(campaign.name)+" ")])],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1)]}}],null,true)})}),1)],1)],1)])])]):_vm._e(),(_vm.canShowMultipleCampaigns)?_c('v-menu',{attrs:{"min-width":"auto","offset-y":"","left":"","transition":"slide-y-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"label":"","ripple":"","color":""}},on),[_vm._v(" veja mais "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,3134007389)},[_c('div',{staticClass:"dropdown --big header-filter"},[_c('div',{staticClass:"dropdown-header"},[_vm._v(" Selecionar múltiplas campanhas ")]),_c('div',{staticClass:"dropdown-content"},[_c('div',{staticClass:"list-item mt-4"},[_c('v-text-field',{staticClass:"pl-4 pr-4",attrs:{"label":"Pesquise uma campanha","prepend-inner-icon":"mdi-magnify","clearable":""},model:{value:(_vm.searchCampaignParam),callback:function ($$v) {_vm.searchCampaignParam=$$v},expression:"searchCampaignParam"}}),_c('v-checkbox',{staticClass:"ml-4",attrs:{"color":"primary","label":"Selecionar tudo"},on:{"change":_vm.selectAllCampaigns}}),_c('v-list',[_c('v-list-item-group',{attrs:{"multiple":"","color":"primary"},model:{value:(_vm.selectedCampaigns),callback:function ($$v) {_vm.selectedCampaigns=$$v},expression:"selectedCampaigns"}},_vm._l((_vm.filteredCampaigns),function(campaign,i){return _c('v-list-item',{key:i,attrs:{"value":campaign.id,"disabled":_vm.isUnique(campaign.id)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(campaign.name)+" ")])],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1)]}}],null,true)})}),1)],1)],1)])])]):_vm._e()],1)])]},proxy:true},{key:"header-right-primary",fn:function(){return [_c('div',{staticClass:"header-actions--primary"},[_c('v-btn',{staticClass:"white--text",attrs:{"disabled":_vm.isMultipleCampaign,"large":"","color":"primary"},on:{"click":_vm.editCampaign}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-folder-edit")]),_c('div',{staticClass:"text"},[_vm._v("Editar Campanha")])],1)],1)]},proxy:true},{key:"header-right-secondary",fn:function(){return [_c('div',{staticClass:"header-actions--secondary"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"80px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"--secondary",attrs:{"disabled":_vm.isMultipleCampaign,"icon":"","ripple":""},on:{"click":_vm.requestCampaignMetricsReport}},on),[_c('v-icon',[_vm._v("mdi-chart-box")])],1)]}}])},[_c('span',[_vm._v("Baixar relatório")])]),_c('v-tooltip',{attrs:{"bottom":"","max-width":"80px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"--secondary cloud-icon",attrs:{"disabled":_vm.isMultipleCampaign,"icon":"","ripple":""},on:{"click":_vm.toggleNewCollaborationDrawer}},on),[_c('v-badge',{attrs:{"color":"red","dot":""}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-cloud-upload ")])],1)],1)]}}])},[_c('span',[_vm._v("Enviar Colaboração")])])],1)]},proxy:true}])}),_c('HeaderActions',{attrs:{"isCollaboration":true,"campaignId":_vm.campaignId,"actionsDisabled":_vm.isMultipleCampaign}}),_c('CollaborationsFiltersDrawer'),(!_vm.canShowFeedback && !_vm.dataIsLoading)?_c('div',{ref:"main",class:['main-content', { noScroll: _vm.viewMode === 'conversation' }]},[_c('FloatingButtons',{attrs:{"batchActionsOptions":{
        isVisible: !_vm.isMultipleCampaignsActive && _vm.viewMode !== 'conversation',
        campaignId: _vm.campaignId,
        disabled: _vm.isMultipleCampaign
      }},on:{"go-to-top":_vm.goToTop,"actualize":_vm.updateCollaborations}}),(!_vm.isConversationViewMode)?_c('PeriodSegmenter',{attrs:{"visibleItems":_vm.collaborations.length,"totalItems":_vm.collaborations_count,"viewTypeText":"colaborações"}}):_vm._e(),(_vm.isTableViewMode)?_c('CollaborationTable'):(_vm.isCardsViewMode)?_c('CardCollaboration'):(_vm.isConversationViewMode)?_c('ConversationView'):_vm._e(),(_vm.collaborations.length && _vm.viewMode !== 'conversation')?_c('Observer',{on:{"intersect":_vm.fetchNextPage}}):_vm._e()],1):_vm._e(),_c('Feedback',{attrs:{"feedbackType":"campaign-feedback","emptyState":_vm.emptyState,"isVisible":_vm.canShowFeedback}}),_c('CopyCollaborationDialog',{attrs:{"collaborationId":_vm.collaboration_selected},model:{value:(_vm.copyDialog),callback:function ($$v) {_vm.copyDialog=$$v},expression:"copyDialog"}}),_c('ResendCollaborationOutputDialog',{attrs:{"collaborationId":_vm.collaboration_selected},model:{value:(_vm.resendDialog),callback:function ($$v) {_vm.resendDialog=$$v},expression:"resendDialog"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.canShowLoader),expression:"canShowLoader"}],staticClass:"loading"},[_vm._m(0)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loading-item"},[_c('img',{attrs:{"src":require("@/assets/images/colaborativo-icon-colors.svg")}})])}]

export { render, staticRenderFns }