<template>
  <v-menu
    v-model="isMenuVisible"
    offset-y
    transition="slide-y-transition"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-chip label ripple v-on="on" :color="filterColor">
        {{ chipTitle }}
        <v-icon right>mdi-chevron-down</v-icon>
      </v-chip>
    </template>

    <div class="dropdown --small">
      <div class="dropdown-header">Escolha uma ou mais opções</div>
      <div class="dropdown-content">
        <div class="list-item">
          <div class="list-item__title">{{ title }}</div>
          <div class="list-item__toggle">
            <v-btn-toggle borderless multiple v-model="selectedItemsControl">
              <v-tooltip
                bottom
                max-width="80px"
                v-for="({ value, name, icon }, i) in items"
                :key="i"
              >
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" :value="value">
                    <v-icon>{{ icon }}</v-icon>
                  </v-btn>
                </template>
                <span> {{ name }}</span>
              </v-tooltip>
            </v-btn-toggle>
          </div>
        </div>

        <div class="list-item">
          <div class="list-item__title">Outros</div>

          <div class="list-item__toggle">
            <v-btn-toggle
              borderless
              multiple
              v-model="subBoxSelectedItemsControl"
            >
              <v-tooltip
                bottom
                max-width="80px"
                v-for="({ value, name, icon, classIcon }, i) in subBoxItems"
                :key="i"
              >
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" :value="value">
                    <v-icon v-if="icon">{{ icon }}</v-icon>
                    <span v-else :class="classIcon" />
                  </v-btn>
                </template>
                <span> {{ name }}</span>
              </v-tooltip>
            </v-btn-toggle>
          </div>
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
export default {
  name: "FilterBox",
  props: {
    filterColor: String,
    chipTitle: String,
    title: String,
    items: Array,
    subBoxItems: Array,
    selectedItems: Array,
    subBoxSelectedItems: Array
  },
  data() {
    return {
      isMenuVisible: false
    };
  },
  computed: {
    selectedItemsControl: {
      get() {
        return this.selectedItems;
      },
      set(value) {
        this.$emit("update", value);
      }
    },
    subBoxSelectedItemsControl: {
      get() {
        return this.subBoxSelectedItems;
      },
      set(value) {
        this.$emit("update-sub-box", value);
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "@/scss/headers.scss";

.modal-content__item {
  width: fit-content;
}
</style>
