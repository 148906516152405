<template>
  <v-navigation-drawer
    v-model="drawer"
    fixed
    temporary
    right
    width="50%"
    overlay-opacity="0.88"
  >
    <div class="container">
      <!-- Topo -->
      <div class="topo">
        <div class="title"><span>Enviar</span> colaboração</div>
      </div>
      <div class="main">
        <v-tabs>
          <v-tab> Manual </v-tab
          ><v-tab
            >URL
            <v-badge color="red" dot></v-badge>
          </v-tab>
          <v-tab-item>
            <v-form class="form" v-model="formValid" ref="form">
              <div class="item">
                <v-text-field
                  label="Título"
                  placeholder="Informe o título da colaboração"
                  counter
                  primary
                  v-model="formData.title"
                  maxlength="100"
                  required
                  class="textfield"
                />
              </div>

              <div class="item margin">
                <v-textarea
                  label="Mensagem"
                  placeholder="Informe a mensagem da colaboração"
                  counter
                  primary
                  v-model="formData.message"
                  maxlength="255"
                  required
                  class="textfield"
                />
              </div>

              <div class="item margin">
                <v-subheader class="pl-0 label">Adicionar Mídia</v-subheader>
                <v-file-input
                  v-model="formData.files"
                  label="Escolha arquivos para enviar..."
                  :loading="isSavingData"
                  single-line
                  rounded
                  filled
                  clearable
                  multiple
                  chips
                  deletable-chips
                  draggable
                  counter
                  show-size
                  prepend-icon="attach_file"
                  color="primary"
                ></v-file-input>
              </div>
            </v-form>
            <div class="footer">
              <div class="slot left">
                <v-btn large color="secondary" @click="drawer = false">
                  <v-icon>close</v-icon>Cancelar
                </v-btn>
              </div>

              <!-- Actions -->
              <div class="slot right">
                <v-btn
                  large
                  color="secondary"
                  @click="resetForm"
                  :disabled="isSavingData"
                >
                  <v-icon>backspace</v-icon>Limpar
                </v-btn>

                <v-btn
                  large
                  color="primary"
                  @click="uploadClicked(false, null)"
                  :disabled="!formValid || isSavingData"
                  :loading="isSavingData"
                >
                  <v-icon>mdi-upload</v-icon>Enviar
                </v-btn>
              </div>
            </div>
          </v-tab-item>
          <v-tab-item>
            <v-form class="form" v-model="xURLFormValid">
              <v-subheader class="pl-0 label"
                >Neste momento não estamos capturando vídeo</v-subheader
              >
              <div class="item">
                <v-text-field
                  label="X(Twitter) URL"
                  placeholder="Informe uma url de um tweet"
                  primary
                  v-model="xurl"
                  maxlength="100"
                  required
                  :rules="xrules"
                  class="textfield"
                />
              </div>
            </v-form>

            <div class="footer">
              <div class="slot left">
                <v-btn
                  large
                  color="secondary"
                  @click="
                    drawer = false;
                    xurl = '';
                  "
                >
                  <v-icon>close</v-icon>Cancelar
                </v-btn>
              </div>

              <div class="slot right">
                <v-btn
                  large
                  color="primary"
                  @click="sendXURL"
                  :disabled="!xURLFormValid"
                  :load="isSavingData"
                >
                  <v-icon>mdi-upload</v-icon>Enviar
                </v-btn>
              </div>
            </div>
          </v-tab-item>
        </v-tabs>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { mapState } from "vuex";

import Analytics from "@/services/analytics";

import { Campaign, Media, S3, xURLScrapper } from "../gateways";
import navdrawerSnackbarFixMixin from "../mixins/navdrawerSnackbarFix";
import { UI_SNACKBAR_HIDE, UI_SNACKBAR_SHOW } from "../store/actions";
import { UI_COLLABORATION_CREATION_DRAWER_SET } from "../store/mutations";
import { getFileExtension } from "../util";

export const defaultFormData = () => ({
  title: null,
  body: null,
  collaborator: {},
  files: [],
  inputtype_id: null
});

export default {
  mixins: [navdrawerSnackbarFixMixin],
  data() {
    return {
      formValid: true,
      isSavingData: false,
      formData: defaultFormData(),
      xURLFormValid: true,
      xurl: "",
      xrules: [
        v => !!v || "Campo obrigatório",
        v => v.startsWith("https://x.com/") || "Informe uma URL do X"
      ],
      progress: []
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.auth.user
    }),
    campaignId: {
      get() {
        return this.$route.params.campaignId;
      }
    },
    drawer: {
      get() {
        return this.$store.state.ui.collaborationCreationDrawer;
      },
      set(value) {
        this.$store.commit(UI_COLLABORATION_CREATION_DRAWER_SET, value);
      }
    }
  },
  methods: {
    resetForm() {
      this.formData = defaultFormData();
      this.$refs.form.resetValidation();
    },
    async sendXURL() {
      this.isSavingData = true;
      try {
        const scrap = await xURLScrapper(`/tweet?url=${this.xurl}`);
        this.makeXURLCollaboration(scrap.data);
        this.uploadClicked(true, scrap.data);
        this.xURLFormValid = true;
      } catch {
        this.xURLFormValid = false;
      } finally {
        this.xurl = "";
      }
    },
    makeXURLCollaboration(data) {
      const _medias = data.base64Images.postMedia;
      const medias = _medias.length
        ? _medias.map(item => this.base64ToFile(item))
        : [];

      this.formData.title = "";
      this.formData.message = data.text;
      this.formData.files = medias;
    },
    base64ToFile(base64String) {
      if (!base64String.startsWith("data:")) {
        base64String = "data:image/jpeg;base64," + base64String;
      }
      const fileName = uuidv4();
      const [header, data] = base64String.split(",");

      const mimeType = header.match(/:(.*?);/)[1];
      const binaryString = atob(data);

      const byteNumbers = new Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        byteNumbers[i] = binaryString.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      const blob = new Blob([byteArray], { type: mimeType });
      const fileExtension = mimeType.split("/")[1];
      const fileNameWithExtension = `${fileName}.${fileExtension}`;

      return new File([blob], fileNameWithExtension, { type: mimeType });
    },
    async uploadClicked(isXURL = false, xurlScrap = null) {
      this.isSavingData = true;
      const isValid = await this.validateCollaboration(isXURL, xurlScrap);
      if (!isValid) {
        this.isSavingData = false;
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "Esta campanha não aceita colaborações como esta!"
        });
        return;
      }
      const success = await this.uploadFiles(isXURL, xurlScrap);
      if (success) {
        this.resetForm();
        this.drawer = false;
        Analytics.sendCollaboration(this.campaignId);
      }
      this.isSavingData = false;
    },
    async validateCollaboration(isXURL, xurlScrap) {
      try {
        await Campaign.validateCollaboration(this.campaignId, {
          body: {
            title: this.formData.title,
            message: this.formData.message
          },
          medias: this.formData.files.map(file => ({
            name: file.name,
            mime_type: file.type,
            media_size: file.size,
            format: getFileExtension(file.name)
          })),
          collaborator: !isXURL
            ? {
                type: "cms",
                email: this.currentUser.email,
                name: this.currentUser.name,
                phone: this.currentUser.phone
              }
            : {
                type: "twitter",
                name: xurlScrap.authorName,
                tw_user_screen_name: xurlScrap.authorHandle,
                profile_image_url: xurlScrap.avatarURL
              }
        });
        return true;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        return false;
      }
    },
    async uploadFiles(isXURL, xurlScrap) {
      try {
        const createMediaTasks = this.formData.files.map(async file => ({
          ...(
            await Media.register({
              name: file.name,
              mime_type: file.type,
              media_size: file.size
            })
          ).data,
          file: file
        }));
        const medias = await Promise.all(createMediaTasks);
        this.progress = [];
        this.progress.length = medias.length;
        const postFileTasks = medias.map((media, i) => {
          if (media.strategy === "presigned") {
            return S3.uploadPresignedPost(
              media.strategy_data.url,
              media.strategy_data.fields,
              media.file,
              progressEvent => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                this.progress[i] = percentCompleted;
              }
            );
          } else {
            throw `Unknown strategy ${media.strategy}`;
          }
        });
        await Promise.all(postFileTasks);

        Campaign.collaborations
          .register(this.campaignId, {
            body: {
              title: this.formData.title,
              message: this.formData.message || ""
            },
            to_campaigns: [parseInt(this.campaignId)],
            medias: medias.map(media => media.id),
            collaborator: !isXURL
              ? {
                  type: "cms",
                  email: this.currentUser.email,
                  name: this.currentUser.name,
                  phone: this.currentUser.phone
                }
              : {
                  type: "twitter",
                  name: xurlScrap.authorName,
                  tw_user_screen_name: xurlScrap.authorHandle,
                  profile_image_url: xurlScrap.avatarURL
                }
          })
          .then(() => {
            // 2s para incluir dentro da store
            setTimeout(() => {
              this.$store.dispatch("CAMPAIGN_COLLABORATIONS_LOAD", {
                tryPaginating: false,
                refresh: true
              });
            }, 1000);
            this.$store.dispatch(UI_SNACKBAR_HIDE);
            this.$store.dispatch(UI_SNACKBAR_SHOW, {
              message: "A colaboração foi enviada com sucesso!"
            });
          });

        return true;
      } catch (err) {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "Erro enviando colaboração :(!"
        });
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/theme.scss";
@import "../scss/navigation-drawer.scss";

::v-deep .v-window__container {
  width: 100%;
}
</style>
