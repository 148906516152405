<template>
  <BaseCard v-if="campaignsObject.length">
    <CardHeader
      title="Preferências de campanhas"
      subtitle="Configure a janela de campanhas para se adequar a você"
    />
    <div class="box mt-12">
      <div class="title--small mb-1">Campanhas favoritas</div>
      <div class="subtitle--small mb-5">
        Configure as campanhas favoritadas.
      </div>
      <div class="box-body">
        <div class="input-item">
          <v-list dense>
            <v-list-item-group
              class="d-flex flex-column campaign_list"
              ref="list"
            >
              <v-list-item
                v-for="(campaign, index) in campaignsObject"
                :key="index"
                class="campaign_list__item"
              >
                <v-list-item-icon>
                  <v-icon color="primary">mdi-drag</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ campaign.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    campaign.product.name
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        small
                        icon
                        color="reprove"
                        v-on="on"
                        @click="unFavorite(campaign.id)"
                      >
                        <v-icon>
                          mdi-heart-off-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Desfavoritar</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import Sortable from "sortablejs";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import BaseCard from "@/components/Commons/BaseCard";
import CardHeader from "@/components/Commons/CardHeader";
import {
  LC_CAMPAIGNS_GET_BY_FILTER,
  LC_CAMPAIGNS_HANDLE_FILTER,
  LC_CAMPAIGNS_SET_RAW
} from "@/store/actions";
import { MT_CAMPAIGN_SET_TOTAL_CAMPAIGNS_AVAILABLE } from "@/store/mutations";
export default {
  components: {
    BaseCard,
    CardHeader
  },
  props: ["favoriteCampaigns"],
  created() {
    this.getCampaignsObject();
  },
  beforeDestroy() {
    this.setDefaultFilters();
    this.cleanCampaignsStore();
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      _campaignsObject: state => state.listCampaign.campaigns
    }),
    ...mapGetters(["filter_data"]),
    campaignsObject() {
      const newArray = this._campaignsObject.filter(campaign =>
        this.favorite_campaigns.includes(campaign.id)
      );
      return newArray;
    },
    favorite_campaigns: {
      get() {
        return this.favoriteCampaigns || [];
      },
      set(campaigns) {
        this.$emit("update:favoriteCampaigns", campaigns);
      }
    }
  },
  watch: {
    campaignsObject(value) {
      if (value.length) {
        this.applyDragAndDrop();
      }
    }
  },
  methods: {
    ...mapActions([
      LC_CAMPAIGNS_HANDLE_FILTER,
      LC_CAMPAIGNS_GET_BY_FILTER,
      LC_CAMPAIGNS_SET_RAW
    ]),
    ...mapMutations([MT_CAMPAIGN_SET_TOTAL_CAMPAIGNS_AVAILABLE]),
    applyDragAndDrop() {
      this.$nextTick(() => {
        const wrapper = document.querySelector(".campaign_list");
        const options = {
          onEnd: ({ oldIndex, newIndex }) => {
            const newArray = [...this.favorite_campaigns];
            const movedItem = newArray.splice(oldIndex, 1)[0];
            newArray.splice(newIndex, 0, movedItem);
            this.favorite_campaigns = newArray;
          },
          animation: 150,
          handle: ".campaign_list__item"
        };
        Sortable.create(wrapper, options);
      });
    },
    unFavorite(id) {
      const position = this.favorite_campaigns.indexOf(id);
      const newArray = [...this.favorite_campaigns];
      newArray.splice(position, 1);
      this.$emit("update:favoriteCampaigns", newArray);
    },
    async setDefaultFilters() {
      await this.LC_CAMPAIGNS_HANDLE_FILTER({
        newFilter: {
          ...this.filter_data,
          is_enabled: null,
          size: 20,
          favorite: false,
          sort_by: "updated_at"
        }
      });
    },
    cleanCampaignsStore() {
      this.LC_CAMPAIGNS_SET_RAW([]);
      this.MT_CAMPAIGN_SET_TOTAL_CAMPAIGNS_AVAILABLE(0);
    },
    setFiltersToGetFavoriteCampaigns() {
      this.LC_CAMPAIGNS_HANDLE_FILTER({
        newFilter: {
          ...this.filter_data,
          is_enabled: null,
          size: 99999,
          favorite: true,
          sort_by: null
        }
      });
    },
    getCampaignsObject() {
      this.setFiltersToGetFavoriteCampaigns();
      this.LC_CAMPAIGNS_GET_BY_FILTER({});
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

.v-card__actions {
  .v-btn {
    margin-left: auto;
  }
}
.input-item {
  .v-list-item {
    border-bottom: 1px solid rgba($color-gray-main, 0.16);

    &__title,
    &__subtitle {
      font-family: $globotipo-condensed;
    }
    &__title {
      color: $color-primary;
      font-size: 1rem !important;
    }
    &__subtitle {
      color: rgba($color-primary, 0.68);
      font-size: 0.8rem !important;
    }
  }
}
</style>
