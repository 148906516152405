import { Campaign } from "@/gateways";
import vue from "@/main";
import vueInstance from "@/main";
import store from "@/store";
import {
  CAMPAIGN_BLOCK_USER,
  CAMPAIGN_COLLABORATIONS_APPROVE,
  CAMPAIGN_COLLABORATIONS_DELETE,
  CAMPAIGN_COLLABORATIONS_FAVORITE,
  CAMPAIGN_COLLABORATIONS_RECYCLE,
  CAMPAIGN_COLLABORATIONS_REJECT,
  CAMPAIGN_COLLABORATIONS_SHOW,
  CAMPAIGN_GROUPED_LOAD_COLLABORATORS,
  COLLABORATION_MEDIA_PLAYER_HANDLER,
  UI_CAMPAIGN_ACTION_DIALOG_HANDLE,
  UI_SHOW_COLLABORATION
} from "@/store/actions";
import { COLLABORATOR_SEND_EMAIL_FIELDS_SET_DEFAULT } from "@/store/mutations";
import { downloadQrCode, generateQrCodeAsBase64 } from "@/util";
import Bus from "@/util/EventBus";

export const collaborationFiltersDefaultState = () => {
  return {
    collaborator_city: "",
    collaborator_country: "",
    collaborator_email: "",
    collaborator_name: "",
    collaborator_phone: "",
    collaborator_state: "",
    collaborator_verified: null,
    toggle_captureSocial: [],
    toggle_captureOthers: [],
    toggle_fileFormatMedia: [],
    toggle_fileFormatOthers: [],
    datePicker: [],
    initial_time: null,
    final_time: null
  };
};

export const collaborationSocialFilter = {
  drawerTitle: "Fontes de captura",
  chipTitle: "captura",
  color: "captureFilterColor",
  title: "Social",
  subtitle: "Outros",
  controller: "toggle_captureSocial",
  principal: [
    { value: "twitter", icon: "mdi-twitter", name: "Twitter" },
    { value: "whatsapp", icon: "mdi-whatsapp", name: "Whatsapp" },
    { value: "youtube", icon: "mdi-youtube", name: "Youtube" },
    { value: "instagram", icon: "mdi-instagram", name: "Instagram" }
  ],
  others: [
    { value: "form", icon: "$formIcon", name: "Formulário" },
    {
      value: "canal-globo",
      icon: "v-icon icon-icon-canalglobo",
      name: "Canal Globo"
    },
    {
      value: "cms",
      classIcon: "v-icon icon-icon-colaborativo",
      name: "Colaborativo"
    },
    {
      value: "uploader",
      classIcon: "v-icon icon-icon-uploader",
      name: "Uploader"
    }
  ]
};

export const collaborationMediaFilter = {
  drawerTitle: "Formatos de arquivos",
  chipTitle: "formatos",
  color: "fileFormatsFilterColor",
  title: "Mídias",
  subtitle: "Outros",
  controller: "toggle_fileFormatMedia",
  principal: [
    { value: "image", icon: "mdi-image-size-select-actual", name: "Fotos" },
    { value: "video", icon: "mdi-movie-open", name: "Vídeo" },
    { value: "audio", icon: "mdi-microphone", name: "Áudios" }
  ],
  others: [
    { value: "text", icon: "mdi-card-text", name: "Textos" },
    {
      value: "application",
      icon: "mdi-package",
      name: "Pdf e zip"
    }
  ]
};

export const collaborationCollaboratorFilter = {
  drawerTitle: "Perfil do público",
  chipTitle: "perfil",
  title: "Identificação",
  subtitle: "Localização",
  principal: [
    {
      icon: "mdi-face",
      label: "Filtrar por nome",
      controller: "collaborator_name",
      dataType: "str"
    },
    {
      icon: "mdi-email-outline",
      label: "Filtrar por email",
      controller: "collaborator_email",
      dataType: "str"
    },
    {
      icon: "mdi-phone-outline",
      label: "Filtrar por telefone",
      controller: "collaborator_phone",
      dataType: "str"
    },
    {
      icon: "mdi-check-decagram",
      label: "Perfis verificados",
      controller: "collaborator_verified",
      dataType: "bool"
    }
  ],
  others: [
    {
      icon: "mdi-map-marker-outline",
      label: "Filtrar por país",
      controller: "collaborator_country",
      dataType: "str"
    },
    {
      icon: "mdi-map-marker-outline",
      label: "Filtrar por estado",
      controller: "collaborator_state",
      dataType: "str"
    },
    {
      icon: "mdi-map-marker-outline",
      label: "Filtrar por cidade",
      controller: "collaborator_city",
      dataType: "str"
    }
  ]
};

export const collaborationGroupFilters = [
  { ...collaborationSocialFilter },
  { ...collaborationMediaFilter }
];

export const getCollaborationTime = date => {
  return new Date(date).toLocaleTimeString("pt-br", {
    hour: "2-digit",
    minute: "2-digit"
  });
};

export const getCollaborationDate = date => {
  return new Date(date).toLocaleDateString("pt-br");
};

export const getAndFormatCounter = (type, medias) => {
  let counter = 0;
  counter = medias.filter(media => media.mime_type.includes(type)).length;
  return counter < 10 ? `0${counter}` : counter;
};

export const deleteCollaboration = (index, collaboration) => {
  store.dispatch(CAMPAIGN_COLLABORATIONS_DELETE, {
    index,
    collaboration
  });
};

export const rejectCollaboration = (index, collaboration) => {
  store.dispatch(CAMPAIGN_COLLABORATIONS_REJECT, {
    index,
    collaboration
  });
};
export const approveCollaboration = (index, collaboration) => {
  store.dispatch(CAMPAIGN_COLLABORATIONS_APPROVE, {
    index,
    collaboration
  });
};
export const copyCollaboration = collaboration_id => {
  store.dispatch(UI_CAMPAIGN_ACTION_DIALOG_HANDLE, {
    dialog: "copyDialog",
    isDialogActive: true,
    collaboration_selected: collaboration_id
  });
};
export const resendCollaborationOutput = collaboration_id => {
  store.dispatch(UI_CAMPAIGN_ACTION_DIALOG_HANDLE, {
    dialog: "resendDialog",
    isDialogActive: true,
    collaboration_selected: collaboration_id
  });
};
export const showCollaboration = (index, collaboration) => {
  store.dispatch(COLLABORATION_MEDIA_PLAYER_HANDLER);
  store.dispatch(CAMPAIGN_COLLABORATIONS_SHOW, {
    index,
    collaboration
  });
  store.dispatch(UI_SHOW_COLLABORATION);
};
export const recycleCollaboration = (index, collaboration) => {
  store.dispatch(CAMPAIGN_COLLABORATIONS_RECYCLE, {
    index,
    collaboration
  });
};
export const favoriteCollaboration = (index, dataFavorites) => {
  const collaboration = { ...dataFavorites["collaboration"] };

  const user = dataFavorites["user"];
  store.dispatch(CAMPAIGN_COLLABORATIONS_FAVORITE, {
    index,
    collaboration,
    user
  });
};
export const openQRCodeModal = async phone => {
  const waMeUrl = `https://wa.me/${phone}`;
  const configMessage = {
    title: {
      featured: "Conversar",
      text: "via Whatsapp"
    },
    message: {
      featured: "",
      text:
        "Leia este QRCODE com seu celular para abrir a conversa com colaborador."
    },
    image: {
      base64OrUrl: await generateQrCodeAsBase64(waMeUrl, 250),
      width: 250,
      height: 250
    },
    action: {
      color: "primary",
      icon: "file_download",
      title: "Baixar QRCode"
    }
  };
  vue.$gtag.event("COLLABORATOR_QRCODE_GENERATED");
  Bus.$emit("openModalConfirm", configMessage, async mustDownloadQrCode => {
    if (mustDownloadQrCode) {
      await downloadQrCode(waMeUrl, `qrcode_whatsapp_${phone}.png`, 300);
    }
  });
};

export const openEmailModal = ({ campaignId, name, email }) => {
  const configMessage = {
    title: {
      featured: `Responder colaborador`,
      text: "via E-mail"
    },
    message: {
      featured: ``,
      text: `Preencha os campos abaixo para enviar uma resposta via e-mail para o colaborador: ${name}.`
    },
    form: {
      type: "email"
    },
    action: {
      color: "primary",
      icon: "$emailIcon",
      title: "Enviar resposta"
    }
  };
  Bus.$emit("openModalConfirm", configMessage, async sendEmail => {
    if (sendEmail) {
      const { subject } = store.state.collaborators.collaboratorSendEmailFields;
      Campaign.sendCollaboratorEmail({
        campaign_id: parseInt(campaignId),
        email,
        subject,
        message: store.getters.message
      });
      vueInstance.$gtag.event("EMAIL_SENT_TO_COLLABORATOR");
      store.dispatch(CAMPAIGN_GROUPED_LOAD_COLLABORATORS, {
        loadMoreCollaborators: false
      });
    }
    store.commit(COLLABORATOR_SEND_EMAIL_FIELDS_SET_DEFAULT);
  });
};

export const handleBlockUser = (phone, email, type, block) => {
  store.dispatch(CAMPAIGN_BLOCK_USER, {
    phone: phone,
    email: email,
    type: type,
    block: block
  });
};
