var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filters-section --collaboration"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndDown),expression:"$vuetify.breakpoint.smAndDown"}],staticClass:"filters-items--mobile"},[_c('v-chip',{attrs:{"label":"","ripple":"","color":_vm.drawerFilterColor},on:{"click":_vm.openDrawer}},[_vm._v(" filtros "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-sort-variant")])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"filters-items"},[_c('FilterBox',{attrs:{"title":"Social","headerTitle":"Fontes de captura","chipTitle":"captura","items":_vm.socialFilters.principal,"selectedItems":_vm.localFilters.toggle_captureSocial,"subBoxSelectedItems":_vm.localFilters.toggle_captureOthers,"subBoxItems":_vm.socialFilters.others,"filterColor":_vm.captureFilterColor},on:{"update":function($event){_vm.localFilters.toggle_captureSocial = $event},"update-sub-box":function($event){_vm.localFilters.toggle_captureOthers = $event}}}),_c('FilterBox',{attrs:{"title":"Mídias","headerTitle":"Formatos de arquivo","chipTitle":"formatos","selectedItems":_vm.localFilters.toggle_fileFormatMedia,"subBoxSelectedItems":_vm.localFilters.toggle_fileFormatOthers,"items":_vm.mediaFilters.principal,"subBoxItems":_vm.mediaFilters.others,"filterColor":_vm.fileFormatsFilterColor},on:{"update":function($event){_vm.localFilters.toggle_fileFormatMedia = $event},"update-sub-box":function($event){_vm.localFilters.toggle_fileFormatOthers = $event}}}),_c('v-menu',{attrs:{"min-width":"auto","offset-y":"","left":"","transition":"slide-y-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"label":"","ripple":"","color":_vm.dateColor}},on),[_vm._v(" data / hora "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}]),model:{value:(_vm.menuDateTime),callback:function ($$v) {_vm.menuDateTime=$$v},expression:"menuDateTime"}},[_c('div',{staticClass:"dropdown"},[_c('div',{staticClass:"dropdown-header --full-space"},[_c('v-date-picker',{attrs:{"range":"","show-current":"","full-width":"","locale":"pt-br"},model:{value:(_vm.localFilters.datePicker),callback:function ($$v) {_vm.$set(_vm.localFilters, "datePicker", $$v)},expression:"localFilters.datePicker"}})],1),_c('div',{staticClass:"dropdown-content"},[_c('div',{staticClass:"list-item"},[_c('v-text-field',{attrs:{"label":"Hora inicial","outlined":"","type":"time","prepend-inner-icon":"mdi-clock-time-four"},model:{value:(_vm.localFilters.initial_time),callback:function ($$v) {_vm.$set(_vm.localFilters, "initial_time", $$v)},expression:"localFilters.initial_time"}}),_c('v-text-field',{attrs:{"label":"Hora final","outlined":"","type":"time","prepend-inner-icon":"mdi-clock-time-four"},model:{value:(_vm.localFilters.final_time),callback:function ($$v) {_vm.$set(_vm.localFilters, "final_time", $$v)},expression:"localFilters.final_time"}})],1)])])]),_c('v-menu',{attrs:{"offset-y":"","left":"","transition":"slide-y-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"label":"","ripple":"","color":_vm.profileFilterColor}},on),[_vm._v(" perfil do colaborador "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}]),model:{value:(_vm.menuUserProfile),callback:function ($$v) {_vm.menuUserProfile=$$v},expression:"menuUserProfile"}},[_c('div',{staticClass:"dropdown --big"},[_c('div',{staticClass:"dropdown-content --column"},[_c('div',{staticClass:"dropdown-content-column --left"},[_c('div',{staticClass:"dropdown-content-column__title"},[_vm._v("Identificação")]),_vm._l((_vm.collaboratorFilters.principal),function(ref,i){
var label = ref.label;
var icon = ref.icon;
var controller = ref.controller;
var dataType = ref.dataType;
return [_c('div',{key:i,staticClass:"dropdown-content-column__item"},[(dataType === 'str')?_c('v-text-field',{attrs:{"value":_vm.localFilters[controller],"color":"graydark","label":label,"outlined":"","single-line":"","hide-details":"","prepend-inner-icon":icon},on:{"change":function($event){_vm.localFilters[controller] = $event}}}):(dataType === 'bool')?_c('v-text-field',{attrs:{"color":"graydark","label":label,"outlined":"","readonly":"","disabled":"","single-line":"","hide-details":"","prepend-inner-icon":icon},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-switch',{attrs:{"value":_vm.localFilters[controller]},on:{"change":function($event){_vm.localFilters[controller] = $event}}})]},proxy:true}],null,true)}):_vm._e()],1)]})],2),_c('div',{staticClass:"dropdown-content-column__divisor"}),_c('div',{staticClass:"dropdown-content-column --right"},[_c('div',{staticClass:"dropdown-content-column__title"},[_vm._v("Localização")]),_vm._l((_vm.collaboratorFilters.others),function(ref,i){
var label = ref.label;
var icon = ref.icon;
var controller = ref.controller;
return _c('div',{key:i,staticClass:"dropdown-content-column__item"},[_c('v-text-field',{attrs:{"value":_vm.localFilters[controller],"color":"graydark","label":label,"outlined":"","single-line":"","hide-details":"","prepend-inner-icon":icon},on:{"change":function($event){_vm.localFilters[controller] = $event}}})],1)})],2)])])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"filters-clear"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"56px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"--back-white --local-btn",attrs:{"icon":"","ripple":""},on:{"click":_vm.cleanFilters}},on),[_c('v-icon',[_vm._v("mdi-filter-variant-remove")])],1)]}}])},[_c('span',[_vm._v("limpar filtros")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }