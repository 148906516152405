import storejs from "store";

import { loginCatraca, loginLogout, UserPreferences } from "@/gateways";
import vue from "@/main";

import router from "../router";
import { oidc, tokenManager } from "../services/auth";
import {
  AUTH_CLEARUSER,
  AUTH_EXPIRED,
  AUTH_LOGOUT,
  AUTH_PREFERENCES_HANDLER,
  AUTH_SAVEUSER,
  AUTH_TRY_LOGIN,
  AUTH_TRY_LOGIN_OIDC,
  SETTINGS_FLUXUX_LOAD,
  UI_SNACKBAR_SHOW
} from "./actions";
import { AUTH_LOGGEDIN_SET, AUTH_USER_SET } from "./mutations";

export default {
  state: {
    user: null,
    isLoggedIn: false
  },
  getters: {
    userId: state => state.user?.id,
    isSuperUser: state => state.user?.is_superuser,
    userFavoriteCampaigns: state => state.user?.preferences?.favorite_campaigns,
    userFavoriteViewMode: state => state.user?.preferences?.preferred_view,
    userFirstName: state => {
      const name = state.user.name.split(" ")[0];
      if (name.toLowerCase() === "desconhecido") {
        const email = state.user.email;
        const emailName = email.split("@")[0];
        return emailName.includes(".") ? emailName.split(".")[0] : emailName;
      }
      return name;
    },
    userLastName: state => {
      let name = state.user.name
        .split(" ")
        .slice(-1)
        .join(" ");
      if (name.toLowerCase() === "desconhecido") {
        const email = state.user.email;
        const emailName = email.split("@")[0];
        if (emailName.includes(".")) {
          name = emailName.split(".")[1];
          return emailName.includes("_") ? name.split("_")[0] : name;
        }
        return "";
      }
      return name;
    },
    userEmail: state => state.user.email
  },
  actions: {
    [AUTH_TRY_LOGIN]: async ({ dispatch }, auth) => {
      try {
        const res = await loginCatraca({
          username: auth.username,
          password: auth.password
        });
        await dispatch(AUTH_SAVEUSER, res.data);
        vue.$gtag.event("login");
        tokenManager.scheduleNextRefresh(
          res.data.expiration,
          res.data.server_time
        );
        router.push("/");
      } catch (err) {
        const { response } = err;
        switch (response.status) {
          case 401:
            dispatch(UI_SNACKBAR_SHOW, {
              message: "Usuário ou senha inválidos!"
            });
            break;
          default:
            dispatch(UI_SNACKBAR_SHOW, {
              message:
                "Ops... Tivemos um problema no servidor. Por favor, tente novamente em alguns instantes :)"
            });
            break;
        }
      }
    },
    [AUTH_TRY_LOGIN_OIDC]: async ({ dispatch }) => {
      const { success, authData, message } = await oidc.getUser();
      if (success) {
        await dispatch(AUTH_SAVEUSER, authData);
        vue.$gtag.event("login");
        tokenManager.scheduleNextRefresh(
          authData.expiration,
          authData.server_time
        );
        router.push("/");
      } else {
        dispatch(UI_SNACKBAR_SHOW, message);
      }
    },

    [AUTH_SAVEUSER]: (
      { commit, dispatch },
      { access_token, user_data, expiration }
    ) => {
      storejs.set("userToken", access_token);
      storejs.set("userData", user_data);
      storejs.set("userTokenExpiration", expiration);

      if (user_data) {
        vue.$gtag.set({
          user_id: user_data.id
        });
      }

      commit(AUTH_USER_SET, user_data);
      commit(AUTH_LOGGEDIN_SET, true);
      dispatch(SETTINGS_FLUXUX_LOAD);
    },
    [AUTH_CLEARUSER]: ({ commit }) => {
      storejs.remove("userToken");
      storejs.remove("userData");
      storejs.remove("userTokenExpiration");
      vue.$gtag.set({
        user_id: ""
      });
      commit(AUTH_USER_SET, null);
      commit(AUTH_LOGGEDIN_SET, false);
    },

    [AUTH_LOGOUT]: async ({ dispatch }, isExpirationLogout = false) => {
      tokenManager.cancelNextRefresh();
      await loginLogout();
      await dispatch(AUTH_CLEARUSER);
      const isAccountsConnect =
        process.env.VUE_APP_ACCOUNTSCONNECT_ENABLED === "1";
      if (isAccountsConnect && !isExpirationLogout) {
        oidc.logout();
      } else {
        router.push("/login");
      }
    },

    [AUTH_EXPIRED]: async ({ dispatch }) => {
      await dispatch(AUTH_LOGOUT, true);
      await dispatch(UI_SNACKBAR_SHOW, {
        message: "A sua sessão expirou. Por favor realize o login novamente."
      });
    },

    [AUTH_PREFERENCES_HANDLER]: async (
      { commit, state, dispatch },
      { campaigns, view }
    ) => {
      const { favorite_campaigns, preferred_view } = state.user.preferences;
      const newFavoriteCampaignsArray =
        campaigns && Array.isArray(campaigns)
          ? campaigns
          : [...favorite_campaigns];
      if (
        campaigns &&
        !Array.isArray(campaigns) &&
        !favorite_campaigns.includes(campaigns)
      ) {
        newFavoriteCampaignsArray.push(campaigns);
      } else if (
        campaigns &&
        !Array.isArray(campaigns) &&
        favorite_campaigns.includes(campaigns)
      ) {
        newFavoriteCampaignsArray.splice(
          newFavoriteCampaignsArray.indexOf(campaigns),
          1
        );
      }
      const updatedPreferences = {
        favorite_campaigns: newFavoriteCampaignsArray,
        preferred_view: view ? view : preferred_view
      };
      try {
        await UserPreferences.editUserPreferences(updatedPreferences);
        commit(AUTH_USER_SET, {
          ...state.user,
          preferences: { ...updatedPreferences }
        });
        dispatch(UI_SNACKBAR_SHOW, {
          message: "Preferências atualizadas com sucesso."
        });
        if (campaigns) {
          vue.$gtag.event("FAVORITE_CAMPAIGNS_UPDATED");
        }
        if (view) {
          vue.$gtag.event("PREFERRED_VIEW_UPDATED");
        }
      } catch (error) {
        dispatch(UI_SNACKBAR_SHOW, {
          message:
            "Erro ao tentar editar preferências, nenhuma alteração foi feita."
        });
      }
    }
  },
  mutations: {
    [AUTH_USER_SET]: (state, value) => {
      state.user = value;
    },
    [AUTH_LOGGEDIN_SET]: (state, value) => {
      state.isLoggedIn = value;
    }
  }
};
