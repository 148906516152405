<template>
  <div>
    <div class="loading" v-if="!dataLoadedSuccessfully">
      <v-progress-circular
        :size="40"
        indeterminate
        color="blue-grey"
      ></v-progress-circular>
    </div>
    <div class="container" v-else>
      <div class="topo">
        <div class="title">
          <span>{{ isEditMode ? "Editar" : "Registrar" }}</span> Grupo
        </div>
      </div>
      <div class="main">
        <v-form class="form" v-model="formValid">
          <div class="left">
            <div class="item">
              <div class="slot">
                <v-text-field
                  placeholder="Exemplo: Mais Você"
                  primary
                  v-model="formData.name"
                  :rules="nameRules"
                  counter
                  maxlength="36"
                  required
                  class="textfield"
                >
                  <template slot="label">
                    Nome do Grupo
                    <span class="text-red">*</span>
                  </template>
                </v-text-field>
              </div>

              <div class="slot">
                <v-text-field
                  placeholder="Exemplo: Entretenimento"
                  primary
                  v-model="formData.area"
                  :rules="areaRules"
                  maxlength="40"
                  counter
                  class="textfield"
                >
                  <template slot="label">
                    Área Responsável
                    <span class="text-red">*</span>
                  </template>
                </v-text-field>
              </div>
            </div>

            <div class="item">
              <v-text-field
                label="Descrição"
                placeholder="Fale um pouco sobre o objetivo da campanha"
                primary
                v-model="formData.description"
                :rules="descriptionRules"
                maxlength="255"
                counter
                class="textfield"
              ></v-text-field>
            </div>

            <div class="item">
              <div class="viewTable">
                <v-text-field
                  label="Moderadores"
                  placeholder="Selecione os usuários que farão a moderação do grupo"
                  primary
                  maxlength="255"
                  counter
                  class="textfield"
                  v-model="tableSearchedItem"
                />

                <v-data-table
                  v-model="moderatorUsers"
                  :items="
                    showOnlySelected
                      ? moderatorUsers
                      : users.filter(u => u.id > 0)
                  "
                  :headers="headers"
                  :search="tableSearchedItem"
                  show-select
                  item-key="id"
                  sort-by="name"
                  :footer-props="{
                    showCurrentPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-arrow-left',
                    nextIcon: 'mdi-arrow-right',
                    itemsPerPageOptions: [10, 50, 100]
                  }"
                >
                  <template v-slot:header[`data-table-select`]="{}"></template>
                  <template v-slot:footer>
                    <v-switch
                      class="showOnlySelected pa-3"
                      v-model="showOnlySelected"
                      label="Mostrar Somente os selecionados"
                    ></v-switch>
                  </template>
                </v-data-table>
              </div>
            </div>

            <div class="item">
              <PosterSelector
                labelName="BUSCA/UPLOAD DE PÔSTER"
                placeholder="Pesquise por um programa da Globo ou envie uma imagem..."
                v-model="poster"
                :currentPosterUrl="originalPosterUrl"
                required
              />
            </div>
          </div>
          <div class="right">
            <PosterDisplay :posterUrl="selectedPosterUrl || placeholderUrl" />
          </div>
        </v-form>
      </div>

      <!-- Footer -->
      <div class="footer">
        <div class="slot left"></div>

        <!-- Actions -->
        <div class="slot right">
          <v-btn
            large
            color="seccondary"
            class="mr-4"
            @click="$emit('cancelCreateClickedEvent')"
          >
            <v-icon>mdi-close</v-icon>Cancelar
          </v-btn>
          <v-btn
            large
            color="primary"
            @click="saveDataHandler"
            :disabled="!formValid || isSavingData || !dataLoadedSuccessfully"
            :loading="isSavingData"
          >
            <v-icon>mdi-content-save</v-icon>Salvar
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { innerJoin, omit } from "ramda";

import placeholderVertical from "@/assets/images/placeholder-vertical.png";
import PosterDisplay from "@/components/Commons/PosterDisplay";
import PosterSelector from "@/components/Commons/PosterSelector";
import { Product, User } from "@/gateways";
import { UI_SNACKBAR_SHOW } from "@/store/actions";

const validateRequired = val => !!val || "Campo obrigatório.";

const validateAreaLenght = area =>
  (!!area && area.length <= 40) || "A area deve ter 40 caracteres ou menos.";

const validateNameLength = name =>
  (!!name && name.length <= 36) || "O nome deve ter menos que 36 caracteres.";
const validateDescriptionlLength = description =>
  (!description ? true : description.length <= 255) ||
  "A descrição deve ter menos que 255 caracteres.";

export default {
  components: {
    PosterSelector,
    PosterDisplay
  },
  data() {
    return {
      tableSearchedItem: null,
      formValid: false,
      formData: {
        id: -1,
        name: null,
        description: null,
        file: null,
        moderators: [],
        area: null
      },
      placeholderVertical: placeholderVertical,
      moderatorUsers: [],
      poster: null,
      users: [],
      isEditMode: false,
      isSavingData: false,
      dataLoadedSuccessfully: false,
      // Auto complete
      showOnlySelected: true,
      headers: [
        {
          text: "nome",
          align: "start",
          sortable: true,
          value: "name"
        },
        {
          text: "email",
          value: "email"
        }
      ],
      originalPosterUrl: null
    };
  },
  props: ["editItem"],
  async created() {
    this.dataLoadedSuccessfully = false;
    const userPromise = User.get();
    let productPromise;
    if (this.editItem) {
      productPromise = Product.get(this.editItem.id);
    }
    Promise.all([userPromise, productPromise])
      .then(results => {
        this.users = results[0].data;

        if (this.editItem) {
          const product = results[1].data;
          this.formData = product;
          this.moderatorUsers = innerJoin(
            (user, moderatorId) => moderatorId === user.id,
            this.users,
            product.moderators
          );
          this.originalPosterUrl =
            this.formData.poster_url && this.formData.poster_url !== ""
              ? this.formData.poster_url
              : null;
          this.isEditMode = true;
        }
        this.dataLoadedSuccessfully = true;
      })
      .catch(() => {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message:
            "Não foi possível carregar os dados para montagem do formulário. Por favor, tente novamente em alguns minutos."
        });
      });
  },
  computed: {
    placeholderUrl() {
      return this.placeholderVertical;
    },
    areaRules() {
      return [validateRequired, validateAreaLenght];
    },

    nameRules() {
      return [validateRequired, validateNameLength];
    },

    descriptionRules() {
      return [validateDescriptionlLength];
    },

    selectedPosterUrl() {
      return this.poster?.url ? this.poster.url : null;
    }
  },
  methods: {
    async saveDataHandler() {
      this.isSavingData = true;

      const data = {
        ...omit(["id", "file"], this.formData),
        poster: this.poster
          ? {
              filename: this.poster.filename,
              data_b64: this.poster.url
            }
          : null
      };

      if (this.isEditMode) {
        await this.editItemHandler(this.formData.id, data);
      } else {
        await this.registerItemHandler(data);
      }

      this.isSavingData = false;
    },
    async registerItemHandler(data) {
      try {
        await Product.register(data);
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "O grupo foi registrado com sucesso!"
        });
        this.$emit("itemRegisteredEvent");
      } catch {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "Erro tentando registrar o grupo :(!"
        });
      }
    },
    async editItemHandler(id, data) {
      try {
        await Product.edit(id, data);
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "O grupo foi editado com sucesso!"
        });
        this.$emit("itemEditedEvent");
      } catch {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "Erro tentando editar o grupo :(!"
        });
      }
    }
  },
  watch: {
    moderatorUsers(val) {
      this.formData.moderators = val.map(m => m.id);
    },
    tableSearchedItem(val) {
      this.showOnlySelected = val.length === 0;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/theme.scss";
@import "../../../scss/navigation-drawer.scss";

.btn-slot {
  display: flex;
}

.main {
  .form {
    display: flex;
    flex-wrap: wrap;

    .left,
    .right {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      flex-direction: column;
    }

    .left {
      width: calc(100% - 400px);

      @media only screen and (max-width: 1140px) {
        width: 100%;

        .item {
          .slot {
            width: 100%;
          }
        }
      }
    }

    .right {
      margin: auto auto;
      width: calc(180px * 2);
      height: calc(296px * 2);

      @media only screen and (max-width: 1000px) {
        margin-top: 16px;
        width: 100%;
        height: 296px;
      }
    }
  }
  .viewTable {
    display: flex;
    flex-direction: column;
    width: 100%;

    ::v-deep .v-data-table__wrapper {
      max-height: 600px !important;
      overflow: auto !important;
    }
  }
}
.showOnlySelected {
  margin-bottom: -85px;
  max-width: 300px;
}
// Loading
.loading {
  position: relative;
  flex: 1;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 8px auto 48px 24px; */
  /* height: calc(100vh - 232px); */

  @include for-size(tablet-landscape-up) {
    margin: 0px auto 0px auto;
    height: calc(100vh - 176px);
  }
}
</style>
