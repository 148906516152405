var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"card",staticClass:"card collaboration-card",class:Object.assign({}, {opacityHover: _vm.opacityHover},
    _vm.cardTypeClass,
    {active: _vm.isCardActive})},[(_vm.hasMedia)?_c('div',{staticClass:"thumb card_media"},[_c('MediaCarousel',{attrs:{"medias":_vm.collaboration.medias,"campaignId":_vm.collaboration.campaign_id}})],1):_vm._e(),_c('div',{staticClass:"card_header"},[_c('div',{staticClass:"boxName"},[(_vm.showCampaign)?_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.campaignName)+" ")]):_vm._e()]),_c('div',{staticClass:"mediaType"},[_c('div',{staticClass:"social rounded-circle",class:_vm.collaborationInputBadgeStyle},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.collaborationInputBadgeIcon)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.collaborationTypeDescription))])])],1)])]),_c('div',{staticClass:"card_content"},[_c('div',{staticClass:"content-user",on:{"click":_vm.filterCollabsByCollaborator}},[_c('div',{staticClass:"avatar",style:(_vm.hasAvatar)}),_c('div',{staticClass:"user"},[_c('div',{staticClass:"user-top"},[_c('div',{staticClass:"user-name",domProps:{"innerHTML":_vm._s(_vm.collaboratorName)}}),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.collaboration.collaborator.verified),expression:"collaboration.collaborator.verified"}],staticClass:"ml-1",class:_vm.userVerificationBadgeClasses},[_vm._v("mdi-check-decagram")])],1),_c('div',{staticClass:"user-handle"},[_vm._v(_vm._s(_vm.collaboratorHandle.value))])])]),_c('div',{staticClass:"content-text",on:{"click":_vm.showDetails}},[_c('v-tooltip',{attrs:{"top":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('div',{staticClass:"message",style:(_vm.messageLineStyling),on:{"mouseenter":on.mouseenter,"mouseleave":on.mouseleave}},[(_vm.collaboration.body.title)?_c('span',{staticClass:"collaboration-title"},[_vm._v(_vm._s(_vm.collaboration.body.title + " "))]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(_vm.messageBody)}})])]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.messageBody)}})])],1)]),_c('div',{staticClass:"card_footer"},[_c('div',{staticClass:"createdAt"},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.collaborationDate))]),_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.collaborationTime))]),(_vm.hasComment)?_c('div',{staticClass:"comments"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("comment")])]}}],null,false,3538401853)},[_c('span',[_vm._v("Comentários")])])],1):_vm._e()]),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"select"},[_c('v-simple-checkbox',{attrs:{"disabled":_vm.actionsDisabled},on:{"input":_vm.handleToggle},model:{value:(_vm.isSelected),callback:function ($$v) {_vm.isSelected=$$v},expression:"isSelected"}})],1),_c('CollaborationActions',{ref:"actions",attrs:{"collaboration":_vm.collaboration_,"currentCollaborator":_vm.collaboration_.collaborator,"index":_vm.index},on:{"handle-active-item":function($event){_vm.isCardActive = $event}}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }