<template>
  <Drawer
    @toggle-drawer="toggleDrawerInputSourceMetadata"
    @hard-update="handleSave"
    :drawer="isMetadataDrawerVisible"
    title="configurar metadata"
    :isFullHeight="true"
  >
    <template v-slot:drawer-content>
      <div class="modal-content__item">
        <CardHeader
          :title="title"
          subtitle="Utilize as configurações para criar um novo campo"
        ></CardHeader>
      </div>
      <v-form ref="metadata-drawer-form">
        <div class="modal-content__item">
          <div class="input-item full">
            <v-text-field
              v-model="editedMetadata.field"
              :rules="[rules.required]"
              maxlength="255"
              color="graydark"
              label="Nome do Campo"
              placeholder="Informe o nome do campo"
              outlined
              prepend-inner-icon="mdi-label"
            />
          </div>
        </div>
        <div class="modal-content__item">
          <div class="input-item full">
            <v-autocomplete
              v-model="type"
              :items="metadataTypeOptions"
              :rules="[rules.required]"
              color="graydark"
              label="Tipo de dado"
              placeholder="Escolha apenas uma opção"
              outlined
              disable-lookup
              prepend-inner-icon="mdi-format-list-bulleted-type"
            ></v-autocomplete>
          </div>
        </div>
        <div class="modal-content__item" v-if="canShowMaskFields">
          <div class="input-item full">
            <v-text-field
              v-model="editedMetadata.mask"
              :rules="[rules.required]"
              maxlength="255"
              color="graydark"
              label="Máscara"
              placeholder="Personalize uma máscara"
              outlined
              prepend-inner-icon="mdi-form-textbox"
            >
              <div class="help" slot="append">
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-information-outline</v-icon>
                  </template>
                  <span>
                    <strong>Formatos</strong> <br /># => Número (0-9) <br />A =>
                    Letra (a-z, A-Z) <br />N => Número ou letra (a-z, A-Z, 0-9)
                    <br />X => Qualquer caractere; <br />? => Opcional (próximo
                    caractere);
                    <br />
                  </span>
                </v-tooltip>
              </div>
            </v-text-field>
          </div>
          <div class="input-item full">
            <v-chip-group v-model="editedMetadata.mask">
              <v-chip
                v-for="mask in defaultMasks"
                :key="mask.name"
                filter
                :value="mask.mask"
                >{{ mask.name }}</v-chip
              >
            </v-chip-group>
          </div>
        </div>
        <div class="modal-content__item" v-if="canShowListFields">
          <v-form>
            <div class="input-item full mb-4">
              <v-text-field
                v-model="editedList.placeholder"
                :rules="[rules.required]"
                maxlength="255"
                color="graydark"
                label="Nome de exibição"
                placeholder="Informe o nome para exibição"
                outlined
                prepend-inner-icon="mdi-label"
              />
            </div>
            <div class="input-item full">
              <v-text-field
                v-model="editedList.value"
                :rules="[rules.required]"
                maxlength="255"
                color="graydark"
                label="Valor"
                placeholder="Informe o valor do campo"
                outlined
                prepend-inner-icon="mdi-label"
              />
            </div>
            <div class="input-item full">
              <v-data-table
                :headers="listHeaders"
                :items="editedMetadata.values"
                :hide-default-footer="true"
                class="mt-2 mb-2"
              >
                <template v-slot:[`item.action`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        small
                        class="mr-2"
                        @click="deleteListItem(item)"
                        v-on="on"
                        >delete</v-icon
                      >
                    </template>
                    <span>Remover campo</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.default`]="{ item, value }">
                  <v-btn
                    color="blue-grey"
                    @click="markAsDefault(item)"
                    class="ma-2 btnModal"
                    elevation="0"
                    text
                    large
                  >
                    <v-icon v-show="value" color="green">done</v-icon>
                    <v-icon v-show="!value" color="red">close</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
              <ButtonPrimary
                class="ml-4"
                icon="mdi-content-save"
                label="Adicionar item à lista"
                @click-handler="updateListItem"
              ></ButtonPrimary>
            </div>
          </v-form>
        </div>
        <div class="modal-content__item">
          <EnablerBox label="Tornar campo obrigatório">
            <template slot="enabler">
              <v-switch v-model="editedMetadata.required" inset></v-switch>
            </template>
          </EnablerBox>
        </div>
      </v-form>
    </template>
  </Drawer>
</template>

<script>
import { mapState } from "vuex";

import ButtonPrimary from "@/components/Commons/Buttons/ButtonPrimary.vue";
import CardHeader from "@/components/Commons/CardHeader";
import Drawer from "@/components/Commons/Dialogs/Drawer";
import EnablerBox from "@/components/Commons/EnablerBox";
import { UI_METADATA_DRAWER_HANDLE } from "@/store/actions";
import { UI_SNACKBAR_SHOW } from "@/store/actions";
import { updateCampaignItem } from "@/util/campaign";
import { defaultMetadata, defaultMetadataList } from "@/util/campaign";

export default {
  name: "MetadataDrawer",
  components: {
    CardHeader,
    Drawer,
    EnablerBox,
    ButtonPrimary
  },
  props: {
    currentMetadata: Object,
    isEditMode: Boolean
  },
  data() {
    return {
      metadataTypeOptions: [
        "Texto",
        "Texto com Máscara",
        "Número inteiro",
        "Número decimal",
        "Lista"
      ],
      defaultMasks: [
        { name: "CPF", mask: "###.###.###-##" },
        { name: "CNPJ", mask: "##.###.###/####-##" },
        { name: "CEP", mask: "#####-###" },
        { name: "CELULAR", mask: "(##) # ####-####" },
        { name: "TELEFONE FIXO", mask: "(##) ####-####" }
      ],
      listHeaders: [
        { text: "Nome de exibição", align: "start", value: "placeholder" },
        { text: "Valor", align: "start", value: "value" },
        {
          text: "Padrão",
          align: "center",
          value: "default"
        },
        { text: "Ação", align: "center", value: "action" }
      ],
      editedList: defaultMetadataList(),
      editedMetadata: defaultMetadata(),
      rules: {
        required: value => !!value || "Campo obrigatório."
      }
    };
  },
  computed: {
    ...mapState({
      formData: state => state.listCampaign.createOrEditCampaignParams.formData,
      isMetadataDrawerVisible: state => state.ui.isMetadataDrawerVisible
    }),
    formMetadata() {
      return this.formData.metadata;
    },
    title() {
      return this.isEditMode ? "EDITAR METADADO" : "CRIAR METADADO";
    },
    type: {
      get() {
        return this.currentMetadata.data_type;
      },
      set(value) {
        this.editedMetadata.data_type = value;
      }
    },
    editedType() {
      return this.editedMetadata.data_type;
    },
    canShowMaskFields() {
      const enabler = "Texto com Máscara";
      return this.editedMetadata.data_type === enabler;
    },
    canShowListFields() {
      const enabler = "Lista";
      return this.editedMetadata.data_type === enabler;
    }
  },
  watch: {
    isEditMode() {
      this.editedMetadata = { ...this.currentMetadata };
    },
    editedType(value) {
      if (this.editedMetadata.mask && value !== "Texto com Máscara") {
        this.editedMetadata.mask = null;
      } else if (
        (this.editedMetadata.values.placeholder ||
          this.editedMetadata.values.value) &&
        value !== "Lista"
      ) {
        this.editedMetadata.values = [...this.currentMetadata.values];
      }
    }
  },
  methods: {
    atLeastOneListItemAsDefault() {
      return this.editedMetadata.values.some(l => l.default);
    },

    deleteListItem(item) {
      item = this.editedMetadata.values.indexOf(item);
      this.editedMetadata.values.splice(item, 1);
      if (this.editedMetadata.values.length === 0) {
        return;
      }

      if (this.atLeastOneListItemAsDefault()) {
        return;
      }
      this.editedMetadata.values[0].default = true;
    },
    updateListItem() {
      const canUpdate = this.canUpdateListItem();
      if (!canUpdate.status) {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: canUpdate.msg
        });
        return;
      }
      this.editedMetadata.values.push({
        ...this.editedList,
        default: !this.atLeastOneListItemAsDefault()
      });
    },
    handleSave() {
      this.updateItem(this.editedMetadata, "metadata");
    },
    updateItem(value, item) {
      const canUpdate = this.canUpdateItem(value);
      if (!canUpdate.status) {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: canUpdate.msg
        });
        return;
      }
      const newMetadataArray = [...this.formMetadata];

      if (this.isEditMode) {
        const indexToBeEdited = this.formMetadata.findIndex(
          item => JSON.stringify(item) === JSON.stringify(this.currentMetadata)
        );
        newMetadataArray[indexToBeEdited] = value;
      } else {
        newMetadataArray.push(value);
      }

      updateCampaignItem(newMetadataArray, item);
      this.toggleDrawerInputSourceMetadata();
    },
    canUpdateItem(item) {
      const hasItem = this.formMetadata.find(currentItem => {
        if (this.isEditMode) {
          return (
            currentItem.field === item.field &&
            currentItem.field !== this.currentMetadata.field
          );
        } else {
          return currentItem.field === item.field;
        }
      });
      const validFields = this.$refs["metadata-drawer-form"].validate();
      if (hasItem) {
        return {
          status: false,
          msg: "Já existe um metadado com este nome cadastrado nessa campanha."
        };
      }
      if (!validFields) {
        return {
          status: false,
          msg: "Existem campos inválidos, nenhuma modificação foi feita"
        };
      }
      return { status: true };
    },
    canUpdateListItem() {
      const hasItem = this.editedMetadata.values.find(
        listItem => listItem.placeholder === this.editedList.placeholder
      );
      const hasNullField =
        !this.editedList.placeholder || !this.editedList.value;
      if (hasItem) {
        return {
          status: false,
          msg: "Já existe uma opção com este nome de exibição."
        };
      }
      if (hasNullField) {
        return {
          status: false,
          msg:
            "É necessário preencher nome e valor para adicionar um item a lista"
        };
      }
      return { status: true };
    },
    reset() {
      this.editedMetadata = defaultMetadata();
      this.hasChangedType = false;
      this.$refs["metadata-drawer-form"].reset();
      this.$emit("clean-current-metadata");
    },
    toggleDrawerInputSourceMetadata(value) {
      if (!value || value === false) {
        this.reset();
      }
      this.$store.dispatch(UI_METADATA_DRAWER_HANDLE, value ? value : false);
    },

    markAsDefault(item) {
      this.editedMetadata.values = this.editedMetadata.values.map(listItem => {
        return {
          ...listItem,
          default:
            JSON.stringify(item) === JSON.stringify(listItem) ? true : false
        };
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/theme.scss";
@import "@/scss/modal.scss";
@import "@/scss/form-inputs.scss";

.material-icons.help::before {
  font-size: 16px;
}
</style>
