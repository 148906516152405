import { CC_CAMPAIGN_CREATE_OR_UPDATE_FORMDATA_HANDLE } from "@/store/actions";
import { UI_SNACKBAR_SHOW } from "@/store/actions";
import store from "@/store/index";

export const newCampaignObject = () => ({
  id: -1,
  name: null,
  collaboration_expiration: "ANNUALLY",
  disclaimer: null,
  poster: null,
  description: null,
  tags: [],
  product_id: null,
  is_enabled: true,
  required_approvals: 0,
  collaborators: [],
  moderators: [],
  inputs: [],
  outputs: [],
  api_statistics: [],
  twitter_configuration: {
    is_enabled: false
  },
  form_configuration: {
    is_enabled: false,
    is_canal_globo_enabled: false,
    canal_globo_twitter_config: {
      hashtags: []
    },
    client_id: "",
    text: "",
    link: "",
    link_text: "",
    agreement: false,
    cadun_id: null,
    blocked_words: []
  },
  uploader_configuration: {
    is_enabled: false
  },
  uploader_web_configuration: {
    aspera_is_enabled: false,
    is_enabled: false
  },
  whatsapp_configuration: {
    is_enabled: false,
    accepted_phones: [],
    blocked_phones: [],
    blocked_words: [],
    id_associates: []
  },
  youtube_configuration: {
    is_enabled: false,
    channels: [],
    users: []
  },
  instagram_configuration: {
    is_enabled: false,
    instagram_ids: []
  },
  notification_is_enabled: null,
  collaboration_limit: 0,
  filesize_max: 50 * 1024 * 1024,
  filecount_min: 0,
  filecount_max: 5,
  image_wanted: true,
  video_wanted: true,
  audio_wanted: true,
  text_wanted: true,
  application_wanted: true,
  media_policies: [],
  retweet_is_enabled: false,
  sftp_id: null,
  twitter_terms: [],
  twitter_blacklist: [],
  metadata: []
});

export const updateCampaignItem = (value, paramName) => {
  store.dispatch(CC_CAMPAIGN_CREATE_OR_UPDATE_FORMDATA_HANDLE, {
    isNestedItem: false,
    paramToBeChanged: paramName,
    value: value
  });
};

export const updateCampaignNestedItem = (value, paramName, paramNestedName) => {
  store.dispatch(CC_CAMPAIGN_CREATE_OR_UPDATE_FORMDATA_HANDLE, {
    isNestedItem: true,
    paramToBeChanged: paramName,
    nestedParamToBeChanged: paramNestedName,
    value: value
  });
};

export const defaultMetadata = () => ({
  field: null,
  data_type: null,
  mask: null,
  required: false,
  values: []
});

export const defaultMetadataList = () => ({
  default: true,
  placeholder: null,
  value: null
});

export const isValidForm = form => {
  if (!form.validate()) {
    store.dispatch(UI_SNACKBAR_SHOW, {
      message: "Existem campos inválidos, nenhuma modificação foi feita"
    });
    return false;
  } else {
    return true;
  }
};
