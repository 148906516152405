<template>
  <div class="container">
    <!-- Topo -->
    <div class="topo">
      <div class="title">
        <span>{{ isEditMode ? "Editar" : "Registrar" }}</span> Usuário
      </div>
    </div>
    <div class="main">
      <v-form class="form" v-model="formValid">
        <div class="item">
          <v-text-field
            placeholder="Informe o nome do usuário"
            primary
            v-model="formData.name"
            :rules="nameRules"
            counter
            maxlength="100"
            required
            class="textfield"
          >
            <template slot="label">
              Nome
              <span class="text-red">*</span>
            </template>
          </v-text-field>
        </div>

        <div class="item margin">
          <v-text-field
            placeholder="Informe o e-mail do usuário"
            primary
            v-model="formData.email"
            :rules="emailRules"
            maxlength="320"
            required
            :disabled="isEditMode"
            class="textfield"
          >
            <template slot="label">
              E-mail
              <span class="text-red">*</span>
            </template>
          </v-text-field>
        </div>

        <div class="item margin highlight">
          <v-switch
            v-model="formData.is_superuser"
            inset
            color="primary"
            label="Perfil super administrador"
          ></v-switch>
        </div>
      </v-form>
    </div>

    <!-- Footer -->
    <div class="footer">
      <div class="slot left"></div>

      <!-- Actions -->
      <div class="slot right">
        <v-btn
          large
          color="seccondary"
          class="mr-4"
          @click="$emit('cancelCreateClickedEvent')"
        >
          <v-icon>mdi-close</v-icon>Cancelar
        </v-btn>
        <v-btn
          large
          color="primary"
          @click="saveDataHandler"
          :disabled="!formValid && !isSavingData"
          :loading="isSavingData"
        >
          <v-icon>check</v-icon>Salvar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { omit, pick } from "ramda";
import { mask } from "vue-the-mask";
import XRegExp from "xregexp";

import { User } from "../../../gateways";
import { UI_SNACKBAR_SHOW } from "../../../store/actions";

const unicodeNameRegex = XRegExp("[\\pL+ ]$");
const validateRequired = val => !!val || "Campo obrigatório.";
const validateNameCharacters = name =>
  (!!name && unicodeNameRegex.test(name)) || "Caracteres inválidos.";
const validateNameLength = name =>
  (!!name && name.length <= 100) || "O nome deve ter menos que 100 caracteres.";
const validateEmailLength = email =>
  (!!email && email.length <= 320) ||
  "O email deve ter menos que 320 caracteres.";

export default {
  directives: {
    mask
  },
  data() {
    return {
      formValid: false,
      formData: {
        id: -1,
        name: null,
        email: null,
        is_superuser: false
      },
      isEditMode: false,
      isSavingData: false
    };
  },
  props: ["editItem"],
  created() {
    if (this.editItem) {
      this.formData = pick(
        ["id", "name", "email", "is_superuser", "is_enabled"],
        this.editItem
      );
      this.isEditMode = true;
    }
  },
  computed: {
    nameRules: {
      get() {
        return [validateRequired, validateNameLength, validateNameCharacters];
      }
    },
    emailRules: {
      get() {
        return [validateRequired, validateEmailLength];
      }
    }
  },
  methods: {
    async saveDataHandler() {
      this.isSavingData = true;
      const data = omit("id", this.formData);
      if (this.isEditMode) {
        await this.editItemHandler(this.formData.id, data);
      } else {
        await this.registerItemHandler(data);
      }
      this.isSavingData = false;
    },
    async registerItemHandler(data) {
      try {
        await User.register(data);
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "O usuário foi registrado com sucesso!"
        });
        this.$emit("itemRegisteredEvent");
      } catch {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "Erro tentando registrar o usuário :(!"
        });
      }
    },

    async editItemHandler(id, data) {
      try {
        await User.edit(id, data);
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "O usuário foi editado com sucesso!"
        });
        this.$emit("itemEditedEvent");
      } catch {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "Erro tentando editar o usuário :(!"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/theme.scss";
@import "../../../scss/navigation-drawer.scss";

::v-deep .item:last-child .v-input .v-label {
  color: $color-primary !important;
  font-size: 16px !important;
  text-transform: initial !important;
}
</style>
