<template>
  <v-btn
    text
    color="graydark"
    :class="{ 'btn--disabled': !!disabled }"
    @click.stop="clickHandler"
  >
    <v-icon v-if="icon" left dark>{{ icon }}</v-icon>
    {{ displayLabel }}
  </v-btn>
</template>

<script>
export default {
  name: "ButtonSecondary",
  data() {
    return {};
  },
  props: {
    label: String,
    icon: String,
    disabled: Boolean
  },
  computed: {
    displayLabel() {
      return this.$vuetify.breakpoint.xs ? "" : this.label;
    }
  },
  methods: {
    clickHandler() {
      this.$emit("click-handler");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";
@import "@/scss/form-inputs.scss";

@include for-size(phone-only) {
  .v-btn {
    max-width: fit-content !important;
    min-width: fit-content !important;
  }
}
</style>
