var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"main"},[_c('v-data-table',{staticClass:"table-main",attrs:{"fixed-header":true,"headers":_vm.headers,"items":_vm.snapshots,"height":"100%","mobile-breakpoint":1200,"sort-by":"name","search":_vm.search,"loading":_vm.isLoading,"loading-text":"Carregando Snapshots..."},scopedSlots:_vm._u([{key:"item.deleted_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convert_timestamp_to_string(item.deleted_at))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.restoreCampaign(item)}}},on),[_vm._v(" published_with_changes ")])]}}],null,true)},[_c('span',[_vm._v("Restaurar Campanha")])])]}},{key:"item.deleted_by_user",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.deleted_by_user.name)+" ")])]}}],null,true)},[_c('span',[_c('strong',[_vm._v("Id:")]),_vm._v(_vm._s(item.deleted_by_user.id)),_c('br'),_c('strong',[_vm._v("E-mail:")]),_vm._v(_vm._s(item.deleted_by_user.email)+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"footer search"},[_c('div',{staticClass:"slot left"},[_c('v-text-field',{attrs:{"solo":"","rounded":"","clearable":"","flat":"","hide-details":"","label":"Pesquise por campanhas excluídas...","append-icon":"search","color":"primary","clear-icon":"close","background-color":"#F5F5F5"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"slot right"},[_c('v-btn',{attrs:{"large":"","color":"seccondary"},on:{"click":function($event){return _vm.$emit('cancelClickedEvent')}}},[_c('v-icon',[_vm._v("mdi-close")]),_vm._v(" Cancelar ")],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"topo"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("Lista")]),_vm._v(" de Campanhas recuperáveis")])])}]

export { render, staticRenderFns }