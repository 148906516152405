<template>
  <BaseCard>
    <CardHeader
      title="Preferências de colaborações"
      subtitle="Configure a janela de colaborações para se adequar a você"
    />
    <div class="box mt-12">
      <div class="title--small mb-1">Modo de exibição preferido</div>
      <div class="subtitle--small mb-5">
        Escolha qual o modo de exibição será o padrão para você.
      </div>
      <div class="box-body">
        <div class="column">
          <div class="input-item">
            <v-chip-group v-model="preferred_view" mandatory>
              <v-chip
                class="badge"
                width="fit-content"
                v-for="(viewMode, index) in viewModes"
                :key="index"
                :value="viewMode.value"
                ><v-icon class="mr-1">{{ viewMode.icon }}</v-icon
                >{{ viewMode.name }}</v-chip
              >
            </v-chip-group>
          </div>
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/Commons/BaseCard";
import CardHeader from "@/components/Commons/CardHeader";
export default {
  components: {
    BaseCard,
    CardHeader
  },
  props: ["preferredView"],
  data() {
    return {
      viewModes: [
        { name: "Card", icon: "$cards", value: "card" },
        { name: "Tabela", icon: "$table", value: "table" },
        { name: "Conversa", icon: "$conversation", value: "conversation" }
      ]
    };
  },
  computed: {
    preferred_view: {
      get() {
        return this.preferredView;
      },
      set(mode) {
        this.$emit("update:preferredView", mode);
      }
    }
  }
};
</script>

<style></style>
