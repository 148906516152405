<template>
  <Drawer
    @toggle-drawer="toggleDrawerInputSourceWhatsapp"
    @reset-enabler="resetEnabler"
    @hard-update="hardUpdate"
    @reset-changes="reset"
    :drawer="isWhatsappDrawerVisible"
    title="configurar whatsapp"
    :isFullHeight="true"
    drawerName="whatsapp"
    formRef="whatsapp-drawer-form"
    :areAllFieldsEmpty="areAllFieldsEmpty"
  >
    <template v-slot:drawer-content>
      <div class="modal-content__item">
        <CardHeader
          title="Gerenciar Números"
          subtitle="Os números de whatsapp estão vinculados ao grupo escolhido nas informações básicas"
        ></CardHeader>
      </div>
      <div class="modal-content__item">
        <div class="input-item full">
          <div class="select-divisor"></div>
          <div class="select-placeholder">
            Escolha os números que vão receber os conteúdos da sua campanha
          </div>
          <AutocompleteGroup
            @update="softUpdate($event, 'id_associates')"
            :items="whatsapps"
            :isWhatsapp="true"
            :selectedItems.sync="localWhatsappConfig.id_associates"
            :itemText="item => `${item.name} ${item.phoneNumber}`"
            prependIcon="fab fa-whatsapp"
          ></AutocompleteGroup>
        </div>
      </div>
      <div class="modal-content__item mt-6">
        <CardHeader
          title="RESTRIÇÃO DE ENVIO"
          subtitle="Utilize as listas de bloqueio ou exclusividade para restringir o envio de conteúdo"
        ></CardHeader>
      </div>
      <div class="modal-content__item">
        <div class="title--small mb-1">BLOQUEIO</div>
        <div class="subtitle--small mb-4">
          (Sua campanha não receberá conteúdo dos números adicionados)
        </div>
        <!-- <div class="input-item full">
          <div class="select-divisor"></div>
          <div class="select-placeholder">
            Clique para adicionar ou importe de uma campanha...
          </div>
          <AutocompleteGroup
            @update="softUpdate('blocked_phones', $event)"
            @remove-item="removeWppBlockedNumber"
            :items="whatsappConfig.blocked_phones"
            :selectedItems.sync="whatsappConfig.blocked_phones"
            :itemText="item => item"
            prependIcon="mdi-block-helper"
            :appendIcon="bookIcon"
            :hasSwitch="true"
          ></AutocompleteGroup>
        </div> -->
        <div
          class="input-item switch-item d-flex align-center"
          :class="{ disabled: exclusivePhoneEnabled }"
        >
          <v-combobox
            ref="blocked-phones"
            v-model="localWhatsappConfig.blocked_phones"
            :rules="phoneRules"
            deletable-chips
            multiple
            :disabled="!blockedPhoneEnabled"
            outlined
            label="Clique para adicionar"
            color="graydark"
            prepend-inner-icon="mdi-block-helper"
            append-icon
          >
            <template v-slot:selection="{ attrs, item, selected, parent }">
              <v-chip
                label
                v-bind="attrs"
                :input-value="selected"
                close
                @click:close="parent.selectItem(item)"
                >{{ item }}
              </v-chip>
            </template>
          </v-combobox>
          <v-switch inset dense v-model="blockedPhoneEnabled"></v-switch>
        </div>
      </div>
      <div class="modal-content__item">
        <div class="title--small mb-1">Exclusividade</div>
        <div class="subtitle--small mb-4">
          (Sua campanha receberá conteúdo apenas dos números adicionados)
        </div>
        <div
          class="input-item switch-item d-flex align-center"
          :class="{ disabled: blockedPhoneEnabled }"
        >
          <v-combobox
            ref="exclusive-phones"
            v-model="localWhatsappConfig.accepted_phones"
            color="graydark"
            label="Clique para adicionar"
            outlined
            :disabled="!exclusivePhoneEnabled"
            prepend-inner-icon="stars"
            :rules="phoneRules"
            deletable-chips
            multiple
            append-icon
          >
            <template v-slot:selection="{ attrs, item, selected, parent }">
              <v-chip
                label
                v-bind="attrs"
                :input-value="selected"
                close
                @click:close="parent.selectItem(item)"
                >{{ item }}
              </v-chip>
            </template>
          </v-combobox>
          <v-switch inset dense v-model="exclusivePhoneEnabled"></v-switch>
        </div>
      </div>
    </template>
  </Drawer>
</template>

<script>
import { mapState } from "vuex";

import AutocompleteGroup from "@/components/Commons/AutocompleteGroup";
import CardHeader from "@/components/Commons/CardHeader";
import Drawer from "@/components/Commons/Dialogs/Drawer";
import { Product } from "@/gateways";
import { UI_WHATSAPP_DRAWER_HANDLE } from "@/store/actions";
import { updateCampaignItem } from "@/util/campaign";

export default {
  name: "WhatsappDrawer",
  components: {
    AutocompleteGroup,
    CardHeader,
    Drawer
  },
  data() {
    return {
      firstWhatsappLoad: true,
      blockedPhoneEnabled: false,
      exclusivePhoneEnabled: false,
      whatsapps: [],
      localWhatsappConfig: {}
    };
  },
  computed: {
    ...mapState({
      formData: state => state.listCampaign.createOrEditCampaignParams.formData,
      isWhatsappDrawerVisible: state => state.ui.isWhatsappDrawerVisible,
      whatsappConfig: state =>
        state.listCampaign.createOrEditCampaignParams.formData
          .whatsapp_configuration
    }),
    phoneRules() {
      return [
        phoneArray => {
          const pattern = /^[0-9]+$/; // only numbers
          for (let i = 0; i < phoneArray.length; i++) {
            const phone = phoneArray[i];
            if (phone.length < 11 || phone.length > 15) {
              return "Um número deve conter entre 11 e 15 dígitos.";
            }
            if (!phone.match(pattern)) {
              return "Um número de telefone contém letras.";
            }
          }
          return true;
        }
      ];
    },
    areAllFieldsEmpty() {
      return (
        !this.localWhatsappConfig.id_associates?.length &&
        !this.localWhatsappConfig.accepted_phones?.length &&
        !this.localWhatsappConfig.blocked_phones?.length
      );
    },
    currentProductId() {
      return this.formData.product_id;
    }
  },
  watch: {
    whatsappConfig: {
      handler() {
        this.localWhatsappConfig = { ...this.whatsappConfig };
        this.blockedPhoneEnabled =
          !!this.localWhatsappConfig.blocked_phones?.length > 0;
        this.exclusivePhoneEnabled =
          !!this.localWhatsappConfig.accepted_phones?.length > 0;
      },
      deep: true
    },
    blockedPhoneEnabled(value) {
      if (value) {
        this.softUpdate([], "accepted_phones");
        this.$refs["exclusive-phones"].reset();
      }
    },
    exclusivePhoneEnabled(value) {
      if (value) {
        this.softUpdate([], "blocked_phones");
        this.$refs["blocked-phones"].reset();
      }
    },
    async currentProductId(newVal) {
      const setDefaultValues = () => {
        this.whatsapps = [];
        this.softUpdate([], "id_associates");
      };
      // ao mudar o produto selecionado, carregar a lista de whatsapps associados
      if (!newVal) {
        setDefaultValues();
        return;
      }

      const whatsapp_response = await Product.whatsapp(newVal);
      if (whatsapp_response.status >= 300) {
        // se der algum erro, geralmente 403, apenas n mostrar nada
        setDefaultValues();
      } else {
        // o primeiro carregamento é para montagem de formulário
        // portanto n limpamos a lista de whatsapp associados
        this.whatsapps = whatsapp_response.data;
        if (this.firstWhatsappLoad) {
          this.firstWhatsappLoad = false;
        } else {
          this.softUpdate([], "id_associates");
        }
      }
    }
  },
  methods: {
    toggleDrawerInputSourceWhatsapp(value) {
      this.$store.dispatch(UI_WHATSAPP_DRAWER_HANDLE, value ? value : false);
    },
    softUpdate(value, keyToUpdate) {
      this.localWhatsappConfig[keyToUpdate] = value;
    },
    hardUpdate() {
      updateCampaignItem(
        {
          ...this.formData.whatsapp_configuration,
          ...this.localWhatsappConfig
        },
        "whatsapp_configuration"
      );
    },
    resetEnabler(value) {
      this.localWhatsappConfig.is_enabled = value;
    },
    reset() {
      this.localWhatsappConfig = this.whatsappConfig;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";
@import "@/scss/modal.scss";
@import "@/scss/form-inputs.scss";

::v-deep .v-list.v-select-list.v-sheet.theme--light {
  display: flex !important;
  .v-list__item {
    flex: auto;
  }
}

::v-deep .switch-item .v-select__selections {
  margin-top: 0 !important;
  .v-chip {
    background-color: $color-primary-chip !important;
  }
}
</style>
