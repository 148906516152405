import fernet from "fernet";

export class EncryptorManager {
  constructor() {
    this.fernet_secret = new fernet.Secret(process.env.VUE_APP_FERNET_KEY);
  }

  decrypt = payload => {
    const token = new fernet.Token({
      secret: this.fernet_secret,
      token: payload,
      ttl: 0
    });
    const result = token.decode();
    return result;
  };
}
