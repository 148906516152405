import { render, staticRenderFns } from "./AutocompleteGroup.vue?vue&type=template&id=8ff2adbc&scoped=true&"
import script from "./AutocompleteGroup.vue?vue&type=script&lang=js&"
export * from "./AutocompleteGroup.vue?vue&type=script&lang=js&"
import style0 from "./AutocompleteGroup.vue?vue&type=style&index=0&id=8ff2adbc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ff2adbc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VAutocomplete,VChip,VMain,VSwitch})
