<template>
  <div class="container">
    <!-- Topo -->
    <div class="topo">
      <div class="title"><span>Lista</span> de Políticas de Mídia</div>
    </div>
    <div class="main">
      <v-data-table
        :fixed-header="true"
        :headers="headers"
        :items="mediaPolicies"
        class="table-main"
        :mobile-breakpoint="1200"
        :search="search"
        :loading="isLoading"
        loading-text="Carregando Políticas..."
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-2"
                @click="$emit('editClickedEvent', item)"
                v-on="on"
              >
                edit
              </v-icon>
            </template>
            <span>Editar perfil de mídia</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small @click="deleteItem(item)" v-on="on">
                delete
              </v-icon>
            </template>
            <span>Remover política de mídia</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
    <div class="footer search">
      <!-- Search -->
      <div class="slot left">
        <v-text-field
          v-model="search"
          solo
          rounded
          clearable
          flat
          hide-details
          label="Pesquise por Políticas de mídia..."
          append-icon="search"
          color="primary"
          clear-icon="close"
          background-color="#F5F5F5"
        ></v-text-field>
      </div>

      <!-- Actions -->
      <div class="slot right">
        <v-btn large color="seccondary" @click="$emit('cancelClickedEvent')">
          <v-icon>mdi-close</v-icon>
          Cancelar
        </v-btn>

        <v-btn large color="primary" @click="$emit('createClickedEvent')">
          <v-icon>add_circle</v-icon>
          Adicionar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { MediaPolicy } from "../../../gateways";
import { UI_SNACKBAR_SHOW } from "../../../store/actions";

export default {
  data: () => ({
    isLoading: false,
    search: "",
    mediaPolicies: [],
    headers: [
      {
        text: "Nome",
        align: "left",
        sortable: true,
        value: "name"
      },
      {
        text: "Tipo de mídia",
        align: "left",
        sortable: true,
        value: "type"
      },
      {
        text: "Descrição",
        align: "left",
        sortable: true,
        value: "description"
      },
      { text: "Ações", value: "action", align: "right", sortable: false }
    ]
  }),
  created() {
    if (this.drawer) this.fetchData();
  },
  computed: {
    ...mapState({ currentTab: state => state.ui.currentTab }),
    ...mapState({ drawer: state => state.ui.drawer })
  },
  watch: {
    drawer() {
      if (this.drawer && this.currentTab === "media-policy") this.fetchData();
    },
    currentTab() {
      if (this.drawer && this.currentTab === "media-policy") this.fetchData();
    }
  },
  methods: {
    async deleteItem(item) {
      try {
        if (confirm("Você tem certeza ?")) {
          (await MediaPolicy).delete(item.id);
          const index = this.mediaPolicies.indexOf(item);
          this.mediaPolicies.splice(index, 1);
        }
      } catch {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message:
            "Não foi possível deletar a Políticas de Mídia. Por favor, tente novamente em alguns minutos."
        });
      }
    },
    async fetchData() {
      this.isLoading = true;
      this.mediaPolicies = [];

      try {
        this.mediaPolicies = (await MediaPolicy.get()).data;
      } catch {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message:
            "Não foi possível carregar a lista de Políticas de Mídia. Por favor, tente novamente em alguns minutos."
        });
      }

      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss">
@import "../../../scss/theme.scss";
@import "../../../scss/navigation-drawer.scss";
</style>
