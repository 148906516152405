<template>
  <v-app-bar
    class="header-main"
    scroll-target=".main-wrapper"
    app
    elevation="0"
    fixed
    hide-on-scroll
    text
    height="88px"
  >
    <div class="header-left">
      <slot name="header-left-btn"></slot>
      <slot name="header-left-brand"></slot>
      <slot name="header-left-title"></slot>
    </div>

    <div class="header-right">
      <slot name="header-right-secondary"></slot>
      <slot name="header-right-primary"></slot>
    </div>

    <v-menu v-model="menu" offset-y transition="slide-y-transition">
      <template v-slot:activator="{ on }">
        <div class="header-user" v-ripple v-on="on">
          <div class="header-user-avatar"></div>
          <div class="header-user-meta">
            <div class="header-user-meta__name">
              {{ userFirstName }}<span>{{ userLastName }}</span>
            </div>
            <div class="header-user-meta__email">{{ userEmail }}</div>
          </div>
          <div class="header-user-arrow">
            <v-icon>mdi-chevron-down</v-icon>
          </div>
        </div>
      </template>

      <div class="dropdown">
        <div class="dropdown-footer">
          <v-list>
            <v-list-item ripple @click.prevent="showSettings">
              <v-icon left>mdi-settings</v-icon>
              Configurações
              <v-icon right>mdi-open-in-new</v-icon>
            </v-list-item>
            <v-list-item ripple @click.prevent="showUserPreferences">
              <v-icon left>mdi-tune</v-icon>
              Preferências
              <v-icon right>mdi-open-in-new</v-icon>
            </v-list-item>
            <v-list-item ripple @click.prevent="logout">
              <v-icon left>mdi-power</v-icon>
              Sair do Colaborativo
            </v-list-item>
          </v-list>
        </div>
      </div>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";

import { AUTH_LOGOUT } from "@/store/actions";
import { UI_DRAWER_TOGGLE } from "@/store/mutations";

export default {
  name: "HeaderMain",
  data() {
    return {
      menu: false
    };
  },
  computed: {
    ...mapGetters(["userFirstName", "userLastName", "userEmail"])
  },
  methods: {
    async logout() {
      this.$store.dispatch(AUTH_LOGOUT);
    },
    showSettings() {
      this.$gtag.event("access_administration");
      this.$store.commit(UI_DRAWER_TOGGLE);
    },
    showUserPreferences() {
      if (this.$route.name !== "userPreferences") {
        this.$router.push("/user_preferences");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/headers.scss";
</style>
