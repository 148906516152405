<template>
  <Drawer
    @toggle-drawer="toggleDrawerInputSourceUploader"
    @hard-update="handleHardUpdate"
    @reset-changes="reset"
    :drawer="isUploaderDrawerVisible"
    title="configurar uploader"
    :isFullHeight="true"
    drawerName="uploader"
    formRef="uploader-drawer-form"
    :areAllFieldsEmpty="areAllFieldsEmpty"
  >
    <template v-slot:drawer-content>
      <div class="modal-content__item">
        <CardHeader
          title="FILTRAR CONTEÚDO"
          subtitle="Escolha as origens de envio do Uploader"
        ></CardHeader>
      </div>
      <div class="modal-content__item">
        <BaseCard>
          <div class="box">
            <div class="box-body">
              <div class="column">
                <div class="input-item">
                  <EnablerBox label="Desktop" icon="mdi-video-input-component">
                    <template slot="enabler">
                      <v-switch
                        inset
                        v-model="localUploaderConfig.is_enabled"
                      ></v-switch>
                    </template>
                  </EnablerBox>
                </div>
                <div class="box">
                  <div class="column">
                    <div class="input-item">
                      <EnablerBox label="Web" icon="mdi-video-input-component">
                        <template slot="enabler">
                          <v-switch
                            inset
                            v-model="localUploaderWebConfig.is_enabled"
                          ></v-switch>
                        </template>
                      </EnablerBox>
                    </div>
                    <div class="input-item">
                      <EnablerBox label="Aspera">
                        <template slot="enabler">
                          <v-switch
                            inset
                            v-model="localUploaderWebConfig.aspera_is_enabled"
                            :disabled="!localUploaderWebConfig.is_enabled"
                          ></v-switch>
                        </template>
                      </EnablerBox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BaseCard>
      </div>
      <div class="modal-content__item mt-6">
        <CardHeader
          title="Restrição de Envio"
          subtitle="Opcionalmente, utilize a lista de usuários abaixo para restringir quem pode enviar colaborações para a campanha via Uploader"
        ></CardHeader>
      </div>
      <div class="modal-content__item mt-4">
        <div class="input-item full">
          <AutocompleteUser
            @update="localCollaborators = $event"
            @remove-item="removeCollaborator"
            label="Colaboradores"
            :itemText="
              item => `id=${item.id} name=${item.name} email=${item.email}`
            "
            :items="collaborators"
            :selectedItems.sync="localCollaborators"
            :disabled="false"
            prependIcon="mdi-star-circle"
          />
        </div>
      </div>
    </template>
  </Drawer>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import AutocompleteUser from "@/components/Commons/AutocompleteUser";
import BaseCard from "@/components/Commons/BaseCard";
import CardHeader from "@/components/Commons/CardHeader";
import Drawer from "@/components/Commons/Dialogs/Drawer";
import EnablerBox from "@/components/Commons/EnablerBox";
import { UI_UPLOADER_DRAWER_HANDLE } from "@/store/actions";
import { updateCampaignItem } from "@/util/campaign";

export default {
  name: "UploaderDrawer",
  components: {
    BaseCard,
    CardHeader,
    Drawer,
    EnablerBox,
    AutocompleteUser
  },
  data() {
    return {
      localCollaborators: [],
      localUploaderConfig: {},
      localUploaderWebConfig: {}
    };
  },
  computed: {
    ...mapState({
      formData: state => state.listCampaign.createOrEditCampaignParams.formData,
      isUploaderDrawerVisible: state => state.ui.isUploaderDrawerVisible
    }),
    ...mapGetters({
      collaborators: "createOrUpdateSortedUser"
    }),
    uploaderWebConfig() {
      return { ...this.formData.uploader_web_configuration };
    },
    uploaderConfig() {
      return { ...this.formData.uploader_configuration };
    },
    formDataCollaborators() {
      return this.formData.collaborators;
    },
    localWebEnabler() {
      return this.localUploaderWebConfig.is_enabled;
    },
    defaultCollaborators() {
      return this.collaborators.filter(collaborator =>
        this.formDataCollaborators.includes(collaborator.id)
      );
    },
    areAllFieldsEmpty() {
      return (
        !this.localUploaderConfig.is_enabled &&
        !this.localUploaderWebConfig.is_enabled &&
        !this.localUploaderWebConfig.aspera_is_enabled &&
        !this.localCollaborators?.length
      );
    }
  },
  watch: {
    formDataCollaborators() {
      this.localCollaborators = this.defaultCollaborators;
    },
    uploaderConfig: {
      handler() {
        this.localUploaderConfig = { ...this.uploaderConfig };
      },
      deep: true
    },
    uploaderWebConfig: {
      handler() {
        this.localUploaderWebConfig = { ...this.uploaderWebConfig };
      },
      deep: true
    },
    localWebEnabler(value) {
      if (!value && this.localUploaderWebConfig.aspera_is_enabled) {
        this.localUploaderWebConfig.aspera_is_enabled = false;
      }
    }
  },
  methods: {
    hardUpdate(value, field) {
      updateCampaignItem(value, field);
    },
    handleHardUpdate() {
      this.hardUpdate(
        { ...this.uploaderConfig, ...this.localUploaderConfig },
        "uploader_configuration"
      );
      this.hardUpdate(
        { ...this.uploaderWebConfig, ...this.localUploaderWebConfig },
        "uploader_web_configuration"
      );
      this.hardUpdate(
        this.localCollaborators.map(collaborator => collaborator.id),
        "collaborators"
      );
    },
    reset() {
      this.localUploaderConfig = this.uploaderConfig;
      this.localUploaderWebConfig = this.uploaderWebConfig;
      this.localCollaborators = this.defaultCollaborators;
    },
    toggleDrawerInputSourceUploader(value) {
      this.$store.dispatch(UI_UPLOADER_DRAWER_HANDLE, value ? value : false);
    },

    removeCollaborator(user) {
      this.localCollaborators = this.localCollaborators.filter(
        lc => lc !== user.id
      );
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/theme.scss";
@import "@/scss/modal.scss";
@import "@/scss/form-inputs.scss";

.base-card .column {
  grid-template-columns: repeat(2, 1fr);

  & > .box .column {
    grid-gap: 0 !important;
    border: 1px solid $color-black-08;
    border-radius: 4px;
    height: 56px;

    &:hover {
      border-color: $color-black-24;
    }

    ::v-deep fieldset {
      border: 0;
    }

    .input-item {
      .enabler-box {
        border: 0;
      }
      &:nth-child(odd) .enabler-box {
        padding-right: 0;
        &:after {
          content: "";
          background: $color-black-08;
          display: block;
          height: 36px;
          width: 1px;
        }
      }
    }
  }
}

@include for-size(phone-only) {
  .base-card .column:not(.base-card .column > .box .column) {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

::v-deep .base-card {
  box-shadow: none !important;
  margin: 0 0 16px;
  padding: 0;
}

::v-deep [role="combobox"].v-input__slot {
  .v-select__selections {
    margin-top: 0 !important;
    .v-chip {
      background-color: $color-primary-chip !important;
    }
  }
}
</style>
