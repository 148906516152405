import Vue from "vue";
import pt from "vuetify/es5/locale/pt";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        // primary: "#3C8795",
        graymain: "#666666",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        graylight: "#EEEEEE",
        refuse: "#E95656",
        primary: "#459BAB",
        reprove: "#F66969",
        approve: "#0CCE14",
        // primary: "#3C8795",
        secondary: "#FFFFFF",
        accent: "#82B1FF",
        error: "#FF5252",
        background: "#F8F8F8",
        white: "#FFFFFF",
        black: "#222222"
      },
      dark: {
        primary: "#3C8795",
        secondary: "#FFFFFF",
        graymain: "#666666",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        graylight: "#EEEEEE",
        approve: "#51C39D",
        refuse: "#E95656",
        white: "#FFFFFF",
        black: "#222222"
      }
    }
  },
  lang: {
    locales: { pt },
    current: "pt"
  },
  icons: {
    iconfont: "mdi",
    values: {
      blockedUser: "mdi-account-cancel",
      conversation: "mdi-forum",
      cards: "mdi-view-dashboard",
      table: "mdi-table-large",
      audioIcon: "mdi-microphone",
      videoIcon: "mdi-movie",
      imageIcon: "mdi-image",
      formIcon: "web",
      emailIcon: "mdi-email-outline",
      collaborationAproveIcon: "check_circle",
      collaborationRejectIcon: "cancel",
      approveLevelOneIcon: "looks_one",
      approveLevelTwoIcon: "looks_two"
    }
  },
  breakpoint: {
    thresholds: {
      xs: 600,
      sm: 980,
      md: 1200,
      lg: 1800
    }
  }
});
