<template>
  <v-main>
    <v-autocomplete
      @change="$emit('update', $event)"
      :items="items"
      :disabled="disabled"
      v-model="selectControl"
      :label="label"
      :placeholder="placeholder"
      :prepend-inner-icon="prependIcon"
      :append-icon="appendIcon"
      :item-text="itemText"
      item-value="id"
      color="graydark"
      outlined
      hide-details
      clearable
      chips
      :multiple="multiple"
      :class="{ hasSwitch: hasSwitch }"
    >
      <template v-slot:selection="{ item, selected, parent }">
        <!-- :input-value="data.selected" -->
        <v-chip
          class="group"
          label
          close
          filter
          @click:close="parent.selectItem(item)"
          :input-value="selected"
        >
          <div class="group-content">
            <div class="group-metadata">
              <div class="group-metadata--name" v-if="isWhatsapp">
                {{ item.phoneNumber }}
              </div>
              <div class="group-metadata--tag" :class="{ 'mt-0': !isWhatsapp }">
                {{ item.name }}
              </div>
            </div>
          </div>
        </v-chip>
      </template>

      <template v-slot:item="{ item }">
        <!-- <v-chip-group v-model="selectControl" multiple> -->
        <v-chip class="group" label filter value="item.id">
          <div class="group-content">
            <div class="group-metadata">
              <div class="group-metadata--name" v-if="isWhatsapp">
                {{ item.phoneNumber }}
              </div>
              <div class="group-metadata--tag" :class="{ 'mt-0': !isWhatsapp }">
                {{ item.name }}
              </div>
            </div>
          </div>
        </v-chip>
        <!-- </v-chip-group> -->
      </template>
    </v-autocomplete>
    <v-switch inset dense v-if="hasSwitch" />
  </v-main>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    label: String,
    items: Array,
    selectedItems: [Array, Object, Number, String],
    placeholder: String,
    prependIcon: String,
    appendIcon: String,
    hasSwitch: Boolean,
    multiple: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    itemText: {
      type: Function
    },
    isWhatsapp: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selectControl: {
      get() {
        return this.selectedItems;
      },
      set(value) {
        this.$emit("update", value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";
@import "@/scss/form-inputs.scss";

.v-main {
  .v-list {
    display: flex !important;
    flex-wrap: wrap;

    ::v-deep .v-list-item {
      flex: auto;
    }
  }

  ::v-deep .mdi-close::before {
    padding-right: 12px;
    font-size: 16px;
  }

  ::v-deep .v-select__selections {
    .v-chip {
      background-color: $color-primary-chip !important;
    }
  }

  ::v-deep .v-select__slot .v-input__append-inner:nth-child(2) {
    border: 0 !important;
  }

  .hasSwitch {
    ::v-deep .v-input__append-inner {
      margin-right: 70px !important;
    }
  }
}
.v-autocomplete__content.v-menu__content .v-select-list {
  display: flex !important;
  flex-wrap: wrap !important;
  ::v-deep .v-list-item {
    flex: inherit;
    padding: 0 5px;
  }
}
</style>
