<template>
  <!-- <div>
    <GradientTopBackground></GradientTopBackground> -->
  <div class="base-card header">
    <div class="left">
      <div v-if="leftButton">
        <ButtonIcon
          :icon="leftButton.icon"
          @click-handler="leftButton.clickHandler"
        ></ButtonIcon>
      </div>

      <div v-if="subtitle" class="title-subtitle-wrapper">
        <div class="title">
          <span>{{ titleFirstWord(title) }}</span
          >{{ restOfTheTitle(title) }}
        </div>
        <div class="subtitle">{{ subtitle }}</div>
      </div>

      <div class="title" v-else>
        <span>{{ titleFirstWord(title) }}</span
        >{{ restOfTheTitle(title) }}
      </div>
    </div>

    <div class="right">
      <div class="actions secondary-actions">
        <slot name="secondary-actions"></slot>
      </div>
      <div class="actions primary-actions">
        <slot name="primary-actions"></slot>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import ButtonIcon from "@/components/Commons/Buttons/ButtonIcon";

export default {
  name: "AppBar",
  components: {
    ButtonIcon
  },
  props: {
    leftButton: {
      type: Object, //{ icon: String, clickHandler: Function }
      required: false,
      validator: buttonProp => {
        return buttonProp.icon && buttonProp.clickHandler;
      }
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    titleFirstWord(title) {
      const [firstWord] = title.split(" ");
      return `${firstWord} `;
    },
    restOfTheTitle(title) {
      const [, ...otherWords] = title.split(" ");
      return otherWords.join(" ");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";
@import "@/scss/basecard.scss";

.header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 80vw;
  height: 88px;
  box-sizing: border-box;
  box-shadow: 0 16px 40px rgba(0, 0, 0, 0.08);

  @include for-size(tablet-landscape-up) {
    width: 100vw;
  }

  @include for-size(phone-only) {
    padding: 24px 12px;
  }

  .left,
  .right {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .left {
    justify-content: flex-start;

    .title-subtitle-wrapper {
      display: flex;
      flex-direction: column;
    }

    .title,
    .subtitle {
      display: inline;
      margin: 0 8px;
      font-family: $primary-fontface !important;
      font-style: italic;
      text-transform: uppercase;
      width: fit-content;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .title {
      font-size: 24px !important;
      font-weight: 300;

      @include for-size(phone-only) {
        font-size: 19px !important;

        width: 170px;
      }

      > span {
        font-weight: 600;
      }
      // background: lightcoral;
    }

    .subtitle {
      font-size: 14px !important;
      font-weight: 600;
      color: $color-primary;
    }
  }

  .right {
    justify-content: flex-end;

    .actions {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;

      &.primary-actions {
        order: 1;

        > div {
          margin-left: 8px;
        }
      }
      &.secondary-actions {
        margin-right: 16px;
        border-right: 1px solid rgba($color-black, 0.08);
        padding: 0 16px 0 8px;

        @include for-size(phone-only) {
          padding: 0;
        }

        > div {
          margin-left: 8px;
        }
      }
    }
  }

  .v-divider {
    margin-left: 16px;
    margin-right: 8px;
  }
}
</style>
