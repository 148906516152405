<template>
  <div
    :class="[
      'card collaboration-card',
      {
        mediaCard: hasMedia,
        active: isCardActive,
        answer: isAnswer
      }
    ]"
    ref="card"
  >
    <div class="thumb card_media" v-if="hasMedia">
      <MediaCarousel :medias="collaboration.medias" />
    </div>
    <div class="card_content" v-if="!isAnswer">
      <div class="content-text" @click="showDetails">
        <div class="message" v-bind:style="messageLineStyling">
          <span class="collaboration-title" v-if="collaboration.body.title">{{
            `${collaboration.body.title} `
          }}</span>
          <span v-html="messageBody" />
        </div>
        <div v-if="collaboration.body.metadatas" class="mt-2">
          <v-chip
            class="group"
            label
            v-for="(item, i) in collaboration.body.metadatas"
            :key="i"
          >
            <div class="group-content">
              <div class="group-metadata">
                <div class="group-metadata--name">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <span v-on="on"> {{ item.value }}</span>
                    </template>
                    <span>{{ item.value }}</span>
                  </v-tooltip>
                </div>
                <div class="group-metadata--tag">
                  {{ item.field }}
                </div>
              </div>
            </div>
          </v-chip>
        </div>
      </div>
    </div>
    <div class="card_content" v-else>
      <div class="content-text">
        <div class="message">
          <span class="collaboration-title">COLABORATIVO: </span
          ><span v-html="collaboration.message" />
        </div>
      </div>
    </div>

    <div class="card_footer" v-if="!isAnswer">
      <div class="createdAt">
        <div class="date">{{ collaborationDate }}</div>
        <div class="time">{{ collaborationTime }}</div>
        <div class="social" :class="['ml-5', collaborationInputBadgeStyle]">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">
                {{ collaborationInputBadgeIcon }}
              </v-icon>
            </template>
            <span>{{ collaborationTypeDescription }}</span>
          </v-tooltip>
        </div>
      </div>
      <v-tooltip top v-if="flagInfo">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" class="mr-3" :color="flagInfo.color">{{
            flagInfo.icon
          }}</v-icon>
        </template>
        <span>{{ flagInfo.tooltip }}</span>
      </v-tooltip>
      <div class="actions">
        <CollaborationActions
          :collaboration="collaboration_"
          :currentCollaborator="curCollaborator"
          :index="index"
          ref="actions"
          @handle-active-item="isCardActive = $event"
        />
      </div>
    </div>
    <div class="card_footer" v-else>
      <div class="createdAt">
        <div class="date">{{ collaborationDate }}</div>
        <div class="time">{{ collaborationTime }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import twemoji from "twemoji";
import { Ripple } from "vuetify/lib/directives";
import { mapState } from "vuex";

import CollaborationActions from "@/components/collaborations/CollaborationActions";
import MediaCarousel from "@/components/MediaCarousel/";
import collaborationMixin from "@/mixins/collaboration";
import collaborationsActions from "@/mixins/collaborations_actions.js";
import { showCollaboration } from "@/util/collaborations";

const EMOJI_TYPES = ["twitter", "whatsapp"];

export default {
  name: "collaboration_conversation_card",
  components: { CollaborationActions, MediaCarousel },
  mixins: [collaborationMixin, collaborationsActions],
  directives: {
    Ripple
  },
  props: {
    index: {
      required: true
    },
    collaboration: {
      required: true
    },
    curCollaborator: {
      required: true
    }
  },
  data() {
    return {
      collaboration_: this.collaboration,
      isCardActive: false
    };
  },
  computed: {
    ...mapState({
      campaign: state => state.campaign.campaign,
      filters: state => state.campaign.tabs[0].filters
    }),
    isAnswer() {
      return this.collaboration.direction;
    },
    messageBody() {
      return EMOJI_TYPES.includes(this.curCollaborator?.type)
        ? twemoji.parse(this.collaboration.body.message)
        : this.collaboration.body.message;
    },
    flagInfo() {
      if (this.collaboration.is_rejected) {
        return {
          tooltip: "Colaboração rejeitada",
          color: "refuse",
          icon: "$collaborationRejectIcon"
        };
      }
      if (this.collaboration.was_approved) {
        return {
          tooltip: "Colaboração aprovada",
          color: "approve",
          icon: "$collaborationAproveIcon"
        };
      }
      if (
        this.collaboration.total_approvals === 0 &&
        this.campaign.required_approvals > 0
      ) {
        return {
          tooltip: "Colaboração em aprovação de nível 1",
          color: "approve",
          icon: "$approveLevelOneIcon"
        };
      }
      if (
        this.collaboration.total_approvals === 1 &&
        this.campaign.required_approvals > 0
      ) {
        return {
          tooltip: "Colaboração em aprovação de nível 2",
          color: "approve",
          icon: "$approveLevelTwoIcon"
        };
      }
      return null;
    }
  },
  watch: {
    collaboration() {
      this.collaboration_ = this.collaboration;
    },
    isCardActive(value) {
      this.$emit("overlay-cards", value);
    }
  },
  methods: {
    showDetails() {
      showCollaboration(this.index, this.collaboration);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/theme.scss";
@import "@/scss/cards.scss";

.overlayedCards {
  .card:not(.active) {
    transition: 0.3s ease-in-out;
    opacity: 0.7;
  }
}
.card {
  transition: all 0.3s ease-in-out !important;
  display: flex;
  flex-direction: column;
  margin: 8px !important;
  &:hover {
    transform: translateY(0) !important;
  }
  .card_header {
    margin: 0 !important;
    width: auto !important;
  }
  ::v-deep .card_media {
    .v-responsive__content {
      width: 356px !important;
    }
  }
  .card_content {
    margin-bottom: 8px !important;
    max-width: max-content !important;
    padding: 8px 10px !important;
    .content-text {
      margin-top: 8px !important;
    }
    .message {
      height: 100% !important;
      overflow: initial !important;
      -webkit-box-orient: initial !important;
    }
  }
  .card_footer {
    border-top: 1px solid rgba($color-gray-main, 0.1);
    background-color: rgba($color-gray-main, 0.1);
    margin: 0 !important;
    padding: 8px !important;
    width: auto !important;
    .date,
    .time {
      background-color: transparent !important;
    }
    .social {
      $mediaTypes: (
        colaborativo: #155b68,
        form: #8b35c1,
        twitter: $color-twitter,
        whatsapp: $color-whatsapp,
        wavy: $color-whatsapp-wavy,
        facebook: $color-facebook,
        instagram: $color-instagram,
        uploader: #284565,
        youtube: $color-youtube,
        others: $color-gray-lighter
      );

      @each $mediaType, $mediaColor in $mediaTypes {
        &.#{$mediaType} i {
          color: $mediaColor !important;
        }
      }
    }
  }
  .actions {
    align-items: center;
    .card_media {
      display: block;
      padding: 0;
      width: 356px;
      ::v-deep .v-window__container {
        width: 100%;
      }
    }
  }
  &.answer {
    color: #fff;

    background-color: rgba($color-primary, 0.64);
    .card_content {
      .message {
        .collaboration-title {
          color: #fff !important;
        }
        color: #f1f1f1 !important;
      }
    }
    .card_footer {
      background-color: rgba($color-primary, 0.48);
    }
  }
}
</style>
