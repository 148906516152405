import { mapState } from "vuex";

import { Output, Product } from "@/gateways";
import {
  LC_CAMPAIGNS_CLEAR_FILTER,
  LC_CAMPAIGNS_HANDLE_FILTER,
  LC_CAMPAIGNS_RESTORE_STATE,
  UI_SNACKBAR_SHOW
} from "@/store/actions";
import { sorting } from "@/util/sorting";

export default {
  data() {
    return {
      products: [],
      outputs: [],
      inputs: [
        { name: "Canal Globo", id: "canalglobo" },
        { name: "Formulário", id: "form" },
        { name: "Twitter", id: "twitter" },
        { name: "Uploader", id: "uploader" },
        { name: "WhatsApp", id: "whatsapp" },
        { name: "Youtube", id: "youtube" },
        { name: "Instagram", id: "instagram" }
      ]
    };
  },
  computed: {
    ...mapState({
      filterData: state => state.listCampaign.filter_data
    }),
    filter_data: {
      get() {
        return this.filterData;
      },
      set(value) {
        this.$store.disptach(LC_CAMPAIGNS_HANDLE_FILTER, { newFilter: value });
      }
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await Promise.all([Product.get(), Output.get()]);
        [this.products, this.outputs] = response.map(res =>
          res.data.map(i => ({ id: i.id, name: i.name }))
        );
        this.products.sort(sorting.orderByName);
        this.outputs.sort(sorting.orderByName);
      } catch {
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message:
            "Não foi possível carregar os dados para montagem dos filtros. Por favor, tente novamente em alguns minutos."
        });
      }
    },
    cleanFilter() {
      this.$store.dispatch(LC_CAMPAIGNS_CLEAR_FILTER);
      this.$store.dispatch(LC_CAMPAIGNS_RESTORE_STATE);
    }
  }
};
