export const campaignCardCaptureIcons = [
  {
    icon: "web",
    label: "Formulário",
    condition: "form_configuration"
  },
  {
    icon: "v-icon icon-icon-canalglobo",
    label: "Canal Globo",
    condition: "form_configuration"
  },
  {
    icon: "mdi-whatsapp",
    label: "Whatsapp",
    condition: "whatsapp_configuration"
  },
  { icon: "mdi-twitter", label: "Twitter", condition: "twitter_configuration" },
  {
    icon: "mdi-instagram",
    label: "Instagram",
    condition: "instagram_configuration"
  },
  { icon: "mdi-youtube", label: "Youtube", condition: "youtube_configuration" },
  {
    iconClass: "v-icon icon-icon-uploader",
    label: "Uploader",
    labelComplemnent: true,
    condition: "uploader_web_configuration",
    seccondCondition: "uploader_configuration"
  }
];

export const campaignAreaFilter = {
  title: "filtro de área",
  boxWidth: "--small",
  drawerTitle: "Área",
  header: "Clique para digitar uma área",
  chipTitle: "área",
  color: "areaFilterColor",
  controller: "area_name",
  label: "Digite uma área",
  isText: true
};

export const campaigGroupFilter = {
  hasSearch: true,
  seeOption: "showAllProducts",
  searchController: "",
  title: "Grupo / Editoria",
  boxWidth: "--medium",
  drawerTitle: "Grupo",
  header: "Escolha uma ou mais opções de grupos",
  chipTitle: "grupo",
  color: "groupFilterColor",
  controller: "group_ids_selected",
  label: "Procure um grupo",
  items: "products",
  drawerItems: "filteredProducts"
};

export const campaigInputFilter = {
  seeOption: "showAllInputs",
  searchController: "",
  title: "Filtro de entrada",
  boxWidth: "--medium",
  drawerTitle: "Entrada",
  header: "Escolha uma ou mais entradas",
  chipTitle: "entrada",
  color: "inputFilterColor",
  controller: "input_types_selected",
  label: "Procure uma entrada",
  items: "inputs",
  drawerItems: "filteredInputs"
};

export const campaigOutputFilter = {
  hasSearch: true,
  seeOption: "showAllOutputs",
  searchController: "",
  title: "Filtro de saída",
  boxWidth: "--medium",
  drawerTitle: "Saída",
  header: "Escolha um ou mais destinos",
  chipTitle: "saída",
  color: "outputFilterColor",
  controller: "output_ids_selected",
  label: "Procure um grupo",
  items: "outputs",
  drawerItems: "filteredOutputs"
};

export const campaignFilters = [
  { ...campaignAreaFilter },
  { ...campaigGroupFilter },
  { ...campaigInputFilter },
  { ...campaigOutputFilter }
];
