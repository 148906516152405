import {
  Campaign,
  MediaPolicy,
  Output,
  Product,
  SftpServer,
  User
} from "@/gateways";
import vue from "@/main";
import Analytics from "@/services/analytics";
import {
  CC_CAMPAIGN_CREATE_OR_UPDATE_FORMDATA_ITEM_SET,
  CC_CAMPAIGN_CREATE_OR_UPDATE_FORMDATA_NESTED_ITEM_SET,
  CC_CAMPAIGN_CREATE_OR_UPDATE_FORMDATA_SET,
  CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_RESET,
  CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_SET,
  LC_CAMPAIGNS_ERROR_SET,
  LC_CAMPAIGNS_REFRESH_MUTATION,
  LC_CAMPAIGNS_RESTORE_STATE_MUTATION,
  LC_CAMPAIGNS_SAVE_STATE_MUTATION,
  LC_CAMPAIGNS_SET,
  LC_DATA_SORT,
  LC_SET_CURRENT_CAMPAIGN,
  MT_CAMPAIGN_SET_TOTAL_CAMPAIGNS_AVAILABLE,
  MT_CAMPAIGNS_CLEAR_SEARCH,
  MT_CAMPAIGNS_SET_FILTER
} from "@/store/mutations";
import { newCampaignObject } from "@/util/campaign";
import Bus from "@/util/EventBus";
import { sorting } from "@/util/sorting";
import { decryptWhatsappConfigurationData } from "@/util/whatsapp";

import {
  CC_CAMPAIGN_CREATE_OR_UPDATE_FORMDATA_HANDLE,
  CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_CLEAN_HANDLE,
  CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_HANDLE,
  LC_CAMPAIGN_FILTER_BY_SEARCH_TEXT,
  LC_CAMPAIGNS_CLEAR_FILTER,
  LC_CAMPAIGNS_CLEAR_SEARCH,
  LC_CAMPAIGNS_GET_BY_FILTER,
  LC_CAMPAIGNS_HANDLE_FILTER,
  LC_CAMPAIGNS_PUT,
  LC_CAMPAIGNS_REFRESH,
  LC_CAMPAIGNS_REMOVE,
  LC_CAMPAIGNS_RESTORE_STATE,
  LC_CAMPAIGNS_SAVE_STATE,
  LC_CAMPAIGNS_SET_RAW,
  LC_CAMPAIGNS_SNAPSHOT_DELETE,
  LC_CAMPAIGNS_SNAPSHOT_GET_ALL,
  LC_CAMPAIGNS_SNAPSHOT_RESTORE,
  LC_DATA_LOAD,
  UI_SNACKBAR_SHOW
} from "../store/actions";

const default_filter_data = () => ({
  group_ids_selected: [],
  input_types_selected: [],
  output_ids_selected: [],
  area_name: "",
  is_enabled: null,
  search_text_in_campaign: "",
  sort_by: "updated_at",
  next_page_token: null,
  size: Number(process.env.VUE_APP_CAMPAIGNS_PER_PAGE) || 20,
  favorite: false
});

const queryTofilterCampaign = (filter_data, refresh) => {
  const {
    is_enabled,
    search_text_in_campaign,
    sort_by,
    next_page_token,
    area_name,
    group_ids_selected,
    input_types_selected,
    output_ids_selected,
    size,
    favorite
  } = filter_data;

  const queries = [
    ...group_ids_selected.map(product => `group_ids=${product}`),
    ...input_types_selected.map(input => `input_types=${input}`),
    ...output_ids_selected.map(output => `output_ids=${output}`)
  ];

  if (sort_by) {
    queries.push(`sort_by=${sort_by}`);
  }
  if (is_enabled !== null) {
    queries.push(`is_enabled=${is_enabled}`);
  }
  if (favorite) {
    queries.push(`favorite=${favorite}`);
  }

  if (search_text_in_campaign)
    queries.push(`search_text_in_campaign=${search_text_in_campaign}`);
  if (next_page_token && !refresh)
    queries.push(`next_page_token=${next_page_token}`);
  if (area_name && area_name.length)
    queries.push(`area_name=${area_name.replace(/[&=!@#$%*]+/g, "")}`);
  queries.push(`size=${size}`);
  return queries.join("&");
};

export function loadDefaultState() {
  return {
    campaigns: [],
    listCampaignsError: false,
    sortParam: 0,
    createOrEditCampaignParams: {
      products: [],
      outputs: [],
      sftp: [],
      users: [],
      mediapolicy: [],
      originalDisclaimerUrl: null,
      campaignCurrentPoster: null,
      formData: newCampaignObject()
    },
    oldCampaigns: [],
    search_text: "",
    filter_data: default_filter_data(),
    totalCampaignsAvaialable: 0,
    requestingCampaignData: false
  };
}

export default {
  state: loadDefaultState(),
  getters: {
    totalCampaignsAvaialable: state => state.totalCampaignsAvaialable,
    campaigns: state => state.campaigns,
    sortedCampaigns: state =>
      state.sortParam == 1
        ? [...state.campaigns].sort(sorting.orderByName)
        : state.campaigns,
    is_filtred: state => !!state.oldCampaigns.length,
    filter_data: state => state.filter_data,
    search_text: state => state.search_text,
    campaignNameById: state => id =>
      state.campaigns.find(c => c.id == id)?.name,
    createOrUpdateSortedUser: state =>
      state.createOrEditCampaignParams.users.sort(sorting.orderByName),
    createOrUpdateSortedApiClients: state =>
      state.createOrEditCampaignParams.outputs.sort(sorting.orderByName),
    createOrUpdateSortedSftpServers: state =>
      state.createOrEditCampaignParams.sftp.sort(sorting.orderByName),
    createOrUpdateSortedProducts: state =>
      state.createOrEditCampaignParams.products.sort(sorting.orderByName),
    createOrUpdateSortedMediaPolicies: state =>
      state.createOrEditCampaignParams.mediapolicy.sort(sorting.orderByName)
  },
  actions: {
    [LC_CAMPAIGNS_SNAPSHOT_RESTORE]: async ({ dispatch }, campaign_id) => {
      try {
        await Campaign.snapshot.restore(campaign_id);
        dispatch(UI_SNACKBAR_SHOW, {
          message: "Campanha restaurada com sucesso!"
        });
        vue.$gtag.event("CAMPAIGN_RESTORED");
        return true;
      } catch (err) {
        const { response } = err;
        dispatch(UI_SNACKBAR_SHOW, {
          message:
            response?.data?.detail || "Houve um problema ao restaurar snapshot."
        });
        return false;
      }
    },
    [LC_CAMPAIGNS_SNAPSHOT_GET_ALL]: async ({ dispatch }) => {
      try {
        return (await Campaign.snapshot.getAll()).data;
      } catch (err) {
        const { response } = err;
        dispatch(UI_SNACKBAR_SHOW, {
          message:
            response?.data?.detail ||
            "Houve um problema obter lista de snapshots."
        });
      }
    },
    [LC_CAMPAIGNS_SNAPSHOT_DELETE]: async ({ dispatch }, campaign_id) => {
      try {
        await Campaign.snapshot.delete(campaign_id);
        dispatch(UI_SNACKBAR_SHOW, {
          message: "Snapshot apagado com sucesso!"
        });
        return true;
      } catch (err) {
        const { response } = err;
        dispatch(UI_SNACKBAR_SHOW, {
          message:
            response?.data?.detail || "Houve um problema ao apagar snapshot."
        });
        return false;
      }
    },
    [LC_CAMPAIGNS_GET_BY_FILTER]: async (
      { commit, dispatch, state },
      {
        messageError = "Nenhuma campanha encontrada para o filtro selecionado.",
        tryPagination = false,
        refresh = false
      }
    ) => {
      if (state.requestingCampaignData) {
        return;
      }
      try {
        state.requestingCampaignData = true;
        const parameters = queryTofilterCampaign(state.filter_data, refresh);
        const result = (await Campaign.get(null, parameters)).data;
        if (result) {
          const {
            total_campaign_available,
            campaign_datas,
            next_page
          } = result;
          commit(
            MT_CAMPAIGN_SET_TOTAL_CAMPAIGNS_AVAILABLE,
            total_campaign_available
          );
          commit(LC_CAMPAIGNS_ERROR_SET, false);
          dispatch(LC_CAMPAIGNS_SAVE_STATE);
          let newCampaigns = campaign_datas;
          if (tryPagination) {
            newCampaigns = [...state.campaigns, ...newCampaigns];
          }

          if (next_page) {
            commit(MT_CAMPAIGNS_SET_FILTER, {
              ...state.filter_data,
              next_page_token: next_page
            });
          }

          if (newCampaigns.length === 0) {
            commit(LC_CAMPAIGNS_ERROR_SET, true);
          }

          if (state.totalCampaignsAvaialable > 0 && newCampaigns.length === 0) {
            dispatch(LC_CAMPAIGNS_RESTORE_STATE);
            dispatch(UI_SNACKBAR_SHOW, {
              message: messageError
            });
          } else {
            commit(LC_CAMPAIGNS_SET, newCampaigns);
          }
        } else {
          commit(LC_CAMPAIGNS_ERROR_SET, true);
          dispatch(UI_SNACKBAR_SHOW, {
            message: messageError
          });
        }
      } finally {
        state.requestingCampaignData = false;
      }
    },
    [LC_DATA_LOAD]: async ({ commit }) => {
      const result = (await Campaign.get()).data;

      if (!result) {
        return commit(LC_CAMPAIGNS_ERROR_SET, true);
      }
      commit(LC_CAMPAIGNS_ERROR_SET, false);
      commit(LC_CAMPAIGNS_SET, result);
    },
    [LC_CAMPAIGN_FILTER_BY_SEARCH_TEXT]: async (
      { state, dispatch, commit },
      searchTextInCampaign
    ) => {
      commit(MT_CAMPAIGNS_SET_FILTER, {
        ...state.filter_data,
        next_page_token: null,
        search_text_in_campaign: searchTextInCampaign
      });
      dispatch(LC_CAMPAIGNS_GET_BY_FILTER, {});
    },
    [LC_CAMPAIGNS_PUT]: async ({ commit }, campaignId) => {
      const campaign = (await Campaign.get(campaignId)).data;
      commit(LC_CAMPAIGNS_PUT, campaign);
    },
    [LC_CAMPAIGNS_REFRESH]: async ({ commit }, campaignId) => {
      const campaign = (await Campaign.get(campaignId)).data;
      commit(LC_CAMPAIGNS_REFRESH_MUTATION, campaign);
    },
    [LC_CAMPAIGNS_SAVE_STATE]: ({ commit, state }) => {
      if (!state.oldCampaigns.length) {
        commit(LC_CAMPAIGNS_SAVE_STATE_MUTATION);
      }
    },
    [LC_CAMPAIGNS_RESTORE_STATE]: ({ commit, state }) => {
      if (state.oldCampaigns.length)
        commit(LC_CAMPAIGNS_RESTORE_STATE_MUTATION);
    },
    [LC_CAMPAIGNS_SET_RAW]: ({ commit }, results) => {
      commit(LC_CAMPAIGNS_SET, results);
    },
    [LC_CAMPAIGNS_CLEAR_FILTER]: ({ commit }) => {
      commit(MT_CAMPAIGNS_SET_FILTER, default_filter_data());
    },
    [LC_CAMPAIGNS_HANDLE_FILTER]: (
      { commit, dispatch },
      { newFilter = default_filter_data(), forceFetch = true }
    ) => {
      newFilter.next_page_token = null;
      commit(MT_CAMPAIGNS_SET_FILTER, newFilter);
      if (forceFetch) dispatch(LC_CAMPAIGNS_GET_BY_FILTER, {});
    },
    [LC_CAMPAIGNS_CLEAR_SEARCH]: ({ commit }) => {
      commit(MT_CAMPAIGNS_CLEAR_SEARCH);
    },

    [LC_CAMPAIGNS_REMOVE]: async ({ state, dispatch, commit }, campaign_id) => {
      // if (!confirm("Você tem certeza que deseja apagar essa campanha?")) return;
      const configMessage = {
        icon: "delete_forever",
        title: {
          featured: "Remover para sempre",
          text: "essa Campanha"
        },
        message: {
          featured: "Você tem certeza que deseja Remover essa campanha?",
          text: `Essa ação irá remover a campanha do Colaborativo e nenhum outro usuário poderá consultá-la novamente.`
        },
        action: {
          color: "error",
          title: "Remover para todos"
        }
      };
      Bus.$emit("openModalConfirm", configMessage, async callback => {
        if (callback) {
          try {
            await Campaign.delete(campaign_id);
            dispatch("UI_SNACKBAR_SHOW", {
              message: "Campanha apagada com sucesso"
            });
            commit(
              LC_CAMPAIGNS_SET,
              state.campaigns.filter(c => c.id !== campaign_id)
            );
            Analytics.deleteCampaign(campaign_id);
          } catch (err) {
            const { response } = err;
            switch (response.status) {
              case 403:
                dispatch("UI_SNACKBAR_SHOW", {
                  message:
                    "Você não possui permissão para apagar esta campanha."
                });
                break;
              default:
                dispatch("UI_SNACKBAR_SHOW", {
                  message:
                    "Ops... Tivemos um problema no servidor. Por favor, tente novamente em alguns instantes :)"
                });
                break;
            }
          }
        }
      });
    },
    [CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_CLEAN_HANDLE]: ({ commit }) => {
      commit(CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_RESET);
    },
    [CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_HANDLE]: async (
      { commit },
      payload
    ) => {
      const _MediaPolicy = payload.isEditMode
        ? MediaPolicy.get("", `campaign_id=${payload.campaignEditId}`)
        : MediaPolicy.get();
      const promises = [
        Product.get(),
        Output.get(),
        SftpServer.get(),
        User.get(),
        _MediaPolicy
      ];

      let campaignPromise;

      if (payload.isEditMode) {
        campaignPromise = Campaign.get(payload.campaignEditId);
        promises.push(campaignPromise);
      }
      const results = await Promise.all(promises);

      for (let result of results) {
        const name = result.config.url.substring(1);
        if (!name.match(/campaigns\/\d+/)) {
          commit(CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_SET, {
            paramToBeChanged: name,
            value: result.data
          });
        }
      }

      if (payload.isEditMode) {
        const campaignData = (await campaignPromise).data;
        commit(CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_SET, {
          paramToBeChanged: "campaignCurrentPoster",
          value: campaignData.poster
        });

        // basic info
        const product_id = campaignData.product.id;

        // moderation
        const moderators = campaignData.moderators.map(i => i.id);

        // file formats
        const { collaboration_expiration } = campaignData;

        // integrations
        const inputs = campaignData.inputs.map(i => i.id);
        const outputs = campaignData.outputs.map(i => i.id);
        const api_statistics = campaignData.api_statistics.map(i => i.id);
        const { sftp_id } = campaignData;

        // input source
        let whatsapp_configuration = {
          ...campaignData.whatsapp_configuration,
          id_associates: campaignData.whatsapp_configuration.whatsapps.map(
            i => i.id
          ),
          blocked_phones: decryptWhatsappConfigurationData(
            campaignData.whatsapp_configuration.blocked_phones
          ),
          accepted_phones: decryptWhatsappConfigurationData(
            campaignData.whatsapp_configuration.accepted_phones
          ),
          whatsapps: decryptWhatsappConfigurationData(
            campaignData.whatsapp_configuration.whatsapps
          )
        };

        whatsapp_configuration.blocked_words.sort(sorting.orderByName);

        let instagram_configuration = {
          ...campaignData.instagram_configuration
        };
        let twitter_terms = campaignData.twitter.terms.concat(
          campaignData.twitter.users.map(i => i.screen_name)
        );
        twitter_terms.sort();
        const retweet_is_enabled = campaignData.twitter.retweet;
        const twitter_blacklist = campaignData.twitter.blacklist;
        twitter_blacklist.sort(sorting.orderByName);

        commit(CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_SET, {
          paramToBeChanged: "originalDisclaimerUrl",
          value: campaignData.disclaimer
        });

        const disclaimer = {
          filename: campaignData.disclaimer
            ? campaignData.disclaimer.split("/").pop()
            : null,
          url: campaignData.disclaimer || null
        };

        const collaborators = campaignData.collaborators.map(i => i.id);

        // metadata
        const metadata = campaignData.metadata || [];
        const uploader_web_configuration = {
          is_enabled: campaignData.uploader_web_configuration.is_enabled,
          aspera_is_enabled:
            campaignData.uploader_web_configuration.aspera_is_enabled || false
        };

        if (
          campaignData.form_configuration.canal_globo_twitter_config === null
        ) {
          campaignData.form_configuration.canal_globo_twitter_config = {
            hashtags: []
          };
        }

        const newFormData = {
          id: campaignData.id,
          name: campaignData.name,
          collaboration_expiration,
          disclaimer,
          description: campaignData.description,
          tags: campaignData.tags,
          product_id: product_id,
          is_enabled: campaignData.is_enabled,
          required_approvals: campaignData.required_approvals,
          collaborators: collaborators,
          moderators: moderators,
          inputs: inputs,
          outputs: outputs,
          api_statistics: api_statistics,
          sftp_id: sftp_id,
          twitter_configuration: campaignData.twitter_configuration,
          twitter_terms,
          retweet_is_enabled,
          twitter_blacklist,
          form_configuration: campaignData.form_configuration,
          uploader_configuration: campaignData.uploader_configuration,
          notification_is_enabled:
            campaignData?.notification?.is_enabled || null,
          poster: {
            filename: null,
            url: null
          },
          uploader_web_configuration,
          whatsapp_configuration,
          instagram_configuration,
          collaboration_limit: campaignData.collaboration_limit,
          filesize_max: campaignData.filesize_max,
          filecount_min: campaignData.filecount_min,
          filecount_max: campaignData.filecount_max,
          image_wanted: campaignData.image_wanted,
          video_wanted: campaignData.video_wanted,
          audio_wanted: campaignData.audio_wanted,
          text_wanted: campaignData.text_wanted,
          application_wanted: campaignData.application_wanted,
          media_policies: campaignData.media_policies,
          metadata,
          twitter: twitter_blacklist,
          youtube_configuration: campaignData.youtube_configuration
        };
        commit(CC_CAMPAIGN_CREATE_OR_UPDATE_FORMDATA_SET, newFormData);
      }
    },
    [CC_CAMPAIGN_CREATE_OR_UPDATE_FORMDATA_HANDLE]: ({ commit }, payload) => {
      commit(
        payload.isNestedItem
          ? CC_CAMPAIGN_CREATE_OR_UPDATE_FORMDATA_NESTED_ITEM_SET
          : CC_CAMPAIGN_CREATE_OR_UPDATE_FORMDATA_ITEM_SET,
        payload
      );
    }
  },
  mutations: {
    [LC_CAMPAIGNS_SET]: (state, campaigns) => {
      for (let c of campaigns) {
        c.whatsapp_configuration.whatsapps = decryptWhatsappConfigurationData(
          c.whatsapp_configuration.whatsapps
        );
      }
      state.campaigns = campaigns;
    },
    [MT_CAMPAIGNS_SET_FILTER]: (state, payload) => {
      state.filter_data = payload;
    },
    [LC_CAMPAIGNS_ERROR_SET]: (state, payload) => {
      state.listCampaignsError = payload;
    },
    [LC_CAMPAIGNS_PUT]: (state, campaign) => {
      state.campaigns.unshift(campaign);
    },
    [LC_CAMPAIGNS_REFRESH_MUTATION]: (state, campaign) => {
      var index = state.campaigns.findIndex(
        element => element.id === campaign.id
      );
      state.campaigns.splice(index, 1);
      state.campaigns.unshift(campaign);
    },
    [LC_CAMPAIGNS_SAVE_STATE_MUTATION]: state => {
      state.oldCampaigns = [...state.campaigns];
    },
    [LC_CAMPAIGNS_RESTORE_STATE_MUTATION]: state => {
      state.campaigns = [...state.oldCampaigns];
      state.oldCampaigns = [];
    },
    [LC_DATA_SORT]: (state, parameter) => (state.sortParam = parameter),
    [MT_CAMPAIGNS_CLEAR_SEARCH]: state => {
      state.filter_data = { ...state.filter_data, search_text_in_campaign: "" };
    },
    [LC_SET_CURRENT_CAMPAIGN]: (state, payload) => {
      state.current_campaign = payload;
    },
    [CC_CAMPAIGN_CREATE_OR_UPDATE_FORMDATA_SET]: (state, payload) => {
      state.createOrEditCampaignParams.formData = payload;
    },
    [CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_SET]: (state, payload) => {
      state.createOrEditCampaignParams[payload.paramToBeChanged] =
        payload.value;
    },
    [CC_CAMPAIGN_CREATE_OR_UPDATE_PARAMS_RESET]: state => {
      state.createOrEditCampaignParams.formData = newCampaignObject();
      state.createOrEditCampaignParams.campaignCurrentPoster = null;
      state.createOrEditCampaignParams.originalDisclaimerUrl = null;
    },
    [CC_CAMPAIGN_CREATE_OR_UPDATE_FORMDATA_ITEM_SET]: (state, payload) => {
      state.createOrEditCampaignParams.formData[payload.paramToBeChanged] =
        payload.value;
    },
    [CC_CAMPAIGN_CREATE_OR_UPDATE_FORMDATA_NESTED_ITEM_SET]: (
      state,
      payload
    ) => {
      state.createOrEditCampaignParams.formData[payload.paramToBeChanged][
        payload.nestedParamToBeChanged
      ] = payload.value;
    },
    [MT_CAMPAIGN_SET_TOTAL_CAMPAIGNS_AVAILABLE]: (
      state,
      totalCampaignsAvaialable
    ) => {
      state.totalCampaignsAvaialable = totalCampaignsAvaialable;
    }
  }
};
