export const availableFloatingFilters = [
  {
    type: "campaign",
    filters: [
      {
        icon: "mdi-eye-outline",
        label: "Todas as campanhas",
        value: 0
      },
      {
        icon: "mdi-eye-check-outline",
        label: "Campanhas Ativas",
        value: true
      },
      {
        icon: "mdi-eye-minus-outline",
        label: "Campanhas Inativas",
        value: false
      },
      {
        icon: "mdi-heart-outline",
        label: "Campanhas favoritas",
        value: "favorite"
      }
    ],
    orderings: [
      {
        icon: "mdi-sort-clock-ascending-outline",
        label: "Mais recentes",
        value: 0
      },
      {
        icon: "mdi-sort-alphabetical-variant",
        label: "Ordem Alfabética",
        value: 1
      }
    ]
  },

  {
    type: "collaborations",
    filters: [
      {
        icon: "$approveLevelOneIcon",
        label: "Nível 1 de moderação"
      },
      {
        icon: "$approveLevelTwoIcon",
        label: "Nível 2 de moderação"
      },
      {
        icon: "$collaborationAproveIcon",
        label: "Colaborações Aprovadas"
      },
      {
        icon: "$collaborationRejectIcon",
        label: "Colaborações Rejeitadas"
      },
      {
        icon: "mdi-heart",
        label: "Colaborações Favoritas"
      }
    ],
    viewModes: [
      {
        icon: "$cards",
        label: "Cards",
        value: "card"
      },
      {
        icon: "$table",
        label: "Tabela",
        value: "table"
      },
      {
        icon: "$conversation",
        label: "Conversas",
        value: "conversation"
      }
    ],
    densityLevels: [
      {
        icon: "mdi-view-sequential",
        label: "Pouco espaçado",
        level: 0
      },
      {
        icon: "mdi-view-stream",
        label: "Espaçado",
        level: 1
      },
      {
        icon: "mdi-view-agenda",
        label: "Muito espaçado",
        level: 2
      }
    ]
  }
];
