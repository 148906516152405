<template>
  <v-btn
    large
    icon
    color="graydark"
    :disabled="!!disabled"
    @click.stop="clickHandler"
  >
    <v-icon dark>{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "ButtonIcon",
  data() {
    return {};
  },
  props: {
    icon: String,
    disabled: Boolean
  },
  computed: {},
  methods: {
    clickHandler() {
      this.$emit("click-handler");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";
@import "@/scss/form-inputs.scss";
</style>
