<template>
  <!-- v-click-outside="clickHandler" -->
  <v-navigation-drawer
    v-model="drawerControl"
    app
    right
    temporary
    overlay-opacity="0.88"
    width="680"
  >
    <div class="modal full fixed">
      <div class="modal-header">
        <div class="title--max">
          {{ mainTitleFirstWord(title)
          }}<span>{{ restOfTheMainTitle(title) }}</span>
        </div>
        <div class="modal-header__actions">
          <ButtonSecondary
            icon="mdi-arrow-left"
            label="Voltar"
            @click-handler="toggleDrawer"
          ></ButtonSecondary>
          <ButtonPrimary
            class="ml-4"
            icon="mdi-content-save"
            label="Salvar"
            @click-handler="handleSaveChanges(false)"
            v-if="hasSaveButton"
          ></ButtonPrimary>
        </div>
      </div>
      <div class="modal-content" :class="{ fullHeight: isFullHeight }">
        <v-form :ref="formRef">
          <slot name="drawer-content"></slot>
        </v-form>
        <Dialog
          :dialog="isEditConfirmDrawerVisible"
          title="Confirmar edição vazia"
          subtitle="Todos os campos estão vazios"
          :description="dialogDescription"
          @toggle-dialog="toggleDialog($event)"
        >
          <template v-slot:modal-actions>
            <ButtonSecondary
              icon="mdi-close-circle-outline"
              label="Cancelar"
              @click-handler="cancelEdit"
            ></ButtonSecondary>
            <ButtonPrimary
              class="ml-4"
              icon="mdi-content-save"
              label="Salvar"
              @click-handler="handleSaveChanges(true)"
            ></ButtonPrimary>
          </template>
        </Dialog>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";

import ButtonPrimary from "@/components/Commons/Buttons/ButtonPrimary";
import ButtonSecondary from "@/components/Commons/Buttons/ButtonSecondary";
import Dialog from "@/components/Commons/Dialogs/Dialog";
import { isValidForm } from "@/util/campaign";

export default {
  components: {
    ButtonPrimary,
    ButtonSecondary,
    Dialog
  },
  data() {
    return {
      isEditConfirmDrawerVisible: false
    };
  },
  props: {
    drawer: Boolean,
    title: {
      type: String,
      required: true
    },
    drawerName: {
      type: String,
      default: ""
    },
    formRef: {
      type: String,
      default: ""
    },
    areAllFieldsEmpty: {
      type: Boolean,
      default: false
    },
    hasSaveButton: {
      type: Boolean,
      default: true
    },
    isFullHeight: Boolean
  },
  computed: {
    ...mapState({
      formData: state => state.listCampaign.createOrEditCampaignParams.formData
    }),
    drawerControl: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.toggleDrawer(value);
      }
    },
    dialogDescription() {
      return `Ao prosseguir aceitando a edição,
        todos os campos terão seus valores vazios, e o canal de
        entrada (${this.drawerName}) será desativado, deseja prosseguir?`;
    }
  },
  watch: {
    areAllFieldsEmpty(value) {
      if (value) {
        this.$emit("reset-enabler", true);
      }
    }
  },
  methods: {
    mainTitleFirstWord(title) {
      const [firstWord] = title.split(" ");
      return `${firstWord} `;
    },
    restOfTheMainTitle(title) {
      const [, ...otherWords] = title.split(" ");
      return otherWords.join(" ");
    },
    handleSaveChanges(proceedButtonClicked) {
      if (!isValidForm(this.$refs[this.formRef])) {
        return;
      }
      if (!proceedButtonClicked && this.areAllFieldsEmpty) {
        this.toggleDialog(true);
        return;
      }
      if (proceedButtonClicked) {
        this.$emit("reset-enabler", false);
      }
      this.$emit("hard-update");
      this.toggleDrawer(false);
    },
    cancelEdit() {
      this.$emit("reset-changes");
      this.toggleDrawer(false);
    },
    toggleDialog(value) {
      this.isEditConfirmDrawerVisible = value;
    },
    toggleDrawer(value) {
      this.$emit("toggle-drawer", value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";
@import "@/scss/modal.scss";
@import "@/scss/form-inputs.scss";
</style>
