<template>
  <div class="base-card">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BaseCard"
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";
@import "@/scss/basecard.scss";
</style>
