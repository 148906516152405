<template>
  <div class="filters-section --campaign">
    <div class="filters-items--mobile" v-show="$vuetify.breakpoint.smAndDown">
      <v-chip label ripple @click="openDrawer" :color="filterDrawerColor">
        filtros
        <div class="badge"></div>
        <v-icon right>mdi-sort-variant</v-icon>
      </v-chip>
    </div>

    <div class="filters-items" v-show="$vuetify.breakpoint.mdAndUp">
      <!-- Grupo -->
      <v-menu
        v-for="(filter, index) in campaignFiltersDefinition"
        :key="index"
        offset-y
        transition="slide-y-transition"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-chip label ripple v-on="on" :color="selectColor(filter.color)">
            {{ filter.chipTitle }}
            <v-icon right>mdi-chevron-down</v-icon>
          </v-chip>
        </template>

        <div class="dropdown" :class="[filter.boxWidth]">
          <div class="dropdown-header">{{ filter.header }}</div>
          <v-text-field
            v-if="filter.hasSearch"
            class="pl-5 pr-5"
            label="Pesquisar"
            prepend-inner-icon="mdi-magnify"
            v-model="filter.searchController"
            clearable
          />
          <div class="dropdown-content">
            <div class="list-item">
              <v-text-field
                v-if="filter.isText"
                @change="updateFilterChange($event, filter.controller)"
                :label="filter.label"
                outlined
              />
              <v-list v-else>
                <v-list-item-group
                  v-model="local_filter_data[filter.controller]"
                  @change="updateFilterChange($event, filter.controller)"
                  multiple
                  color="primary"
                >
                  <v-list-item
                    v-for="({ id, name }, i) in selectItems(filter)"
                    :key="i"
                    :value="id"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ name }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-checkbox :input-value="active" color="primary" />
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </div>
        </div>
      </v-menu>
    </div>
    <div class="filters-clear" v-show="$vuetify.breakpoint.mdAndUp">
      <v-tooltip bottom max-width="56px">
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            ripple
            class="--back-white --local-btn"
            v-on="on"
            @click="clearLocalFilter"
          >
            <v-icon>mdi-filter-variant-remove</v-icon>
          </v-btn>
        </template>
        <span>limpar filtros</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import campaignsFilters from "@/mixins/campaigns_filters";
import {
  LC_CAMPAIGNS_HANDLE_FILTER,
  UI_CAMPAIGNS_FILTERS_DRAWER_HANDLE
} from "@/store/actions";
import { campaignFilters } from "@/util/campaigns";
export default {
  name: "CampaignFilters",
  mixins: [campaignsFilters],
  data() {
    return {
      local_filter_data: null,
      campaignFiltersDefinition: [...campaignFilters]
    };
  },
  beforeMount() {
    this.copyStoreFilterToLocalContext();
  },
  computed: {
    areaFilterColor() {
      return this.local_filter_data.area_name ? "primary" : "";
    },
    groupFilterColor() {
      return this.local_filter_data.group_ids_selected.length ? "primary" : "";
    },
    inputFilterColor() {
      return this.local_filter_data.input_types_selected.length
        ? "primary"
        : "";
    },
    outputFilterColor() {
      return this.local_filter_data.output_ids_selected.length ? "primary" : "";
    },
    filterDrawerColor() {
      return this.areaFilterColor ||
        this.groupFilterColor ||
        this.inputFilterColor ||
        this.outputFilterColor
        ? "primary"
        : "";
    }
  },
  methods: {
    updateFilterChange(newValue, field) {
      const newFilter = { ...this.filter_data };
      newFilter[field] = newValue;
      this.$store.dispatch(LC_CAMPAIGNS_HANDLE_FILTER, {
        newFilter
      });
      this.copyStoreFilterToLocalContext();
    },
    copyCampaignFilterDefinitionDefault() {
      this.campaignFiltersDefinition = [...campaignFilters];
    },
    clearLocalFilter() {
      this.cleanFilter();
      this.copyStoreFilterToLocalContext();
      this.copyCampaignFilterDefinitionDefault();
      this.$store.dispatch(LC_CAMPAIGNS_HANDLE_FILTER, {});
    },
    copyStoreFilterToLocalContext() {
      this.local_filter_data = { ...this.filter_data };
    },
    selectColor(color) {
      return this[color];
    },
    selectItems(filter) {
      const allPossibleValues = [...this[filter.items]] || [];
      if (
        filter.searchController === null ||
        filter.searchController.length === 0
      ) {
        return allPossibleValues;
      }
      return allPossibleValues.filter(item =>
        item?.name.toLowerCase().includes(filter.searchController.toLowerCase())
      );
    },
    openDrawer() {
      this.$store.dispatch(UI_CAMPAIGNS_FILTERS_DRAWER_HANDLE, true);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/headers.scss";

.v-list-item:not(:first-child) {
  border-top: 1px solid rgba($color-gray-main, 0.2);
  &:last-child {
    border-bottom: 1px solid rgba($color-gray-main, 0.2);
  }
}
::v-deep .v-input__prepend-inner {
  margin-top: 0 !important;
}
.v-item-group {
  flex-direction: column;
  max-height: 42vh;
  overflow-y: scroll;
}
.v-icon {
  color: $color-gray-main;
  font-size: 16px;
}
.v-list-item__title {
  color: $color-gray-main;
  font-size: 14px !important;
}
</style>
