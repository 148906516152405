<template>
  <v-dialog
    :value="showDialog"
    max-width="520"
    :persistent="executingCopy"
    @input="updateDialog"
  >
    <v-card>
      <v-card-title class="headline">Copiar colaboração</v-card-title>
      <v-card-text>
        Selecione as campanhas para onde deseja copiar a colaboração selecionada
        <br />
      </v-card-text>
      <v-card-text>
        <v-autocomplete
          :disabled="executingCopy || loadingCampaigns"
          v-model="campaignsSelected"
          filled
          hide-details
          draggable
          deletable-chips
          multiple
          color="primary"
          placeholder="Escolha uma ou mais campanhas..."
          :item-text="i => `${i.name}`"
          item-value="id"
          class="textfield"
          :items="campaigns"
        >
          <template
            v-slot:selection="{
              attrs,
              item,
              selected,
              parent
            }"
          >
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              @click:close="parent.selectItem(item)"
              color="primary"
            >
              <strong>{{ item.name }}</strong
              >&nbsp;
            </v-chip>
          </template>
        </v-autocomplete>
      </v-card-text>
      <v-card-text>
        <div class="loading" v-if="executingCopy || loadingCampaigns">
          <v-progress-linear
            indeterminate
            height="8"
            color="blue-grey"
          ></v-progress-linear>
        </div>
      </v-card-text>
      <v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            text
            color="primary"
            x-large
            @click="close"
            :disabled="executingCopy"
          >
            <v-icon right dark>mdi-close</v-icon>Fechar
          </v-btn>

          <v-btn
            :loading="executingCopy || loadingCampaigns"
            color="primary"
            @click.prevent.stop="handleCopyCollaboration"
            :disabled="copyDisabled"
            x-large
          >
            <v-icon right dark>content_copy</v-icon>Copiar
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import * as R from "ramda";

import { Campaign } from "@/gateways";

export default {
  props: ["value", "collaborationId"],

  data() {
    return {
      loadingCampaigns: false,
      executingCopy: false,
      campaignsSelected: [],
      campaigns: []
    };
  },

  computed: {
    showDialog() {
      return this.value;
    },
    copyDisabled() {
      return (
        !this.campaignsSelected ||
        this.executingCopy ||
        this.loadingCampaigns ||
        (this.campaignsSelected && this.campaignsSelected.length === 0)
      );
    }
  },

  watch: {
    value(newValue) {
      if (newValue) {
        this.initComponent();
      } else {
        this.cleanupComponent();
      }
    }
  },
  methods: {
    close() {
      this.updateDialog(false);
    },
    async initComponent() {
      this.loadingCampaigns = true;
      try {
        const sortByCampaignName = R.sortBy(
          R.compose(R.toLower, R.prop("name"))
        );
        this.campaigns = sortByCampaignName(
          (await Campaign.get(null, "seek_public=true", false)).data
        );
      } catch {
        this.$store.dispatch("UI_SNACKBAR_SHOW", {
          message: "Oops... Não conseguimos processar essa requisição..."
        });
        this.close();
      } finally {
        this.loadingCampaigns = false;
      }
    },

    async cleanupComponent() {
      this.loadingCampaigns = false;
      this.executingCopy = false;
      this.campaignsSelected = [];
      this.campaigns = [];
    },

    updateDialog(value) {
      this.$emit("input", value);
    },

    async handleCopyCollaboration() {
      this.executingCopy = true;
      try {
        const data = {
          collaboration: this.collaborationId,
          to_campaigns: this.campaignsSelected
        };
        await this.$store.dispatch("CAMPAIGN_COLLABORATIONS_COPY", data);
      } finally {
        this.executingCopy = false;
      }
      this.close();
    }
  }
};
</script>
