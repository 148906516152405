<template>
  <!-- Refactor Search -->
  <div class="search-wrapper">
    <v-chip-group
      v-model="hasChips"
      :class="hasChips ? '--has-chips' : '--no-chips'"
      class="search-tags"
      show-arrows
      ref="chipGroup"
    >
      <v-chip
        v-for="(item, i) in chips"
        :key="i"
        :input-value="null"
        close
        label
        class="primary"
        @click:close="removeTermItem(item)"
        v-on="(hasChips === true) | $emit('hasChipsHandler')"
        >{{ item }}
      </v-chip>
    </v-chip-group>

    <v-text-field
      v-if="isCollaborations"
      v-model="searchText"
      :placeholder="
        $vuetify.breakpoint.smAndDown
          ? 'Pesquisar...'
          : 'Digite e tecle enter para pesquisar...'
      "
      class="search-input"
      hide-details
      filled
      flat
      solo
      autocomplete="off"
      @keydown.tab.prevent="addTerm"
      @keyup.enter.prevent="addTerm"
      @keyup="removeTerm"
      prepend-inner-icon="mdi-magnify"
    />

    <v-text-field
      v-else
      @click:append="searchHandler"
      @click:clear="clearHandler"
      class="search-input"
      color="primary"
      flat
      filled
      autocomplete="off"
      hide-details
      clearable
      placeholder="Informe aqui sua busca"
      prepend-inner-icon="mdi-magnify"
      solo
      v-model="searchBarText"
      v-on:keyup.enter="searchHandler"
    ></v-text-field>

    <v-tooltip v-if="chips.length > 0" bottom max-width="56px">
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          ripple
          class="--back-white --local-btn search-clear"
          v-on="on"
          @click="removeAllChips"
        >
          <v-icon>mdi-backspace-outline</v-icon>
        </v-btn>
      </template>
      <span>limpar busca</span>
    </v-tooltip>
    <div v-if="chips.length > 0" class="separator --line"></div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import Analytics from "@/services/analytics";
import {
  CAMPAIGN_COLLABORATIONS_LOAD,
  CAMPAIGN_GROUPED_LOAD_COLLABORATORS,
  COLLABORATION_SEARCH_TERMS,
  LC_CAMPAIGN_FILTER_BY_SEARCH_TEXT,
  LC_CAMPAIGNS_CLEAR_FILTER,
  LC_DATA_LOAD
} from "@/store/actions";
import Bus from "@/util/EventBus";

export default {
  name: "Search",

  data() {
    return {
      /* loadingRefresh: false, */
      searchStrategi: null,
      searchBarText: null,
      searchTerms: [],
      chips: [],
      searchText: "",
      removeTermAllowed: false,
      hasChips: false
    };
  },

  computed: {
    ...mapState({
      campaignId: state => state.currentCampaign.campaign.id,
      filters: state => state.campaign.tabs[0].filters
    }),
    ...mapGetters(["search_terms", "viewMode"]),
    isCollaborations() {
      return this.searchStrategi === "campaignDetails";
    }
  },
  watch: {
    $route(novo) {
      this.changeSearchStrategi(novo.name);
    }
  },
  methods: {
    addTerm() {
      if (this.searchText && !this.chips.includes(this.searchText)) {
        this.chips.push(this.searchText);
        this.requestSearchTerms();
        this.searchText = "";
      }

      return;
    },
    removeTerm(event) {
      if (this.removeTermAllowed) {
        if (this.chips && event.keyCode === 8) {
          this.chips.pop();
          this.requestSearchTerms();
        }
      }
      this.removeTermAllowed = this.searchText.length === 0;
      this.hasChips = false;
    },
    removeTermItem(item) {
      const itemSearchRemove = this.chips.indexOf(item);
      this.chips.splice(itemSearchRemove, 1);
      this.requestSearchTerms();
      this.$refs.chipGroup.scrollOffset = 0;
    },
    removeAllChips() {
      this.chips = [];
      this.requestSearchTerms();
      this.hasChips = false;
    },
    changeSearchStrategi(routeName) {
      this.searchStrategi = routeName;
    },
    async searchHandler() {
      if (this.searchStrategi == "campaignlist") {
        this.$store.dispatch(
          LC_CAMPAIGN_FILTER_BY_SEARCH_TEXT,
          this.searchBarText
        );
      }
    },
    clearHandler() {
      this.searchBarText = null;
      if (this.searchStrategi == "campaignlist") {
        this.$store.dispatch(LC_DATA_LOAD);
      }
    },
    resetSeach() {
      this.searchBarText = null;
    },
    requestSearchTerms() {
      Analytics.filterCollaborations(
        this.filters.campaign_ids.length === 1
          ? this.filters.campaign_ids[0]
          : "MANY"
      );
      this.$store.dispatch(COLLABORATION_SEARCH_TERMS, this.chips);
      this.viewMode !== "conversation"
        ? this.$store.dispatch(CAMPAIGN_COLLABORATIONS_LOAD, {
            tryPaginating: false
          })
        : this.$store.dispatch(CAMPAIGN_GROUPED_LOAD_COLLABORATORS, {
            loadMoreCollaborators: false
          });
    }
  },
  mounted() {
    this.changeSearchStrategi(this.$route.name);
    this.$store.dispatch(LC_CAMPAIGNS_CLEAR_FILTER);

    this.resetSeach();

    Bus.$root.$on("removeTab", () => {
      this.searchTerms = this.search_terms;
      this.chips = this.search_terms;
    });
    Bus.$root.$on("updateTerms", () => {
      this.searchTerms = this.search_terms;
      this.chips = this.search_terms;
    });
  },
  created() {
    this.changeSearchStrategi(this.$route.name);
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/commons.scss";

// ---
// SEARCH SECTION / Search Input + Chips
// ---

.search-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex: 1;
  width: 100%;
  height: 56px;
  padding: 0 !important;
  margin: 0 !important;

  // Chips
  .v-chip-group {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto !important;
    max-width: 280px !important;
    height: 24px;
    box-sizing: border-box;

    &.--has-chips {
      border-right: solid 1px $color-border-divisor;
      margin: 0 8px;
      padding: 0 8px 0 0;
    }

    &.v-slide-group--is-overflowing {
      ::v-deep .v-slide-group__prev,
      ::v-deep .v-slide-group__next {
        min-width: 16px !important;
        flex: none !important;

        .v-icon {
          color: rgba($color-black, 0.4) !important;
          will-change: color;
          transition: color 0.4s ease-in-out;
        }

        // Hover
        &:hover {
          .v-icon {
            color: rgba($color-primary, 1) !important;
            will-change: color;
            transition: color 0.4s ease-in-out;
          }
        }

        // Disabled
        &.v-slide-group__prev--disabled,
        &.v-slide-group__next--disabled {
          .v-icon {
            color: rgba($color-black, 0.16) !important;
          }
        }
      }
    }

    .v-chip {
      flex: none;
      margin: 0 4px 0 0;
    }
  }

  // Search Input
  .search-input {
    flex: 1;
    height: 56px;
    min-width: 264px !important;
    border-radius: 0 !important;

    ::v-deep .v-input__control {
      .v-input__slot {
        height: 56px !important;
        min-height: 56px !important;
        width: 100%;
        padding: 0 !important;
        margin: 0 !important;
        box-sizing: border-box;
        border-radius: 0 !important;
        background-color: transparent !important;

        .v-input__icon--prepend-inner {
          margin: -16px 4px 0 0 !important;

          .v-icon {
            font-size: 1.4em !important;
          }
        }

        &:hover {
          background-color: transparent !important;
        }

        input {
          font-family: $globotipo-text !important;
          font-size: 0.88em !important;
        }

        ::placeholder {
          font-family: $globotipo-text !important;
          font-size: 1em !important;
          font-style: italic;
          font-weight: 400 !important;
          color: rgba($color-black, 0.56) !important;
          opacity: 1 !important;
        }
      }
    }
  }

  // Button Clear
  .search-clear {
    margin: 0 16px 0 0;

    &.v-btn {
      width: 32px !important;
      height: 32px !important;
      font-size: 0.72em !important;
    }
  }

  // Transformacao para telas menores
  @include for-size(tablet-landscape-up) {
    align-items: flex-start;
    position: relative;
    height: 116px !important;

    .search-input {
      margin: 0 0 8px 0 !important;
    }

    .search-tags {
      position: absolute;
      bottom: 0;
      left: -16px;
      width: calc(100% + 96px) !important;
      max-width: calc(100% + 96px) !important;
      height: 64px;
      box-sizing: border-box;

      &.--has-chips {
        padding: 8px 16px !important;
        border-top: solid 1px $color-border-divisor;
        border-right: none;
      }

      &.v-slide-group--is-overflowing {
        padding: 8px !important;
      }

      .v-chip {
        height: 32px !important;
      }
    }

    .search-clear {
      margin: 8px 8px 0 0;
    }
  }
}
</style>
