<template>
  <BaseCard>
    <CardHeader :title="headerTitle" :subtitle="headerSubTitle"> </CardHeader>

    <div class="box-body">
      <div class="column flex left">
        <div class="input-item full">
          <v-text-field
            :rules="rules.name"
            :value="formData.name"
            @change="updateItem($event, 'name')"
            required
            maxlength="255"
            color="graydark"
            label="Nome da Campanha"
            placeholder="Informe o nome da campanha *"
            outlined
            prepend-inner-icon="mdi-label"
          />
        </div>

        <div class="input-item full">
          <v-menu bottom origin="center center" transition="scale-transition">
          </v-menu>
          <v-autocomplete
            :value="formData.product_id"
            @change="updateProductId"
            required
            :rules="rules.product"
            :items="products"
            item-value="id"
            item-text="name"
            color="graydark"
            label="Grupo / Editoria"
            placeholder="Escolha apenas uma opção *"
            outlined
            prepend-inner-icon="mdi-ticket"
          ></v-autocomplete>
        </div>

        <div class="input-item full">
          <v-textarea
            color="graydark"
            label="Descrição"
            placeholder="Fale um pouco sobre sua campanha..."
            outlined
            no-resize
            height="84px"
            prepend-inner-icon="mdi-comment-text"
            :value="formData.description"
            @change="updateItem($event, 'description')"
          />
        </div>

        <div class="input-item full">
          <EnablerBox label="Ativar campanha">
            <template slot="enabler">
              <v-switch v-model="campaignEnabler" inset></v-switch>
            </template>
          </EnablerBox>
        </div>

        <div class="input-item full">
          <v-menu bottom origin="center center" transition="scale-transition">
          </v-menu>
          <PosterSelector
            ref="posterSelector"
            class="margin"
            labelName="Capa da campanha"
            placeholder="Pesquisar nome do programa..."
            @reset-delete-poster="deletePoster = !deletePoster"
            @reset-undo-delete-poster="undoDeletePoster = !undoDeletePoster"
            :triggerDeletePoster="deletePoster"
            :triggerUndoDeletePoster="undoDeletePoster"
            :value="formData.poster"
            @input="updateItem($event, 'poster')"
            :currentPosterUrl="campaignCurrentPoster"
          />
        </div>
      </div>

      <div class="column flex right">
        <div
          class="thumb"
          :class="{ 'no-image': !selectedPosterUrl }"
          :style="[bgImagePoster]"
        >
          <v-btn
            @click="undoDeletePoster = true"
            :class="{ 'btn--disabled': selectedPosterUrl }"
            color="white"
            icon
            tile
          >
            <v-icon title="desfazer excluir">loop</v-icon>
          </v-btn>
          <v-btn
            @click="deletePoster = true"
            :class="{ 'btn--disabled': !selectedPosterUrl }"
            color="white"
            icon
            tile
          >
            <v-icon title="excluir">delete_forever</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </BaseCard>
</template>
<script>
import { mapGetters, mapState } from "vuex";

import BaseCard from "@/components/Commons/BaseCard";
import CardHeader from "@/components/Commons/CardHeader";
import EnablerBox from "@/components/Commons/EnablerBox";
import PosterSelector from "@/components/Commons/PosterSelector";
import { updateCampaignItem } from "@/util/campaign";

export default {
  components: {
    BaseCard,
    CardHeader,
    EnablerBox,
    PosterSelector
  },
  data() {
    return {
      deletePoster: false,
      undoDeletePoster: false,
      headerTitle: "informações básicas",
      headerSubTitle: "Preencha os campos para identificar sua campanha.",
      messages: {
        required: "Este é um campo obrigatório!"
      }
    };
  },
  computed: {
    ...mapState({
      formData: state => state.listCampaign.createOrEditCampaignParams.formData,
      campaignCurrentPoster: state =>
        state.listCampaign.createOrEditCampaignParams.campaignCurrentPoster
    }),
    ...mapGetters({
      products: "createOrUpdateSortedProducts"
    }),
    bgImagePoster() {
      return this.selectedPosterUrl
        ? { backgroundImage: `url(${this.selectedPosterUrl})` }
        : {};
    },
    selectedPosterUrl() {
      return this.formData.poster && this.formData.poster.url
        ? this.formData.poster.url
        : null;
    },
    rules() {
      return {
        name: [name => !!name || this.messages.required],
        product: [product => !!product || this.messages.required]
      };
    },
    campaignEnabler: {
      get() {
        return this.formData.is_enabled;
      },
      set(value) {
        this.updateItem(value, "is_enabled");
      }
    }
  },
  methods: {
    updateItem(value, item) {
      updateCampaignItem(value, item);
    },

    updateProductId(value) {
      this.updateItem(value, "product_id");
      this.updateItem(
        {
          ...this.formData.whatsapp_configuration,
          id_associates: []
        },
        "whatsapp_configuration"
      );
      this.updateItem(
        {
          ...this.formData.instagram_configuration,
          instagram_ids: []
        },
        "instagram_configuration"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";
@import "@/scss/form-inputs.scss";

.box-body {
  display: flex;

  @include for-size(phone-only) {
    flex-direction: column;
    align-items: center;

    .right {
      margin: 20px 8px 0 0 !important;
      width: 100% !important;
    }
  }
}

.thumb {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 16px;
  width: 100%;
  height: 390px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  background-color: #333333;
  border-radius: 4px;

  &.no-image {
    background-image: url("../../assets/images/placeholder-cover.svg");
    background-color: transparent;
    background-size: cover;
  }

  .v-btn {
    border-radius: 4px;
    background: #222;
    margin-left: 8px;
  }

  .v-btn {
    border-radius: 4px;
    background: #222;
    margin-left: 8px;
  }
}
</style>
