<template>
  <BaseCard>
    <CardHeader :title="headerTitle" :subtitle="headerSubtitle" />

    <div class="box">
      <div class="box-body">
        <div class="column">
          <v-tooltip bottom :disabled="!whatsappToggleDisabled">
            <template v-slot:activator="{}">
              <EnablerBox
                :hasClick="true"
                label="Whatsapp"
                icon="fab fa-whatsapp"
                appendIcon="mdi-chevron-right"
                @enabler-was-clicked="openWhatsappDrawer"
              >
                <template slot="enabler">
                  <v-switch
                    @click.native.stop
                    @change="changeEnabler($event, 'whatsapp_configuration')"
                    :input-value="formData.whatsapp_configuration.is_enabled"
                    :disabled="whatsappToggleDisabled"
                    inset
                  />
                </template>
              </EnablerBox>
            </template>
            <span
              >Clique para configurar a captura de WhatsApp antes de tentar
              habilitá-la</span
            >
          </v-tooltip>
          <v-tooltip bottom :disabled="!twitterToggleDisabled">
            <template v-slot:activator="{}">
              <EnablerBox
                :hasClick="true"
                label="Twitter"
                icon="fab fa-twitter"
                appendIcon="mdi-chevron-right"
                @enabler-was-clicked="openTwitterDrawer"
              >
                <template slot="enabler">
                  <v-switch
                    @click.native.stop
                    @change="changeEnabler($event, 'twitter_configuration')"
                    :input-value="formData.twitter_configuration.is_enabled"
                    :disabled="twitterToggleDisabled"
                    inset
                  />
                </template>
              </EnablerBox>
            </template>
            <span
              >Clique para configurar a captura de Twitter antes de tentar
              habilitá-la</span
            >
          </v-tooltip>
          <v-tooltip bottom :disabled="!instagramToggleDisabled">
            <template v-slot:activator="{}">
              <EnablerBox
                :hasClick="true"
                label="Instagram"
                icon="mdi-instagram"
                appendIcon="mdi-chevron-right"
                @enabler-was-clicked="openInstagramDrawer"
              >
                <template slot="enabler">
                  <v-switch
                    @click.native.stop
                    @change="changeEnabler($event, 'instagram_configuration')"
                    :input-value="formData.instagram_configuration.is_enabled"
                    :disabled="instagramToggleDisabled"
                    inset
                  />
                </template>
              </EnablerBox>
            </template>
            <span
              >Clique para selecionar as credenciais de Instagram
              registradas</span
            >
          </v-tooltip>
          <v-tooltip bottom :disabled="!youtubeToggleDisabled">
            <template v-slot:activator="{}">
              <EnablerBox
                :hasClick="true"
                label="Youtube"
                icon="fab fa-youtube"
                appendIcon="mdi-chevron-right"
                @enabler-was-clicked="openYoutubeDrawer"
              >
                <template slot="enabler">
                  <v-switch
                    @click.native.stop
                    @change="changeEnabler($event, 'youtube_configuration')"
                    :input-value="formData.youtube_configuration.is_enabled"
                    :disabled="youtubeToggleDisabled"
                    inset
                  />
                </template>
              </EnablerBox>
            </template>
            <span
              >Clique para configurar a captura de Youtube antes de tentar
              habilitá-la</span
            >
          </v-tooltip>
          <EnablerBox
            :hasClick="true"
            label="Formulário"
            icon="mdi-page-next"
            appendIcon="mdi-chevron-right"
            @enabler-was-clicked="openFormDrawer"
          >
            <template slot="enabler">
              <v-switch
                @click.native.stop
                @change="changeEnabler($event, 'form_configuration')"
                :input-value="formData.form_configuration.is_enabled"
                inset
              />
            </template>
          </EnablerBox>
          <v-tooltip bottom>
            <template v-slot:activator="{}">
              <EnablerBox
                :hasClick="true"
                label="Uploader"
                icon="icon-icon-uploader"
                appendIcon="mdi-chevron-right"
                @enabler-was-clicked="openUploaderDrawer"
              >
                <template slot="enabler">
                  <v-switch
                    :disabled="true"
                    :input-value="
                      formData.uploader_configuration.is_enabled ||
                        formData.uploader_web_configuration.is_enabled
                    "
                    inset
                  />
                </template>
              </EnablerBox>
            </template>
            <span
              >Clique para habilitar o Uploader Web e/ou o Uploader
              Desktop</span
            >
          </v-tooltip>
        </div>
      </div>

      <div class="divisor"></div>
      <div class="box-body align-center">
        <div class="column flex left align-center">
          <div class="title--small">Bloqueio de Termos</div>
        </div>

        <!-- <div class="column flex right">
          <ButtonLink
            @click-handler="manageGroupsOfTerms.clickHandler"
            :icon="manageGroupsOfTerms.icon"
            :label="manageGroupsOfTerms.label"
          ></ButtonLink>
        </div> -->
      </div>
      <div class="column full">
        <div class="input-item full">
          <v-combobox
            @change="updateBlockedTerms($event)"
            :value="blockedWords"
            deletable-chips
            multiple
            outlined
            clearable
            label="Clique aqui para adicionar novos termos…"
            color="graydark"
            prepend-inner-icon="speaker_notes_off"
            append-icon
          >
            <template v-slot:selection="{ attrs, item, selected, parent }">
              <v-chip
                label
                v-bind="attrs"
                :input-value="selected"
                close
                @click:close="parent.selectItem(item)"
                >{{ item }}
              </v-chip>
            </template>
            <template slot="append">
              <div class="append-icon-box">
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      class="btn-icon"
                      v-on="on"
                      @click="toggleDialog(!isDialogVisible)"
                      >mdi-book-open-variant</v-icon
                    >
                  </template>
                  <span> Copiar termos de outra campanha </span>
                </v-tooltip>
              </div>
              <div class="append-icon-box ml-2">
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-information-outline</v-icon>
                  </template>
                  <span>
                    <strong>Dica</strong>
                    <br />
                    Este bloqueio será aplicado para as entradas de Whatsapp,
                    Formulário e Twitter.
                    <br />
                  </span>
                </v-tooltip>
              </div>
            </template>
          </v-combobox>
        </div>
      </div>
      <Dialog
        :dialog="isDialogVisible"
        title="Copiar de campanha"
        subtitle="Selecione uma campanha para copiar as palavras bloqueadas configuradas na mesma"
        @toggle-dialog="toggleDialog($event)"
      >
        <template v-slot:content>
          <v-autocomplete
            v-model="selectedCampaigns"
            :items="selectableCampaigns"
            :item-text="campaign => campaign.name"
            item-value="id"
            chips
            color="graydark"
            class="mt-5"
            label="Campanhas"
            placeholder="Clique para adicionar uma campanha"
            outlined
            @update:search-input="updateBlockedWordsDialogSearchTerm"
            hide-details
            disable-lookup
            allow-overflow
          >
            <template v-slot:prepend-item>
              <v-list-item class="v-list-item--top">
                Escolha apenas uma opção
                <span>{{ blockedWordsDialogItemsLength }} disponíveis</span>
              </v-list-item>
            </template>
          </v-autocomplete>
        </template>
        <template v-slot:modal-actions>
          <ButtonSecondary
            icon="mdi-close-circle-outline"
            label="Cancelar"
            @click-handler="closeDialog"
          ></ButtonSecondary>
          <ButtonPrimary
            class="ml-4"
            icon="mdi-content-copy"
            label="Copiar"
            @click-handler="copyBlockedWordsFromSelectedCampaign"
          ></ButtonPrimary>
        </template>
      </Dialog>
    </div>
  </BaseCard>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import BaseCard from "@/components/Commons/BaseCard";
import ButtonPrimary from "@/components/Commons/Buttons/ButtonPrimary.vue";
import ButtonSecondary from "@/components/Commons/Buttons/ButtonSecondary.vue";
import CardHeader from "@/components/Commons/CardHeader";
import Dialog from "@/components/Commons/Dialogs/Dialog";
import EnablerBox from "@/components/Commons/EnablerBox";
import { Campaign } from "@/gateways";
import {
  LC_DATA_LOAD,
  UI_FORM_DRAWER_HANDLE,
  UI_INSTAGRAM_DRAWER_HANDLE,
  UI_TWITTER_DRAWER_HANDLE,
  UI_UPLOADER_DRAWER_HANDLE,
  UI_WHATSAPP_DRAWER_HANDLE,
  UI_YOUTUBE_DRAWER_HANDLE
} from "@/store/actions";
import { updateCampaignItem, updateCampaignNestedItem } from "@/util/campaign";
export default {
  components: {
    BaseCard,
    ButtonPrimary,
    ButtonSecondary,
    CardHeader,
    Dialog,
    EnablerBox
  },
  props: {
    currentCampaignId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      blockedWordsDialogItemsLength: 0,
      selectableCampaigns: [],
      selectedCampaigns: null,
      headerTitle: "captura",
      headerSubtitle:
        "Configure os canais de entrada de conteúdo para sua campanha",
      isDialogVisible: false
      /* manageGroupsOfTerms: {
        label: "Gerenciar Grupos de Termos",
        icon: "trending_flat",
        clickHandler: this.toggleDrawer
      }, */
    };
  },
  computed: {
    ...mapState({
      formData: state => state.listCampaign.createOrEditCampaignParams.formData
    }),
    ...mapGetters(["campaigns"]),
    blockedWords() {
      return [
        ...new Set([
          ...this.formData.whatsapp_configuration.blocked_words,
          ...this.formData.twitter_blacklist,
          ...this.formData.form_configuration.blocked_words
        ])
      ];
    },
    instagramToggleDisabled() {
      const disableToggle =
        this.formData.instagram_configuration.instagram_credentials?.length ===
        0;
      if (disableToggle) {
        updateCampaignNestedItem(
          false,
          "instagram_configuration",
          "is_enabled"
        );
      }
      return disableToggle;
    },
    whatsappToggleDisabled() {
      const disableToggle =
        this.formData.whatsapp_configuration.id_associates.length === 0;

      if (disableToggle) {
        updateCampaignNestedItem(false, "whatsapp_configuration", "is_enabled");
      }
      return disableToggle;
    },

    twitterToggleDisabled() {
      const disableToggle = this.formData.twitter_terms.length === 0;
      if (disableToggle) {
        updateCampaignNestedItem(false, "twitter_configuration", "is_enabled");
      }
      return disableToggle;
    },

    youtubeToggleDisabled() {
      const disableToggle =
        this.formData.youtube_configuration.channels.length === 0 &&
        this.formData.youtube_configuration.users.length === 0;
      if (disableToggle) {
        updateCampaignNestedItem(false, "youtube_configuration", "is_enabled");
      }
      return disableToggle;
    }
  },
  created() {
    if (!this.campaigns.length) {
      this.loadCampaigns();
    }
    this.setCampaigns();
  },
  watch: {
    campaigns() {
      this.setCampaigns();
    }
  },
  methods: {
    setCampaigns() {
      this.selectableCampaigns = this.campaigns.filter(
        c => c.id !== this.currentCampaignId
      );
    },
    updateBlockedWordsDialogSearchTerm(value) {
      if (!value || value.length === 0) {
        this.blockedWordsDialogItemsLength = this.selectableCampaigns.length;
        return;
      }
      this.blockedWordsDialogItemsLength = this.selectableCampaigns
        .map(campaign =>
          campaign.name.toLowerCase().includes(value?.toLowerCase())
        )
        .reduce((a, b) => a + b, 0);
    },
    loadCampaigns() {
      this.$store.dispatch(LC_DATA_LOAD);
    },
    changeEnabler(value, item) {
      updateCampaignNestedItem(value, item, "is_enabled");
    },
    updateBlockedTerms(value) {
      const newValue = value.map(v => v.trim());
      updateCampaignNestedItem(
        newValue,
        "whatsapp_configuration",
        "blocked_words"
      );
      updateCampaignNestedItem(newValue, "form_configuration", "blocked_words");
      updateCampaignItem(newValue, "twitter_blacklist");
    },
    openWhatsappDrawer() {
      this.$store.dispatch(UI_WHATSAPP_DRAWER_HANDLE, true);
    },
    openTwitterDrawer() {
      this.$store.dispatch(UI_TWITTER_DRAWER_HANDLE, true);
    },
    openYoutubeDrawer() {
      this.$store.dispatch(UI_YOUTUBE_DRAWER_HANDLE, true);
    },
    openInstagramDrawer() {
      this.$store.dispatch(UI_INSTAGRAM_DRAWER_HANDLE, true);
    },
    openFormDrawer() {
      this.$store.dispatch(UI_FORM_DRAWER_HANDLE, true);
    },
    openUploaderDrawer() {
      this.$store.dispatch(UI_UPLOADER_DRAWER_HANDLE, true);
    },
    toggleDialog(value) {
      this.isDialogVisible = value;
    },
    copyBlockedWordsFromSelectedCampaign() {
      Campaign.get(this.selectedCampaigns).then(({ data: campaign }) => {
        this.updateBlockedTerms([
          ...new Set([
            ...this.formData.whatsapp_configuration.blocked_words,
            ...this.formData.twitter_blacklist,
            ...campaign.whatsapp_configuration.blocked_words,
            ...campaign.twitter.blacklist
          ])
        ]);
      });

      this.closeDialog();
    },
    closeDialog() {
      this.isDialogVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";
@import "@/scss/form-inputs.scss";

.box {
  font-family: $secondary-fontface;
  background-color: #fff;

  &.full {
    width: 100%;
  }

  .v-icon {
    font-size: 16px;
  }

  ::v-deep .mdi-close::before {
    padding-right: 12px;
  }

  ::v-deep .v-select__slot .v-input__append-inner:nth-child(3) {
    border: 0 !important;
  }

  .append-icon-box {
    height: 24px;
    display: flex;
    align-items: center;

    .btn-icon {
      cursor: pointer;
    }
  }

  .box-title {
    margin: 0 0 8px 0;
    font-size: 0.88em;
    font-weight: 600;
    color: $color-black-80;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  .box-subtitle {
    margin: 0 0 16px 0;
    font-size: 0.8em;
    font-weight: 400;
    color: $color-black-64;
    text-transform: capitalize;
    letter-spacing: 0.1em;
  }

  .divisor {
    flex: 1;
    height: 1px;
    margin: 16px 0;
    // background-color: $color-black-08;
  }

  .box-description {
    font-size: 1em;
    font-weight: 400;
    color: $color-black-80;
  }

  .box-body {
    display: flex;
  }

  ::v-deep .mdi-close::before {
    font-size: 16px;
  }
}
::v-deep .v-select__selections {
  margin-top: 0 !important;
  .v-chip {
    background-color: $color-primary-chip !important;
  }
}
</style>
