<template>
  <v-main>
    <transition name="component-fade" mode="out-in">
      <component
        :is="view"
        :editItem="editItemData"
        :reauthenticateItem="reauthenticateItemData"
        v-on:cancelClickedEvent="
          showList = true;
          editItemData = false;
          drawer = false;
        "
        v-on:createClickedEvent="
          showList = false;
          editItemData = false;
          reauthenticateItemData = false;
        "
        v-on:editClickedEvent="
          instagram => {
            showList = false;
            editItemData = instagram;
            reauthenticateItemData = false;
          }
        "
        v-on:reauthenticateClickedEvent="
          instagram => {
            showList = false;
            editItemData = false;
            reauthenticateItemData = instagram;
          }
        "
        v-on:cancelCreateClickedEvent="
          showList = true;
          editItemData = false;
          reauthenticateItemData = false;
        "
        v-on:itemRegisteredEvent="
          showList = true;
          editItemData = false;
          reauthenticateItemData = false;
        "
        v-on:itemEditedEvent="
          showList = true;
          editItemData = false;
          reauthenticateItemData = false;
        "
      />
    </transition>
  </v-main>
</template>

<script>
import tabMixin from "../../../mixins/tabMixin";
import InstagramCreation from "./InstagramCreation";
import InstagramList from "./InstagramList";

export default {
  mixins: [tabMixin],
  components: {
    InstagramList,
    InstagramCreation
  },
  computed: {
    view() {
      return this.showList ? InstagramList : InstagramCreation;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/tab.scss";
</style>
