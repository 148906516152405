<template>
  <BaseCard>
    <CardHeader
      title="METADADOS"
      subtitle="Configure campos específicos para sua campanha (Formulário ou Uploader)"
    />
    <v-btn
      color="primary"
      dark
      text
      large
      class="mb-2"
      :disabled="disabled"
      @click="createItem"
    >
      NOVO CAMPO
      <v-icon class="pl-2">mdi-plus-circle</v-icon>
    </v-btn>
    <v-data-table
      class="metadata-table"
      :headers="metadataHeaders"
      :items="metadataArray"
      disable-sort
      item-key="field"
      show-select
    >
      <template v-slot:header.data-table-select="{}"> </template>
      <template v-slot:item.data-table-select="{}">
        <v-icon class="metadata-item-handle">mdi-drag</v-icon>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              v-on="on"
              :disabled="disabled"
              >edit</v-icon
            >
          </template>
          <span>Editar campo</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              class="mr-2"
              @click="softDelete(item)"
              v-on="on"
              :disabled="disabled"
              >delete</v-icon
            >
          </template>
          <span>Remover campo</span>
        </v-tooltip>
      </template>
      <template v-slot:item.required="{ item }">
        <span>{{ item.required ? "Sim" : "Não" }}</span>
      </template>
    </v-data-table>
    <Dialog
      :dialog="isModalVisible"
      title="Excluir metadado"
      subtitle="Ao prosseguir o metadado será excluído, confirma a exclusão?"
      @toggle-dialog="toggleDialog($event)"
    >
      <template v-slot:modal-actions>
        <ButtonSecondary
          icon="mdi-close-circle-outline"
          label="Cancelar"
          @click-handler="cancelDeleteOperation"
        ></ButtonSecondary>
        <ButtonPrimary
          color="reprove"
          class="ml-4"
          icon="mdi-delete-outline"
          label="Excluir"
          @click-handler="hardDelete"
        ></ButtonPrimary>
      </template>
    </Dialog>
  </BaseCard>
</template>

<script>
import Sortable from "sortablejs";
import { mapState } from "vuex";

import BaseCard from "@/components/Commons/BaseCard";
import ButtonPrimary from "@/components/Commons/Buttons/ButtonPrimary";
import ButtonSecondary from "@/components/Commons/Buttons/ButtonSecondary";
import CardHeader from "@/components/Commons/CardHeader";
import Dialog from "@/components/Commons/Dialogs/Dialog";
import { UI_METADATA_DRAWER_HANDLE } from "@/store/actions";
import { UI_SNACKBAR_SHOW } from "@/store/actions";
import { updateCampaignItem } from "@/util/campaign";

export default {
  components: { BaseCard, ButtonPrimary, ButtonSecondary, CardHeader, Dialog },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      metadataHeaders: [
        {
          text: "Nome do campo",
          align: "start",
          value: "field"
        },
        { text: "Tipo de dado", value: "data_type" },
        { text: "Obrigatório", value: "required", align: "center" },
        { text: "Máscara", value: "mask", align: "center" },
        { text: "Ações", value: "action", align: "right", sortable: false }
      ],
      isModalVisible: false,
      newMetadataArray: {}
    };
  },
  computed: {
    ...mapState({
      metadataArray: state =>
        state.listCampaign.createOrEditCampaignParams.formData.metadata
    })
  },

  mounted() {
    const _this = this;
    const options = {
      onEnd: function({ oldIndex, newIndex }) {
        const newMetadataArray = [..._this.metadataArray];
        const movedItem = newMetadataArray.splice(oldIndex, 1)[0];
        newMetadataArray.splice(newIndex, 0, movedItem);
        updateCampaignItem(newMetadataArray, "metadata");
      },
      animation: 150,
      handle: ".metadata-item-handle"
    };
    const table = document.querySelector(".metadata-table tbody");
    Sortable.create(table, options);
  },

  methods: {
    sendFeedbackMessage(action) {
      const feedbackText =
        action === "cancel"
          ? "Operação cancelada, nenhuma modificação foi aplicada."
          : "Item excluído com sucesso!";
      this.$store.dispatch(UI_SNACKBAR_SHOW, {
        message: feedbackText
      });
    },
    closeModal() {
      this.isModalVisible = false;
    },
    openModal() {
      this.isModalVisible = true;
    },
    cancelDeleteOperation() {
      this.closeModal();
      this.sendFeedbackMessage("cancel");
    },
    softDelete(item) {
      this.newMetadataArray = [...this.metadataArray];
      item = this.newMetadataArray.indexOf(item);
      this.newMetadataArray.splice(item, 1);
      this.openModal();
    },
    hardDelete() {
      updateCampaignItem(this.newMetadataArray, "metadata");
      this.closeModal();
      this.sendFeedbackMessage("delete");
    },
    createItem() {
      this.$emit("clean-current-metadata");
      this.openDrawerInputSourceMetadata();
    },
    editItem(item) {
      this.$emit("set-current-metadata", item);
      this.openDrawerInputSourceMetadata();
    },
    openDrawerInputSourceMetadata() {
      this.$store.dispatch(UI_METADATA_DRAWER_HANDLE, true);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/theme.scss";
@import "@/scss/form-inputs.scss";

::v-deep .v-toolbar__content {
  padding-left: 0 !important;
}
</style>
