<template>
  <v-dialog :value="showDialog" max-width="520" @input="updateDialog">
    <v-card>
      <v-card-title class="headline">Reenviar para saída</v-card-title>
      <v-card-text>
        Selecione as saídas para reenviar a colaboração
        <br />
      </v-card-text>
      <v-card-text>
        <v-autocomplete
          v-model="outputsSelected"
          filled
          hide-details
          draggable
          deletable-chips
          multiple
          color="primary"
          placeholder="Escolha as saídas"
          :item-text="i => `${i.name}`"
          item-value="id"
          class="textfield"
          :items="outputs"
        >
        </v-autocomplete>
      </v-card-text>
      <v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn text color="primary" x-large @click="close">
            <v-icon right dark>mdi-close</v-icon>Fechar
          </v-btn>
          <v-btn
            color="primary"
            x-large
            :disabled="outputsSelected.length == 0"
            @click="sendData"
          >
            <v-icon right dark>content_copy</v-icon>Reenviar
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";

import { Campaign, Output } from "../../../gateways";

export default {
  props: ["value", "collaborationId"],
  data() {
    return {
      outputs: [],
      outputsSelected: []
    };
  },

  computed: {
    ...mapState({
      idCampaign: state => state.campaign.campaign.id
    }),
    showDialog() {
      return this.value;
    }
  },
  async mounted() {
    let response = await Output.get();
    this.outputs = response.data;
  },
  methods: {
    sendData() {
      let data = {
        output_ids: [...this.outputsSelected],
        campaign_id: this.idCampaign
      };
      let message;
      try {
        Campaign.resendCollaboration(this.collaborationId, data);
        message = "Colaboração reenviada com sucesso ;)";
      } catch {
        message = "Não foi possível reenviar a colaboração :(";
      } finally {
        this.$store.dispatch("UI_SNACKBAR_SHOW", {
          message: message
        });
        this.close();
      }
    },
    close() {
      this.updateDialog(false);
      this.outputsSelected = [];
    },
    updateDialog(value) {
      this.$emit("input", value);
    }
  }
};
</script>
